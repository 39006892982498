import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import PropertyApiService from "../../../../data/api/services/property/property-api-service";
import './add-professionalpage-service-area.css';
import { FaSearch } from "react-icons/fa";
import FormFieldError from "../../../../components/form-field-error/form-field-error";
import ProfessionalPageServiceAreaImage from '../../../../assets/professional-page-3.jpeg';

const AddProfessionalPageServiceArea = ({ professionalPageFormData, onPreviousCallback, onNextCallback }) => {
    const tabNumber: number = 3;
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const propertyApiService = new PropertyApiService();
    const [locationData, setLocationData] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);

    const preview = () => {
        onPreviousCallback(tabNumber - 1)
    }

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        formatLocationData(data);
    };

    const formatLocationData = (data: any) => {
        formatLocationDataResponse(data);
        setLocationData(data);
    }

    const formatLocationDataResponse = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            data[i].nameLabel = data[i].name + ' | ' + data[i].pinCode + ' | ' + data[i].postOfficeName+ ' | ' + data[i].districtName + ' | '+ data[i].stateName;
        }
    }
    const highlightServiceAreaFormData = () => {
        let formData: FormData = professionalPageFormData;
        if (!formData.has('serviceAreasStr')) {
            return;
        }
        let pageServiceAreas: any[] = JSON.parse(formData.get('serviceAreasStr').toString());
        if (selectedLocations.length === 0) {
            setSelectedLocations([...pageServiceAreas]);
        }
    }

    const onLocationSelected = (selected: any) => {
        setSelectedLocations((prevLocations: any[]) => {
            const updatedLocations = [...prevLocations, ...selected];
            const uniqueLocations = Array.from(new Set(updatedLocations.map(location => location.id)))
                .map(id => updatedLocations.find(location => location.id === id));

            return uniqueLocations;
        });
    };

    const validateAndSaveServiceAreaFormData = () => {
        try {
            if (professionalPageFormData.has('serviceAreasStr')) {
                professionalPageFormData.delete('serviceAreasStr');
            }
            professionalPageFormData.append('serviceAreasStr', JSON.stringify(selectedLocations));
            let serviceAreasStr = JSON.stringify(selectedLocations);
            console.log('serviceAreasStr', serviceAreasStr);
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            throw error;
        }
    }

    const removeLocation = (location: { id: any; }) => {
        setSelectedLocations((prevLocations: any[]) =>
            prevLocations.filter((prevLocation) => prevLocation.id !== location.id)
        );
    };

    useEffect(() => {
        highlightServiceAreaFormData();
    }, []);

    return <>
        <div className="container boder-sy  my-4">
            <div className="row d-flex justify-content-center">
                <div className="col-sm-1 col-lg-1text-center mt-5">
                    <span className=""> <ArrowBackIcon onClick={preview} /></span>
                </div>
                <div className="col-sm-5 col-lg-5 d-flex align-self-center pt-4">
                    <img src={ProfessionalPageServiceAreaImage} className='service_area_image' alt="google-bussiness" ></img>
                </div>
                <div className="col-sm-7 col-lg-6 mt-3">
                    <section className="p-0 pt-4">
                        <div className="row">
                            <div className="col-sm-10 col-lg-12 d-flex LKfzVb mt-2">
                                <h3 className="text-center">Where do you serve your customers? (optional)</h3>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Add areas where your business provides deliveries or home and office visits. This will appear on your Business Profile.</span>

                        </div>

                        <div className="below-text mt-5">
                            <div className="search-input-container">
                                <div className="search-icon pb-3">
                                    <FaSearch />
                                </div>
                                <div className="col-sm-7 input100">
                                    <AsyncTypeahead
                                        className={` test ${locationValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                        id="basic-typeahead-multi"
                                        labelKey="nameLabel"
                                        onSearch={(e: any) => searchLocation(e)}
                                        options={locationData}
                                        onChange={onLocationSelected}
                                        useCache={false}
                                        placeholder="Search Locations"
                                        multiple
                                        isLoading={locationSearchLoading} />
                                    <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                                </div>
                            </div>
                            <div className="selected-locations">
                                {selectedLocations.map((location) => (
                                    <div key={location.id} className="location-chip">
                                        {location.nameLabel}
                                        <span className="remove-icon" onClick={() => removeLocation(location)}>
                                            x
                                        </span>
                                    </div>
                                ))}
                            </div>

                            <hr className="divider-line" />
                        </div>
                        <div className="text-end p-3">
                            <button className="btn btn-success" onClick={validateAndSaveServiceAreaFormData} type="button">Next</button>
                            {/* {(processing) && <Spinner animation="grow" variant="warning" />}              */}
                        </div>
                        <div className="text-center">
                        </div>
                    </section>

                </div>
            </div>
        </div>
        <div>
        </div>
    </>

}
export default AddProfessionalPageServiceArea;