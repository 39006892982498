import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import AppRouter from "./router/app-router";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Helmet } from "react-helmet";

const App = () => {

  return <>
    <Helmet>
      <script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "ki3nfadoa1");`}
      </script>
      <script>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '419167380617255');
        fbq('track', 'PageView');`}
      </script>
    </Helmet>
    <noscript>
      <img height="1" width="1" style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=419167380617255&ev=PageView&noscript=1" />
    </noscript>
    <ToastContainer enableMultiContainer containerId={'TR'} position={toast.POSITION.TOP_RIGHT} />
    <ToastContainer enableMultiContainer containerId={'BC'} position={toast.POSITION.BOTTOM_CENTER} />
    <AppRouter />
  </>
}
export default App;
