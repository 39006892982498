import { useState, useEffect } from "react";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import "./change-password.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Footer, Header } from "../../layouts";
import Spinner from "react-bootstrap/esm/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { UpdatePayload } from "../../data/api/services/register/signup-payload";

const Changepassword = () => {

  const authService: AuthApiService = new AuthApiService();
  const [processing, setProcessing] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const location = useLocation();
  const [data, setData] = useState<any>([]);
  const [coupenCode, setCoupenCode] = useState("");
  let navigate: any = useNavigate();
  const userId = location?.state;
  const [newPasswordValidationError, setNewPasswordNoValidationError] = useState(false);
  const [confirmPasswordValidationError, setConfirmPasswordValidationError] = useState(false);

  const handlePasswordChange = (value: any) => {
    setPassword(value);
    setNewPasswordNoValidationError(false);
  };

  const handleConfirmPasswordChange = (value: any) => {
    setConfirmPassword(value);
    setConfirmPasswordValidationError(false);
  };

  const getUser = async () => {
    try {
      let url = userId;
      let res = await authService.getUser(url.userId);
      console.log('dataaaaaa=>', res);
      setData(res)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const validatePassword = () => {
    let isFormValid = true;
    if (!password) {
      setNewPasswordNoValidationError(true);
      isFormValid = false;
    }
    if (!confirmPassword) {
      setConfirmPasswordValidationError(true);
      isFormValid = false;
    }
    if (password != confirmPassword) {
      toast.error("Password does not match", { containerId: 'TR' });
      isFormValid = false;
    }
    return isFormValid;
  }

  const updatePassword = async () => {
    try {
      if (validatePassword()) {
        let url = data.id;
        let payload: UpdatePayload = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobile: data.mobile,
          password: password,
          userType: data.roleCode,
          locationId: data.locationId,
          membershipBenefitMapperId: data.membershipBebefitMapperId || "",
          amount: "",
          promoCode: coupenCode
        };
        await authService.updatePassword(url, payload);
        toast.success('Password updated Successfully.', { containerId: 'TR' });
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="d-flex justify-content-center align-items-center my-5 pt-5">
        <div className="card h-80 m-3 col-lg-4">
          <div className="card-body">
            <h5 className="card-title login-titles">  <FontAwesomeIcon icon={faLock} /> Reset Password</h5>
            <form className="row g-3">
              <div className="col-md-12">
                <label>Enter New Password</label>
                <input type="password"
                  className={`input-with-icon form-control  ${newPasswordValidationError ? 'is-invalid' : ''}`}
                  value={password} onChange={e => { handlePasswordChange(e.target.value) }} placeholder="New Password" name="password" />
                {newPasswordValidationError && <div className="invalid-feedback">New Password required</div>}
              </div>
              <div className="col-md-12">
                <label>Enter Confirm Password</label>
                <input type="password"
                  className={`input-with-icon form-control  ${confirmPasswordValidationError ? 'is-invalid' : ''}`}
                  value={confirmPassword} onChange={e => { handleConfirmPasswordChange(e.target.value) }} placeholder="Confirm Password" name="password_confirmation" />
                {confirmPasswordValidationError && <div className="invalid-feedback">Confirm Password required</div>}
              </div>
              <div className="form-group">
                <button disabled={processing}
                  type="button"
                  className="btn btn-md w-100 p-3 rounded login-btn"
                  onClick={updatePassword}
                >
                  Change Password
                  <span style={{
                    position: 'relative',
                    top: '8px',
                    right: '37px',
                  }}>
                    {processing && <Spinner animation="grow" variant="warning" />}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Changepassword;