import HttpClientWrapper from "../../http-client-wrapper";

class BenefitApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getMembershipTypes = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/picklists/memberShip');
            console.log("PropertyTypeApi getPropertyTypes() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getMemberShipPlans = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/memberShipPlans');
            console.log("PropertyTypeApi getPropertyTypes() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getTemplateData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/memberShipPlans/templates');
            console.log("PropertyTypeApi getPropertyTypes() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    getSubscriptionByPersonId = async (personId: any) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/subscription/getSubscriptionByPersonId/' + personId);
            return data;
        } catch (error) {
            throw error;
        }
    }
}
export default BenefitApiService;