import "./view-profession-post-tag.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import React from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useRef } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ProfessionPostReport from "../../profession/profession-post-report/profession-post-report";
import EditProfessionPost from "../../profession/edit-profession-post/edit-profession-post";
import back from "../../../assets/back.png"

import { faBookmark, faEdit, faFlag, faTrash } from '@fortawesome/free-solid-svg-icons';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Col, Modal } from "react-bootstrap";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { CommentLikePayload, CommentReplyLikePayload, FavouritePostPayload, PagePostCommentPayload, PagePostCommentReplyPayload, PagePostLikePayload, PagePostTagViewPayload } from "../../../data/api/services/professional-page/professional-page-payload";

import { Tooltip } from 'react-tooltip'
import StorageService from "../../../data/storage/storage-service";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { toast } from "react-toastify";
import { PagePostTagFollowPayload } from "../../../data/api/services/professional-page/professional-page-payload";
import { Header } from "../../../layouts";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Figure } from "react-bootstrap";
import person from "../../../assets/profile-pic.jpeg"
import crown from "../../../assets/crown.png"
import important from "../../../assets/important.png"
import DefaultImage from "../../../assets/tag.png"

import TuneIcon from '@mui/icons-material/Tune';
import { Carousel } from "react-bootstrap";
import RecommendIcon from '@mui/icons-material/Recommend';
import ProfessionPostShare from "../profession-post-share/profession-post-share";
import TagPostSearch from "../tag-post-search/tag-post-search";

const ViewProfessionPostTag = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const PostTagId = new URLSearchParams(location.search).get('tagId');
    const rank = new URLSearchParams(location.search).get('rank');
    const [showContent, setShowContent] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [isView, setIsView] = useState(false);

    const handleButtonClick = () => {
        setShowContent(prevShowContent => !prevShowContent);
    };
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [postId, setPostId] = useState<any>([]);
    const [isSaved, setIsSaved] = useState([]);
    const [reportData, setReportData] = useState<any>([]);
    const [reportShow, setReportShow] = useState(false);
    const reportClose = () => setReportShow(false);
    const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
    const [tagSearchLoading, setTagSearchLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const userId = StorageService.getUserId();
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [recent, setRecent] = useState(' ');
    const [moreLikes, setMoreLikes] = useState(' ');
    const [moreComments, setMoreComments] = useState(' ');
    const [moreShares, setMoreShares] = useState(' ');




    const [likedPosts, setLikedPosts] = useState([]);
    const [shareData, setShareData] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>([]);

    const [shareShow, setShareShow] = useState(false);

    const shareClose = () => setShareShow(false);

    const [professionalPagePostTags, setProfessionalPagePostTags] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10000000);
    const [isExpanded, setIsExpanded] = useState([]);
    const [editShow, setEditShow] = useState(false);
    const [filterShow, setFilterShow] = useState(false);

    const editHandleClose = () => setEditShow(false);
    const handleReadMore = (index) => {
        setIsExpanded((prev) => {
            const newExpandedState = [...prev];
            newExpandedState[index] = !newExpandedState[index];
            return newExpandedState;
        });
    };
    const propertyApiService = new PropertyApiService();
    const professionalPageApiService = new ProfessionalPageApiService();
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any[]>([]);
    const [tagData, setTagData] = useState([]);
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [editData, setEditData] = useState<any>([]);

    const [selectedTagId, setSelectedTagId] = useState('');
    const [selectedTagName, setSelectedTagName] = useState('');
    const [selectedParticularTagName, setSelectedParticularTagName] = useState('');
    const [totalRecord, setTotalRecord] = useState(0);
    const [showExtraFilters, setShowExtraFilters] = useState(false);
    const handlePlayIconClick = (e, imageDataId) => {
        e.stopPropagation();
        if (videoRefs.current[imageDataId]) {
            videoRefs.current[imageDataId].play();
        }
    };

    const savePageView = async (userPageId: any) => {
        setIsView((prevIsView) => !prevIsView);

        try {
            let payload: PagePostTagViewPayload = {
                viewTagId: PostTagId,
                userPageId: userPageId
            };
            await professionalPageApiService.savePagePostTagView(payload);
            getProfessionalPageDetails();
        } catch (error) {
            console.log(error)
        }
    }
    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            const res = await professionalPageApiService.getProfessionalPageData(userId);
            setUserPageData(res);

            // Perform other necessary operations first
            const activePageItem = res.find(item => item.activePage === true);
            const activeUserPageId = activePageItem?.id;
            getProfessionalPageById(activeUserPageId);
            getPageData(currentPage, pageSize);

            // Call savePageView last if PostTagId is available
            if (PostTagId) {
                await savePageView(activeUserPageId);
            }

        } catch (error) {
            console.log(error);
        }
    };
    const formattedDate = (dateString) => {
        const date = new Date(dateString);
        const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours and 30 minutes
        const istDate = new Date(date.getTime() + istOffset);
        console.log('istDate:', istDate)
        return istDate.toLocaleString('en-US', {
            timeZone: 'Asia/Kolkata',
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };
    const handleIsSavedClick = (item: any, index: any) => {
        setIsSaved((prevIsSaved) => {
            const newIsSaved = [...prevIsSaved];
            newIsSaved[index] = !newIsSaved[index];
            return newIsSaved;
        });
        saveFavouritePost(item, index);
    };
    const saveFavouritePost = async (item: any, index: any) => {
        try {
            let payload: FavouritePostPayload = {
                userPageId: userPageId,
                pagePostId: item.id
            };
            await professionalPageApiService.saveFavouritePost(payload);
            getProfessionalPageDetails();
        } catch (error) {
            console.log(error)
        }
    }
    const getProfessionalPageById = async (userPageId: string) => {
        try {
            let res = await professionalPageApiService.getProfessionalPageById(userPageId);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    };
    const handleCommentIconClick = (item, index) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            showLoggedInConfirmDialog();
        } else {
            // setCommentReply([]);
            // setVisibleReplies([]);
            // setShowCommentSection(showCommentSection === index ? null : index);
            // getProfessionalPagePostDataList(userPageId);
            // getProfessionalPageById(props.pageId, item.professionalPageId);
        }
    };
    const handleLikeClickIcon = (item, index) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            showLoggedInConfirmDialog();
        } else {
            handleLikeClick(item, index);
        }
    };
    const closeSubMenu = (e) => {
        if (selectedItemIndex !== null && !e.target.closest('.dropdown-content')) {
            setSelectedItemIndex(null);
        }
        // if (selectedItemCommentIndex !== null && !e.target.closest('.dropdown-content')) {
        //     setSelectedItemCommentIndex(null);
        // }
        // if (selectedItemReplyIndex !== null && !e.target.closest('.dropdown-content')) {
        //     setSelectedItemReplyIndex(null);
        // }
    };
    useEffect(() => {
        document.addEventListener('click', closeSubMenu);
        return () => {
            document.removeEventListener('click', closeSubMenu);
        };
    }, [isDropdownVisible]);

    const handleLikeClick = (item, index) => {
        setLikedPosts((prevLikedPosts) => {
            const newLikedPosts = [...prevLikedPosts];
            newLikedPosts[index] = !newLikedPosts[index];
            return newLikedPosts;
        });
        saveProfessionalPagePostLike(item, index);
    };
    const isLikedByUser = (item) => {
        const likedByUser = item.professionalPagePostLikeData.some((likeData) => likeData.likedByPageId === userPageId);
        return likedByUser;
    };
    const handleReport = (item) => {
        setReportData(item);
        setReportShow(true);
        setSelectedItemIndex(null);
    };

    const saveProfessionalPagePostLike = async (item, index) => {
        try {
            let payload: PagePostLikePayload = {
                postId: item.id,
                likedByPageId: userPageId
            };
            await professionalPageApiService.saveProfessionalPagePostLike(payload);
            getProfessionalPageDetails();

        } catch (error) {
            console.log(error)
        }
    }

    const getPageData = async (page, size) => {
        try {
            let url = `?page=${page}&pageSize=${size}&id=${PostTagId}`;
            // let result = await professionalPageApiService.getAllPostData(url);
            let result = await professionalPageApiService.getAllTagData(url);
            console.log("sssssssssssssssssssssssssssssss", result);
            setProfessionalPagePostTags(result.data);
            setTotalRecord(result.totalRecord);
        } catch (error) {
            console.log("poperty search Error");
            console.log(error);
            if (error.response) {
                toast.error(error.response.data.message, { containerId: 'TR' });
                StorageService.clear();
                navigate('/login');
            } else if (error.request) {
                toast.error('Error: No response received from the server');
            } else {
                toast.error(`Error: ${error.message}`);
            }
        }
    }
    const handleShare = (item) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            showLoggedInConfirmDialog();
        } else {
            setShareData(item);
            setShareShow(true);
            setSelectedItemIndex(null);
        }
    };
    const deleteProfessionalPagePost = async (id: any) => {
        try {
            if (id) {
                await professionalPageApiService.deleteProfessionalpagePost(id);
                toast.success('Post has been Deleted Successfully', { containerId: 'TR' });
                window.location.reload();
                return;
            }
        } catch (error) {
            console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
        }
    };

    const handleDeleteConfirmed = (id: any) => {
        setSelectedItemIndex(null);
        confirmAlert({
            title: "Delete",
            message: "Are you sure to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteProfessionalPagePost(id),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };
    const filterClose = (Filter?: any) => {
        setFilterShow(false);

        let isRecentSelected = false;
        let isMoreLikesSelected = false;
        let isMoreCommentsSelected = false;
        let isMoreShareSelected = false;


        if (Filter && Filter.length > 0) {
            const selectedFilter = Filter.map((item: any) => item.label).join(',');
            setSelectedFilter(selectedFilter);

            isRecentSelected = selectedFilter.includes('Recent');
            isMoreLikesSelected = selectedFilter.includes('More Likes');
            isMoreCommentsSelected = selectedFilter.includes('More Comments');
            isMoreShareSelected = selectedFilter.includes('More Shares');

        } else if (!Filter) {
            setSelectedFilter('');
        }

        // Set the values based on the consolidated conditions

        setRecent(isRecentSelected ? 'recent' : ' ');
        setMoreLikes(isMoreLikesSelected ? 'likes' : ' ');

        setMoreComments(isMoreCommentsSelected ? 'comments' : ' ');
        setMoreShares(isMoreShareSelected ? 'shares' : ' ');

    };

    const getProfessionalPagePostDataList = async () => {
        try {
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setProfessionalPagePosts(res);
        } catch (error) {
            console.log(error);
        }
    };
    const handleFilterClick = () => {

        setFilterShow(true);
    };
    const handlePlay = (imageDataId) => {
        if (playingVideoIndex !== null && videoRefs.current[playingVideoIndex]) {
            videoRefs.current[playingVideoIndex].pause();
        }
        setPlayingVideoIndex(imageDataId);
    };

    const handlePause = (imageDataId) => {
        if (playingVideoIndex === imageDataId) {
            setPlayingVideoIndex(null);
        }
    };
    const handleFollowClick = (id: any) => {
        setIsFollowing((prevIsFollowing) => !prevIsFollowing);
        savePageFollow(id);
    };
    const handleEdit = (item) => {
        setEditData(item);
        setPostId(item.id);
        setEditShow(true);
        setSelectedItemIndex(null);
    };
    const videoRefs = useRef([]);

    const savePageFollow = async (id: any) => {
        try {
            let payload: PagePostTagFollowPayload = {
                followingTagId: id,
                userPageId: userPageId
            };
            await professionalPageApiService.savePagePostTagFollow(payload);
            getProfessionalPageDetails();
        } catch (error) {
            console.log(error)
        }
    };

    const handleFollowButtonClick = (id: any) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            showLoggedInConfirmDialog();
        } else {
            handleFollowClick(id);
        }
    };
    const toggleDropdown = (index) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            showLoggedInConfirmDialog();
        } else {
            setSelectedItemIndex(selectedItemIndex === index ? null : index);
            setDropdownVisible(!isDropdownVisible);
        }
    };

    const showLoggedInConfirmDialog = () => {
        confirmAlert({
            title: 'Alert',
            message: 'You have to log in before Follow ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { navigate('/login'); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log('clicked no') }
                }
            ]
        });
    };

    useEffect(() => {
        getProfessionalPageDetails();


        getProfessionalPagePostDataList();
    }, []);

    useEffect(() => {

        getPageData(currentPage, pageSize);

    }, [PostTagId]);


    const handleViewPage = (userId: number, id: number) => {
        // navigate(`/view-profession-profile?userId=${userId}&id=${id}`);
        const combined = `userId=${userId}&id=${id}`;
        const encoded = btoa(combined);
        navigate(`/professional?v=${encoded}`);
        console.log(`View button clicked for userId: ${userId}, id: ${id}`);
    };

    const handleArrowClick = (tagName: string, tagUrl: string) => {
        setSelectedParticularTagName(tagName);
        navigate(`/your-url/${tagUrl}`); // Replace with your actual URL
    };

    const handleDateClick = (pageId: number, postId: number, index: any) => {
        // navigate(`/view-professional-page-post?pageId=${pageId}&postId=${postId}`, { state: {} });
        const combined = `pageId=${pageId}&postId=${postId}`;
        const encoded = btoa(combined);
        navigate(`/post?v=${encoded}`);
        console.log(`View button clicked for pageId: ${pageId}, postId: ${postId}`);
    };

    const renderMenuItemChildren = (option: any, props: any) => (
        <div>
            <span>{option.tagName}</span>
        </div>
    );

    const searchTag = async (s: any) => {
        setTagSearchLoading(true);
        let data = await propertyApiService.searchTag(s);
        console.log(s);
        setTagSearchLoading(false);
        setTagData(data);
    };
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const onTagSelected = (t: any) => {
        if (t.length > 0) {
            setSelectedTagId(t[0].id);
            setSelectedTagName(t[0].tagName);
        }
    };
    const handleBackToTag = () => {
        navigate('/tags');  // Replace '/tags' with the actual route you want to navigate to
    };

    return (
        <>
            <Header />
            <section className="">
                <div className="col-md-6 mt-5 mx-auto text-end">
                    <img
                        src={back}
                        alt="Crown"
                        onClick={handleBackToTag}
                        style={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%',
                            backgroundColor: '#e6dede',
                            cursor: 'pointer', // Add cursor pointer to indicate clickability
                        }}
                    />
                </div>

                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="card grey-background">
                                <div className="row g-0">
                                    <div className="col-md-2">
                                        <Figure className="mb-0" style={{ height: '162px', width: '100%', position: 'relative' }}>
                                            <img
                                                src={professionalPagePostTags.length > 0 && professionalPagePostTags[0].tagImage ? professionalPagePostTags[0].tagImage : DefaultImage}
                                                className="card-img tags-img-container"
                                                alt="Default Sample Image"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',

                                                    objectFit: 'cover',
                                                }}
                                            />
                                            {professionalPagePostTags.length > 0 && professionalPagePostTags[0].tagDifficulty !== null && (
                                                <img
                                                    src={crown}
                                                    alt="Crown"
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        position: 'absolute',
                                                        backgroundColor: '#8080807a',
                                                        top: '2px', // Adjust as needed
                                                        right: '4px', // Adjust as needed
                                                    }}
                                                />
                                            )}
                                        </Figure>

                                    </div>
                                    <div className="col-md-10">
                                        <div className="card-body h-100 d-flex flex-column justify-content-around">

                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon icon={faTag} style={{ color: 'red', fontSize: '24px', marginRight: '10px' }} />
                                                <div className="mx-2">
                                                    <label className="card-title mb-0" style={{ color: 'black', fontSize: '16px' }}>
                                                        {professionalPagePostTags.length > 0 ? professionalPagePostTags[0].tagName : ''}
                                                    </label>
                                                </div>
                                                <label style={{ color: 'grey', fontSize: '15px', marginRight: '26px', marginLeft: 'auto', marginTop: '-6px' }}>Rank: {rank}</label>

                                            </div>


                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <label style={{ color: 'rgb(117, 113, 113)', marginRight: '10px' }}>
                                                        {professionalPagePostTags.length > 0 ? professionalPagePostTags[0].professionalPagePostTagData.length : 0} Posts
                                                    </label>
                                                    {professionalPagePostTags.length > 0 && professionalPagePostTags[0].professionalPagePostTagFollowDataList.map((item: any, index: number) => (
                                                        <img
                                                            key={index}
                                                            src={item.profileImageUrl || person}
                                                            alt="Profile Image"
                                                            style={{
                                                                width: '35px',
                                                                height: '35px',
                                                                borderRadius: '50%',
                                                                position: 'relative',
                                                                marginLeft: index === 0 ? '0' : '-20px',
                                                                zIndex: index, // Higher zIndex for images on the right
                                                            }}
                                                        />
                                                    ))}
                                                    <label style={{ color: 'rgb(117, 113, 113)', marginLeft: '10px' }}>
                                                        {professionalPagePostTags.length > 0 ? professionalPagePostTags[0].professionalPagePostTagFollowDataList.length : 0} Followers
                                                    </label>
                                                </div>
                                                <button
                                                    onClick={() => handleFollowButtonClick(professionalPagePostTags.length > 0 ? professionalPagePostTags[0].id : '')}
                                                    style={{
                                                        borderRadius: '20px',
                                                        padding: '1px 25px',
                                                        backgroundColor: 'transparent',
                                                        color: '#009de2',
                                                        border: '2px solid lightblue',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    {professionalPagePostTags.length > 0 && professionalPagePostTags[0].professionalPagePostTagFollowDataList.some((item: any) => item.userPageId === userPageId) ? 'Followers' : 'Follow'}
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <ul className="mt-4 container row pt-0 label-container">
                                {professionalPagePostTags.length > 0 &&
                                    (professionalPagePostTags[0].monthlySearch !== null ||
                                        professionalPagePostTags[0].tagDifficulty !== null ||
                                        professionalPagePostTags[0].monthlyTagCost > 0) && (
                                        <div className="col-auto px-1">
                                            <button
                                                className="rounded-pill label-item"
                                                style={{
                                                    backgroundColor: 'lightgreen',
                                                    border: 'none',
                                                    padding: 'revert', // Adjust padding as needed
                                                    display: 'flex', // Use flexbox to align text and arrow
                                                    alignItems: 'center' // Center items vertically
                                                }}
                                                onClick={handleButtonClick} // Attach the click handler
                                            >
                                                Tag Overview
                                                <span style={{ marginLeft: '8px' }}>➡️</span>
                                            </button>
                                        </div>
                                    )}

                                {/* Conditional rendering based on `showContent` state */}
                                {!showContent && (
                                    <div className="col px-1">
                                        {professionalPagePostTags.length > 0 && professionalPagePostTags[0].monthlySearch !== null && professionalPagePostTags[0].monthlySearch !== 'null' && professionalPagePostTags[0].monthlySearch !== '0' && (
                                            <label
                                                className="label-item me-1"
                                                style={{ cursor: professionalPagePostTags[0].monthlySearchNote ? 'pointer' : 'default' }}
                                            >
                                                Monthly Search:
                                                <span className="highlight-text mx-1">{professionalPagePostTags[0].monthlySearch}</span>
                                                <img
                                                    src={important}
                                                    alt="Profile Image"
                                                    data-tooltip-id="my-tooltip-styles-1"
                                                    data-tooltip-content={professionalPagePostTags[0].monthlySearchNote ? professionalPagePostTags[0].monthlySearchNote : ''}
                                                    style={{
                                                        width: '18px',
                                                        height: '18px',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                        cursor: professionalPagePostTags[0].monthlySearchNote ? 'pointer' : 'default'
                                                    }}
                                                />
                                                <Tooltip
                                                    id="my-tooltip-styles-1"
                                                    className="tooltip"
                                                    opacity={0.8}
                                                    place={'bottom'}
                                                />
                                            </label>
                                        )}

                                        {professionalPagePostTags.length > 0 && professionalPagePostTags[0].tagDifficulty !== null && professionalPagePostTags[0].tagDifficulty !== 'null' && (
                                            <label className="label-item me-1"
                                                style={{ cursor: professionalPagePostTags[0].tagDifficultyNote ? 'pointer' : 'default' }}
                                            >
                                                Tag Difficulty:
                                                <span className="highlight-text mx-1">{professionalPagePostTags[0].tagDifficulty}</span>
                                                <button className="rounded-pill label-item me-1"
                                                    style={{
                                                        backgroundColor: (() => {
                                                            if (professionalPagePostTags[0].tagDifficulty >= 0 && professionalPagePostTags[0].tagDifficulty <= 10) {
                                                                return '#4CAF50';
                                                            } else if (professionalPagePostTags[0].tagDifficulty > 10 && professionalPagePostTags[0].tagDifficulty <= 30) {
                                                                return '#FF9800';
                                                            } else if (professionalPagePostTags[0].tagDifficulty > 30 && professionalPagePostTags[0].tagDifficulty <= 70) {
                                                                return '#F44336';
                                                            } else if (professionalPagePostTags[0].tagDifficulty > 70 && professionalPagePostTags[0].tagDifficulty <= 100) {
                                                                return '#F44336';
                                                            }
                                                            return '#e6c1ad';
                                                        })(),
                                                        border: 'none',
                                                        padding: 'revert'
                                                    }}
                                                >
                                                    {professionalPagePostTags[0].tagDifficulty >= 0 && professionalPagePostTags[0].tagDifficulty <= 10 && (
                                                        <span style={{ color: 'white' }}>Easy</span>
                                                    )}
                                                    {professionalPagePostTags[0].tagDifficulty > 10 && professionalPagePostTags[0].tagDifficulty <= 30 && (
                                                        <span style={{ color: 'white' }}>Medium</span>
                                                    )}
                                                    {professionalPagePostTags[0].tagDifficulty > 30 && professionalPagePostTags[0].tagDifficulty <= 70 && (
                                                        <span style={{ color: 'white' }}>Hard</span>
                                                    )}
                                                    {professionalPagePostTags[0].tagDifficulty > 70 && professionalPagePostTags[0].tagDifficulty <= 100 && (
                                                        <span style={{ color: 'white' }}>SuperHard</span>
                                                    )}
                                                </button>
                                                <img
                                                    src={important}
                                                    alt="Profile Image"
                                                    data-tooltip-id="my-tooltip-styles-1"
                                                    data-tooltip-content={professionalPagePostTags[0].tagDifficultyNote ? professionalPagePostTags[0].tagDifficultyNote : ''}
                                                    style={{
                                                        width: '18px',
                                                        height: '18px',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                    }}
                                                />
                                            </label>
                                        )}

                                        {professionalPagePostTags.length > 0 && professionalPagePostTags[0].monthlyTagCost !== null && professionalPagePostTags[0].monthlyTagCost > 0 && (
                                            <label className="label-item me-1"
                                                style={{ cursor: professionalPagePostTags[0].tagCostNote ? 'pointer' : 'default' }}>
                                                Tag Cost:
                                                {/* <span className="mx-1" style={{ fontSize: '15px', color: 'black' }}>₹</span> */}
                                                {/* <span className="highlight-text mx-1">{professionalPagePostTags[0].monthlyTagCost}</span> */}
                                                <span className="highlight-text mx-1">
                                                    <span className="mx-1" style={{ color: 'black' }}>₹</span>{professionalPagePostTags[0].monthlyTagCost}/M
                                                    <span className="ms-1" style={{ color: 'black' }}>-</span>
                                                    <span className="mx-1" style={{ color: 'black' }}>₹</span>{professionalPagePostTags[0].yearlyTagCost > 0
                                                        ? professionalPagePostTags[0].yearlyTagCost
                                                        : professionalPagePostTags[0].monthlyTagCost * 10}/Y
                                                </span>
                                                <img
                                                    src={important}
                                                    alt="Profile Image"
                                                    data-tooltip-id="my-tooltip-styles-1"
                                                    data-tooltip-content={professionalPagePostTags[0].tagCostNote ? professionalPagePostTags[0].tagCostNote : ''}
                                                    style={{
                                                        width: '18px',
                                                        height: '18px',
                                                        borderRadius: '50%',
                                                        position: 'relative',
                                                    }}
                                                />
                                            </label>
                                        )}
                                    </div>
                                )}

                                <div className="col-auto">
                                    <label className="label-item mx-1">Filters</label>
                                    <label onClick={handleFilterClick} className="label-item"><TuneIcon /></label>
                                </div>
                            </ul>
                            {professionalPagePostTags?.length > 0 && professionalPagePostTags[0]?.professionalPagePostTagData
                                .filter((tagData: any) => tagData.professionalPagePostData.length > 0) // Filter for non-empty professionalPagePostData
                                .map((tagData: any, tagIndex: number) => (
                                    <div key={tagIndex} className="card mt-5 mb-3 mx-auto col-10">
                                        <div>
                                            {tagData.professionalPagePostData.map((item: any, index: number) => (
                                                <div key={index} className="col-md-12 mb-3">
                                                    <div className="row p-3">
                                                        <div className="col-auto pe-0">
                                                            <img
                                                                src={item?.pageProfileImageUrl || person}
                                                                alt="Profile Image"
                                                                style={{
                                                                    width: '35px',
                                                                    height: '35px',
                                                                    borderRadius: '50%',
                                                                    position: 'relative',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ms-2 col-sm d-flex justify-content-between">

                                                            <div className="mb-2">
                                                                <span className="page-name-style fw-semibold" style={{ wordBreak: "break-word" }}
                                                                    onClick={() => handleViewPage(item.pageCreatedBy, item.professionalPageId)}>
                                                                    {item.professionalName}
                                                                </span>
                                                                <div className="upload_date ">
                                                                    <div className="pe-0">
                                                                        <a data-tooltip-id="my-tooltip-styles"
                                                                            data-tooltip-content={formattedDate(item.createdAt)}>
                                                                            <span style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleDateClick(item.professionalPageId, item.id, index)}>
                                                                                {new Date(item.createdAt).getDate()}{' '}
                                                                                {new Date(item.createdAt).toLocaleString('default', { month: 'long' })}{' '}
                                                                                {new Date(item.createdAt).getFullYear()}
                                                                            </span>
                                                                        </a>
                                                                        <Tooltip id="my-tooltip-styles" className="tooltip" opacity={0.8} place={'bottom'} />
                                                                        <span className="ms-1">·</span>
                                                                        <span className="location_name_size">
                                                                            <LocationOnIcon className="location_icon_size" />{item.taggedLocationName}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="" onClick={(e) => e.stopPropagation()}>
                                                                <span className=""
                                                                    onClick={() => toggleDropdown(index)}>
                                                                    <MoreHorizIcon />
                                                                </span>
                                                                {item.professionalPageId == userPageId && (
                                                                    <div
                                                                        className={`dropdown-content ${selectedItemIndex === index ? 'show' : ''}`}>
                                                                        <div className="mb-2 ms-1" onClick={() => handleEdit(item)}>
                                                                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                                            Edit post
                                                                        </div>
                                                                        <div className="mb-2" onClick={() => handleIsSavedClick(item, index)}>
                                                                            {isSaved[index] ? (
                                                                                <><TurnedInIcon className="me-1" />
                                                                                    Saved Post</>
                                                                            ) : (
                                                                                <><TurnedInNotIcon className="me-1" />
                                                                                    Save Post</>
                                                                            )}
                                                                        </div>
                                                                        <div className="ms-1" onClick={() => handleDeleteConfirmed(item.id)}>
                                                                            <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                                            Move to trash
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.professionalPageId != userPageId && (
                                                                    <div
                                                                        className={`dropdown-content ${selectedItemIndex === index ? 'show' : ''}`}
                                                                        style={{ marginRight: '125px' }}>
                                                                        <div className="mb-2" onClick={() => handleIsSavedClick(item, index)}>
                                                                            {isSaved[index] ? (
                                                                                <><TurnedInIcon className="me-1" />
                                                                                    Saved Post</>
                                                                            ) : (
                                                                                <><TurnedInNotIcon className="me-1" />
                                                                                    Save Post</>
                                                                            )}
                                                                        </div>
                                                                        <div className="ms-1" onClick={() => handleReport(item)}>
                                                                            <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                                            Report
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-2">
                                                        <div className="upload_date ">
                                                            <div className="pe-0">
                                                                {item.caption && item.caption !== "null" && (
                                                                    <div className="ms-3 me-5">
                                                                        <div className={isExpanded[index] ? 'full-caption' : 'caption'} style={{ color: 'black', fontSize: '16px' }}>
                                                                            {item.caption}
                                                                        </div>
                                                                        {item.caption.split(' ').length > 20 && (
                                                                            <span
                                                                                className="read-more-caption"
                                                                                onClick={() => handleReadMore(index)}
                                                                                style={{ color: 'black', cursor: 'pointer' }} // Adding cursor style as an example
                                                                            >
                                                                                {isExpanded[index] ? 'Read less...' : 'Read more...'}
                                                                            </span>

                                                                        )}
                                                                    </div>
                                                                )}
                                                                {item.professionalPagePostImageGalleryData && (
                                                                    <Carousel interval={null}
                                                                        indicators={item.professionalPagePostImageGalleryData.length > 1}
                                                                        controls={item.professionalPagePostImageGalleryData.length > 1}>
                                                                        {item.professionalPagePostImageGalleryData.map((imageData, imageIndex) => (
                                                                            <Carousel.Item key={imageIndex}>
                                                                                <div className="single-post-image-overlay d-flex justify-content-center align-items-center"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => handleDateClick(item.professionalPageId, item.id, index)}>
                                                                                    {item.professionalPagePostImageGalleryData.length > 1 &&
                                                                                        <div className="d-flex align-items-center justify-content-center video-play-icon m-3 p-1 px-2"
                                                                                            style={{ borderRadius: '15px', position: 'absolute', top: '0', right: '0' }}>
                                                                                            <span style={{ fontSize: 'small' }}>
                                                                                                {imageIndex + 1}/{item.professionalPagePostImageGalleryData.length}
                                                                                            </span>
                                                                                        </div>
                                                                                    }
                                                                                    {imageData.mimeType === 'IMAGE' ? (
                                                                                        <img
                                                                                            src={imageData.imagePath}
                                                                                            width={'100%'}
                                                                                            height={'100%'}
                                                                                            style={{ maxHeight: '65vh', objectFit: 'cover' }}
                                                                                            alt={`Image ${imageIndex + 1}`}
                                                                                        />
                                                                                    ) : (
                                                                                        <>
                                                                                            <video
                                                                                                className=""
                                                                                                height={'100%'}
                                                                                                width={'100%'}
                                                                                                // onMouseOver={() => handleMouseOver(imageData.id)}
                                                                                                // onMouseOut={() => handleMouseOut(imageData.id)}
                                                                                                // muted
                                                                                                // loop
                                                                                                style={{ maxHeight: '65vh' }}
                                                                                                controls={playingVideoIndex === imageData.id}
                                                                                                onPlay={() => handlePlay(imageData.id)}
                                                                                                onPause={() => handlePause(imageData.id)}
                                                                                                ref={(ref) => (videoRefs.current[imageData.id] = ref)} >
                                                                                                <source src={imageData.imagePath} />
                                                                                            </video>
                                                                                            {/* {isMouseOver && hoveredVideoIndex === imageData.id ? null : ( */}
                                                                                            {playingVideoIndex !== imageData.id && (
                                                                                                <div className="d-flex align-items-center justify-content-center video-play-icon"
                                                                                                    onClick={(e) => handlePlayIconClick(e, imageData.id)}>
                                                                                                    <PlayCircleOutlineIcon style={{ fontSize: 'xxx-large' }} />
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </Carousel.Item>
                                                                        ))}
                                                                    </Carousel>
                                                                )}



                                                                {item.callToAction == 0 || item.callToAction == null ? (
                                                                    null
                                                                ) : (
                                                                    <>
                                                                        {item.callToActionUrl && item.callToActionUrl != 'null' && (
                                                                            <a href={item.callToActionUrl.startsWith('http') ? item.callToActionUrl : `http://${item.callToActionUrl}`} className="shop-now-link" target="_blank" rel="nofollow"
                                                                                style={{ textDecoration: 'none' }}>
                                                                                <div className="shop-now-bar" style={{ backgroundColor: getRandomColor() }}>
                                                                                    {item.callToActionLabel}
                                                                                    <span>
                                                                                        <ChevronRightIcon />
                                                                                    </span>
                                                                                </div>
                                                                            </a>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {item.professionalPagePostLikeData.length > 0 && (
                                                                    <div className="px-2 mb-2 d-flex align-items-center">
                                                                        <RecommendIcon style={{ color: '#007bff' }} />
                                                                        {item.professionalPagePostLikeData.length}
                                                                    </div>
                                                                )}

                                                                <hr />
                                                            </div>
                                                            <div>
                                                                <Col className="d-flex justify-content-evenly">
                                                                    <div className="col-sm-4 d-flex justify-content-center">
                                                                        <span className="d-flex align-items-center"
                                                                            style={{ cursor: 'pointer', color: isLikedByUser(item) ? '#007bff' : 'black' }}
                                                                            onClick={() => handleLikeClick(item, index)}>
                                                                            <ThumbUpOffAltIcon />
                                                                            {isLikedByUser(item) ? 'Liked' : 'Like'}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-sm-4 d-flex justify-content-center">
                                                                        <span className="d-flex align-items-center"
                                                                            style={{ cursor: 'pointer', color: 'black' }}
                                                                            onClick={() => { handleCommentIconClick(item, index) }}>
                                                                            <ChatBubbleOutlineOutlinedIcon />
                                                                            Comment
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-sm-4 d-flex justify-content-center">
                                                                        <span className="d-flex align-items-center" style={{ cursor: 'pointer', color: 'black' }}
                                                                            onClick={() => handleShare(item)}>
                                                                            <ShareIcon /> Share
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <hr />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            <Modal size="lg" show={editShow} onHide={editHandleClose}>
                                <EditProfessionPost data={editData} postId={postId} handleClose={editHandleClose} />
                            </Modal>

                            <Modal size="lg" show={reportShow} onHide={reportClose}>
                                <ProfessionPostReport data={reportData} reportType={'POST_REPORT'} handleClose={reportClose} />
                            </Modal>

                            <Modal size="lg" show={shareShow} onHide={shareClose}>
                                <ProfessionPostShare data={shareData} handleClose={shareClose} />
                            </Modal>

                            <Modal size="sm" show={filterShow} onHide={filterClose}>
                                <TagPostSearch data={filterData} handleClose={filterClose} />
                            </Modal>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );

};

export default ViewProfessionPostTag;





