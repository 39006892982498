import HttpClientWrapper from "../../http-client-wrapper";
import { ContactUsPayload } from "./contactus-payload";

class ContactUsApiService {
    

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }
    getTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/footerContactus/templates');
            console.log("PropertyApi getTemplatesData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    saveContact = async (payload:ContactUsPayload) => {
        console.log("ContactUsApiService () start = " + JSON.stringify(payload));
        try {
            let data: any = await this.httpClientWrapper.post('/v1/footerContactus', payload);
            console.log("ContactUsApiService addContactUsApiService() response data " + JSON.stringify(data));
            console.log("ContactUsApiService addContactUsApiService() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }
   
}

export default ContactUsApiService;