import HttpClientWrapper from "../../http-client-wrapper";

class TaxApiService {

    [x: string]: any;

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getTax = async () => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/tax/fetchTaxByStatusActive');
            return data;
        } catch (error) {
            console.error('Error while fetching Tax response:', error);
            throw error;
        }
    }
}
export default TaxApiService;