import { Button, Card, Col, Row } from "react-bootstrap";
import { Footer, Header } from "../../../layouts";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import Avatar from "../../../assets/avatar.png"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import "./view-profession-tag-follow.css";
import ProfilePic from "../../../assets/profile-pic.jpeg"
import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import { PageFollowPayload } from "../../../data/api/services/professional-page/professional-page-payload";
import back from "../../../assets/back.png"

import PinterestIcon from '@mui/icons-material/Pinterest';
import StorageService from "../../../data/storage/storage-service";
import { useState, useEffect } from "react";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { confirmAlert } from "react-confirm-alert";
import { useLocation, useNavigate } from "react-router-dom";
import { faMagnifyingGlass, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TuneIcon from '@mui/icons-material/Tune';
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead";
import PropertyApiService from "../../../data/api/services/property/property-api-service";

const ViewProfessionTagFollow = (props: any) => {
  let navigate: any = useNavigate();
  const professionalPageApiservice = new ProfessionalPageApiService();
  const propertyApiService = new PropertyApiService();
  const [professionalPostShareData, setprofessionalPostShareData] = useState([]);
  const [shareUrl, setShareUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [userId, setUserId] = useState('');
  const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
  const [userPageData, setUserPageData] = useState<any>([]);
  const activePageItem = userPageData.find(item => item.activePage === true);
  const userPageId = activePageItem?.id;
  const [selectedProfessionalPostShare, setSelectedProfessionalPostShare] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100000);
  const [totalRecord, setTotalRecord] = useState(0);
  const professionalName = props.professionalName;
  const professionaTypeId = props.professionaTypeId;
  const professionalLocationId = props.professionalLocationId;
  const [professionalData, setProfessionalData] = useState([]);
  const [selectedProfessional, setSelectedProfessional] = useState([]);
  const [professionalSearchLoading, setProfessionalSearchLoading] = useState(false);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState('');
  const [selectedProfessionalName, setSelectedProfessionalName] = useState('');

  const [professionalPagePostTags, setProfessionalPagePostTags] = useState<any[]>([]);
  const location = useLocation();

  const PostTagId = new URLSearchParams(location.search).get('tagId');


  const [loading, setLoading] = useState(true);

  const [isHovered, setIsHovered] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followingId, setFollowingId] = useState('')

  const handleMouseEnter = () => {
    setIsHovered(true); // Set hovered to true when mouse enters
  };

  const handleMouseLeave = () => {
    setIsHovered(false); // Set hovered to false when mouse leaves
  };
  const searchProfessional = async (r: any) => {
    setProfessionalSearchLoading(true);
    let data = await propertyApiService.searchProfessional(r);
    console.log(r);
    setProfessionalSearchLoading(false);
    setProfessionalData(data);
  };
  const renderMenuItemChildren = (option, props) => (
    <div>
      <img
        src={option.profileImageUrl != null ? option.profileImageUrl : ProfilePic}

        style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
      />
      <span>{option.name}</span>
    </div>
  );

  const handleButtonClick = (selectedId: number, index: number) => {
    const newButtonStates = [...buttonStates];
    newButtonStates[index] = !newButtonStates[index];
    setButtonStates(newButtonStates);
    const isSelected = selectedProfessionalPostShare.some((item) => item.id === selectedId);
    if (isSelected) {
      setSelectedProfessionalPostShare((previousValue) =>
        previousValue.filter((item) => item.id !== selectedId)
      );
    } else {
      setSelectedProfessionalPostShare((previousValue) => [...previousValue, { id: selectedId }]);
    }
  };
  useEffect(() => {
    if (data) {
      const isFollowing = data?.professionalPageFollowDataList?.some((follow) => {
        console.log('following:', follow.userPageId, 'followedBy:', userPageId);
        return follow.userPageId == userPageId;
      });
      console.log("Is Liked:", isFollowing);
      setIsFollowing(isFollowing);
    }
  }, [data, userPageId]);
  const getProfessionalPagePostDataList = async () => {
    try {
      let res = await professionalPageApiservice.getProfessionalPageData(userId);
      setProfessionalPagePosts(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMoreClick = () => {
    const updatedPageSize = pageSize + 10;
    setPageSize(updatedPageSize);
    getPageData(currentPage, updatedPageSize);
  };

  const getProfessionalPageById = async (userPageId) => {
    try {
      let res = await professionalPageApiservice.getProfessionalPageById(userPageId);
      setData(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfessionalPageDetails();
    getProfessionalPagePostDataList();
  }, []);



  const getPageData = async (page, size) => {
    try {
      let url = `?page=${page}&pageSize=${size}&id=${PostTagId}&pageId=${selectedProfessionalId}`;

      let result = await professionalPageApiservice.getAllTagData(url);
      console.log("sssssssssssssssssssssssssssssss", result);
      setProfessionalPagePostTags(result.data);
      console.log("professionalPagePostTagFollowDataList", result.data)
      setTotalRecord(result.totalRecord);
    } catch (error) {
      console.log("poperty search Error");
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, { containerId: 'TR' });
        StorageService.clear();
        navigate('/login');
      } else if (error.request) {
        toast.error('Error: No response received from the server');
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  }
  const [buttonStates, setButtonStates] = useState(
    professionalPostShareData.map(() => false)
  );
  const handleFollowClick = (id: any) => {
    setIsFollowing((prevIsFollowing) => !prevIsFollowing);
    savePageFollow(id);
  };
  const handleFollowButtonClick = (id: any) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      showLoggedInConfirmDialog();
    } else {
      handleFollowClick(id);
    }
  };


  const savePageFollow = async (id: any) => {
    try {
      let payload: PageFollowPayload = {
        followingPageId: id,
        userPageId: userPageId
      };
      await professionalPageApiservice.savePageFollow(payload);
      getProfessionalPageDetails();
    } catch (error) {
      console.log(error)
    }
  }
  const showLoggedInConfirmDialog = () => {
    confirmAlert({
      title: 'Alert',
      message: 'You have to log in before create new',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { navigate('/login'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('clicked no') }
        }
      ]
    });
  };
  const onProfessionalSelected = (p: any) => {
    if (p.length > 0) {
      console.log(p[0]);
      setSelectedProfessional(p);
      setSelectedProfessionalId(p[0].id);
      setSelectedProfessionalName(p[0].name)
    }
  }
  const handleClick = () => {
    navigate('/tags');
  };

  const handleViewPage = (userId: number, id: number) => {
    // navigate(`/view-profession-profile?userId=${userId}&id=${id}`);
    const combined = `userId=${userId}&id=${id}`;
    const encoded = btoa(combined);
    navigate(`/professional?v=${encoded}`);
    console.log(`View button clicked for userId: ${userId}, id: ${id}`);
  };

  const getProfessionalPageDetails = async () => {
    try {
      const userId = StorageService.getUserId();
      console.log(userId);
      let res = await professionalPageApiservice.getProfessionalPageData(userId);
      setUserPageData(res);
      const activePageItem = res.find(item => item.activePage === true);
      const userPageId = activePageItem?.id;
      getProfessionalPageById(userPageId);
      getPageData(currentPage, pageSize);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPageData(currentPage, pageSize);
  }, [PostTagId, selectedProfessionalId]);
  return <>
    <Header />
    <div className="p-0">
      <section className="">

        <>
          <div className="container mx-auto">
            <div className=" mt-4">
              <div className="col-sm-10 mx-auto my-3 d-flex flex-column align-items-center justify-content-center">
                <div className=" align-items-center justify-content-between w-50">
                  <div className="mx-2 d-flex justify-content-between w-100">
                    <div className="col-sm-10 d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faTag} style={{ color: 'red', fontSize: '24px', marginRight: '10px' }} />

                      <label className="card-title mb-0" style={{ color: 'grey', fontSize: '16px' }}>
                        {professionalPagePostTags.length > 0 ? professionalPagePostTags[0].tagName : ''}
                      </label>
                    </div>
                    <div className="col-auto px-0 ps-4 text-end">
                      <img
                        src={back}
                        alt="Crown"
                        onClick={handleClick}

                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          backgroundColor: '#e6dede',
                        }}
                      />
                    </div>
                  </div>


                </div>
                <div className="text-centre">
                  <label className="card-title mb-0" style={{ color: 'black', fontSize: '20px', marginRight: '10px' }}>
                    Followers
                  </label>

                </div>
                <div className=" col-sm-6 ">
                  <div className="w-100 mb-3">
                    <Col sm={12}>
                      <div className="tag-search-container px-3 align-items-centre d-flex">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />


                        <AsyncTypeahead
                          id="tag-search"
                          className="async-typeahead-container custom-async-typeahead rounded-pill focus"
                          labelKey="name"
                          onSearch={(query) => searchProfessional(query)}
                          options={professionalData}
                          onChange={onProfessionalSelected}




                          useCache={false}
                          placeholder="Search Professionals"
                          defaultSelected={selectedProfessional}
                          isLoading={professionalSearchLoading}
                          renderMenuItemChildren={renderMenuItemChildren}


                        />

                      </div>
                    </Col>

                  </div>
                </div>
                <div className=" col-sm-6">
                  {professionalPagePostTags &&
                    professionalPagePostTags.map((ppsData: any, index: number) => (

                      <Col key={ppsData.id} className="">
                        {ppsData.professionalPagePostTagFollowDataList && ppsData.professionalPagePostTagFollowDataList.map((psData: any, idx: any) => (
                          <div className="justify-content-between d-flex flex-wrap my-1">
                            <div className="d-flex align-items-center">
                              <img
                                className="pc-user-image mx-2 p-0"
                                src={psData.profileImageUrl != null ? psData.profileImageUrl : ProfilePic}
                                width="50"
                                height="50"
                                alt="Profile"
                              />
                              <div>
                                <h3 style={{ marginBottom: '1px' }}>
                                  <a

                                    className="pc-user-name"
                                    style={{ wordBreak: "break-word", color: "#A199AD", fontSize: '17px' }}
                                    onClick={() => handleViewPage(ppsData.createdBy, ppsData.id)}
                                  >
                                    {psData.name}
                                  </a>
                                </h3>


                                <div className="pc-user-title" style={{ marginTop: '0', marginBottom: '1px' }}>
                                  <div style={{ color: "#A199AD" }}>{psData.professionTypeLabel}</div>
                                </div>
                              </div>

                            </div>
                            <div className="pc-user-location d-flex align-items-center p-2">
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block"
                                }}
                              >
                                <div
                                  style={{
                                    color: isHovered ? "#A199AD" : "",
                                    cursor: "pointer",
                                    display: "inline-block"
                                  }}
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <LocationOnIcon titleAccess={psData.locationName} style={{ fontSize: '30px' }} />


                                </div>

                              </div>


                              {
                                psData.userPageId !== userPageId && (
                                  <>
                                    <Col md={6} xs={6} style={{ cursor: "pointer" }} onClick={() => handleFollowButtonClick(psData.userPageId)}>
                                      <button className="btn  rounded-pill btn-sm" style={{ padding: '5px 10px', fontSize: '12px', backgroundColor: psData?.professionalPageFollowDataList?.some((follow) => follow.userPageId === userPageId) ? '#CCCCCC' : '#007bff', color: psData?.professionalPageFollowDataList?.some((follow) => follow.userPageId === userPageId) ? '#000000' : '#FFFFFF' }}>
                                        {psData?.professionalPageFollowDataList?.some((follow) => follow.userPageId === userPageId) ? (
                                          <>Following</>
                                        ) : (
                                          <>Follow</>
                                        )}
                                      </button>
                                    </Col>
                                  </>
                                )
                              }

                            </div>
                          </div>
                        ))}



                      </Col>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>

      </section>
    </div>
  </>
}
export default ViewProfessionTagFollow;