import { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Step, Stepper } from "react-form-stepper";
import { Footer, Header } from "../../layouts";
import AddAmenitiesPage from "./add-amenities-page/add-amenities-page";
import AddGeneralPage from "./add-general-page/add-general-page";
import AddInformationPage from "./add-information-page/add-information-page";
import AddLocationPage from "./add-location-page/add-location-page";
import AddMediaPage from "./add-media-page/add-media-page";
import "./property-container.css";
import sale from "../../assets/for sale.jpg"
import Rent from "../../assets/for rent.jpg"
import Land from "../../assets/land.jpg"
import Model from "../../assets/model house.jpg"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddLandGeneralPage from "./add-land-general/add-land-general";
import AddLandInformationPage from "./add-land-information-page/add-land-information-page";
import AddModelInformationPage from "./add-model-house-information-page/add-model-house-information-page";
import AddRentInformationPage from "./add-rent-information/add-rent-information";
import EditGeneralPage from "./edit-general-page/edit-general-page";
import EditLocationPage from "./edit-location-page/edit-location-page";
import EditAmenitiesPage from "./edit-amenities-page/edit-amenities-page";
import EditMediaPage from "./edit-media-page/edit-media-page";
import AddModelLocationPage from "./add-model-location-page/add-model-location-page";
import EditLandGeneralPage from "./edit-land-general/edit-land-general";
import EditRentInformationPage from "./edit-rent-information/edit-rent-information";
import EditModelLocationPage from "./edit-model-location-page/edit-model-location-page";
import EditLandInformationPage from "./edit-land-information-page/edit-land-information-page";
import EditModelHouseInformationPage from "./edit-model-house-information-page/edit-model-house-information-page";
import DeedTypeWidget from "./widgets/deed-type-widget";
import PropertyApiService from "../../data/api/services/property/property-api-service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import EditInformationPage from "./edit-information-page/edit-information-page";



const EditPropertyContainer = () => {

    // const [deedTypeToggle, setDeedTypeToggle] = useState<String>(null);
    const [goSteps, setGoSteps] = useState(0);
    const propertyApiService = new PropertyApiService();

    const [deedTypeToggle, setDeedTypeToggle] = useState<string>();

    const [activeStep, setActiveStep] = useState(1);

    const [deedType, setDeedType] = useState<string>();


    const [propertyFormData, setPropertyFormData] = useState<any>(new FormData());

    const [searchParams, setSearchParams] = useSearchParams();
    const propertyId = searchParams.get('propertyId');


    const location = useLocation();


    let navigate: any = useNavigate();

    const [generalInfoCoverImage, setGeneralInfoCoverImage] = useState<any>();

    const [steps, setSteps] = useState<any>(
        [
            { label: 'General', completed: false, code: 'general', stepid: 1 },
            { label: 'Information', completed: false, code: 'information', stepid: 2 },
            { label: 'Location', completed: false, code: 'location', stepid: 3 },
            { label: 'Amenities', completed: false, code: 'amenities', stepid: 4 },
            { label: 'Media', completed: false, code: 'media', stepid: 5 }
        ]
    );
    const [deedTypes] = useState([
        { label: 'For Sale', code: 'SALE', alt: 'for sale', src: sale },
        { label: 'For Rent', code: 'RENT', alt: 'for rent', src: Rent },
        { label: 'Land/ Plot', code: 'LAND_PLOT', alt: 'for land/plot', src: Land },
        { label: 'Model House', code: 'MODEL_HOUSE', alt: 'for Model House', src: Model }
    ]);
    const handleCurrentStepper = (stepperId: number) => {
        setActiveStep(stepperId);
    }

    const highlightEditPropertyData = (data: any) => {
        propertyFormData.append('propertyName', data.propertyName);
        propertyFormData.append('contactPerson', data.contactPerson);
        propertyFormData.append('propertyTypeId', data.propertyTypeId);
        propertyFormData.append('buildingTypeId', data.buildingTypeId);
        propertyFormData.append('houseTypeIdStr', data.houseTypeId);
        propertyFormData.append('houseModelIdStr', data.houseModelId);
        propertyFormData.append('bhkTypeIdStr', data.bhkTypeId);
        propertyFormData.append('furnishingType', data.furnishingType);
        propertyFormData.append('buildingFacing', data.buildingFacingId);
        propertyFormData.append('facing', data.facingId);
        propertyFormData.append('sellerTypeIdStr', data.sellerTypeId);
        propertyFormData.append('contactPerson', data.contactPerson);
        propertyFormData.append('contactPhone', data.contactPhone);
        propertyFormData.append('totalSqFt', data.totalSqFt);
        propertyFormData.append('deedType', data.deedType);
        propertyFormData.append('totalRoomId', data.totalRoomId);
        propertyFormData.append('price', data.price);
        propertyFormData.append('bedId', data.bedId);
        propertyFormData.append('roadAccessId', data.roadAccessId);
        propertyFormData.append('bathId', data.bathId);
        propertyFormData.append('kitchenId', data.kitchenId);
        propertyFormData.append('kitchenTypeIdStr', data.pickListKitchenType);
        propertyFormData.append('livingRoomId', data.livingRoomId);
        propertyFormData.append('frontDoorTypeIdStr', data.pickListFrontDoorType);
        propertyFormData.append('balconyId', data.balconyId);
        propertyFormData.append('plotArea', data.plotArea);
        propertyFormData.append('plotAreaTypeIdStr', data.plotAreaTypeId);
        propertyFormData.append('parkingId', data.parkingId);
        propertyFormData.append('flooringTypeIdStr', data.pickListFlooringType);
        propertyFormData.append('waterFacilityIdStr', data.pickListWaterFacility);
        propertyFormData.append('electricityIdStr', data.pickListElectricity);
        propertyFormData.append('plotLength', data.plotLength);
        propertyFormData.append('plotBreadth', data.plotBreadth);
        propertyFormData.append('buildingLength', data.buildingLength);
        propertyFormData.append('propertyAvailableStatus', data.propertyAvailableStatus);
        propertyFormData.append('buildingBreadth', data.buildingBreadth);
        propertyFormData.append('buildingLength', data.buildingLength);
        propertyFormData.append('description', data.description);
        propertyFormData.append('longitude', data.longitude);
        propertyFormData.append('latitude', data.latitude);
        propertyFormData.append('landmark', data.landmark);
        propertyFormData.append('address', data.address);
        if (data.locationData) {
            propertyFormData.append('locationIdStr', data.locationData.id);
        }
        if (data.propertyNearbyFacilityData) {
            propertyFormData.append('nearbyFacilityStr', JSON.stringify(data.propertyNearbyFacilityData));
        }
        if (data.amenityData) {
            propertyFormData.append('amenityData', JSON.stringify(data.amenityData));
        }
        propertyFormData.append('officialWebsite', data.officialWebSite);
        propertyFormData.append('videoUrl', data.videoUrl);
        propertyFormData.append('planUrl', data.planUrl);
        propertyFormData.append('virtualTour', data.virtualTour);
        //propertyFormData.append('images',JSON.stringify(data.propertyImagesData));
        propertyFormData.append("widthRoad", data.widthRoad);
        propertyFormData.append("soilTypeIdStr", data.pickListSoilType);
        propertyFormData.append("currentYieldIdStr", data.pickListCurrentYield);
        propertyFormData.append("landSuitableForIdStr", data.pickListLandSuitableFor);
        propertyFormData.append("boundaryIdStr", data.pickListBoundary);
        propertyFormData.append("approvedByIdStr", data.pickListApprovedBy);
        propertyFormData.append("yearlyIncomeIdStr", data.yearlyIncomeId);
        propertyFormData.append("gatedCommunity", data.gatedCommunity);
        propertyFormData.append("leastDurationIdStr", data.leaseDurationId);
        propertyFormData.append("rentType", data.rentType);
        propertyFormData.append("priceInHundredIdStr", data.priceInHundred);
        propertyFormData.append("priceInThousandIdStr", data.priceInThousand);
        propertyFormData.append("priceInLakhsIdStr", data.priceInLakhs);
        propertyFormData.append("priceInCroresIdStr", data.priceInCrores);

    }

    const getPropertyDetails = async () => {
        try {
            console.log("START getPropertyDetails() ====");
            let data = await propertyApiService.getProperty(propertyId);
            console.log(data);
            highlightEditPropertyData(data);
            setDeedType(data.deedType)
            console.log("END getPropertyDetails() ====");
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const handleStepperCallback = (stepperId: number) => {
        setActiveStep(stepperId);
    }


    // const toggleDeedType = (item: any) => {
    //     console.log(item)
    //     setDeedTypeToggle(item.code);
    //     setActiveStep(1);
    // }

    const onGeneralInfoSaved = (stepperId: number) => {
        setActiveStep(stepperId);
    }

    const onLandGeneralInfoSaved = (stepperId: number) => {
        onGeneralInfoSaved(stepperId);
    }

    const handleNextStepperCallback = (stepperId: number) => {
        refreshSteps(stepperId);
    }

    const handlePreviousStepperCallback = (stepperId: number) => {
        refreshSteps(stepperId);
    }

    const refreshSteps = (stepperId: number) => {
        let previousStepId = (stepperId - 1);
        for (let i = 0; i < steps.length; i++) {
            if (previousStepId == steps[i].stepid) {
                steps[i].completed = true;
            } else {
                steps[i].completed = false;
            }
        }
        setSteps([...steps]);
        setActiveStep(stepperId);
    }

    useEffect(() => {
        getPropertyDetails();
    }, []);

    return <>
        <Fragment>
            <Header></Header>
            <div className="bgs">
                <div className="main-div ">
                    <h1>What type of property would you like to create?</h1>
                    <div className="radio-buttons">
                        <Row>
                            <Col >
                                {
                                    deedTypes.map((item: any, idx: number) => {
                                        return <>
                                            <label className="custom-radio" key={'deed_type_' + idx}>
                                                <input type="radio" checked={item.code == deedType} value={item.code} name="radio" />
                                                <span className="radio-btn">
                                                    <i><CheckCircleOutlineIcon /></i>
                                                    <div className="hobbies-icon">
                                                        <img className="hobbies-icon" src={item.src} alt={item.alt} />
                                                        <h3 className="txt">{item.label}</h3>
                                                    </div>
                                                </span>
                                            </label>
                                        </>
                                    })
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
                {
                    deedType ?
                        <div className="main-page">
                            <Stepper activeStep={activeStep - 1}
                                styleConfig={{
                                    activeBgColor: '#ffcc00',
                                    activeTextColor: '#ffffff', completedBgColor: '#198754', completedTextColor: '#ffffff', inactiveBgColor: '#ffcc00',
                                    inactiveTextColor: '#ffffff', size: '2em', circleFontSize: '1rem', labelFontSize: '0.875rem', borderRadius: '50%', fontWeight: '500'
                                }}>
                                <Step label="General" onClick={() => handleCurrentStepper(1)} />
                                <Step label="Information" onClick={() => handleCurrentStepper(2)} />
                                <Step label="Location" onClick={() => handleCurrentStepper(3)} />
                                <Step label="Amenities" onClick={() => handleCurrentStepper(4)} />
                                <Step label="Media" onClick={() => handleCurrentStepper(5)} />
                            </Stepper>
                        </div> : ''
                }
                {deedType == 'SALE' &&
                    <div>
                        {activeStep === 1 && (
                            <div>
                                <EditGeneralPage propertyId={propertyId} generalInfoCoverImage={generalInfoCoverImage} setGeneralInfoCoverImage={setGeneralInfoCoverImage} propertyFormData={propertyFormData} deedType={deedType} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div>
                                <EditInformationPage deedType={deedType} propertyId={propertyId}  propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 3 && (
                            <div>
                                <EditLocationPage deedType={deedType} propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 4 && (
                            <div>
                                <EditAmenitiesPage propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div>
                                <EditMediaPage propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} />
                            </div>
                        )}
                    </div>
                }
                {deedType == 'RENT' &&
                    <div>
                        {activeStep === 1 && (
                            <div>
                                <EditGeneralPage propertyId={propertyId} generalInfoCoverImage={generalInfoCoverImage} setGeneralInfoCoverImage={setGeneralInfoCoverImage} propertyFormData={propertyFormData} deedType={deedType} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div>
                                <EditRentInformationPage deedType={deedType} propertyId={propertyId}  propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 3 && (
                            <div>
                                <EditLocationPage deedType={deedType} propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 4 && (
                            <div>
                                <EditAmenitiesPage propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div>
                                <EditMediaPage propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} />
                            </div>
                        )}
                    </div>}
                {deedType == 'LAND_PLOT' &&
                    <div>
                        {activeStep === 1 && (
                            <div>
                                <EditLandGeneralPage propertyId={propertyId} generalInfoCoverImage={generalInfoCoverImage} setGeneralInfoCoverImage={setGeneralInfoCoverImage} propertyFormData={propertyFormData} deedType={deedType} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div>
                                <EditLandInformationPage deedType={deedType} propertyId={propertyId}  propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 3 && (
                            <div>
                                <EditLocationPage deedType={deedType} propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 4 && (
                            <div>
                                <EditAmenitiesPage propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div>
                                <EditMediaPage propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} />
                            </div>
                        )}
                    </div>}
                {deedType == 'MODEL_HOUSE' &&
                    <div>
                        {activeStep === 1 && (
                            <div>
                                <EditGeneralPage propertyId={propertyId} generalInfoCoverImage={generalInfoCoverImage} setGeneralInfoCoverImage={setGeneralInfoCoverImage} propertyFormData={propertyFormData} deedType={deedType} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 2 && (
                            <div>
                                <EditModelHouseInformationPage deedType={deedType} propertyId={propertyId}   onPreviousCallback={handlePreviousStepperCallback} propertyFormData={propertyFormData} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 3 && (
                            <div>
                                <EditModelLocationPage deedType={deedType} propertyId={propertyId} onPreviousCallback={handlePreviousStepperCallback} propertyFormData={propertyFormData} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 4 && (
                            <div>
                                <EditAmenitiesPage propertyId={propertyId} onPreviousCallback={handlePreviousStepperCallback} propertyFormData={propertyFormData} onNextCallback={handleNextStepperCallback} />
                            </div>
                        )}
                        {activeStep === 5 && (
                            <div>
                                <EditMediaPage propertyId={propertyId} propertyFormData={propertyFormData} onPreviousCallback={handlePreviousStepperCallback} />
                            </div>
                        )}
                    </div>}
            </div>
            <div>
                <Footer></Footer>
            </div>
        </Fragment>
    </>
}

export default EditPropertyContainer;