import { Button, Col, Container, FormControl, InputGroup, ListGroup, Row } from "react-bootstrap";
import { Footer, Header } from "../../../layouts";
import "../professional-page-message/professional-page-message.css";
import { useEffect, useState } from "react";
import Avatar from "../../../assets/avatar.png"
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";

const ProfessionalPageMessage = () => {

  const professionalPageUsers = [
    {
      name: "R Raju Ram",
      title: "Web Developer",
      location: "New York, USA",
      image: Avatar,
      message: [
        { type: 'sender', content: 'Hey, Are you there?', time: '10:06 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:16 am' },
        { type: 'reply', content: 'yes!', time: '10:20 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:06 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:16 am' },
        { type: 'reply', content: 'yes!', time: '10:20 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:06 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:16 am' },
        { type: 'reply', content: 'yes!', time: '10:20 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:06 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:16 am' },
        { type: 'reply', content: 'yes!', time: '10:20 am' }
      ]
    },
    {
      name: "Marios Pittas",
      title: "Web Developer",
      location: "New York, USA",
      image: Avatar,
      message: []
    },
    {
      name: "John Doe",
      title: "Web Developer",
      location: "New York, USA",
      image: Avatar,
      message: [
        { type: 'sender', content: 'Hey, Are you there?', time: '10:06 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:16 am' },
        { type: 'reply', content: 'yes!', time: '10:20 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:06 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:16 am' },
        { type: 'reply', content: 'yes!', time: '10:20 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:06 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:16 am' },
        { type: 'reply', content: 'yes!', time: '10:20 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:06 am' },
        { type: 'sender', content: 'Hey, Are you there?', time: '10:16 am' },
        { type: 'reply', content: 'yes!', time: '10:20 am' }
      ]
    },
    {
      name: "John Doe",
      title: "Web Developer",
      location: "New York, USA",
      image: Avatar,
      message: []
    },
    {
      name: "R Raju Ram",
      title: "Web Developer",
      location: "New York, USA",
      image: Avatar,
      message: []
    },
    {
      name: "Marios Pittas",
      title: "Web Developer",
      location: "New York, USA",
      image: Avatar,
      message: []
    },
    {
      name: "John Doe",
      title: "Web Developer",
      location: "New York, USA",
      image: Avatar,
      message: []
    },
    {
      name: "John Doe",
      title: "Web Developer",
      location: "New York, USA",
      image: Avatar,
      message: []
    },
  ]

  const professionalPageApiService = new ProfessionalPageApiService();
  const [professionalPageUsersData, setProfessionalPageUsersData] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getAllProfessionalPagData = async () => {
    try {
      let url = `?page=${currentPage}&pageSize=${pageSize}`;
      let res = await professionalPageApiService.getAllProfessionalPageData(url);
      setProfessionalPageUsersData(res.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProfessionalPagData();
  }, []);

  return <>
    <div className="my_professional_page_background">
      <Header></Header>
      <div className="main_page">
        <Container fluid>
          <Row>
            {/* Left Side */}
            <Col sm={3} className="p-3 pb-0 page_message_border" style={{ background: "white" }}>
              <Col>
                <div className="page_message_title">Chats</div>
              </Col>
              <Col className="mt-3">
                <InputGroup className="">
                  <FormControl placeholder="Search..." />
                </InputGroup>
              </Col>
              <Col className="mt-4 mb-2">
                <div className="page_message_inbox ps-3 pe-3 p-2">Inbox</div>
              </Col>
              <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "452px" }}>
                {Array.isArray(professionalPageUsersData) && professionalPageUsersData.map((page, pageIndex) => (<Col
                  key={pageIndex}
                  className="m-0 mt-2 d-flex align-items-center mb-3"
                  onClick={() => setSelectedUser(page)}
                  style={{ cursor: 'pointer', backgroundColor: selectedUser === page ? '#f0f0f0' : '' }}>
                  <Col md={2} className="">
                    <img
                      className="page_message_profile_img"
                      src={page.profileImageUrl}
                      alt="Profile"
                    />
                  </Col>
                  <Col md={10} className="ms-3">
                    <div>{page.name}</div>
                  </Col>
                </Col>
                ))}
              </div>
            </Col>

            {/* Right Side */}
            {selectedUser && (
              <Col sm={9} className="p-0">
                <Row className="chatbox m-0">
                  <Row className="m-0 p-0">
                    <Col className="p-3 d-flex align-items-center" style={{ background: "white", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
                      <img
                        className="page_chat_profile_img"
                        src={selectedUser.profileImageUrl}
                        width={"100%"} height={"100%"}
                        alt="Profile"
                      />
                      <div className="ms-2">{selectedUser.name}</div>
                    </Col>
                  </Row>
                  <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "480px" }}>
                    <Row className="">
                      <div className="msg-body">
                        <ul style={{ height: "464px" }}>
                          {/* {selectedUser.message.map((message, index) => (
                            <li key={index} className={message.type}>
                              <p> {message.content} </p>
                              <span className="time">{message.time}</span>
                            </li>
                          ))} */}
                          <li className="sender">
                            <p> Hey, Are you there? </p>
                            <span className="time">10:06 am</span>
                          </li>
                          <li className="sender">
                            <p> Hey, Are you there? </p>
                            <span className="time">10:16 am</span>
                          </li>
                          <li className="reply">
                            <p>yes!</p>
                            <span className="time">10:20 am</span>
                          </li>
                        </ul>
                      </div>
                    </Row>
                  </div>
                  <div className="send-box">
                    <form action="">
                      <input type="text" className="form-control" aria-label="message…" placeholder="Write message…" />
                      <button type="button"><i className="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
                    </form>
                  </div>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </div >
    </div >
  </>
}
export default ProfessionalPageMessage;