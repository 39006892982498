import { Header, Footer } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { Accordion, Carousel } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AuthApiService from "../../../data/api/services/auth/auth-api-service";
import { AuthPayload } from "../../../data/api/services/auth/auth-payload";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import UserTypeWidget from "../../../widgets/user-type/user-type-widget";
import "./faq-page.css"
import parse from 'html-react-parser';

const FaqPage = ({ loanType }) => {
  const [faqData, setFaqData] = useState<any>([]);
  const [personalLoanData, setPersonalLoanData] = useState<any>([]);

  const propertyApiService = new PropertyApiService();
  const [searchParams, setSearchParams] = useSearchParams();

  const loanTypeCode = searchParams.get('loanTypeCode');

  let navigate: any = useNavigate();

  const getFAQ = async () => {
    try {
      let data = await propertyApiService.getFAQ(loanType);
      setFaqData(data);
      console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", data)
    } catch (error) {
      console.log("FAQ Error");
      console.log(error);
    }
  }

  useEffect(() => {
    getFAQ();
  }, []);



  return (
    <>
      <div className="App mt-4">
        <div>
          <h2>Frequently Asked Question</h2>
        </div>
        <div className="container">
            <div className="row">
        {faqData && faqData.map((faqData: any, index: any) => (
          <div className="col-sm-6 my-3">

          <Accordion key={faqData.id} >
            <Accordion.Item eventKey={faqData.id} >
              <Accordion.Header >
              {faqData.questions ? parse(faqData.questions) : ''}
              </Accordion.Header>
              <Accordion.Body>
                <div >
                {faqData.answers ? parse(faqData.answers) : ''}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </div>
        ))}
        </div>
        </div>
      </div>
    </>
  );
};
export default FaqPage;

