import React, { Fragment, useEffect, useState } from "react";
import "./add-loan-container.css"
import loanImage from "../../../assets/loanImage.jpeg";
import { Footer, Header } from "../../../layouts";
import house from "../../../assets/housing.png"
import Mortgage from "../../../assets/mortgage-loan.png"
import Personal from "../../../assets/personal.png"
import Other from "../../../assets/ontherLoan.png"
import LoanWidgets from "../loan-widgets/loan-widgets";
import HousingLoan from "../housing-loan/housing-loan";
import MortgageLoan from "../mortgage-loan/mortgage-loan";
import PersonalLoan from "../personal-loan/personal-loan";
import OtherLoan from "../other-loan/other-loan";
import PropertyLoanApiService from "../../../data/api/services/property-loan/property-loan-api-service";
import StorageService from "../../../data/storage/storage-service";
import { createSearchParams, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import FaqPage from "../faq-page/faq-page";


const AddLoanContainer = () => {

    let navigate: any = useNavigate();
    const propertyLoanApiService = new PropertyLoanApiService();
    const [loanTypeToggle, setLoanypeToggle] = useState<string>();
    const [captcha, setCaptcha] = useState<string>();
    const [customerToggle, setCustomerToggle] = useState('');
    const toggleCustomerType = (customerTypeEv: any) => {
        setCustomerToggle(customerTypeEv.defaultValue);
    };

    const [loanType] = useState([
        { label: 'Housing Loan', alt: 'Housing Loan', src: house },
        { label: 'Mortgage Loan', alt: 'Mortgage Loan', src: Mortgage },
        { label: 'Personal Loan', alt: 'Personal Loan', src: Personal },
        { label: 'Other  Loans', alt: 'Other Loan', src: Other }
    ]);

    const toggleLoanType = (item: any) => {
        console.log(item)
        setLoanypeToggle(item.code);
        getGeneratedCaptcha();
    }

    const getGeneratedCaptcha = async () => {
        try {
            let res = await propertyLoanApiService.getGeneratedCaptcha();
            console.log(res);
            setCaptcha(res);
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    const showLoggedInConfirmDialog = () => {
        confirmAlert({
          title: 'Alert',
          message: 'You have to log in before apply for loan',
          buttons: [
            {
              label: 'Yes',
              onClick: () => { 
                
                navigate({
                    pathname: '/login', search: createSearchParams(
                        {
                            code: 'loan',
                        }).toString()
                }); }
            },
            
            
            {
              label: 'No',
              onClick: () => { console.log('clicked no') }
            }
          ]
        });
      }

    useEffect(() => {

    }, []);


    return <>
        <Fragment>
            <Header></Header>
            <div className="main-bg ">
                <h2 className="text-primary pt-2">What Type Of Loan You Need?</h2>
                <div className="radio-button">
                    <LoanWidgets onLoanTypeClick={toggleLoanType}></LoanWidgets>
                </div>

            </div>
          {loanTypeToggle &&  <div>
            <h1 className="text-center text-primary">i'm</h1>
            <div className=" d-flex flex-wrap  justify-content-center  ">
                <div className="dev me-5 text-center"  >
                    <input type="radio" className="box" name="loan" id="Salaried" value="SALARIED" onClick={e => { toggleCustomerType(e.target) }}
                        checked={customerToggle === 'SALARIED'} />
                    <label htmlFor="Salaried" className="label-name ps-4" >
                        <h4>Salaried</h4>
                    </label>
                </div>
                <div className="dev text-center"  >
                    <input type="radio" className="box" name="loan" id="SelfEmployed" value="SELF_EMPLOYED" onClick={e => { toggleCustomerType(e.target) }} />
                    <label htmlFor="SelfEmployed" className="label-name" >
                        <h4>Self Employed</h4>
                    </label>
                </div>
            </div>

            </div>}
            <div>
                {loanTypeToggle == 'HOUSING_LOAN' &&
                    <div className="container boder-sy my-4">
                        <div className="row">
                           
                            <div className="col-sm-7 ">
                                <HousingLoan customerToggle={customerToggle} loanType={loanTypeToggle} captcha={captcha} refreshCaptcha={getGeneratedCaptcha} />
                            </div>
                            {customerToggle &&  <div className="col-sm-5">
                                <div className="image-container">
                                <img src={loanImage} alt="Your Image"></img>
                                </div>
                            </div>}
                       <div className="col-sm-12">
                                <FaqPage loanType={loanTypeToggle}/>
                            </div> 
                        </div>
                    </div>
                }
                {loanTypeToggle == 'MORTGAGE_LOAN' &&
                    <div className="container boder-sy my-4">
                        <div className="row">                           
                            <div className="col-sm-7">
                                <MortgageLoan customerToggle={customerToggle} loanType={loanTypeToggle} captcha={captcha} refreshCaptcha={getGeneratedCaptcha} />
                            </div>
                            {customerToggle &&  <div className="col-sm-5">
                            <div className="image-container">
                                <img src={loanImage} alt="Your Image"></img>
                                </div>
                                 </div>}
                            <div className="col-sm-12">
                                <FaqPage loanType={loanTypeToggle}/>
                            </div>
                        </div>
                    </div>
                }
                {loanTypeToggle == 'PERSONAL_LOAN' &&
                    <div className="container boder-sy my-4">
                        <div className="row">
                            <div className="col-sm-7 ">
                                <PersonalLoan customerToggle={customerToggle} loanType={loanTypeToggle} captcha={captcha} refreshCaptcha={getGeneratedCaptcha} />
                            </div>
                            {customerToggle &&  <div className="col-sm-5">
                            <div className="image-container">
                                <img src={loanImage} alt="Your Image"></img>
                                </div>                            </div>}
                            <div className="col-sm-12">
                                <FaqPage loanType={loanTypeToggle}/>
                            </div>
                        </div>
                    </div>
                }
                {loanTypeToggle == 'OTHER_LOAN' &&
                    <div className="container boder-sy my-4">
                        <div className="row">
                            <div className="col-sm-7 ">
                                <OtherLoan customerToggle={customerToggle} loanType={loanTypeToggle} captcha={captcha} refreshCaptcha={getGeneratedCaptcha} />
                            </div>
                            {customerToggle &&  <div className="col-sm-5">
                            <div className="image-container">
                                <img src={loanImage} alt="Your Image"></img>
                                </div>                            </div>}
                            <div className="col-sm-12">
                                <FaqPage loanType={loanTypeToggle}/>
                            </div>
                        </div>
                    </div>
                }

            </div>

            <Footer />
        </Fragment>
    </>

};
export default AddLoanContainer;
