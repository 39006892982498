import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import Footer from "../../../layouts/footer/footer";
import Header from "../../../layouts/header/header";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ValidationError from "../../../common/validation-error";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "react-bootstrap/esm/Spinner";



const EditGeneralPage = ({ propertyId, deedType, propertyFormData, onNextCallback, setGeneralInfoCoverImage,
    generalInfoCoverImage }) => {

    let navigate: any = useNavigate();
    const tabNumber: number = 1;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [propertyTypeData, setPropertyTypeData] = useState([]);
    const [propertyTypeIdStr, setPropertyTypeIdStr] = useState("");
    const [showPropertyTypeOption, setShowPropertyTypeOption] = useState(true);
    const [propertyName, setPropertyName] = useState("");
    const [propertyNameValidationErrorMsg, setPropertyValidationNameErrorMsg] = useState(null);
    const [propertyTypeValidationErrorMsg, setPropertyTypeValidationErrorMsg] = useState(null);
    const [buildingTypeData, setBuildingTypeData] = useState([]);
    const [buildingTypeIdStr, setBuildingTypeIdStr] = useState("");
    const [showBuildingOption, setShowBuildingOption] = useState(true);
    const [houseTypeData, setHouseTypeData] = useState<any>([]);
    const [houseTypeIdStr, setHouseTypeIdStr] = useState("");
    const [showHouseTypeOption, setShowHouseTypeOption] = useState(true);
    const [houseModelIdStr, setHouseModelIdStr] = useState("");
    const [houseModelData, setHouseModelData] = useState<any>([]);
    const [showHouseDataOption, setShowHouseDataOption] = useState(true);
    const [bhkTypeIdStr, setBhkTypeIdStr] = useState("");
    const [bhkType, setBhkType] = useState<any>([]);
    const [furnishingType, setFurnishingType] = useState('');
    const [showFurnishingOption, setShowFurnishingOption] = useState(true);
    const [buildingFacingData, setBuildingFacingData] = useState([]);
    const [buildingFacingIdStr, setBuildingFacingIdStr] = useState("")
    const [facingData, setFacingData] = useState([]);
    const [showBuildingFacingOption, setShowBuildingFacingOption] = useState(true);
    const [deedTypeData, setDeedTypeData] = useState([]);
    const [facingIdStr, setFacingIdStr] = useState("");
    const [showFacingOption, setShowFacingOption] = useState(true);
    const [sellerTypeData, setSellerTypeData] = useState<any>([]);
    const [sellerTypeIdStr, setSellerIdTypeStr] = useState("");
    const [showSellerTypeOption, setShowSellerTypeOption] = useState(true);
    const [contactPerson, setContactPerson] = useState("");
    const [contactPersonValidationErrorMsg, setContactPersonValidationErrorMsg] = useState(null);
    const [contactPhone, setContactPhone] = useState("");
    const [contactPhoneValidationErrorMsg, setContactPhoneValidationErrorMsg] = useState(null);
    const [totalSqFtStr, setTotalSqFtStr] = useState("");
    const [totalSqFtStrValidationErrorMsg, setTotalSqFtStrValidationErrorMsg] = useState(null);
    const properyCoverImageInput: any = useRef(null);
    const [coverImage, setCoverImage] = useState<any>();
    const propertyApiService = new PropertyApiService();
    const [buildingTypeValidationErrorMsg, setBuildingTypeValidationErrorMsg] = useState(null);
    const [houseTypeValidationErrorMsg, setHouseTypeValidationErrorMsg] = useState(null);
    const [houseModelValidationErrorMsg, setHouseModelValidationErrorMsg] = useState(null);
    const [furnishingTypeValidationErrorMsg, setFurnishingTypeValidationErrorMsg] = useState(null);
    const [propertyFacingValidationErrorMsg, setPropertyFacingValidationErrorMsg] = useState(null);
    const [buildingFacingValidationErrorMsg, setBuildingFacingValidationErrorMsg] = useState(null);
    const [sellerTypeValidationErrorMsg, setSellerTypeValidationErrorMsg] = useState(null);
    const [coverImageValidationErrorMsg, setCoverImageValidationErrorMsg] = useState(null);
    const [bhkTypeValidationErrorMsg, setBhkTypeValidationErrorMsg] = useState(null);
    const [isNewCoverImage, setIsNewCoverImage] = useState("false");
    const [processing, setProcessing] = useState(false);



    const handleOnPropertyNameChange = (value: any) => {
        setPropertyName(value);
        setPropertyValidationNameErrorMsg('');
    }
    const handleOnPropertyTypeChange = (value: any) => {
        setPropertyTypeData(value);
        setPropertyTypeValidationErrorMsg('');
    }
    const handleOnTotalSqFtChange = (value: any) => {
        setTotalSqFtStr(value);
        setTotalSqFtStrValidationErrorMsg('');
    }
    const handleOnContactPersonChange = (value: any) => {
        setContactPerson(value);
        setContactPersonValidationErrorMsg('');
    }
    const handleOnContactPhoneChange = (value: any) => {
        setContactPhone(value);
        setContactPhoneValidationErrorMsg('');
    }

    const handlePropertyTypeChange = (ptId: any) => {
        setPropertyTypeIdStr(ptId);
        setPropertyTypeValidationErrorMsg('');
        setShowPropertyTypeOption(false);
    };

    const handleBuildingTypeChange = (btId: any) => {
        setBuildingTypeIdStr(btId);
        setBuildingTypeValidationErrorMsg('');
        setShowBuildingOption(false);

    };

    const handleHouseTypeChange = (htId: any) => {
        setHouseTypeIdStr(htId);
        setHouseTypeValidationErrorMsg('');
        setShowHouseTypeOption(false);

    };

    const handleHouseModelChange = (hmId: any) => {
        setHouseModelIdStr(hmId);
        setHouseModelValidationErrorMsg('');
        setShowHouseDataOption(false);

    };

    const handleOnBhkChange = (e: any, item: any, idx: any) => {
        let newBhkType = bhkType.map((bhk: any) => ({ ...bhk, checked: false }));
        // Set the checked property of the selected item
        newBhkType[idx].checked = e.target.checked;
        setBhkType(newBhkType);
        console.log('id', item.id);
        setBhkTypeIdStr(item.id);
        setBhkTypeValidationErrorMsg('');
    };

    const handleFurnishingTypeChange = (furnishingType: any) => {
        setFurnishingTypeValidationErrorMsg('');
        setFurnishingType(furnishingType);
        setShowFurnishingOption(false);

    };

    const handleBuildingFacingChange = (buildingFacingId: any) => {
        setBuildingFacingIdStr(buildingFacingId);
        setBuildingFacingValidationErrorMsg('');
        setShowBuildingFacingOption(false);

    };

    const handleFacingChange = (facingId: any) => {
        setFacingIdStr(facingId);
        setPropertyFacingValidationErrorMsg('');
        setShowFacingOption(false);

    };

    const handleSellerTypeChange = (stId: any) => {
        setSellerIdTypeStr(stId);
        setSellerTypeValidationErrorMsg('');
        setShowSellerTypeOption(false);

    };

    const validateGeneralFormData = (): void => {
        let errors: any[] = [];
        if (!propertyTypeIdStr) {
            let error: any = {};
            error.validationCode = "propertyType";
            error.message = 'Property Type required';
            errors.push(error);
        }
        if (!propertyName) {
            let error: any = {};
            error.validationCode = "propertyName";
            error.message = 'Property Name required';
            errors.push(error);
        }
        if (!buildingTypeIdStr) {
            let error: any = {};
            error.validationCode = "buildingTypeId";
            error.message = 'Building Type required';
            errors.push(error);
        }
        if (!houseTypeIdStr) {
            let error: any = {};
            error.validationCode = "houseTypeIdStr";
            error.message = 'House Type required';
            errors.push(error);
        }
        if (!houseModelIdStr) {
            let error: any = {};
            error.validationCode = "houseModelIdStr";
            error.message = 'House Model required';
            errors.push(error);
        }
        if (!furnishingType) {
            let error: any = {};
            error.validationCode = "furnishingType";
            error.message = 'Furnishing Type required';
            errors.push(error);
        }
        if (!facingIdStr) {
            let error: any = {};
            error.validationCode = "facing";
            error.message = 'Property Facing required';
            errors.push(error);
        }
        if (!contactPerson) {
            let error: any = {};
            error.validationCode = "contactPerson";
            error.message = 'Contact Person required';
            errors.push(error);
        }
        if (!contactPhone) {
            let error: any = {};
            error.validationCode = "contactPhone";
            error.message = 'Contact Phone required';
            errors.push(error);
        }
        if (!totalSqFtStr) {
            let error: any = {};
            error.validationCode = "totalSqFt";
            error.message = 'TotalSqFt required';
            errors.push(error);
        }
        if (!sellerTypeIdStr) {
            let error: any = {};
            error.validationCode = "sellerTypeIdStr";
            error.message = 'Seller Type required';
            errors.push(error);
        }
        if (isNewCoverImage == 'true' && !coverImage) {
            let error: any = {};
            error.validationCode = "coverImage";
            error.message = 'Cover Image required';
            errors.push(error);
        }
        if (!buildingFacingIdStr) {
            let error: any = {};
            error.validationCode = "buildingFacing";
            error.message = 'Building Facing required';
            errors.push(error);
        }
        if (!bhkTypeIdStr) {
            let error: any = {};
            error.validationCode = "bhkTypeIdStr";
            error.message = 'BHK Type required';
            errors.push(error);
        }
        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const submitGeneralFormData = async () => {
        try {
            validateAndSaveGeneralFormData();
            onNextCallback((tabNumber + 1));
        } catch (error) {
            showValidationErrorInlineUI(error);
        }
    }
    const updateGeneralFormData = async () => {
        try {
            validateAndSaveGeneralFormData();
            setProcessing(true);
            await propertyApiService.updateGeneral(propertyId, propertyFormData);
            toast.success('Successfully Property Updated ', { containerId: 'TR' });
            navigate('/')
        } catch (error) {
            setProcessing(false);
            showValidationErrorInlineUI(error);
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'propertyType') {
                setPropertyTypeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'propertyName') {
                setPropertyValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'buildingTypeId') {
                setBuildingTypeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'houseTypeIdStr') {
                setHouseTypeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'houseModelIdStr') {
                setHouseModelValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'furnishingType') {
                setFurnishingTypeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'facing') {
                setPropertyFacingValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'contactPerson') {
                setContactPersonValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'contactPhone') {
                setContactPhoneValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'totalSqFt') {
                setTotalSqFtStrValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'sellerTypeIdStr') {
                setSellerTypeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'buildingFacing') {
                setBuildingFacingValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'coverImage') {
                setCoverImageValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'bhkTypeIdStr') {
                setBhkTypeValidationErrorMsg(errorMsg);
            }
        }
    }

    const validateAndSaveGeneralFormData = () => {
        try {
            validateGeneralFormData();
            if (propertyFormData.has('propertyName')) {
                propertyFormData.delete('propertyName');
            }
            propertyFormData.append('propertyName', propertyName);

            if (propertyFormData.has('propertyTypeId')) {
                propertyFormData.delete('propertyTypeId');
            }
            propertyFormData.append('propertyTypeId', propertyTypeIdStr.toString());

            if (propertyFormData.has('buildingTypeId')) {
                propertyFormData.delete('buildingTypeId');
            }
            propertyFormData.append('buildingTypeId', buildingTypeIdStr);

            if (propertyFormData.has('houseTypeIdStr')) {
                propertyFormData.delete('houseTypeIdStr');
            }
            propertyFormData.append('houseTypeIdStr', houseTypeIdStr);

            if (propertyFormData.has('houseModelIdStr')) {
                propertyFormData.delete('houseModelIdStr')
            }
            propertyFormData.append('houseModelIdStr', houseModelIdStr);

            if (propertyFormData.has('bhkTypeIdStr')) {
                propertyFormData.delete('bhkTypeIdStr')
            }
            propertyFormData.append('bhkTypeIdStr', bhkTypeIdStr);

            if (propertyFormData.has('furnishingType')) {
                propertyFormData.delete('furnishingType')
            }
            propertyFormData.append('furnishingType', furnishingType);

            if (propertyFormData.has('buildingFacing')) {
                propertyFormData.delete('buildingFacing')
            }
            propertyFormData.append('buildingFacing', buildingFacingIdStr);

            if (propertyFormData.has('facing')) {
                propertyFormData.delete('facing')
            }
            propertyFormData.append('facing', facingIdStr);

            if (propertyFormData.has('sellerTypeIdStr')) {
                propertyFormData.delete('sellerTypeIdStr')
            }
            propertyFormData.append('sellerTypeIdStr', sellerTypeIdStr);

            if (propertyFormData.has('contactPerson')) {
                propertyFormData.delete('contactPerson')
            }
            propertyFormData.append('contactPerson', contactPerson);

            if (propertyFormData.has("contactPhone")) {
                propertyFormData.delete('contactPhone');
            }
            propertyFormData.append('contactPhone', contactPhone);

            if (propertyFormData.has("totalSqFt")) {
                propertyFormData.delete('totalSqFt');
            }
            propertyFormData.append('totalSqFt', totalSqFtStr);

            if (propertyFormData.has("deedType")) {
                propertyFormData.delete('deedType');
            }
            propertyFormData.append('deedType', deedType);

            if (propertyFormData.has("coverImage")) {
                propertyFormData.delete('coverImage');
            }
            propertyFormData.append('coverImage', coverImage.file);
            setGeneralInfoCoverImage(coverImage);

            if (propertyFormData.has("isNewCoverImage")) {
                propertyFormData.delete('isNewCoverImage');
            }
            propertyFormData.append('isNewCoverImage', isNewCoverImage.toString());

        } catch (error: any) {
            throw error;
        }
    }


    const highlightFormData = () => {
        let formData: FormData = propertyFormData;

        if (formData.get('propertyName')) {

            setPropertyName(formData.get('propertyName').toString());
        }
        if (formData.get('propertyTypeId')) {
            setPropertyTypeIdStr(formData.get('propertyTypeId').toString());
        }
        if (formData.get('buildingTypeId')) {
            setBuildingTypeIdStr(formData.get('buildingTypeId').toString());
        }
        if (formData.get('contactPhone')) {
            setContactPhone(formData.get('contactPhone').toString());
        }
        if (formData.get('contactPerson')) {
            setContactPerson(formData.get('contactPerson').toString());
        }
        if (formData.get('totalSqFt')) {
            setTotalSqFtStr(formData.get('totalSqFt').toString());
        }
        if (formData.get('houseTypeIdStr')) {
            setHouseTypeIdStr(formData.get('houseTypeIdStr').toString());
        }
        if (formData.get('houseModelIdStr')) {
            setHouseModelIdStr(formData.get('houseModelIdStr').toString());
        }
        if (formData.get('bhkTypeIdStr')) {
            setBhkTypeIdStr(formData.get('bhkTypeIdStr').toString());
        }
        if (formData.get('facing')) {
            setFacingIdStr(formData.get('facing').toString());
        }
        if (formData.get('furnishingType')) {
            setFurnishingType(formData.get('furnishingType').toString());
        }
        if (formData.get('sellerTypeIdStr')) {
            setSellerIdTypeStr(formData.get('sellerTypeIdStr').toString());
        }
        if (formData.get('buildingFacing')) {
            setBuildingFacingIdStr(formData.get('buildingFacing').toString());
        }

        hightlightPropertyCoverImage();

    }

    const onCoverImageUploadListener = () => {
        properyCoverImageInput.current?.click();
    }

    const hightlightPropertyCoverImage = async () => {
        if (isNewCoverImage == 'true') {
            setCoverImage(generalInfoCoverImage);
            return;
        }
        try {
            console.log("START hightlightPropertyCoverImage() ====");
            let data = await propertyApiService.getProperty(propertyId);
            console.log(data);
            if (!data || !data.coverImageURL) {
                return;
            }
            let coverImage: any = {};
            coverImage.imagePath = data.coverImageURL;
            setCoverImage(coverImage);
            console.log("END hightlightPropertyCoverImage() ====");
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const onPropertyCoverImageUploadListener = (event: any) => {
        let coverImage: any = {};
        coverImage.file = event.target.files[0];
        coverImage.imagePath = URL.createObjectURL(coverImage.file);
        setIsNewCoverImage("true");
        setCoverImage(coverImage);
        setCoverImageValidationErrorMsg('');
    }

    const clearCoverImage = () => {
        setIsNewCoverImage("false");
        setCoverImage(null);
    }

    const getTemplatesData = async () => {
        try {
            let res = await propertyApiService.getGeneralPropertyTemplatesData();
            console.log(res);
            setPropertyTypeData(res.propertyTypeData);
            setBuildingTypeData(res.buildingTypeData);
            setHouseTypeData(res.houseTypeData);
            setDeedTypeData(res.deedTypeData);
            setHouseModelData(res.houseModelData);
            setBhkType(res.bhkTypeData);
            setBuildingFacingData(res.buildingFacingData);
            setFacingData(res.facingData);
            setSellerTypeData(res.sellerTypeData);
            highlightFormData();

        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    const removePropertyCoverImage = async (propertyId: any) => {
        try {
            if (!coverImage) {
                await propertyApiService.deleteCoverImage(propertyId);
            }
            setIsNewCoverImage("false");
            setCoverImage(null);
        } catch (error) {
            console.log("ERROR occurred in removePropertyCoverImage(): " + JSON.stringify(error));
        }
    }

    const CoverImage = () => {
        confirmAlert({
            title: "Delete",
            message: "Are you sure to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => removePropertyCoverImage(propertyId),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };
    useEffect(() => {
        getTemplatesData();


    }, []);

    return (
        <>
            <div className="body container-fluid row">
                <div className=" container w-100  ms-1">
                    <div className="card-body">
                        <div className="col-sm-9 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-color text-center">
                                        <p> General</p>
                                    </div>
                                    <div className="row ms-5">
                                        <div className="col-sm-6 ">
                                            <div className="mb-2">
                                                <label className="mt-4">Property Name<sup className="required">*</sup></label>
                                                <input
                                                    className={propertyNameValidationErrorMsg ? 'error-field input-edit form-control  mt-4 ' : 'input-edit form-control  mt-4'}
                                                    type="text"
                                                    placeholder="Property Name"
                                                    value={propertyName}
                                                    onChange={e => { handleOnPropertyNameChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={propertyNameValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2" >
                                                <label className="mt-4">Property Type<sup className="required">*</sup></label>
                                                <select className={propertyTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={propertyTypeIdStr} onChange={(e) => handlePropertyTypeChange(e.target.value)}>
                                                    {showPropertyTypeOption && <option value="">Select</option>}
                                                    {propertyTypeData.map((ptData: any, index: any) => (<option key={ptData.id} value={ptData.id}>{ptData.propertyTypeName}</option>))}
                                                </select>
                                                <FormFieldError errorMessage={propertyTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Building Type<sup className="required">*</sup></label>
                                                <select className={buildingTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={buildingTypeIdStr} onChange={(e) => handleBuildingTypeChange(e.target.value)}>
                                                    {showBuildingOption && <option value="">Select</option>}
                                                    {buildingTypeData.map((btData: any, index: any) => (<option className="text-edit" key={btData.id} value={btData.id}>{btData.buildingTypeName}</option>))}
                                                </select>
                                                <FormFieldError errorMessage={buildingTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">House Type<sup className="required">*</sup></label>
                                                <select className={houseTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={houseTypeIdStr} onChange={(e) => handleHouseTypeChange(e.target.value)} >
                                                    {showHouseTypeOption && <option className="text-edit" value="">select </option>}
                                                    {houseTypeData.map((htData: any, index: any) => (
                                                        <option key={htData.id} value={htData.id}>{htData.houseType}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={houseTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">House Model<sup className="required">*</sup></label>
                                                <select className={houseModelValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={houseModelIdStr} onChange={(e) => handleHouseModelChange(e.target.value)} >
                                                    {showHouseDataOption && <option value="">Select</option>}
                                                    {houseModelData.map((hmData: any, index: any) => (
                                                        <option key={hmData.id} value={hmData.id}>{hmData.houseModel}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={houseModelValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Bhk Type<sup className="required">*</sup></label>
                                                {
                                                    bhkType.map((item: any, idx: any) => {
                                                        return <>
                                                            <div className="mb-2" key={item.id}>
                                                                <input type="radio"
                                                                    value={bhkTypeIdStr}
                                                                    className={bhkTypeValidationErrorMsg ? 'error-field  form-check-input ' : 'form-check-input'}
                                                                    name={'bhk_type_${item.id}'} id={item.id} checked={item.id == bhkTypeIdStr ? true : false} onChange={(e) => handleOnBhkChange(e, item, idx)} />
                                                                <label htmlFor={item.id} className="ms-2" >{item.bhkType}</label>

                                                            </div>
                                                        </>
                                                    })
                                                }
                                                <FormFieldError errorMessage={bhkTypeValidationErrorMsg}></FormFieldError>

                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Furnishing Type<sup className="required">*</sup></label>
                                                <select className={furnishingTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={furnishingType} onChange={(e) => handleFurnishingTypeChange(e.target.value)}>
                                                    {showFurnishingOption && <option value="">Select</option>}
                                                    <option value='SF'>Semi Furnished</option>
                                                    <option value='FF'>Full Furnished</option>
                                                    <option value='UN'>UnFurnished</option>
                                                </select>
                                                <FormFieldError errorMessage={furnishingTypeValidationErrorMsg}></FormFieldError>
                                            </div>

                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="mb-2">
                                                <label className="mt-4">Property Facing<sup className="required">*</sup></label>
                                                <select className={propertyFacingValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={facingIdStr} onChange={(e) => handleFacingChange(e.target.value)}>
                                                    {showFacingOption && <option value="">Select</option>}
                                                    {facingData.map((facingData: any, index: any) => (<option className="text-edit" key={facingData.id} value={facingData.id}>{facingData.facingName}</option>))}
                                                </select>
                                                <FormFieldError errorMessage={propertyFacingValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Building Facing<sup className="required">*</sup></label>
                                                <select className={buildingFacingValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={buildingFacingIdStr} onChange={(e) => handleBuildingFacingChange(e.target.value)}>
                                                    {showBuildingFacingOption && <option value="">Select</option>}
                                                    {buildingFacingData.map((buildingFacingData: any, index: any) => (<option className="text-edit" key={buildingFacingData.id} value={buildingFacingData.id}>{buildingFacingData.buildingFacingName}</option>))}
                                                </select>
                                                <FormFieldError errorMessage={buildingFacingValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Total Sq.ft<sup className="required">*</sup></label>
                                                <input
                                                    className={totalSqFtStrValidationErrorMsg ? 'error-field input-edit form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    type="number"
                                                    placeholder="Total Sq.ft"
                                                    value={totalSqFtStr}
                                                    min='0'
                                                    onChange={(e) => { handleOnTotalSqFtChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={totalSqFtStrValidationErrorMsg}></FormFieldError>

                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Seller Type<sup className="required">*</sup></label>
                                                <select className={sellerTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={sellerTypeIdStr} onChange={(e) => handleSellerTypeChange(e.target.value)}>
                                                    {showSellerTypeOption && <option value="">Select</option>}
                                                    {sellerTypeData.map((stData: any, index: any) => (
                                                        <option key={stData.id} value={stData.id}>{stData.sellerType}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={sellerTypeValidationErrorMsg}></FormFieldError>

                                            </div>
                                            <div className="mb-2">
    <label className="mt-4">Contact Person<sup className="required">*</sup></label>
    <input
        className={contactPersonValidationErrorMsg ? 'error-field input-edit form-control mt-4' : 'input-edit form-control mt-4'}
        type="text"
        placeholder="Contact Person"
        value={contactPerson}
        onChange={e => {
            const inputValue = e.target.value;
            const lettersOnlyRegex = /^[A-Za-z]*$/;

            if (lettersOnlyRegex.test(inputValue) || inputValue === "") {
                handleOnContactPersonChange(inputValue);
                setContactPersonValidationErrorMsg(""); 
            } else {
                
                setContactPersonValidationErrorMsg("Please enter letters only.");
            }
        }}
    />
    <FormFieldError errorMessage={contactPersonValidationErrorMsg}></FormFieldError>
</div>


                                            <div className="mb-2">
                                                <label className="mt-4">Contact Phone<sup className="required">*</sup></label>
                                                <input
                                                    className={contactPhoneValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    type="number"
                                                    min='0'
                                                    placeholder="Contact Phone"
                                                    value={contactPhone}
                                                    onChange={e => { handleOnContactPhoneChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={contactPhoneValidationErrorMsg}></FormFieldError>

                                            </div>
                                            <div className="col-md-6 mb-2 ">
                                                <label className="mt-4">Upload Cover Image<sup className="required">*</sup></label>
                                                <div className="mt-4 h-auto" style={{ display: 'none' }}>
                                                    <input type="file" className={coverImageValidationErrorMsg ? 'error-field form-control border border-dark w-100 ' : 'form-control border border-dark w-100 '} ref={properyCoverImageInput} onChange={onPropertyCoverImageUploadListener} />
                                                </div>
                                                <div className="mt-4 h-auto">
                                                    <input type="button" className="form-control border border-dark w-100" value="Upload Cover Image" onClick={onCoverImageUploadListener} />
                                                </div>
                                                <FormFieldError errorMessage={coverImageValidationErrorMsg}></FormFieldError>
                                                <div>
                                                    {
                                                        coverImage ? (<div className="col-md-6 mt-2">
                                                            <div className="col-md-12">
                                                                <div className="col-md-6" ><img src={coverImage.imagePath} alt='image' width={300} height={200}></img></div>
                                                                <div className="col-md-3">
                                                                    <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={CoverImage}>
                                                                        <span className="" aria-hidden="true">Remove</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4  d-flex justify-content-end me-5">
                                    <button disabled={processing} onClick={updateGeneralFormData} className="btn btn-warning form-btn me-3 " type="button">
                                        Update & Skip
                                    </button>
                                    {(processing) && <Spinner animation="grow" variant="warning" />}
                                    <button className="btn btn-success form-btn" onClick={submitGeneralFormData} type="button">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default EditGeneralPage;