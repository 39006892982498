import { useState, useEffect } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ValidationError from "../../../common/validation-error";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import LocationApiService from "../../../data/api/services/location/location-api-service";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import Header from "../../../layouts/header/header";
import { updateLocationData } from "../../../data/api/services/property/create-property-payload";
import Spinner from "react-bootstrap/esm/Spinner";

const EditModelLocationPage = ({ deedType, propertyId, onPreviousCallback, propertyFormData, onNextCallback }) => {

    const tabNumber: number = 3;
    const propertyApiService = new PropertyApiService();
    const locationApiService = new LocationApiService();
    let navigate: any = useNavigate();
    const [locationData, setLocationData] = useState<any>([]);
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<any>();
    const [showSelectedLocation, setShowSelectedLocation] = useState("");
    const [showSelectedPinCode, setShowSelectedPincode] = useState("");
    const [processing, setProcessing] = useState(false);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);

    const validateModelHouseLocationFormData = (): void => {
        let errors: any[] = [];
        if (!selectedLocation) {
            let error: any = {};
            error.validationCode = "location";
            error.message = 'Location required';
            errors.push(error);
        }
        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }
    const updateLocation = async () => {
        try {
            validateModelHouseLocationFormData();
            setProcessing(true);
            let payload: updateLocationData = {
                locationId: selectedLocation[0].id,
                deedTypeId: deedType,
                nearbyFacility: "",
                longitude: "",
                latitude: "",
                landmark: "",
                address: ""
            };
            await propertyApiService.updateLocation(propertyId, payload);
            toast.success('Successfully Updated Location', { containerId: 'TR' });
            navigate('/');

        } catch (error) {
            setProcessing(false);
            showValidationErrorInlineUI(error);
        }
    }
    const submitModelHouseLocationFormData = () => {
        try {
            validateAndSaveModelHouseLocationFormData();
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'location') {
                setLocationValidationNameErrorMsg(errorMsg);
            }
        }
    }

    const validateAndSaveModelHouseLocationFormData = () => {
        try {
            validateModelHouseLocationFormData();
            if (propertyFormData.has('locationIdStr')) {
                propertyFormData.delete('locationIdStr');
            }
            if (selectedLocation.length > 0) {
                propertyFormData.append('locationIdStr', selectedLocation[0].id);
            }
        } catch (error: any) {
            throw error;
        }
    }

    const highlightLocationFormData = async () => {
        let formData: FormData = propertyFormData;
        if (formData.get('locationIdStr')) {
            setShowSelectedLocation(formData.get('locationIdStr').toString());
            let selectedLocationId: any = parseInt(formData.get('locationIdStr').toString());
            let locationData: any = await locationApiService.getLocationById(selectedLocationId);
            setShowSelectedLocation(locationData.name);
            setShowSelectedPincode(locationData.pinCode)
            let existingSelectedLocation: any[] = [];
            existingSelectedLocation.push(locationData);
            formatLocationData(existingSelectedLocation);
            if (locationData.id == selectedLocationId) {
                let locationOptions: any[] = [];
                locationOptions.push(locationData);
                setSelectedLocation([...locationOptions]);
            }
        }
    }

    const previousPage = () => {
        onPreviousCallback(tabNumber - 1);
    };

    const fetchPropertyById = async (id: any) => {
        try {
            let data = await propertyApiService.getProperty(id);
            updateUi(data);
        } catch (error: any) {
            console.log(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    }

    const updateUi = (data: any) => {
        setLocationData(data.locationData);
        highlightLocationFormData();
    }

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        formatLocationData(data);
    };

    const formatLocationData = (data: any) => {
        formatLocationDataResponse(data);
        setLocationData(data);
    }

    const formatLocationDataResponse = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            data[i].nameLabel = data[i].name + ' | ' + data[i].pinCode;
        }
    }

    const onLocationSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
        }
        setLocationValidationNameErrorMsg('');
    }

    const getLocationTemplatesData = async () => {
        try {
            let res = await propertyApiService.getLocationTemplatesData();
            highlightLocationFormData();

        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    const renderMenuItemChildren = (option, props, index) => {
    
        return (
        <div key={index}>
        <span style={{ color: 'black' }}>{option.name}</span>
        {' | '}
        <span style={{ color: 'blue' }}>{option.pinCode}</span>
        {' | '}
        <span style={{ color: 'red' }}>{option.postOfficeName}</span>
        {' | '}
        <span style={{ color: 'green' }}>{option.talukaName}</span>
        {' | '}
        <span style={{ color: 'orange' }}>{option.districtName}</span>
        {' | '}
        <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>
        
      </div>
    );
      };
    useEffect(() => {
        getLocationTemplatesData();
    }, []);

    return (
        <>
            <div className="body container-fluid row">
                <div className=" container  w-100 ms-1">
                    <div className="card-body">
                        <div className="col-sm-9 mx-auto ">
                            <div className="card ">
                                <div className="card-body">
                                    <div className="text-color text-center">
                                        <p> Location</p>
                                    </div>
                                    <div className="row ms-5">
                                        <div className="col-sm-6 ">
                                            <div className="mb-2">
                                                <label className="mt-4">Location<sup className="required">*</sup></label>
                                                <AsyncTypeahead
                                                    id="basic-typeahead-single"
                                                    className={locationValidationErrorMsg ? 'error-field ' : ''}
                                                    labelKey="nameLabel"
                                                    onSearch={(e) => searchLocation(e)}
                                                    options={locationData}
                                                    onChange={onLocationSelected}
                                                    useCache={false}
                                                    placeholder="Search Location"
                                                    defaultSelected={selectedLocation}
                                                    isLoading={locationSearchLoading}
                                                    renderMenuItemChildren={renderMenuItemChildren}
                                                    />
                                                <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                                                <label className="mt-4">Selected Location : </label> <span>{showSelectedLocation}|{showSelectedPinCode}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4  d-flex justify-content-end me-5">
                                    <button className="btn btn-primary me-3" onClick={previousPage}>Previous</button>
                               
                                    <button disabled={processing}  onClick={updateLocation} className="btn btn-warning me-3 " type="button">
                                        Update & Skip
                                        </button>
                                    {(processing)&& <Spinner animation="grow" variant="warning" />}
                                    <button onClick={submitModelHouseLocationFormData} className="btn btn-success " type="button">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditModelLocationPage;