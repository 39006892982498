import { SetStateAction, useState } from 'react';
import RegisterPage from './register-page';
import OtpDialogBox from './otp-dialog ';
import RegisterRole from './register-role';
import { Footer, Header } from '../../layouts';
import VeeduBenefits from '../veedu-benefits/veedu-benefits';
import { SignupPayload } from '../../data/api/services/register/signup-payload';
const RegisterPageContainer = () => {

    const [activeStep, setActiveStep] = useState(1);
    const [payload, setPayload] = useState([]);

    const updatePayload = (updatedPayload: SignupPayload[]) => {
        setPayload(updatedPayload);
    };

    const handleNextStepperCallback = (stepperId: number) => {
        refreshSteps(stepperId);
    }


    const handlePreviousStepperCallback = (stepperId: number) => {
        refreshSteps(stepperId);
    }

    const refreshSteps = (stepperId: number) => {
        setActiveStep(stepperId);
    }


    return <>
        {/* <Header></Header> */}
        <div className="">

            {activeStep === 1 && (
                <RegisterPage updatePayload={updatePayload} onNextCallback={handleNextStepperCallback} />
            )}

            {activeStep === 2 && (
                <OtpDialogBox registrationPayload={payload} onNextCallback={handleNextStepperCallback} />
            )}

            {activeStep === 3 && (
                <RegisterRole registrationPayload={payload} updatePayload={updatePayload} onNextCallback={handleNextStepperCallback} />
            )}
            {activeStep === 4 && (
                <VeeduBenefits registrationPayload={payload} onPreviousCallback={handlePreviousStepperCallback} />
            )}

        </div>
        {/* <Footer></Footer> */}
    </>
}
export default RegisterPageContainer;