import { Button, Col, Row } from "react-bootstrap";
import "../professional-page-users/professional-page-users.css";
import { Footer, Header } from "../../../layouts";
import Avatar from "../../../assets/avatar.png"
import ProfilePic from "../../../assets/profile-pic.jpeg"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { useEffect, useState } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useLocation, useNavigate } from "react-router-dom";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StorageService from "../../../data/storage/storage-service";
import { confirmAlert } from "react-confirm-alert";

const ProfessionalPageUsers = () => {

    const professionalPageApiService = new ProfessionalPageApiService();
    const [professionalPageUsersData, setProfessionalPageUsersData] = useState<any>([]);
    const [professionalPosts, setProfessionalPosts] = useState<any>([]);
    let navigate: any = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const [loading, setLoading] = useState(true);
    const { state } = useLocation();
    const professionalName = state?.name;
    const handleLoadMoreClick = () => {
        const updatedPageSize = pageSize + 10;
        setPageSize(updatedPageSize);
        getAllProfessionalPagData(currentPage, updatedPageSize, userPageId);
    };

    const getAllProfessionalPagData = async (page, size, userPageId) => {
        try {
            let url = `?page=${page}&pageSize=${size}&id=${userPageId}&name=${professionalName}`;

            let res = await professionalPageApiService.getAllProfessionalPageData(url);
            setProfessionalPageUsersData(res.data);
            setTotalRecord(res.totalRecord);
            const normalPosts = res.data.map((pp) => pp.professionalPagePosts.filter(post => post.postType === "NORMAL_POST"));
            setProfessionalPosts(normalPosts);
            console.log(res);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getProfessionalPageDetailsByCreatedBy = async () => {
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            if (userId != null) {
                let res = await professionalPageApiService.getProfessionalPageData(userId);
                setUserPageData(res);
                const activePageItem = res.find(item => item.activePage === true);
                const userPageId = activePageItem?.id;
                getAllProfessionalPagData(currentPage, pageSize, userPageId);
            } else {
                getAllProfessionalPagData(currentPage, pageSize, userPageId);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleViewButtonClick = (userId: number, id: number) => {
        const combined = `userId=${userId}&id=${id}`;
        const encoded = btoa(combined);
        navigate(`/professional?v=${encoded}`);
        // navigate(`/view-profession-profile?userId=${userId}&id=${id}`);
        console.log(`View button clicked for userId: ${userId}, id: ${id}, encoded: ${encoded}`);
    };

    const showLoggedInConfirmDialog = () => {
        confirmAlert({
            title: 'Alert',
            message: 'You have to logIn.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { navigate('/login'); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log('clicked no') }
                }
            ]
        });
    };

    const handleEyeClick = (userId: number, pageId: number, postId: number) => {
        // navigate(`/view-professional-page-post?userId=${userId}&pageId=${pageId}&postId=${postId}`);
        const combined = `pageId=${pageId}&postId=${postId}`;
        const encoded = btoa(combined);
        navigate(`/post?v=${encoded}`);
        console.log(`View button clicked for userId: ${userId}, pageId: ${pageId}, postId: ${postId}`);
    };

    useEffect(() => {
        getProfessionalPageDetailsByCreatedBy();
        window.scrollTo(0, 0);
    }, []);

    const avatars = [
        {
            image: Avatar
        },
        {
            image: Avatar
        },
        {
            image: Avatar
        },
        {
            image: Avatar
        }
    ];

    return <>
        <div className="my_professional_page_background">
            <Header></Header>
            {loading ? (
                <div className="d-flex justify-content-center"
                    style={{ marginTop: '20%', marginBottom: '20%', background: 'white' }}>
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="main_page container">
                        <Row>
                            <div className="sec-heading center mt-4 m-0 mb-3">
                                <h2>Professionals</h2>
                                {/* <p>Description</p> */}
                            </div>
                        </Row>
                        <Row className="p-3 pt-0 pb-0 d-flex justify-content-center">
                            {Array.isArray(professionalPageUsersData) && professionalPageUsersData.map((page, pageIndex) => (
                                <Col key={pageIndex} md={6} className="d-flex justify-content-center mb-5">
                                    <div className="professional_page_users_card">
                                        {page.professionalPagePosts.slice(0, 4).filter(pp => pp.postType === "NORMAL_POST").map((post, postIndex) => (
                                            <div className="pc-project" key={postIndex}>
                                                {post.professionalPagePostImageGalleryData.map((image, imageIndex) => (
                                                    <Col key={imageIndex}>
                                                        {image.mimeType === 'IMAGE' ? (
                                                            <img
                                                                className="pc-project-link"
                                                                src={image.imagePath}
                                                                width={"100%"} height={"100%"}
                                                                alt="Profile"
                                                            />
                                                        ) : (
                                                            <div className="pc-project-link d-flex align-items-center justify-content-center">
                                                                <video
                                                                    className=""
                                                                    style={{ width: '100%', height: '100%', background: '#2e2e2e' }}>
                                                                    <source src={image.imagePath} />
                                                                </video>
                                                                <div className="d-flex align-items-center justify-content-center video-play-icon">
                                                                    <PlayCircleOutlineIcon style={{ fontSize: 'xx-large' }} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="pc-project-overlay">
                                                            <a className="pc-project-overlay-link">
                                                                <RemoveRedEyeOutlinedIcon onClick={() => handleEyeClick(page.createdBy, page.id, post.id)} />
                                                            </a>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </div>
                                        ))}
                                        {/* {Array.from({ length: Math.max(0, 4 - page.professionalPagePosts.length) }).map((_, index) => ( */}
                                        {Array.from({ length: Math.max(0, 4 - page.professionalPagePosts.slice(0, 4).filter(pp => pp.postType === "NORMAL_POST").length) }).map((_, index) => (
                                            <div className="pc-project" key={`avatar-${index}`}>
                                                <Col>
                                                    <img
                                                        className="pc-project-link"
                                                        src={avatars[index].image}
                                                        width={"100%"} height={"100%"}
                                                        alt="Profile"
                                                    />
                                                </Col>
                                            </div>
                                        ))}
                                        <div className="pc-user">
                                            {/* <div> */}
                                            <img
                                                className="pc-user-image mt-3 ms-3 p-0"
                                                // src={page.profileImageUrl}
                                                src={page.profileImageUrl != null ? page.profileImageUrl : ProfilePic}
                                                width="90" height="90"
                                                alt="Profile"
                                            />
                                            {/* </div> */}
                                            <div className="pc-user-info">
                                                <h3>
                                                    <a className="pc-user-name" style={{ wordBreak: "break-word" }}>{page.name}</a>
                                                </h3>
                                                <div className="pc-user-title">
                                                    <div style={{ color: "#A199AD" }}>{page.professionTypeLabel}</div>
                                                </div>
                                                <div className="pc-user-location">
                                                    <div style={{ color: "#A199AD" }}>
                                                        <LocationOnIcon className="mb-1 professional_page_users_location_icon" />{page.locationName}
                                                    </div>
                                                </div>
                                                <Row className="mt-3">
                                                    <FacebookIcon className="professional_page_users_social_icon me-2" />
                                                    <TwitterIcon className="professional_page_users_social_icon me-2" />
                                                    <YouTubeIcon className="professional_page_users_social_icon me-2" />
                                                    <InstagramIcon className="professional_page_users_social_icon me-2" />
                                                    <PinterestIcon className="professional_page_users_social_icon" />
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="pc-user-buttons">
                                            <a className="pc-btn accent" onClick={() => handleViewButtonClick(page.createdBy, page.id)}>View</a>
                                            <a className="pc-btn ">Media</a>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        <Row className="pb-5">
                            <div className="text-center">
                                {professionalPageUsersData.length < totalRecord && (
                                    <Button
                                        onClick={handleLoadMoreClick}
                                        type="button"
                                        className="">Load More
                                    </Button>
                                )}
                            </div>
                        </Row>
                    </div>
                    <Footer></Footer>
                </>
            )}
        </div>
    </>
}
export default ProfessionalPageUsers;