import "./register-page.css";
import { useEffect, useState } from "react";
import RegisterApiService from "../../data/api/services/register/register-api-service";
import { SignupPayload, UpdateRegistrationPayload } from "../../data/api/services/register/signup-payload";
import { toast } from 'react-toastify';
import { Footer, Header } from "../../layouts";
import UserTypeWidget from "../../widgets/user-type/user-type-widget";
import ProfessionalPageApiService from "../../data/api/services/professional-page/professional-page-api-service";
import Select from "react-select";
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead/AsyncTypeahead";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropertyApiService from "../../data/api/services/property/property-api-service";

const RegisterRole = ({ registrationPayload, updatePayload, onNextCallback }) => {

    const registerService: RegisterApiService = new RegisterApiService();
    const tabNumber: number = 3;
    const [userType, setUserType] = useState("");
    const propertyApiService = new PropertyApiService();
    const professionalPageApiservice = new ProfessionalPageApiService();
    const [professionTypeData, setProfessionTypeData] = useState([]);
    const [selectedProfessionTypeIdStr, setselectedProfessionTypeIdStr] = useState<any>([]);
    const [isVisibleProfessionType, setIsVisibleProfessionType] = useState(false);
    const [professionTypeValidationErrorMsg, setProfessionTypeValidationErrorMsg] = useState(false);
    const [locationData, setLocationData] = useState<any>([]);
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [locationValidationError, setLocationValidationError] = useState(false);
    const [showCodeAppliedText, setShowAppliedText] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [promoCodeValidationError, setPromoCodeValidationError] = useState(false);
    const [verifyCodeProcess, setVerifyCodeProcess] = useState(false);
    const [promoCode, setPromoCode] = useState<any>('');
    const [promoCodeText, setPromoCodeText] = useState("");
    const [coupenCode, setCoupenCode] = useState("");

    // const handleUserTypeChange = (userType: any) => {
    //     setUserType(userType);
    //     setIsVisibleProfessionType(true);
    // };

    const getProfessionTypeData = async () => {
        let data = await professionalPageApiservice.getAllProfessionType();
        setProfessionTypeData(data);
    };

    const handleSelectedProfessionType = (spt: any) => {
        setselectedProfessionTypeIdStr(spt);
        setProfessionTypeValidationErrorMsg(false)
    };

    const filterOption = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    const validateUserType = () => {
        let isFormValid = true;
        // if (!userType) {
        //     toast.error("Role required", { containerId: 'TR' });
        //     isFormValid = false;
        // }
        if (selectedProfessionTypeIdStr.length === 0) {
            setProfessionTypeValidationErrorMsg(true);
            isFormValid = false;
        }
        if (selectedLocation.length == 0) {
            setLocationValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    }

    const executeRegisterApi = async () => {
        try {
            if (validateUserType()) {
                if (registrationPayload.logInType == "NORMAL") {
                    submitContactDetailsFormData();
                    let payload: SignupPayload = {
                        firstName: registrationPayload.firstName,
                        lastName: registrationPayload.lastName,
                        email: registrationPayload.email,
                        mobile: registrationPayload.mobile,
                        password: registrationPayload.password,
                        userType: "",
                        locationId: selectedLocation[0].id,
                        otp: registrationPayload.otp,
                        membershipBenefitMapperId: "",
                        amount: "",
                        promoCode: coupenCode,
                        logInType: registrationPayload.logInType,
                        professionTypeId: selectedProfessionTypeIdStr.id
                    };
                    updatePayload(payload);
                    onNextCallback((tabNumber + 1));
                } else {
                    let payload: UpdateRegistrationPayload = {
                        id: registrationPayload.id,
                        firstName: registrationPayload.firstName,
                        lastName: "",
                        email: registrationPayload.email,
                        mobile: "",
                        password: registrationPayload.password,
                        userType: "",
                        locationId: selectedLocation[0].id,
                        membershipBenefitMapperId: "",
                        amount: "",
                        promoCode: coupenCode,
                        logInType: registrationPayload.logInType,
                        professionTypeId: selectedProfessionTypeIdStr.id
                    };
                    updatePayload(payload);
                    onNextCallback((tabNumber - 1));
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const submitContactDetailsFormData = async () => {
        try {
            let formData: FormData = new FormData();
            formData.append('firstName', registrationPayload.firstName)
            formData.append('address', "")
            formData.append('companyName', "")
            formData.append('latitude', "")
            formData.append('longitude', "")
            formData.append('lastName', registrationPayload.lastName)
            formData.append('whatsAppNumber', registrationPayload.mobile)
            formData.append('email', registrationPayload.email)
            formData.append('genderType', "")
            formData.append('professionIdStr', "")
            formData.append('ageIdStr', "")
            formData.append('locationIdStr', selectedLocation[0].id)
            formData.append('countryCode', "")
            formData.append('profileImageURL', "")
            formData.append('contactEntryTypeCode', "FROM_REGISTER")
            await registerService.saveContactDetails(formData);
        } catch (error: any) {
            console.error(error);
        }
    };

    const onLocationSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
        }
        setLocationValidationError(e.length === 0);
    };

    const clearSelectedLocation = () => {
        setSelectedLocation([]);
    };

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        setLocationData(data);
    };

    const renderMenuItemChildren = (option, props, index) => {
        return (
            <div key={index}>
                <span style={{ color: 'black' }}>{option.name}</span>
                {' | '}
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName}</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.talukaName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.districtName}</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>
            </div>
        );
    };

    const openDialog = () => {
        setShowPopUp(true);
    };

    const handleClose = () => {
        setShowPopUp(false);
    };

    const handlePromoCodeChange = (value: any) => {
        setPromoCodeText(value);
        setPromoCodeValidationError(false);
    };

    const validatePromoCode = () => {
        let isFormValid = true;

        if (!promoCodeText) {
            setPromoCodeValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    };

    const getPromoCodeByCode = async () => {
        try {
            if (validatePromoCode()) {
                setVerifyCodeProcess(true);
                let res = await registerService.getPromoCodeByCode(promoCodeText);
                setPromoCode(res);
                toast.success("PromoCode Verified!", { containerId: 'TR' });
                console.log('PromoCode', res);
            }
        } catch (err: any) {
            setVerifyCodeProcess(false);
            toast.error(err.response.data.message, { containerId: 'TR' });
        }
    };

    const applyPromoCode = () => {
        setShowPopUp(false);
        toast.success("PromoCode Applied Successfully!", { containerId: 'TR' });
        setCoupenCode(promoCodeText);
        setShowAppliedText(true);
    };


    useEffect(() => {
        getProfessionTypeData();
    }, []);

    return <>
        <div id="app">
            <Header></Header>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9 mt-3">
                            <div id="sign-up" className="modal-content">
                                <div className="modal-body">
                                    <h5 className="text-center">Select Your Location and Profession</h5>
                                    {/* <br /> */}
                                    {/* <div className="row d-flex justify-content-center">
                                        <div className="col-lg-6 col-md-6">
                                            <UserTypeWidget onUserTypeChangeListener={handleUserTypeChange}></UserTypeWidget>
                                        </div>
                                    </div> */}
                                    {/* {isVisibleProfessionType && ( */}
                                    <Row className="d-flex justify-content-center align-items-center">
                                        <div className="col-lg-6 col-md-8">
                                            <label>Select Your Location<sup className="required">*</sup></label>
                                            {/* <div className="form-group"> */}
                                            <Col className="justify-content-end align-items-center d-flex async-container">
                                                <Col xs="auto" className="close-icon">
                                                    {selectedLocation.length > 0 && (
                                                        <FontAwesomeIcon icon={faTimes} onClick={clearSelectedLocation} />
                                                    )}
                                                </Col>
                                                <Col>
                                                    <AsyncTypeahead
                                                        className={`form-control p-0 ${locationValidationError ? 'is-invalid' : ''}`}
                                                        // style={{ height: '56px', backgroundImage: 'none', width: 'auto' }}
                                                        id="basic-typeahead-single"
                                                        labelKey="formatedLocationData"
                                                        onSearch={(e) => searchLocation(e)}
                                                        options={locationData}
                                                        onChange={onLocationSelected}
                                                        useCache={false}
                                                        placeholder="Location"
                                                        defaultSelected={selectedLocation}
                                                        isLoading={locationSearchLoading}
                                                        selected={selectedLocation}
                                                        renderMenuItemChildren={renderMenuItemChildren}
                                                    />
                                                    {locationValidationError && <div className="invalid-feedback">Location required</div>}
                                                </Col>
                                            </Col>
                                            {/* </div> */}
                                        </div>
                                    </Row>
                                    <Row className="d-flex justify-content-center align-items-center mt-2">
                                        <div className="col-lg-6 col-md-8">
                                            <label>Select Your Profession<sup className="required">*</sup></label>
                                            <Select
                                                className={`custom-select form-control p-0 ${professionTypeValidationErrorMsg ? 'is-invalid' : ''}`}
                                                options={professionTypeData}
                                                value={selectedProfessionTypeIdStr}
                                                onChange={handleSelectedProfessionType}
                                                filterOption={filterOption}
                                                isSearchable
                                                placeholder="Search..."
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderColor: '#ced4da',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            borderColor: '#80bdff'
                                                        },
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: 'black',
                                                        background: 'white',
                                                        '&:hover': {
                                                            backgroundColor: '#f8f9fa',
                                                        },
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: '#495057',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '15px',
                                                        overflow: 'hidden',
                                                    }),
                                                    placeholder: (provided) => ({
                                                        ...provided,
                                                        color: '#6c757d',
                                                    }),
                                                }}
                                            />
                                            {professionTypeValidationErrorMsg && <div className="invalid-feedback">Profession required</div>}
                                        </div>
                                    </Row>
                                    {/* )} */}
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group text-center mt-3">
                                            {!showCodeAppliedText && <p>Have any PromoCode ? <a className="link d-block d-sm-inline-block text-sm-left text-danger text-center" onClick={openDialog}> apply</a>
                                            </p>}
                                            {showCodeAppliedText && <p className="green-text">Promo Code Applied !</p>}
                                        </div>
                                    </div>
                                    <Row className="d-flex justify-content-center align-items-center mt-3">
                                        <div className="form-group col-lg-6 col-md-8">
                                            <button type="button" className="btn btn-md full-width btn-theme-light-2 rounded" onClick={executeRegisterApi}>
                                                Register
                                            </button>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
        <Modal size="lg" show={showPopUp} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Verify Your Promo Code</Modal.Title>
            </Modal.Header>
            <div >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-2 mt-2">
                                    <label>Promo Code :</label>
                                </div>
                                <div className="col-sm-4">
                                    <input type="text" className={`input-value form-control ${promoCodeValidationError ? 'is-invalid' : ''}`} value={promoCodeText} onChange={(e) => { handlePromoCodeChange(e.target.value) }}
                                        placeholder="Enter your code" name="firstName" />                                                 <i className="ti-user"></i>
                                    {promoCodeValidationError && <div className="invalid-feedback"> Promo code required</div>}
                                </div>
                                <div className="col-sm-3 mt-1">
                                    {!verifyCodeProcess && <button className="btn btn-success" type="button"
                                        onClick={getPromoCodeByCode}> verify</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {verifyCodeProcess && <div className="modal-body">
                <div className="row">
                    <div className="row">
                        <div className="col-sm-2 mt-2">
                            <label>You will get </label>
                        </div>
                        <div className="col-sm-3">
                            <label>{promoCode.discountAmount}</label>
                        </div>
                        <div className="col-sm-3 mt-1">
                            <button className="btn btn-success" type="button" onClick={applyPromoCode}>apply</button>
                        </div>
                    </div>
                </div>
            </div>}
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default RegisterRole;

