import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import Header from "../header/header";
import "./contact.css"
import { Button, Col, Row } from "antd";
import FooterApiService from "../../data/api/services/footer/footer-api-service";
const Contact = () => {
    const footerApiService = new FooterApiService();
    const [data, setData] = useState<any>([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const footerType = queryParams.get('footerType');
    const [aboutToggle, setAboutToggle] = useState('');



    const aboutType = [
        // {
        //     title: "About us",
        //     value: "ABOUT",


        // },
        {
            title: "Contact us",
            value: "CONTACT_US",


        },
        // {
        //     title: "Terms & Condition",
        //     value: "TERMS_CONDITION",

        // }
    ]
    const toggleCustomerType = (customerTypeEv: any) => {
        setAboutToggle(customerTypeEv.defaultValue);
        getFooterTypeDetails(customerTypeEv.defaultValue);
    };

    const getFooterTypeDetails = async (customerTypeEv: any) => {
        try {
            if (customerTypeEv) {
                let data = await footerApiService.getFooterAboutByCode(customerTypeEv);
                setData(data);

            } else {
                let data = await footerApiService.getFooterAboutByCode(footerType);
                setData(data);
            }
            console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", data)
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    useEffect(() => {
        getFooterTypeDetails(null);
        setAboutToggle(footerType);
        }, []);
    return <>
        <div className="mb-5">
            <Header />
        </div>
        <section>
            {/* <div className="container">
                <div className="row">
                    <label className="about-header"> About </label>
                </div>
            </div> */}
            <div className="container mt-3">
                <Row>
                    {aboutType.map((item: any) => (
                        <Col>
                            <label className="about-radio">
                                <input type="radio" value={item.value} checked={aboutToggle === item.value} name="radio" onClick={e => { toggleCustomerType(e.target) }} />
                                <span className="about-btn">
                                    <h3 className="text-label">{item.title}</h3>
                                </span>
                            </label>
                        </Col>))
                    }
                </Row>
                <Row>
                    {aboutToggle == 'ABOUT' &&
                        <Col className="full-card my-3">
                            {data.map((itemData: any) => (
                                <p className="text-fs" >
                                    {itemData.content ? parse(itemData.content) : ''}
                                </p>
                            ))}
                        </Col>
                    }
                    </Row>
                    <Row>
                    {aboutToggle == 'CONTACT_US' &&
                        <Col className="full-card my-3 w-100 text-center p-5">
                            {data.map((itemData: any) => (
                                <p className="text-fs" >
                                    {itemData.content ? parse(itemData.content) : ''}
                                </p>
                            ))}
                        </Col>
                    }
                    {aboutToggle == 'TERMS_CONDITION' &&
                        <Col className="full-card my-3">
                            {data.map((itemData: any) => (
                                <p className="text-fs" >
                                    {itemData.content ? parse(itemData.content) : ''}
                                </p>
                            ))}
                        </Col>
                    }
                </Row>
            </div>
        </section>


    </>
}
export default Contact