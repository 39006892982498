import { Col } from "react-bootstrap";
import Header from "../header/header";
import "./contact-us.css";
import Footer from "../footer/footer";
import { useEffect, useState } from "react";
import FormFieldError from "../../components/form-field-error/form-field-error";
import ContactUsApiService from "../../data/api/services/contactus/contactus-api-service";
import { toast } from "react-toastify";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import { ContactUsPayload } from "../../data/api/services/contactus/contactus-payload";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
    const [contactUsValidationErrorMsg, setContactUsValidationNameErrorMsg] = useState(null);
    // const [contactUsIdStr, setContactUsIdStr] = useState("");
    const [showContactUsOption, setShowContactUsOption] = useState(true);
    const [contactUsData, setContactUsData] = useState([]);
    const [showFrontDoorTypeOption, setShowFrontDoorTypeOption] = useState(true);
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [purpose, setContactUsIdStr] = useState()
    const [phoneNumber, setPhoneNumber] = useState('');
    const [website, setWebsite] = useState('');
    const [message, setMessage] = useState('');

    const contactUsApiService = new ContactUsApiService();
    let navigate: any = useNavigate();


    const getTemplateData = async () => {
        try {
            let result = await contactUsApiService.getTemplatesData();
            console.log("sssssssssssssssssssssssssssssss", result);
            setData(result.purposeData);
        } catch (error) {
            console.log("getTemplateData Error");
            console.log(error);
        }
    }
    const saveContact = async () => {
        if (!name) {
            toast.error("please enter name", { containerId: 'TR' });
            return;
        }
        if (!purpose) {
            toast.error("please enter purpose", { containerId: 'TR' });
            return;
        }
        if (!email) {
            toast.error("please select email", { containerId: 'TR' });
            return;
        }
        if (!phoneNumber) {
            toast.error("please select phoneNumber", { containerId: 'TR' });
            return;
        }
        if (!website) {
            toast.error("please select website", { containerId: 'TR' });
            return;
        }
        if (!message) {
            toast.error("please select message", { containerId: 'TR' });
            return;
        }
        await Contactus();
    }


    const Contactus = async () => {
        try {
            let payload: ContactUsPayload = {
                name: name,
                purpose: purpose,
                email: email,
                phoneNumber: phoneNumber,
                website: website,
                message: message
            }
                ;
            await contactUsApiService.saveContact(payload);
            toast.success('Successfully ContactSaved', { containerId: 'TR' });
            navigate('/')
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const handleContactUsChange = (cuId: any) => {
        setContactUsIdStr(cuId);
        setContactUsValidationNameErrorMsg('');
        setShowContactUsOption(false);
    };

    useEffect(() => {
        getTemplateData();
    }, []);

    return <>
        <div className="mb-5">
            <Header />
        </div>
        <div className="container mb-5">
            <div className="row contact-us">
                <div className="col-sm-3 d-flex justify-content-center mt-3 mb-3">
                    <div>
                        <div className="mb-3">
                            <span className="fw-bold">Veedu App Digital Marketing<br />
                                {/* <span>CIN: U74120MH2013PTC249271
                                </span> */}
                            </span>
                        </div>
                        <div className="mb-3">
                            <span>Registered Address</span>
                        </div>
                        <span className="fw-semibold">Veedu App
                        </span><br />
                        {/* 2/12, South Thamaraikulam & Post,<br />
                        Krishnasamy Compound<br />
                        K.K. DIST.<br />
                        Pincode - 629701<br />
                        +91 93 8430 8530 */}
                        No: 2/12, South Thamaraikulam, Kanyakumari District - 629701. <br />
                        +91 94 8606 0448
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="row justify-content-center">
                        <div className="card contact-us-container">
                            <div className="card-body p-4">
                                <h5 className="">Contact Us</h5>
                                <form className="row g-2">
                                    <div className="mb-2">
                                        <select className={contactUsValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                            value={purpose} onChange={(e) => handleContactUsChange(e.target.value)}>
                                            {showContactUsOption && <option value="">Purpose</option>}
                                            {data.map((cuData: any, index: any) => (<option className="text-edit" key={cuData.id} value={cuData.id}>{cuData.label}</option>))}
                                        </select>
                                        <FormFieldError errorMessage={contactUsValidationErrorMsg}></FormFieldError>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <input type="text"
                                            value={name} onChange={e => { setName(e.target.value) }} className="form-control" placeholder="Your Name" />
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <input type="text"
                                            value={email} onChange={e => { setEmail(e.target.value) }} className="form-control" placeholder="Email" />
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <input type="text"
                                            value={phoneNumber} onChange={e => { setPhoneNumber(e.target.value) }} className="form-control" placeholder="PhoneNumber" />
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <input type="text"
                                            value={website} onChange={e => { setWebsite(e.target.value) }} className="form-control" placeholder="Website" />
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <input type="text"
                                            value={message} onChange={e => { setMessage(e.target.value) }} className="form-control" placeholder="Message" />
                                    </div>
                                    <div className="form-group">
                                        <button onClick={saveContact} type="button" className="btn w-100 submit-btn">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="">
            <Footer />
        </div>
    </>

}
export default ContactUs;