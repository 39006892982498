import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import Avatar from "../../../assets/profile-pic.jpeg";
import { toast } from 'react-toastify';
import ProfessionalPageApiService from '../../../data/api/services/professional-page/professional-page-api-service';
import CloseIcon from '@mui/icons-material/Close';
import CoverImage from "../../../assets/coverImage.jpeg"

const UploadProfileImage = (props: any) => {

    const professionalPageApiservice = new ProfessionalPageApiService();
    const [uploadProfileOrCoverImageFormData, setUploadProfileOrCoverImageFormData] = useState<any>(new FormData());
    const fileInputRef = useRef(null);
    const [profileImage, setProfileImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const cropperRef = useRef(null);

    const handleImageSelection = (event) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            setProfileImage(URL.createObjectURL(file));
            setShowModal(true);
        } else {
            setProfileImage(null);
        }
    };

    const handleCropComplete = async (croppedArea, croppedAreaPixels) => {
        try {
            const croppedImageBase64 = await getCroppedImg(
                profileImage,
                croppedAreaPixels,
                1
            );
            setCroppedImage(croppedImageBase64);
        } catch (e) {
            console.error('Error creating cropped image:', e);
        }
    };

    const handleCropImage = async () => {
        if (profileImage) {
            try {
                setShowModal(false);
            } catch (error) {
                console.error('Error cropping image:', error.message);
                setShowModal(false);
            }
        } else {
            console.error('Error: Unable to crop image. Missing data.');
            setShowModal(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCroppedImage(null);
    };

    const clearProfileImage = () => {
        setCroppedImage(null);
    };

    const getCroppedImg = (imagePath, crop, quality = 0.92) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imagePath;
            image.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = crop.width;
                    canvas.height = crop.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(
                        image,
                        crop.x,
                        crop.y,
                        crop.width,
                        crop.height,
                        0,
                        0,
                        crop.width,
                        crop.height
                    );
                    resolve(canvas.toDataURL('image/jpeg', quality));
                } catch (error) {
                    console.error('Error in getCroppedImg:', error);
                    reject(error);
                }
            };
            image.onerror = (error) => {
                console.error('Image failed to load in getCroppedImg:', error);
                reject(error);
            };
        });
    };

    const dataURItoFile = (dataURI, type, filename) => {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type });
        return new File([blob], filename, { type });
    };

    const uploadPageProfileAndCoverImageFormData = () => {
        try {
            if (croppedImage) {
                if (uploadProfileOrCoverImageFormData.has("imagePath")) {
                    uploadProfileOrCoverImageFormData.delete('imagePath');
                }
                const filename = 'profileImage.jpg';
                const file = dataURItoFile(croppedImage, 'image/jpeg', filename);
                uploadProfileOrCoverImageFormData.append('imagePath', file);
            } else {
                if (uploadProfileOrCoverImageFormData.has("imagePath")) {
                    uploadProfileOrCoverImageFormData.delete('imagePath');
                }
                uploadProfileOrCoverImageFormData.append('imagePath', profileImage);
            }

            if (uploadProfileOrCoverImageFormData.has('imageType')) {
                uploadProfileOrCoverImageFormData.delete('imageType');
            }
            uploadProfileOrCoverImageFormData.append('imageType', props.imageType);

        } catch (error: any) {
            throw error;
        }
    }

    const upload = async () => {
        try {
            uploadPageProfileAndCoverImageFormData();
            let data = await professionalPageApiservice.uploadPageProfileAndCoverImage(props.pageId, uploadProfileOrCoverImageFormData);
            console.log(data)
            toast.success('Uploaded Image Successfully', { containerId: 'TR' });
            window.location.reload();
        } catch (error: any) {
        }
    }

    return (
        <div className="container d-flex justify-content-center p-5"
        >
            <Col md={12}>
                <div className="d-flex justify-content-end">
                    <CloseIcon
                        style={{ background: '#dceded', fontSize: 'xx-large', borderRadius: '30%' }}
                        onClick={props.handleClose} />
                </div>
                <div className="d-flex justify-content-center mb-3" style={{ fontWeight: 'bold' }}>
                    {props.imageType == "PROFILE_IMAGE" ? ("Upload Profile Image") : ("Upload Cover Image")}
                </div>
                {croppedImage ? (
                    <Col className="mt-2">
                        <Col md={12} className="d-flex justify-content-center">
                            {/* <img
                                src={croppedImage}
                                alt="Selected Profile"
                                height={300} width={300}
                            /> */}
                            {props.imageType === "PROFILE_IMAGE" ? (
                                <img
                                    src={croppedImage}
                                    alt="Selected Profile Image"
                                    height={300} width={300}
                                />
                            ) : (
                                <img
                                    src={croppedImage}
                                    alt="Selected Cover Image"
                                    height={"100%"} width={"100%"}
                                />
                            )}
                        </Col>
                        <Col md={12} className="d-flex justify-content-center mt-3">
                            <button
                                type="button"
                                className="btn btn-danger m-2"
                                aria-label="Close"
                                onClick={() => clearProfileImage()}>
                                <span className="" aria-hidden="true">
                                    Remove
                                </span>
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary m-2"
                                onClick={() => upload()}>
                                <span className="" aria-hidden="true">
                                    Upload
                                </span>
                            </button>
                        </Col>
                    </Col>
                ) : (
                    <Col>
                        <Col md={12} className="d-flex justify-content-center">
                            {/* <img
                                src={Avatar}
                                alt="Selected Profile"
                                height={300} width={300}
                            /> */}
                            {props.imageType === "PROFILE_IMAGE" ? (
                                <img
                                    src={props.pageData.profileImageUrl ? props.pageData.profileImageUrl : Avatar}
                                    alt="Select Profile Image"
                                    height={300} width={300}
                                />
                            ) : (
                                <img
                                    src={props.pageData.coverImageURL ? props.pageData.coverImageURL : CoverImage}
                                    alt="Select Cover Image"
                                    height={"100%"} width={"100%"}
                                />
                            )}
                        </Col>
                        <Col md={12} className="mt-4 d-flex justify-content-center">
                            <input type="file" onChange={handleImageSelection} ref={fileInputRef} style={{ display: 'none' }} />
                            <input
                                type="button"
                                className="form-control border-dark w-auto"
                                value="Upload Image"
                                onClick={() => fileInputRef.current.click()}
                            />
                        </Col>
                    </Col>
                )}
                <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
                    <Modal.Body className="p-3">
                        <Col className="" style={{ position: 'relative', width: '100%', height: '450px' }}>
                            {props.imageType === "PROFILE_IMAGE" ? (
                                <Cropper
                                    ref={cropperRef}
                                    image={profileImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={2 / 2}
                                    onCropChange={setCrop}
                                    onCropComplete={handleCropComplete}
                                    onZoomChange={setZoom}
                                />
                            ) : (
                                <Cropper
                                    ref={cropperRef}
                                    image={profileImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 2}
                                    onCropChange={setCrop}
                                    onCropComplete={handleCropComplete}
                                    onZoomChange={setZoom}
                                />
                            )}
                        </Col>
                        <Row className='d-flex align-items-baseline'>
                            <Col md={6} className='d-flex justify-content-center pt-2'>
                                <input
                                    type="range"
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    onChange={(e) => setZoom(parseFloat(e.target.value))}
                                />
                            </Col>
                            <Col md={6} className='d-flex justify-content-around pt-2'>
                                <Button onClick={handleCropImage}>Crop Image</Button>
                                <Button onClick={handleCloseModal}>Cancel</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button onClick={handleCropImage}>Crop Image</Button>
                        <Button onClick={handleCloseModal}>Cancel</Button>
                    </Modal.Footer> */}
                </Modal>
            </Col>
        </div>
    );
};

export default UploadProfileImage;