import { useEffect, useState } from "react";
import "./profession-post-share.css";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import StorageService from "../../../data/storage/storage-service";
import { Button, Col, Row } from 'react-bootstrap';
import { PostSharePayload } from "../../../data/api/services/professional-page/professional-page-payload";
import Avatar from "../../../assets/profile-pic.jpeg";

const ProfessionPostShare = (props: any) => {

    const professionalPageApiservice = new ProfessionalPageApiService();
    const [professionalPostShareData, setprofessionalPostShareData] = useState([]);
    const [shareUrl, setShareUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const [userId, setUserId] = useState('');
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const [selectedProfessionalPostShare, setSelectedProfessionalPostShare] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecord, setTotalRecord] = useState(0);

    const handleButtonClick = (selectedId: number, index: number) => {
        const newButtonStates = [...buttonStates];
        newButtonStates[index] = !newButtonStates[index];
        setButtonStates(newButtonStates);
        const isSelected = selectedProfessionalPostShare.some((item) => item.id === selectedId);
        if (isSelected) {
            setSelectedProfessionalPostShare((previousValue) =>
                previousValue.filter((item) => item.id !== selectedId)
            );
        } else {
            setSelectedProfessionalPostShare((previousValue) => [...previousValue, { id: selectedId }]);
        }
    };

    const getProfessionalPagePostDataList = async () => {
        try {
            let res = await professionalPageApiservice.getProfessionalPageData(userId);
            setProfessionalPagePosts(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleLoadMoreClick = () => {
        const updatedPageSize = pageSize + 10;
        setPageSize(updatedPageSize);
        getTemplateData(currentPage, updatedPageSize, userPageId);
    };

    const getProfessionalPageById = async (userPageId) => {
        try {
            let res = await professionalPageApiservice.getProfessionalPageById(userPageId);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProfessionalPageDetails();
        getProfessionalPagePostDataList();
    }, []);

    const saveProfessionalPageShare = async () => {
        setIsLoading(true);
        try {
            if (selectedProfessionalPostShare.length === 0) {
                toast.error('please selected Any Person', { containerId: 'TR' });
                setIsLoading(false);
                return;
            }
            let payload: PostSharePayload = {
                shareUrl: shareUrl,
                sharedById: userPageId,
                parentPostId: props.data.id,
                shareTo: selectedProfessionalPostShare,
            };
            await professionalPageApiservice.saveProfessionalPostShare(payload);
            toast.success('Post Share Successfully', { containerId: 'TR' });
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const getTemplateData = async (page, size, userPageId) => {
        try {
            let url = `?page=${page}&pageSize=${size}&logInPageId=${userPageId}`;
            let res = await professionalPageApiservice.getAllProfessionalPageData(url);
            console.log(res);
            setprofessionalPostShareData(res.data);
            setTotalRecord(res.totalRecord);
        } catch (error) {
            console.log("getDimensionData Error");
            console.log(error);
        }
    };

    const [buttonStates, setButtonStates] = useState(
        professionalPostShareData.map(() => false)
    );

    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let res = await professionalPageApiservice.getProfessionalPageData(userId);
            setUserPageData(res);
            const activePageItem = res.find(item => item.activePage === true);
            const userPageId = activePageItem?.id;
            getProfessionalPageById(userPageId);
            getTemplateData(currentPage, pageSize, userPageId);
        } catch (error) {
            console.log(error);
        }
    };

    return <>
        <div className="p-3">
            <div className="d-flex justify-content-between align-items-center">
                <div className="col-sm-10 text-center">
                    <label className="fs-5  my-auto text-dark fw-bold">Share Post</label>
                </div>
                <button className="btn " onClick={props.handleClose} ><CloseIcon /></button>
            </div>
            <hr />
            <div className="switch-account-scrollbar"
                style={{ maxHeight: '600px', overflowY: 'auto' }}>
                {professionalPostShareData &&
                    professionalPostShareData.map((ppsData: any, index: number) => (
                        <Col key={ppsData.id} className="d-flex align-items-center p-2 m-2 post-share-users justify-content-between">
                            <Col md={1} className=""
                                style={{ height: '50px', width: '50px' }}>
                                <img
                                    src={ppsData.profileImageUrl ? ppsData.profileImageUrl : Avatar}
                                    height={50} width={50}
                                    style={{ borderRadius: '50%' }}
                                    className=""
                                    alt="Profile"
                                />
                            </Col>
                            <Col md={9} className="m-1">
                                <div style={{ fontWeight: '600', fontSize: 'large', wordBreak: 'break-word' }}>
                                    {ppsData.name}
                                </div>
                                <div style={{ color: 'grey' }}>
                                    {ppsData.email}
                                </div>
                            </Col>
                            <Col md={2}
                                onClick={() => handleButtonClick(ppsData.id, index)}
                                className="d-flex justify-content-center report-send-undo-button-color"
                                style={{
                                    background: buttonStates[index] ? '#e74c3c' : '#3498db'
                                }}>
                                <button
                                    className={"report-send-undo-button"}>
                                    {buttonStates[index] ? 'UNDO' : 'SEND'}
                                </button>
                            </Col>
                        </Col>
                    ))}
            </div>
            <div className="row">
                <div className="text-center mt-4">
                    <div className="d-inline-block">
                        {professionalPostShareData.length < totalRecord && (
                            <Button
                                onClick={handleLoadMoreClick}
                                type="button"
                                className="btn btn-primary">
                                Load More
                            </Button>
                        )}
                    </div>
                    <div className="text-end share-btn-container">
                        <Button
                            className="btn btn-success"
                            type="submit"
                            onClick={saveProfessionalPageShare}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Sharing...' : 'ShareNow'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default ProfessionPostShare;