import { useEffect, useState } from "react";
import "./profession-page-report.css";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import StorageService from "../../../data/storage/storage-service";
import { PageReportPayload, } from "../../../data/api/services/professional-page/professional-page-payload";

const ProfessionPageReport = (id: any) => {

    const professionalPageApiservice = new ProfessionalPageApiService();
    const maxLength = 100;
    const minLength = 0;
    const [professionalPageReportData, setprofessionalPageReportData] = useState([]);
    const [report, setReport] = useState('');
    const [data, setData] = useState<any>([]);
    const [userId, setUserId] = useState('');
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const [reportValidationErrorMsg, setReportValidationErrorMsg] = useState(null);
    const [professionalPostReportsValidationErrorMsg, setProfessionalPostReportsValidationErrorMsg] = useState(null);
    const [selectedProfessionalPostReports, setselectedProfessionalPostReports] = useState<any[]>([]);
    const [selectedOthersCheckbox, setSelectedOthersCheckbox] = useState(false);

    const handleProfessionalPostReportChange = (selectedValues: string[]) => {
        setselectedProfessionalPostReports(selectedValues);
        console.log(selectedValues, "selectedValues");
        setProfessionalPostReportsValidationErrorMsg('');
    };

    const handleReportOnChange = (ds: any) => {
        setReport(ds);
    };

    const getProfessionalPagePostDataList = async () => {
        try {
            let res = await professionalPageApiservice.getProfessionalPageData(userId);
            setProfessionalPagePosts(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    const getProfessionalPageById = async (userPageId) => {
        try {
            let res = await professionalPageApiservice.getProfessionalPageById(userPageId);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    };

    const saveProfessionalPageReport = async () => {
        try {
            let payload: PageReportPayload = {
                report: report,
                pageUserId: id.id,
                professionalPageLoginPersonId: userPageId,
                professionalPostReport: selectedProfessionalPostReports,
            };
            await professionalPageApiservice.saveProfessionalPageReport('PAGE_REPORT', payload);
            toast.success('Report Page Successfully', { containerId: 'TR' });
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }

    const getTemplateData = async () => {
        let data = await professionalPageApiservice.getProfessionalPageTemplateData();
        console.log(data);
        setprofessionalPageReportData(data.professionalPageReportData);
    };

    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let res = await professionalPageApiservice.getProfessionalPageData(userId);
            setUserPageData(res);
            const activePageItem = res.find(item => item.activePage === true);
            const userPageId = activePageItem?.id;
            getProfessionalPageById(userPageId);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProfessionalPageDetails();
        getProfessionalPagePostDataList();
        getTemplateData();
    }, []);

    return <>
        <div className="p-3">
            <div className="d-flex justify-content-between">
                <div className="col-sm-10 text-center">
                    <label className="fs-5  my-auto text-dark fw-bold">Report</label>
                </div>
                <button className="btn report-close-button" onClick={id.handleClose} ><CloseIcon /></button>
            </div>
            <hr />
            <div className="row px-5">
                <div className="col-sm-12">
                    <div className="">
                        {professionalPageReportData &&
                            professionalPageReportData.map((pprData: any) => (
                                <label key={pprData.id} className="form-check d-flex justify-content-between p-2">
                                    <span className="form-check-label text-dark"
                                        style={{ fontSize: '15px', fontWeight: '500' }}>
                                        {pprData.label}
                                    </span>
                                    <input
                                        type="checkbox"
                                        id={`ctaCheckbox_${pprData.id}`}
                                        className="form-check ml-2"
                                        value={pprData.id}
                                        checked={selectedProfessionalPostReports.some(item => item.id === pprData.id)}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                                handleProfessionalPostReportChange([...selectedProfessionalPostReports, { id: pprData.id }]);
                                            } else {
                                                handleProfessionalPostReportChange(
                                                    selectedProfessionalPostReports.filter((item) => item.id !== pprData.id)
                                                );
                                            }
                                        }}
                                    />
                                </label>
                            ))}
                    </div>
                    <FormFieldError errorMessage={professionalPostReportsValidationErrorMsg} />
                    <div className="form-check d-flex justify-content-between p-2">
                        <label htmlFor="othersCheckbox" className="form-check-label text-dark"
                            style={{ fontSize: '15px', fontWeight: '500' }}>
                            Other
                        </label>
                        <input
                            type="checkbox"
                            id="othersCheckbox"
                            className="form-check ml-2"
                            checked={selectedOthersCheckbox}
                            onChange={(e) => {
                                setSelectedOthersCheckbox(e.target.checked);
                            }}
                        />
                    </div>
                </div>
            </div>

            {selectedOthersCheckbox && (
                <div className="row mt-1">
                    <div className="col-sm-12 px-5">
                        <textarea
                            className={`input-value form-control p-3 ${reportValidationErrorMsg ? 'error-field input-edit' : ''}`}
                            placeholder="Add report"
                            value={report}
                            minLength={minLength}
                            maxLength={maxLength}
                            onChange={(e) => handleReportOnChange(e.target.value)}
                            rows={3}
                            cols={28}
                        />
                        <FormFieldError errorMessage={reportValidationErrorMsg}></FormFieldError>
                        <div className='under-text m-0 mt-2'>
                            <div className="d-flex justify-content-between">
                                <span>Remaining :</span>
                                <span>{minLength + report.length}/{maxLength}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="text-end p-3">
                <button className="btn btn-success" onClick={saveProfessionalPageReport} type="button">Report Page</button>
            </div>

        </div>
    </>
}
export default ProfessionPageReport;