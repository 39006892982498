import { Header, Footer } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { Accordion, Carousel } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import "./free-questions.css"
import parse from 'html-react-parser';
import PropertyApiService from "../../data/api/services/property/property-api-service";

const FreeQuestions = (props: { priceType: any; }) => {

  const [freeQuestionData, setFreeQuestionData] = useState<any>([]);
  // const {state} = useLocation();
  // const id = state.id
  const propertyApiService = new PropertyApiService();
  const [searchParams, setSearchParams] = useSearchParams();

  const memberShipCode = searchParams.get('memberShipCode');

  let navigate: any = useNavigate();

  const getMemberShipFAQ = async (priceType:any) => {
    try {
      let data = await propertyApiService.getMemberShipFAQ(priceType);
      setFreeQuestionData(data);
      console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", data)
    } catch (error) {
      console.log("FAQ Error");
      console.log(error);
    }
  }

  useEffect(() => {
    console.log("priceType",props.priceType);
    getMemberShipFAQ(props.priceType);

  }, [props.priceType]);



  return (
    <>
      <div className="App ">
        <div>
          <h2>Frequently Asked Question</h2>
        </div>
        <div className="container mb-5">
            <div className="row">
        {freeQuestionData && freeQuestionData.map((freeQuestionData: any, index: any) => (
          <div className="col-sm-6 my-3">

          <Accordion key={freeQuestionData.id} >
            <Accordion.Item eventKey={freeQuestionData.id} >
              <Accordion.Header >
              {freeQuestionData.questions ? parse(freeQuestionData.questions) : ''}
              </Accordion.Header>
              <Accordion.Body>
                <div >
                {freeQuestionData.answers ? parse(freeQuestionData.answers) : ''}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </div>
        ))}
        </div>
        </div>
      </div>
    </>
  );
};

export default FreeQuestions;