import { useEffect, useRef, useState } from 'react';
import OTPPageLogo from '../../assets/otp-page-logo.png'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { toast } from 'react-toastify';
import AuthApiService from '../../data/api/services/auth/auth-api-service';
import { useNavigate } from 'react-router-dom';

const ForgetPasswordOTP = (props: any) => {

    const authService: AuthApiService = new AuthApiService();
    let navigate: any = useNavigate();
    const [mobileNumber, setMobileNumber] = useState(props.mobileNo);
    const [otp, setOtp] = useState(props.otpData.otp)
    const [otpDigits, setOtpDigits] = useState(['', '', '', '']);
    const inputRefs = useRef([]);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [seconds, setSeconds] = useState(10);
    const [userId, setUserId] = useState(props.userData);

    useEffect(() => {
        let timer;
        if (isOtpSent && seconds > 0) {
            timer = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        }
        if (seconds === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, seconds]);

    const handleResendOtp = () => {
        setIsOtpSent(true);
        setSeconds(10);
        resendOtp();
    };

    useEffect(() => {
        setIsOtpSent(true);
        setSeconds(10);
    }, []);

    const handleOtpChange = (index, value) => {
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            const newOtpDigits = [...otpDigits];
            newOtpDigits[index] = value;
            setOtpDigits(newOtpDigits);
            if (index < otpDigits.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const displayLastTwoDigits = (number) => {
        const length = number.length;
        if (length < 2) {
            return number;
        }
        const firstTwoDigits = number.substring(0, 2);
        const lastTwoDigits = number.substring(number.length - 2);
        const maskedNumber = `${firstTwoDigits}******${lastTwoDigits}`;
        return maskedNumber;
    };

    const handleContinue = () => {
        const enteredOtp = otpDigits.join('');
        if (enteredOtp === otp) {
            console.log('OTP is correct');
            navigate('/changePassword', { state: { userId } });
        } else {
            toast.error('Incorrect OTP. Please try again.', { containerId: 'TR' });
        }
    };

    const resendOtp = async () => {
        try {
            let data = await authService.generateOtp(mobileNumber);
            setOtp(data.otp);
            console.log(data.otp);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    };

    return (
        <>
            <div className="d-flex justify-content-end">
                <button className="btn text-danger" onClick={props.handleClose}> <HighlightOffIcon /></button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="px-3">
                    <div className="text-center">
                        <img src={OTPPageLogo} width="50px" alt="" />
                    </div>
                    <h5 className="m-0 text-center">Mobile phone verification</h5>
                    <span className="mobile-text">
                        Enter the code we just sent on your mobile phone {' '}
                        <b className="text-danger">{displayLastTwoDigits(mobileNumber)}</b>
                    </span>
                    <div className="d-flex justify-content-center mt-5">
                        {otpDigits.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                className="form-control text-input text-center"
                                value={digit}
                                maxLength={1}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                ref={(input) => (inputRefs.current[index] = input)}
                            />
                        ))}
                    </div>
                    <div className="text-center my-3">
                        <span className="d-block mobile-text">
                            Not received an OTP?{' '}
                            <a
                                href="#"
                                className={`${isOtpSent ? 'text-muted' : 'text-danger'}`}
                                onClick={e => {
                                    e.preventDefault();
                                    if (!isOtpSent) handleResendOtp();
                                }}
                                style={{ pointerEvents: isOtpSent ? 'none' : 'auto', opacity: isOtpSent ? 0.5 : 1 }}
                            >
                                Resend OTP{isOtpSent && ` (${seconds}s)`}
                            </a>
                        </span>
                        <button
                            className="font-weight-bold rounded btn btn-danger cursor mt-2"
                            onClick={handleContinue}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgetPasswordOTP;