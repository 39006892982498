import { useCallback, useEffect, useRef, useState } from "react";
import './edit-professionalpage-contact-info.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FaPhone } from "react-icons/fa";
import Select from 'react-select';
import ProfessionalPageApiService from "../../../../data/api/services/professional-page/professional-page-api-service";
import FormFieldError from "../../../../components/form-field-error/form-field-error";
import { Link } from "@mui/material";
import { Button, Col, Modal, Nav, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ValidationError from "../../../../common/validation-error";
import { confirmAlert } from "react-confirm-alert";
import StorageService from "../../../../data/storage/storage-service";
import ProfessionalPageContactInfoImage from '../../../../assets/professional-page-5.jpeg';
import Cropper from "react-easy-crop";

const EditProfessionalPageContactInfo = ({ professionalPageId, professionalPageFormData, onPreviousCallback }) => {
    const tabNumber: number = 5;
    const [showNext, setShowNext] = useState(false);
    const [croppedImageSrc, setCroppedImageSrc] = useState<any>();
    const [cropCover, setCropCover] = useState({ x: 0, y: 0 });
    const [cropCoverConfirmed, setCropCoverConfirmed] = useState(false);

    const professionalPageApiservice = new ProfessionalPageApiService();
    const [countryOptions, setcountryOptions] = useState([]);
    const [nickNameValidationErrorMsg, setNickValidationNameErrorMsg] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [showExtraFilters, setShowExtraFilters] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [website, setWebsite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [showCroppedImage, setShowCroppedImage] = useState(true);
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youTube, setYouTube] = useState('');
    const [pinterest, setPinterest] = useState('');
    const [profileImage, setProfileImage] = useState<any>();
    const profileImageInput: any = useRef(null);
    const [coverImage, setCoverImage] = useState<any>();
    const coverImageInput: any = useRef(null);
    const [isNewProfileImage, setIsNewProfileImage] = useState("false");
    const [isNewCoverImage, setIsNewCoverImage] = useState("false");
    const [zoom, setZoom] = useState(1)
    const [cropConfirmed, setCropConfirmed] = useState(false);
    const [showProfileImageModal, setShowProfileImageModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const [profileImageValidationErrorMsg, setProfileImageValidationErrorMsg] = useState(null);
    const [coverImageValidationErrorMsg, setCoverImageValidationErrorMsg] = useState(null);
    const [croppedCoverImageSrc, setCroppedCoverImageSrc] = useState<any>();

    let navigate: any = useNavigate();

    const handleCountryChange = (cc: any) => {
        setSelectedCountry(cc);
        console.log(cc);
    };
    const dataURItoFiles = (dataURI, type, filename) => {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
      
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
      
        // Create a Blob
        const blob = new Blob([ab], { type });
      
        // Create a File with the Blob and filename
        return new File([blob], filename, { type });
      };
    const dataURItoFile = (dataURI, type, filename) => {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
      
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
      
        // Create a Blob
        const blob = new Blob([ab], { type });
      
        // Create a File with the Blob and filename
        return new File([blob], filename, { type });
      };
    const handleMobileNumberOnChange = (mn: any) => {
        setMobileNumber(mn);
    };

    const handleWebsiteOnChange = (ws: any) => {
        setWebsite(ws);
    };

    const handleFacebookOnChange = (fb: any) => {
        setFacebook(fb);
    }

    const handleTwitterOnChange = (tw: any) => {
        setTwitter(tw);
    }

    const handleInstagramOnChange = (ig: any) => {
        setInstagram(ig);
    }

    const handleYouTubeOnChange = (yt: any) => {
        setYouTube(yt);
    }
    const [crop, setCrop] = useState({ x: 0, y: 0 });

    const handlePinterestOnChange = (pt: any) => {
        setPinterest(pt);
    }
    const [completedCrop, setCompletedCrop] = useState<Point>({
        x: 0,
        y: 0,

    });
    const validateContactInfoFormData = (): void => {
        let errors: any[] = [];

        if (!selectedCountry) {
            let error: any = {};
            error.validationCode = "countryIdStr";
            error.message = 'Country Code required';
            errors.push(error);
        }
        if (!mobileNumber) {
            let error: any = {};
            error.validationCode = "mobile";
            error.message = 'Mobile Number required';
            errors.push(error);
        }
        if (!profileImage) {
            let error: any = {};
            error.validationCode = "profileImage";
            error.message = 'Profile Image required';
            errors.push(error);
        }
        if (!coverImage) {
            let error: any = {};
            error.validationCode = "coverImage";
            error.message = 'Cover Image required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const submitContactInfoFormData = async () => {
        try {
            validateAndSaveContactInfoFormData();
            let data = await professionalPageApiservice.updateProfessionalPage(professionalPageId, professionalPageFormData);
            navigate('/')
            console.log(data)
            toast.success('Successfully Updated Your ProfessionalPage Id as ' + data.id + '.', { containerId: 'TR' });
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const validateAndSaveContactInfoFormData = () => {
        try {
            validateContactInfoFormData();
            if (professionalPageFormData.has('countryIdStr')) {
                professionalPageFormData.delete('countryIdStr');
            }
            professionalPageFormData.append('countryIdStr', selectedCountry.countryCode);
            if (professionalPageFormData.has('mobile')) {
                professionalPageFormData.delete('mobile');
            }
            professionalPageFormData.append('mobile', mobileNumber);
            if (professionalPageFormData.has('website')) {
                professionalPageFormData.delete('website');
            }
            professionalPageFormData.append('website', website);
            if (croppedImageSrc) {
                // If croppedImageSrc has a value, remove the existing profileImage from FormData
                if (professionalPageFormData.has("profileImage")) {
                  professionalPageFormData.delete('profileImage');
                }
              
                // Provide a filename for the File object
                const filename = 'profileImage.jpg';
                const file = dataURItoFile(croppedImageSrc, 'image/jpeg', filename);
              
                // Append the new cropped image to FormData
                professionalPageFormData.append('profileImage', file);
              } else {
                // If croppedImageSrc is null, remove the existing profileImage from FormData
                if (professionalPageFormData.has("profileImage")) {
                  professionalPageFormData.delete('profileImage');
                }
              
                // Append the original profileImage to FormData
                professionalPageFormData.append('profileImage', profileImage.file);
              }
              
              if (croppedCoverImageSrc) {
                // If croppedImageSrc has a value, remove the existing profileImage from FormData
                if (professionalPageFormData.has("coverImage")) {
                  professionalPageFormData.delete('coverImage');
                }
              
                // Provide a filename for the File object
                const filename = 'coverImage.jpg';
                const file = dataURItoFiles(croppedCoverImageSrc, 'image/jpeg', filename);
              
                // Append the new cropped image to FormData
                professionalPageFormData.append('coverImage', file);
              } else {
                // If croppedImageSrc is null, remove the existing profileImage from FormData
                if (professionalPageFormData.has("coverImage")) {
                  professionalPageFormData.delete('coverImage');
                }
              
                // Append the original profileImage to FormData
                professionalPageFormData.append('coverImage', coverImage.file);
              }
              
            if (professionalPageFormData.has('facebook')) {
                professionalPageFormData.delete('facebook');
            }
            professionalPageFormData.append('facebook', facebook);

            if (professionalPageFormData.has('twitter')) {
                professionalPageFormData.delete('twitter');
            }
            professionalPageFormData.append('twitter', twitter);

            if (professionalPageFormData.has('instagram')) {
                professionalPageFormData.delete('instagram');
            }
            professionalPageFormData.append('instagram', instagram);

            if (professionalPageFormData.has('youTube')) {
                professionalPageFormData.delete('youTube');
            }
            professionalPageFormData.append('youTube', youTube);

            if (professionalPageFormData.has('pinterest')) {
                professionalPageFormData.delete('pinterest');
            }
            professionalPageFormData.append('pinterest', pinterest);
            if (professionalPageFormData.has("isNewProfileImage")) {
                professionalPageFormData.delete('isNewProfileImage');
            }
            professionalPageFormData.append('isNewProfileImage', isNewProfileImage.toString());
            if (professionalPageFormData.has("isNewCoverImage")) {
                professionalPageFormData.delete('isNewCoverImage');
            }
            professionalPageFormData.append('isNewCoverImage', isNewCoverImage.toString());

        } catch (error: any) {
            throw error;
        }
    }

    const onProfileImageUploadListener = () => {
        profileImageInput.current?.click();
    }
    const [zoomCover, setZoomCover] = useState(1)

    const handleCloseProfileImageModal = () => {
        setShowProfileImageModal(false);
    };
    const onProfessionalProfileImageUploadListener = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            let profileImage: any = {};
            profileImage.file = file;
            profileImage.imagePath = URL.createObjectURL(profileImage.file);
            setIsNewProfileImage("true");
            setProfileImage(profileImage);
            setProfileImageValidationErrorMsg('');
            
            // Update showProfileImageModal to true when a new image is selected
            setShowProfileImageModal(true);
        } else {
            setProfileImage(null);
            setProfileImageValidationErrorMsg('Invalid file. Please upload a valid image file.');
        }
    }
    
    const clearProfileImage = () => {
        setProfileImage(null);
    }

    const onCoverImageUploadListener = () => {
        coverImageInput.current?.click();
    }

    const onProfessionalCoverImageUploadListener = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            let coverImage: any = {};
            coverImage.file = file;
            coverImage.imagePath = URL.createObjectURL(coverImage.file);
            setIsNewCoverImage("true");
            setCoverImage(coverImage);
            setCoverImageValidationErrorMsg('');
            setShowModal(true);

        } else {
            setCoverImage(null);
            setCoverImageValidationErrorMsg('Invalid file. Please upload a valid image file.');
        }
    }
   
    
    const [showCoverCroppedImage, setShowCoverCroppedImage] = useState(true);
    const [completedCoverCrop, setCompletedCoverCrop] = useState<Point>({
        x: 0,
        y: 0,

    });
    const clearCoverImage = () => {
        setCoverImage(null);
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'profileImage') {
                setProfileImageValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'coverImage') {
                setCoverImageValidationErrorMsg(errorMsg);
            }
        }
    }

    const highlightBasicInformationFormData = async () => {
        let formData: FormData = professionalPageFormData;
        if (formData.get('countryIdStr')) {
            const countryId = formData.get('countryIdStr').toString();
            const selectedLocationData = await professionalPageApiservice.getCountryById(countryId);
            setSelectedCountry(selectedLocationData);
        }
        if (formData.get('mobile')) {
            setMobileNumber(formData.get('mobile').toString());
        }
        if (formData.get('website')) {
            setWebsite(formData.get('website').toString());
        }
        if (formData.get('facebook')) {
            setFacebook(formData.get('facebook').toString());
        }
        if (formData.get('twitter')) {
            setTwitter(formData.get('twitter').toString());
        }
        if (formData.get('instagram')) {
            setInstagram(formData.get('instagram').toString());
        }
        if (formData.get('youTube')) {
            setYouTube(formData.get('youTube').toString());
        }
        if (formData.get('pinterest')) {
            setPinterest(formData.get('pinterest').toString());
        }
        hightlightProfessionalPageProfileImage();
        hightlightProfessionalPageCoverImage();
    }

    const hightlightProfessionalPageProfileImage = async () => {
        if (isNewProfileImage == 'true') {
            // setProfileImage();
            return;
        }
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let data = await professionalPageApiservice.getProfessionalPageById(professionalPageId);
            console.log(data);
            if (!data || !data.profileImageUrl) {
                return;
            }
            let profileImage: any = {};
            profileImage.imagePath = data.profileImageUrl;
            setProfileImage(profileImage);
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const hightlightProfessionalPageCoverImage = async () => {
        if (isNewCoverImage == 'true') {
            // setCoverImage();
            return;
        }

        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let data = await professionalPageApiservice.getProfessionalPageById(professionalPageId);
            console.log(data);
            if (!data || !data.coverImageURL) {
                return;
            }
            let coverImage: any = {};
            coverImage.imagePath = data.coverImageURL;
            setCoverImage(coverImage);
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    const onLoad = useCallback((img) => {
        setCrop((prevCrop) => ({
            ...prevCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
        setCompletedCrop((prevCompletedCrop) => ({
            ...prevCompletedCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
    }, []);
    const handleImageCrop = async () => {
        if (completedCrop && profileImage) {
            try {
                const originalAspectRatio = profileImage.width / profileImage.height;
                const croppedAspectRatio = completedCrop.width / completedCrop.height;
                 const desiredAspectRatio = 1; 
                  const aspectRatioTolerance = 1
                   setCropConfirmed(true);
                   setShowProfileImageModal(false);
                
            } catch (error) {
                console.error('Error cropping image:', error.message);
                setShowProfileImageModal(false);
            }
        } else {
            console.error('Error: Unable to crop image. Missing data.');
            setShowProfileImageModal(false);
        }
    };
    const onCropComplete = async (_, croppedAreaPixels) => {
        try {
          const croppedImageBase64 = await getCroppedImg(
            profileImage.imagePath, // Original image path
            croppedAreaPixels,
            1 // Output image quality, you can adjust this value
          );
          setCroppedImageSrc(croppedImageBase64);
        } catch (e) {
          console.error('Error creating cropped image:', e);
        }
      };
     
    type Point = {
        x: number;
        y: number;
        width?: number; // Add the width property
        height?: number; // Add the height property
    };
    const handleCoverImageCrop = async () => {
        if (completedCoverCrop && coverImage) {
            try {
                const originalAspectRatio = coverImage.width / coverImage.height;
                const croppedAspectRatio = completedCoverCrop.width / completedCoverCrop.height;
                 const desiredAspectRatio = 1; 
                  const aspectRatioTolerance = 1
                   setCropCoverConfirmed(true);
                   setShowModal(false);
                
            } catch (error) {
                console.error('Error cropping image:', error.message);
                setShowModal(false);
            }
        } else {
            console.error('Error: Unable to crop image. Missing data.');
            setShowModal(false);
        }
    };
    const onLoads = useCallback((img) => {
        setCropCover((prevCrop) => ({
            ...prevCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
        setCompletedCoverCrop((prevCompletedCrop) => ({
            ...prevCompletedCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
    }, []);
    const getCroppedImg = (imagePath, crop, quality = 0.92) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imagePath;

            image.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = crop.width;
                    canvas.height = crop.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(
                        image,
                        crop.x, // X position on the source image
                        crop.y, // Y position on the source image
                        crop.width, // Width on the source image
                        crop.height, // Height on the source image
                        0, // X position on the canvas
                        0, // Y position on the canvas
                        crop.width, // Width on the canvas
                        crop.height // Height on the canvas
                    );

                    resolve(canvas.toDataURL('image/jpeg', quality));
                } catch (error) {
                    console.error('Error in getCroppedImg:', error);
                    reject(error);
                }
            };

            image.onerror = (error) => {
                console.error('Image failed to load in getCroppedImg:', error);
                reject(error);
            };
        });
    }; const onCropCoverComplete = async (_, croppedPixels) => {
        try {
          const croppedImageBase = await getCroppedCoverImg(
            coverImage.imagePath, // Original image path
            croppedPixels,
            1 // Output image quality, you can adjust this value
          );
          setCroppedCoverImageSrc(croppedImageBase);
        } catch (e) {
          console.error('Error creating cropped image:', e);
        }
      };
      const getCroppedCoverImg = (imagePath, cropCover, quality = 0.92) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imagePath;

            image.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = cropCover.width;
                    canvas.height = cropCover.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(
                        image,
                        cropCover.x, // X position on the source image
                        cropCover.y, // Y position on the source image
                        cropCover.width, // Width on the source image
                        cropCover.height, // Height on the source image
                        0, // X position on the canvas
                        0, // Y position on the canvas
                        cropCover.width, // Width on the canvas
                        cropCover.height // Height on the canvas
                    );

                    resolve(canvas.toDataURL('image/jpeg', quality));
                } catch (error) {
                    console.error('Error in getCroppedImg:', error);
                    reject(error);
                }
            };

            image.onerror = (error) => {
                console.error('Image failed to load in getCroppedImg:', error);
                reject(error);
            };
        });
    };
    const getTemplateData = async () => {
        let data = await professionalPageApiservice.getProfessionalPageTemplateData();
        setcountryOptions(data.countryData)
        highlightBasicInformationFormData();
        console.log(data);
    }

    const preview = () => {
        onPreviousCallback(tabNumber - 1);
        setShowNext(false);
    }
    const previous = () => {
        confirmAlert({
            title: "Alert Message",
            message: "The form you have filled will get lost in this page. Are you sure want to go previous?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => preview(),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const handleShowFilters = () => {
        setShowExtraFilters(true);
    };

    const handleHideFilters = () => {
        setShowExtraFilters(false);
    };

    const hasSocialMediaData = () => {
        return facebook || twitter || instagram || youTube || pinterest;
    };

    useEffect(() => {
        getTemplateData();
        if (hasSocialMediaData()) {
            setShowExtraFilters(true);
        }
    }, []);

    return <>
        <div className="container boder-sy  my-4">
            <div className="row d-flex justify-content-center">
                <div className="col-sm-1 col-lg-1text-center mt-5">
                    <span className=""> <ArrowBackIcon onClick={previous} /></span>
                </div>
                <div className="col-sm-5 col-lg-5 d-flex align-self-center">
                    <img src={ProfessionalPageContactInfoImage} className='contact_info_image' alt="google-bussiness" ></img>
                </div>
                <div className="col-sm-7 col-lg-6 mt-3">
                    <section className="p-0 pt-4">
                        <div className="row">
                            <div className="col-sm-10 col-lg-12 d-flex LKfzVb mt-2">
                                <h3 className="text-center">What contact details do you want to show to customers?</h3>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Help customers get in touch by including this info on your listing Phone number.</span>

                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <hr />
                            </div>
                            <div className="col-sm-12">
                                <div className="mobile-number-input">
                                    <div className="input-container">
                                        <span className="phone-icon">
                                            <FaPhone />
                                        </span>
                                        <Select
                                            className="country-code-select custom-select"
                                            options={countryOptions}
                                            getOptionLabel={(option) => `${option.countryCode}`}
                                            value={selectedCountry}
                                            onChange={handleCountryChange}
                                            placeholder="Code"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: 'black',
                                                    background: 'white',
                                                })
                                            }}
                                        />
                                        <input
                                            className={`input-value form-control p-3 ${nickNameValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                            type="text"
                                            placeholder="Enter Mobile Number"
                                            value={mobileNumber}
                                            onChange={e => { handleMobileNumberOnChange(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="span-font mt-5">
                        </div>
                        <div className="col-sm-12">
                            <input
                                className="input-value form-control p-3 "
                                value={website !== null && website !== "null" ? website : ''}
                                onChange={e => { handleWebsiteOnChange(e.target.value) }}
                                type="text"
                                placeholder='Website (optional)'
                            />
                            <FormFieldError errorMessage={nickNameValidationErrorMsg}></FormFieldError>
                        </div>
                         <div className="row col-sm-12">
      <div className="col-sm-6 mb-2 ">
        <label className="mt-4">Upload Profile Image<sup className="required">*</sup></label>
        <div className="mt-4 h-auto" style={{ display: 'none' }}>
          <input
            type="file"
            className={profileImageValidationErrorMsg ? 'error-field input-edit ' : 'input-edit '}
            ref={profileImageInput}
            onChange={onProfessionalProfileImageUploadListener}
          />
        </div>
        {!profileImage && (
          <div>
            <div className="mt-4 h-auto">
              <input
                type="button"
                className="form-control border-dark w-auto"
                value="Upload Profile Image"
                onClick={onProfileImageUploadListener}
              />
            </div>
            <FormFieldError errorMessage={profileImageValidationErrorMsg}></FormFieldError>
          </div>
        )}
        {profileImage ? (
  <Row className="mt-2">
    <Col md={12} className="d-flex justify-content-center">
      {croppedImageSrc ? (
        // Render the cropped image if croppedImageSrc has a value
        <img
          src={croppedImageSrc}
          onLoad={(e) => onLoad(e.target)}
          alt="Cropped Profile"
          style={{ width: '100%', height: 200 }}
        />
      ) : (
        // Render the original profile image if croppedImageSrc is null
        <img
          src={profileImage.imagePath}
          width={'100%'}
          height={200}
          alt="Selected Profile"
        />
      )}
    </Col>
    <Col md={12} className="">
      <button
        type="button"
        className="btn btn-danger btn-sm rounded-pill mt-2"
        aria-label="Close"
        onClick={() => clearProfileImage()}
      >
        <span className="" aria-hidden="true">
          Remove
        </span>
      </button>
    </Col>
  </Row>
) : null}

      </div>

      {showProfileImageModal && (
        <Modal show={showProfileImageModal} onHide={handleCloseProfileImageModal}>
          <Modal.Body>
            {profileImage && profileImage.imagePath && (
              <>
                {!showCroppedImage && (
                  <img src={profileImage.imagePath} width={'100%'} height={200} alt="Selected Profile" />
                )}
                {showCroppedImage && (
                  <div className="row" style={{ position: 'relative', width: '100%', height: '450px' }}>
                    <Cropper
                      image={profileImage.imagePath}
                      crop={crop}
                      zoom={zoom}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      aspect={1 / 1}
                    />
                    <div className="controls row">
                      <input
                        type="range"
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e) => {
                          setZoom(parseFloat(e.target.value));
                        }}
                        className="zoom-range"
                      />
                    </div>
                    {cropConfirmed && croppedImageSrc && (
                      <img src={croppedImageSrc} width={'100%'} height={'100%'} alt="Cropped Image" />
                    )}
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {showCroppedImage && (
              <Button variant="primary" onClick={handleImageCrop}>
                Confirm Crop
              </Button>
            )}
          </Modal.Footer>
        </Modal>
                            )}
                            <div className="col-sm-6 mb-2 ">
                                <label className="mt-4">Upload Cover Image<sup className="required">*</sup></label>
                                <div className="mt-4 h-auto" style={{ display: 'none' }}>
                                    <input type="file" className={coverImageValidationErrorMsg ? 'error-field input-edit ' : 'input-edit '} ref={coverImageInput} onChange={onProfessionalCoverImageUploadListener} />
                                </div>
                                {!coverImage && <div>
                                    <div className="mt-4 h-auto">
                                        <input type="button" className="form-control border-dark w-auto" value="Upload Cover Image" onClick={onCoverImageUploadListener} />
                                    </div>
                                    <FormFieldError errorMessage={coverImageValidationErrorMsg}></FormFieldError>
                                </div>}
                                {
                                    coverImage ? (
                                        <Row className="mt-2">
                                            <Col md={12} className="d-flex justify-content-center">
                                            {croppedCoverImageSrc ? (
        // Render the cropped image if croppedImageSrc has a value
        <img
          src={croppedCoverImageSrc}
          onLoad={(e) => onLoads(e.target)}
          alt="Cropped Profile"
          style={{ width: '100%', height: 200 }}
        />
      ) : (
        // Render the original profile image if croppedImageSrc is null
        <img
          src={coverImage.imagePath}
          width={'100%'}
          height={200}
          alt="Selected Profile"
        />
      )}                                            </Col>
                                            <Col md={12} className="">
                                                <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={() => clearCoverImage()}>
                                                    <span className="" aria-hidden="true">Remove</span>
                                                </button>
                                            </Col>
                                        </Row>
                                    ) : null
                                }
                            </div>
                        </div>
                        {showModal && (
                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Body>
                                        {coverImage && coverImage.imagePath && (
                                            <>
                                                {/* Display the original selected image */}
                                                {!showCoverCroppedImage && (
                                                    <img
                                                        src={coverImage.imagePath}
                                                        width={'100%'}
                                                        height={'100%'}
                                                        alt="Selected Profile"
                                                    />
                                                )}

                                                {/* Display the Cropper only if the user clicked the "Crop Image" button */}
                                                {showCoverCroppedImage && (
                                                    <div className="row" style={{ position: 'relative', width: '100%', height: '450px' }}>
                                                        <Cropper
  image={coverImage.imagePath}
  crop={cropCover}
  zoom={zoomCover}
  onCropChange={setCropCover}
  onZoomChange={setZoomCover}
  onCropComplete={onCropCoverComplete}
  aspect={1 / 1}
  cropSize={{ width: 400, height: 200 }} 
/>

                                                        <div className="controls row">
                                                            <input
                                                                type="range"
                                                                value={zoomCover}
                                                                min={1}
                                                                max={3}
                                                                step={0.1}
                                                                aria-labelledby="Zoom"
                                                                onChange={(e) => {
                                                                    setZoom(parseFloat(e.target.value))
                                                                }}
                                                                className="zoom-range"
                                                            />
                                                        </div>
                                                        {/* Display the cropped image */}
                                                        {cropCoverConfirmed && croppedCoverImageSrc && (
                                                            <img
                                                                src={croppedCoverImageSrc}
                                                                width={'100%'}
                                                                height={'100%'}
                                                                alt="Cropped Image"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>

                                        {showCoverCroppedImage && (
                                            <Button variant="primary" onClick={handleCoverImageCrop}>
                                                Confirm Crop
                                            </Button>
                                        )}


                                    </Modal.Footer>
                                </Modal>
                            )}
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <label className="d-flex justify-content-end p-2">
                                {!showExtraFilters && <Nav.Link className="underline-on-hover" onClick={handleShowFilters}>More Options</Nav.Link>}
                                {showExtraFilters && <Nav.Link className="underline-on-hover" onClick={handleHideFilters}>Less Options</Nav.Link>}
                            </label>
                        </div>

                        {showExtraFilters && (
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <input
                                        className="input-value form-control p-3 "
                                        value={facebook !== null && facebook !== "null" ? facebook : ''}
                                        onChange={e => { handleFacebookOnChange(e.target.value) }}
                                        type="text"
                                        placeholder='Enter Facebook Link (optional)'
                                    />
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <input
                                        className="input-value form-control p-3 "
                                        value={twitter !== null && twitter !== "null" ? twitter : ''}
                                        onChange={e => { handleTwitterOnChange(e.target.value) }}
                                        type="text"
                                        placeholder='Enter Twitter Link (optional)'
                                    />
                                </div>
                            </div>
                        )}

                        {showExtraFilters && (
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <input
                                        className="input-value form-control p-3 "
                                        value={instagram !== null && instagram !== "null" ? instagram : ''}
                                        onChange={e => { handleInstagramOnChange(e.target.value) }}
                                        type="text"
                                        placeholder='Enter Instagram Link (optional)'
                                    />
                                </div>

                                <div className="col-sm-6 mb-3">
                                    <input
                                        className="input-value form-control p-3 "
                                        value={youTube !== null && youTube !== "null" ? youTube : ''}
                                        onChange={e => { handleYouTubeOnChange(e.target.value) }}
                                        type="text"
                                        placeholder='Enter YouTube Link (optional)'
                                    />
                                </div>
                            </div>
                        )}

                        {showExtraFilters && (
                            <div className="col-sm-12 mb-3">
                                <input
                                    className="input-value form-control p-3 "
                                    value={pinterest !== null && pinterest !== "null" ? pinterest : ''}
                                    onChange={e => { handlePinterestOnChange(e.target.value) }}
                                    type="text"
                                    placeholder='Enter Pinterest Link (optional)'
                                />
                            </div>
                        )}
                        <div className="text-end p-5">
                            <button className="btn btn-success" onClick={submitContactInfoFormData} type="button">submit</button>
                        </div>
                        <div className="text-center">
                        </div>
                    </section>

                </div>
            </div>
        </div>
        <div>
        </div>
    </>
}
export default EditProfessionalPageContactInfo;