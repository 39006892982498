import HttpClientWrapper from "../../http-client-wrapper";

class LocationApiService {

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getLocationById = async (id:any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/locations/'+id);
            console.log("LocationApi getLocationById() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    
}
export default LocationApiService;