import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import { Footer, Header } from "../../../layouts";
import ToggleSwitch from "../../../components/toggle-switch/toggle-switch";
import sale from "../../../assets/for sale.jpg"
import Rent from "../../../assets/for rent.jpg"
import Land from "../../../assets/land.jpg"
import Model from "../../../assets/model house.jpg"
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ValidationError from "../../../common/validation-error";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "react-bootstrap/esm/Spinner";


const EditLandGeneralPage = ({ propertyId, deedType, propertyFormData, onNextCallback, setGeneralInfoCoverImage,
    generalInfoCoverImage }) => {

    const [landGeneralInfoFormData, setLandGeneralInfoFormData] = useState<any>(new FormData());

    const [showPropertyTypeOption, setShowPropertyTypeOption] = useState(true);
    const [showFacingOption, setShowFacingOption] = useState(true);
    const [showSellerTypeOption, setShowSellerTypeOption] = useState(true);

    const tabNumber: number = 1;

    const [propertyTypeData, setPropertyTypeData] = useState([]);
    const [propertyTypeIdStr, setPropertyTypeIdStr] = useState("");
    let navigate: any = useNavigate();
    const [propertyName, setPropertyName] = useState("");
    const [facingData, setFacingData] = useState([]);
    const [facingIdStr, setFacingIdStr] = useState("");
    const [sellerTypeData, setSellerTypeData] = useState<any>([]);
    const [sellerTypeIdStr, setSellerIdTypeStr] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const properyCoverImageInput: any = useRef(null);
    const [coverImage, setCoverImage] = useState<any>();
    const [deedTypeToggle, setDeedTypeToggle] = useState<String>(null);
    const propertyApiService = new PropertyApiService();
    const [deedTypeIdStr, setDeedTypeIdStr] = useState("");
    const [isNewCoverImage, setIsNewCoverImage] = useState("false");

    const [propertyNameValidationErrorMsg, setPropertyValidationNameErrorMsg] = useState(null);
    const [propertyTypeValidationErrorMsg, setPropertyTypeValidationErrorMsg] = useState(null);
    const [contactPhoneValidationErrorMsg, setContactPhoneValidationErrorMsg] = useState(null);
    const [contactPersonValidationErrorMsg, setContactPersonValidationErrorMsg] = useState(null);
    const [coverImageValidationErrorMsg, setCoverImageValidationErrorMsg] = useState(null);
    const [sellerTypeValidationErrorMsg, setSellerTypeValidationErrorMsg] = useState(null);
    const [propertyFacingValidationErrorMsg, setPropertyFacingValidationErrorMsg] = useState(null);
    const [processing, setProcessing ] = useState(false);


    const handlePropertyTypeChange = (ptId: any) => {
        setPropertyTypeIdStr(ptId);
        setPropertyTypeValidationErrorMsg('');
        setShowPropertyTypeOption(false);
    };

    const handleFacingChange = (facingId: any) => {
        setFacingIdStr(facingId);
        setPropertyFacingValidationErrorMsg('');
        setShowFacingOption(false);
    };

    const handleSellerTypeChange = (stId: any) => {
        setSellerIdTypeStr(stId);
        setSellerTypeValidationErrorMsg('');
        setShowSellerTypeOption(false);
    };

    const handleOnPropertyNameChange = (value: any) => {
        setPropertyName(value);
        setPropertyValidationNameErrorMsg('');
    }

    const handleOnContactPersonChange = (value: any) => {
        setContactPerson(value);
        setContactPersonValidationErrorMsg('');
    }

    const handleOnContactPhoneChange = (value: any) => {
        setContactPhone(value);
        setContactPhoneValidationErrorMsg('');
    }

    const validateLandGeneralFormData = (): void => {
        let errors: any[] = [];
        if (!propertyTypeIdStr) {
            let error: any = {};
            error.validationCode = "propertyType";
            error.message = 'Property Type required';
            errors.push(error);
            //setPropertyTypeValidationErrorMsg('Property Type required');
        }
        if (!propertyName) {
            let error: any = {};
            error.validationCode = "propertyName";
            error.message = 'Property Name required';
            errors.push(error);
            // setPropertyValidationNameErrorMsg('Property Name required');
        }
        if (!facingIdStr) {
            let error: any = {};
            error.validationCode = "facing";
            error.message = 'Property Facing required';
            errors.push(error);
            // setPropertyFacingValidationErrorMsg('Property Facing required')
        }
        if (!contactPerson) {
            let error: any = {};
            error.validationCode = "contactPerson";
            error.message = 'Contact Person required';
            errors.push(error);
            // setContactPersonValidationErrorMsg('Contact Person required');
        }
        if (!contactPhone) {
            let error: any = {};
            error.validationCode = "contactPhone";
            error.message = 'Contact Phone required';
            errors.push(error);
            // setContactPhoneValidationErrorMsg('Contact Phone required');
        }
        if (!sellerTypeIdStr) {
            let error: any = {};
            error.validationCode = "sellerTypeIdStr";
            error.message = 'Seller Type required';
            errors.push(error);
            // setSellerTypeValidationErrorMsg('Seller Type required');
        }
        if (isNewCoverImage == 'true' && !coverImage) {
            let error: any = {};
            error.validationCode = "coverImage";
            error.message = 'Cover Image required';
            errors.push(error);
        }
        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const submitLandGeneralFormData = async () => {
        try {
            validateAndSaveLandGeneralFormData();
            onNextCallback((tabNumber + 1));
        } catch (error) {
            showValidationErrorInlineUI(error);
        }
    }

    const updateLandGeneralFormData = async () => {
        try {
            validateAndSaveLandGeneralFormData();
            setProcessing(true);
            await propertyApiService.updateGeneral(propertyId, propertyFormData);
            toast.success('Successfully Property Updated ', { containerId: 'TR' });
            navigate('/')
        } catch (error) {
            setProcessing(false);
            showValidationErrorInlineUI(error);
        }
    }
    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'propertyType') {
                setPropertyTypeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'propertyName') {
                setPropertyValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'facing') {
                setPropertyFacingValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'contactPerson') {
                setContactPersonValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'contactPhone') {
                setContactPhoneValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'sellerTypeIdStr') {
                setSellerTypeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'coverImage') {
                setCoverImageValidationErrorMsg(errorMsg);
            }
        }
    }

    const validateAndSaveLandGeneralFormData = () => {
        try {
            validateLandGeneralFormData();
            if (propertyFormData.has('propertyName')) {
                propertyFormData.delete('propertyName');
            }
            propertyFormData.append('propertyName', propertyName);

            if (propertyFormData.has('propertyTypeId')) {
                propertyFormData.delete('propertyTypeId');
            }
            propertyFormData.append('propertyTypeId', propertyTypeIdStr.toString());

            if (propertyFormData.has('facing')) {
                propertyFormData.delete('facing');
            }
            propertyFormData.append('facing', facingIdStr);

            if (propertyFormData.has('sellerTypeIdStr')) {
                propertyFormData.delete('sellerTypeIdStr');
            }
            propertyFormData.append('sellerTypeIdStr', sellerTypeIdStr);

            if (propertyFormData.has('contactPerson')) {
                propertyFormData.delete('contactPerson');
            }
            propertyFormData.append('contactPerson', contactPerson);

            if (propertyFormData.has('contactPhone')) {
                propertyFormData.delete('contactPhone');
            }
            propertyFormData.append('contactPhone', contactPhone);

            if (propertyFormData.has('deedType')) {
                propertyFormData.delete('deedType');
            }
            propertyFormData.append('deedType', deedType);

            if (propertyFormData.has("coverImage")) {
                propertyFormData.delete('coverImage');
            }
            propertyFormData.append('coverImage', coverImage.file);
            setGeneralInfoCoverImage(coverImage);

            if (propertyFormData.has("isNewCoverImage")) {
                propertyFormData.delete('isNewCoverImage');
            }
            propertyFormData.append('isNewCoverImage', isNewCoverImage.toString());

        } catch (error: any) {
            console.log(error);
            throw error;
        }
    }
    const highlightLandFormData = () => {
        let formData: FormData = propertyFormData;

        if (formData.get('propertyName')) {
            setPropertyName(formData.get('propertyName').toString());
        }
        if (formData.get('propertyTypeId')) {
            setPropertyTypeIdStr(formData.get('propertyTypeId').toString());
        }
        if (formData.get('contactPhone')) {
            setContactPhone(formData.get('contactPhone').toString());
        }
        if (formData.get('contactPerson')) {
            setContactPerson(formData.get('contactPerson').toString());
        }
        if (formData.get('facing')) {
            setFacingIdStr(formData.get('facing').toString());
        }
        if (formData.get('sellerTypeIdStr')) {
            setSellerIdTypeStr(formData.get('sellerTypeIdStr').toString());
        }
        hightlightPropertyCoverImage();
    }

    const onCoverImageUploadListener = () => {
        properyCoverImageInput.current?.click();
    }

    const hightlightPropertyCoverImage = async () => {
        if (isNewCoverImage == 'true') {
            setCoverImage(generalInfoCoverImage);
            return;
        }
        try {
            console.log("START hightlightPropertyCoverImage() ====");
            let data = await propertyApiService.getProperty(propertyId);
            console.log(data);
            if (!data || !data.coverImageURL) {
                return;
            }
            let coverImage: any = {};
            coverImage.imagePath = data.coverImageURL;
            setCoverImage(coverImage);
            console.log("END hightlightPropertyCoverImage() ====");
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const onPropertyCoverImageUploadListener = (event: any) => {
        let coverImage: any = {};
        coverImage.file = event.target.files[0];
        coverImage.imagePath = URL.createObjectURL(coverImage.file);
        setIsNewCoverImage("true");
        setCoverImage(coverImage);
        setCoverImageValidationErrorMsg('');
    }

    const clearCoverImage = () => {
        setIsNewCoverImage("false");
        setCoverImage(null);
    }

    const getTemplatesData = async () => {
        try {
            let res = await propertyApiService.getGeneralPropertyTemplatesData();
            setPropertyTypeData(res.propertyTypeData);
            setFacingData(res.facingData);
            setSellerTypeData(res.sellerTypeData);
            highlightLandFormData();
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const removePropertyCoverImage = async (propertyId: any) => {
        try {
            if (!coverImage) {
                await propertyApiService.deleteCoverImage(propertyId);
            }
            setIsNewCoverImage("false");
            setCoverImage(null);
        } catch (error) {
            console.log("ERROR occurred in removePropertyCoverImage(): " + JSON.stringify(error));
        }
    }

    const CoverImage = () => {
        confirmAlert({
            title: "Delete",
            message: "Are you sure to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => removePropertyCoverImage(propertyId),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    useEffect(() => {
        getTemplatesData();
    }, []);

    return (
        <>
            <div className="body container-fluid row">
                <div className=" container w-100  ms-1">
                    <div className="card-body">
                        <div className="col-sm-9 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-color text-center">
                                        <p> General</p>
                                    </div>
                                    <div className="row ms-5">
                                        <div className="col-sm-6 ">
                                            <div className="mb-2">
                                                <label className="mt-4">Property Name<sup className="required">*</sup></label>
                                                <input
                                                    className={propertyNameValidationErrorMsg ? 'error-field input-edit form-control  mt-4 ' : 'input-edit form-control  mt-4'}
                                                    type="text"
                                                    placeholder="Property Name"
                                                    value={propertyName}
                                                    onChange={e => { handleOnPropertyNameChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={propertyNameValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2" >
                                                <label className="mt-4">Property Type<sup className="required">*</sup></label>
                                                <select className={propertyTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={propertyTypeIdStr} onChange={(e) => handlePropertyTypeChange(e.target.value)}>
                                                    {showPropertyTypeOption && <option value="">Select</option>}
                                                    {propertyTypeData.map((ptData: any, index: any) => (<option key={ptData.id} value={ptData.id}>{ptData.propertyTypeName}</option>))}
                                                </select>
                                                <FormFieldError errorMessage={propertyTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Property Facing<sup className="required">*</sup></label>
                                                <select className={propertyFacingValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={facingIdStr} onChange={(e) => handleFacingChange(e.target.value)}>
                                                    {showFacingOption && <option value="">Select</option>}
                                                    {facingData.map((facingData: any, index: any) => (<option className="text-edit" key={facingData.id} value={facingData.id}>{facingData.facingName}</option>))}
                                                </select>
                                                <FormFieldError errorMessage={propertyFacingValidationErrorMsg}></FormFieldError>
                                            </div>

                                            <div className="col-md-6 mb-2 ">
                                                <label className="mt-4">Upload Cover Image<sup className="required">*</sup></label>
                                                <div className="mt-4 h-auto" style={{ display: 'none' }}>
                                                    <input type="file" className={coverImageValidationErrorMsg ? 'error-field input-edit form-control border border-dark w-100' : 'input-edit form-control border border-dark w-100 '} ref={properyCoverImageInput} onChange={onPropertyCoverImageUploadListener} />
                                                </div>
                                                <div className="mt-4 h-auto">
                                                    <input type="button"  className="form-control border border-dark w-100" value="Upload Cover Image" onClick={onCoverImageUploadListener} />
                                                </div>
                                                <FormFieldError errorMessage={coverImageValidationErrorMsg}></FormFieldError>
                                                <div>
                                                    {
                                                        coverImage ? (<div className="col-md-6 mt-2">
                                                            <div className="col-md-12">
                                                                <div className="col-md-6" ><img src={coverImage.imagePath} alt='image' width={300} height={200}></img></div>
                                                                <div className="col-md-3">
                                                                    <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={CoverImage}>
                                                                        <span className="" aria-hidden="true">Remove</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">

                                            <div className="mb-2">
                                                <label className="mt-4">Seller Type<sup className="required">*</sup></label>
                                                <select className={sellerTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={sellerTypeIdStr} onChange={(e) => handleSellerTypeChange(e.target.value)}>
                                                    {showSellerTypeOption && <option value="">Select</option>}
                                                    {sellerTypeData.map((stData: any, index: any) => (
                                                        <option key={stData.id} value={stData.id}>{stData.sellerType}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={sellerTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Contact Person<sup className="required">*</sup></label>
                                                <input
                                                    className={contactPersonValidationErrorMsg ? 'error-field input-edit  form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    type="text"
                                                    placeholder="Contact Person"
                                                    value={contactPerson}
                                                    onChange={e => { handleOnContactPersonChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={contactPersonValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Contact Phone<sup className="required">*</sup></label>
                                                <input
                                                    className={contactPhoneValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    type="number"
                                                    min='0'
                                                    placeholder="Contact Phone"
                                                    value={contactPhone}
                                                    onChange={e => { handleOnContactPhoneChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={contactPhoneValidationErrorMsg}></FormFieldError>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4  d-flex justify-content-end">
                                    <button disabled={processing}  onClick={updateLandGeneralFormData} className="btn btn-warning me-3 " type="button">
                                        Update & Skip
                                        </button>
                                    {(processing)&& <Spinner animation="grow" variant="warning" />}
                                    <button onClick={submitLandGeneralFormData} className="btn btn-success me-5" type="button">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditLandGeneralPage;