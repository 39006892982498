import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import Header from "../header/header";
import "./about.css"
import { Col, Row } from "antd";
import FooterApiService from "../../data/api/services/footer/footer-api-service";
import Footer from "../footer/footer";



const About = ({ aboutType }) => {

    const footerApiService = new FooterApiService();
    const [data, setData] = useState<any>([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const footerType = queryParams.get('footerType');
    const [aboutToggle, setAboutToggle] = useState('');



    const aboutTypes = [
        {
            title: "Privacy policy",
            value: "PRIVACY_POLICY",
        },
        {
            title: "Terms of use",
            value: "TERMS_OF_USE",
        },
        {
            title: "Refund Policy",
            value: "REFUND_POLICY",
        }
    ]

    const getFooterTypeDetails = async (aboutTypeCode: any) => {
        try {
            if (aboutTypeCode) {
                let data = await footerApiService.getFooterAboutByCode(aboutTypeCode);
                setData(data);
            }
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    useEffect(() => {
        getFooterTypeDetails(aboutType);
        setAboutToggle(footerType);
    }, []);
    return <>
        <div className="mb-5">
            <Header />
        </div>
        <section>
            <div className="container mt-3">
                <Row>
                    {aboutTypes.map((item: any) => (
                        <Col>
                            <h3 className="text-label">
                                {item.value == aboutType ? parse(item.title) : ''}
                            </h3>
                        </Col>))
                    }
                </Row>
                <Row>

                    <Col className="full-card my-3">
                        {data.map((itemData: any) => (
                            <p className="text-fs" >
                                {itemData.content ? parse(itemData.content) : ''}
                            </p>
                        ))}
                    </Col>
                </Row>
            </div>
        </section>
        <Footer/>

    </>
}
export default About;