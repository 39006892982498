import { useEffect, useImperativeHandle, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateAmenities } from "../../../data/api/services/property/create-property-payload";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import Footer from "../../../layouts/footer/footer"
import Header from "../../../layouts/header/header"
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ValidationError from "../../../common/validation-error";
import Spinner from "react-bootstrap/esm/Spinner";

const EditAmenitiesPage = ({propertyId, onPreviousCallback, onNextCallback, propertyFormData }) => {
  const [amenitiesFormData, setAmenitiesFormData] = useState<any>(new FormData());
  let navigate: any = useNavigate();
  const tabNumber: number = 4;
  const [amenities, setAmenities] = useState<any>([]);
  const propertyApiService = new PropertyApiService();
  const [amenitiesValidationNameErrorMsg, setAmenitiesValidationNameErrorMsg] = useState(null);
  const [processing, setProcessing ] = useState(false);

  const previousPage = async () => {
    onPreviousCallback(tabNumber - 1);
  };

  const validateAmenityFormData = (): void => {
    let errors: any[] = [];
    const selectedAmenities = JSON.parse(getAllSelectedAmenity());
    if (selectedAmenities.length === 0) {
      let error: any = {};
      error.validationCode = "amenities";
      error.message = 'Amenities required';
      errors.push(error);
    }
    if (errors.length > 0) {
      throw new ValidationError(errors);
    }
  }
  const updateAmenity = async () => {
    try {
      validateAmenityFormData();
      let payload = getAllSelectedAmenity();
      setProcessing(true);
      await propertyApiService.updateAmenities(propertyId,payload);
      toast.success('Successfully Amenities Updated', { containerId: 'TR' });
      navigate('/');

    } catch (error) {
      setProcessing(false);
      showValidationErrorInlineUI(error);
    }
  }
  
  const submitAmenityFormData = () => {
    try {
      validateAndSaveAmenityFormData();
      onNextCallback((tabNumber + 1));
    } catch (error: any) {
      showValidationErrorInlineUI(error);
    }
  }

  const showValidationErrorInlineUI = (error: ValidationError) => {
    let errors: any[] = error.errors;
    for (let i = 0; i < errors.length; i++) {
      let error: any = errors[i];
      let errorCode: any = error.validationCode;
      let errorMsg: any = error.message;
      if (errorCode === 'amenities') {
        setAmenitiesValidationNameErrorMsg(errorMsg);
        console.log("Error message set in showValidationErrorInlineUI(): ", errorMsg);
      }
    }
  }

  const validateAndSaveAmenityFormData = () => {
    try {
      validateAmenityFormData();
      if (propertyFormData.has('amenities')) {
        propertyFormData.delete('amenities');
      }
      propertyFormData.append('amenities', getAllSelectedAmenity());
    } catch (error: any) {
      throw error;
    }
  }


  const highlightLocationFormData = (amenities: any[]) => {
    let formData: FormData = propertyFormData;
    if (!formData.has('amenityData')) {
      return;
    }
    console.log(formData.get('amenityData').toString())
    let amenityData: any[] = JSON.parse(formData.get('amenityData').toString());
    for (let j = 0; j < amenities.length; j++) {
      for (let i = 0; i < amenityData.length; i++) {
        if (amenities[j].id == amenityData[i].id) {
          amenities[j].checked = true;
        }
      }
    }
    console.log(amenities);
    setAmenities([...amenities]);
  }

  const getAllSelectedAmenity = () => {
    let selectedAmenities: any[] = [];
    for (let i = 0; i < amenities.length; i++) {
      if (amenities[i].checked) {
        let selectedAmenity: any = {};
        selectedAmenity.id = amenities[i].id;
        selectedAmenities.push(selectedAmenity);
      }
    }
    return JSON.stringify(selectedAmenities);
  }

  const handleOnAmenityChange = (e: any, item: any, idx: any) => {
    item.checked = e.target.checked;
    amenities[idx] = item;
    setAmenities(amenities);
    setAmenitiesValidationNameErrorMsg('');
  }

  const getTemplatesData = async () => {
    try {
      let res = await propertyApiService.getTemplatesData();
      console.log(res);
      highlightLocationFormData(res.amenityData);
    } catch (error) {
      console.log("LoginPage Error");
      console.log(error);
    }
  }

  useEffect(() => {
    getTemplatesData();
  }, []);

  return (
    <>
      <div className="body container-fluid row">
        <div className=" container  w-100  ms-1 ">
          <div className="card-body">
            <div className="col-sm-9 mx-auto ">
              <div className="card">
                <div className="card-body">
                  <div className="text-color text-center">
                    <p> Amenities</p>
                  </div>
                  <div className="row ms-5">
                    <div className="col-sm-6 ">
                      <div className="mb-2">
                        <div className="mb-2">
                          <label className="my-3">Amenities<sup className="required">*</sup></label>
                          {
                            amenities.map((item: any, idx: any) => {
                              return <>
                                <div className="mb-2">
                                  <input type="checkbox" className={amenitiesValidationNameErrorMsg ? 'error-field  form-check-input ' : ' form-check-input'}
                                    value={amenities} id={item.id} key={'amenity_' + item.id} defaultChecked={item.checked} onChange={(e) => handleOnAmenityChange(e, item, idx)} />
                                  <label htmlFor={item.id} className="ms-2">{item.name}</label>
                                </div>
                              </>
                            })
                          }
                          <FormFieldError errorMessage={amenitiesValidationNameErrorMsg}></FormFieldError>
                        </div>
                      </div>
                    </div>
                    <div className="my-4  d-flex justify-content-end me-5">
                      <button className="btn btn-primary me-3" onClick={previousPage}>Previous</button>
                      <button disabled={processing}  onClick={updateAmenity} className="btn btn-warning me-3 " type="button">
                                        Update & Skip
                                        </button>
                                    {(processing)&& <Spinner animation="grow" variant="warning" />}
                      <button onClick={submitAmenityFormData} className="btn btn-success " type="button">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default EditAmenitiesPage