import { Fragment, useEffect, useState } from "react";
import { Footer, Header } from "../../layouts";
import ProfessionBio from "./profession-bio/profession-bio";
import ProfessionPost from "./profession-post/profession-post";
import "./view-profession-profile-container.css";
import ProfessionalPageApiService from "../../data/api/services/professional-page/professional-page-api-service";
import { useLocation } from "react-router-dom";
import CoverImage from "../../assets/coverImage.jpeg"
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Modal } from "react-bootstrap";
import UploadProfileImage from "./upload-profile-image/upload-profile-image";
import StorageService from "../../data/storage/storage-service";

const ViewProfessionProfileContainer = () => {

    const professionalPageApiService = new ProfessionalPageApiService();
    const [data, setData] = useState<any>([]);
    const location = useLocation();
    const encoded = new URLSearchParams(location.search).get('v');
    const decoded = atob(encoded);
    const params = new URLSearchParams(decoded);
    const professionalPageId = params.get('id');
    // const professionalPageId = new URLSearchParams(location.search).get('id');
    const [loading, setLoading] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find((item) => item.activePage === true);
    const userPageId = activePageItem?.id;

    const getProfessionalPageById = async () => {
        try {
            let res = await professionalPageApiService.getProfessionalPageById(professionalPageId);
            setData(res);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    };

    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            if (userId) {
                let res = await professionalPageApiService.getProfessionalPageData(userId);
                setUserPageData(res);
                getProfessionalPageById();
            } else {
                getProfessionalPageById();
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        // getProfessionalPageById();
        getProfessionalPageDetails();
    }, [professionalPageId]);

    return <>
        <div>
            <Header></Header>
            {loading ? (
                <div className="d-flex justify-content-center"
                    style={{ marginTop: '20%', marginBottom: '20%', background: 'white' }}>
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            ) : (
                <>
                    <Fragment>
                        <div className="container-fluid main_page">
                            <div className="row">
                                {userPageId != data.id ? (
                                    <img className="banner-image-professional"
                                        src={data.coverImageURL ? data.coverImageURL : CoverImage} alt="" />
                                ) : (
                                    <div className="col-sm-12 page-size d-flex justify-content-end">
                                        {/* <img className="banner-image-professional"
                                        src={data.coverImageURL ? data.coverImageURL : CoverImage} alt="" /> */}
                                        <img className="banner-image-professional"
                                            src={data.coverImageURL ? data.coverImageURL : CoverImage} alt=""
                                            onMouseEnter={() => setHovered(true)}
                                            onMouseLeave={() => setHovered(false)} />
                                        {hovered && (
                                            <div className="d-flex align-items-center justify-content-center video-play-icon m-3 p-1"
                                                style={{ borderRadius: '0' }}
                                                onMouseEnter={() => setHovered(true)}
                                                onMouseLeave={() => setHovered(false)}
                                                onClick={handleShow}>
                                                <CameraAltIcon className="" style={{ fontSize: 'xx-large' }} />
                                                Change Cover
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="col-sm-12 w-100 total-color ">
                                    <div className="row justify-content-center" >
                                        <div className="col-md-3">
                                            <ProfessionBio id={parseInt(professionalPageId)} />
                                        </div>
                                        <div className="col-md-6">
                                            <ProfessionPost id={parseInt(professionalPageId)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    <Footer></Footer>
                </>
            )}
            <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
                <UploadProfileImage pageId={data.id} pageData={data} imageType={"COVER_IMAGE"} handleClose={handleClose} />
            </Modal>
        </div>
    </>
}

export default ViewProfessionProfileContainer;