import "./plan-list.css";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Sale from "../../../assets/for sale.jpg";
import Rent from "../../../assets/for rent.jpg";
import Land from "../../../assets/land.jpg";
import Model from "../../../assets/model house.jpg";
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import React from "react";
import TuneIcon from '@mui/icons-material/Tune';
import ProfilePic from "../../../assets/profile-pic.jpeg";
import StorageService from "../../../data/storage/storage-service";
import { useDispatch } from "react-redux";
import { Col, Figure, InputGroup, Modal, Nav, Row } from "react-bootstrap";
import map from "../../../assets/map.png";
import { faArrowRight, faBars, faCrosshairs, faMagnifyingGlass, faMap, faTag } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from "../../../components/form-field-error/form-field-error";
import HomeProfessionalFollow from "../../home/components/home-professional-follow";
import HomeProfessionalFollowing from "../../home/components/home-professional-following";
import HomeProfessionalList from "../../home/components/home-professional-list";
import ProfessionLocationSearch from "../../home/components/profession-location-search";
import ProfessionTypeSearch from "../../home/components/profession-type-search";
import { PropertySearchAction } from "../../home/state/search-action";
import { Footer, Header } from "../../../layouts";
import elevation from "../../../assets/front.elevation.jpeg";
import avatar from "../../../assets/avatar.png";
import person from "../../../assets/profile-pic.jpeg"
import crown from "../../../assets/crown.png"
import VisibilityIcon from '@mui/icons-material/Visibility'; // Import the view icon

import DefaultImage from "../../../assets/tag.png"
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { toast } from "react-toastify";
import { PagePostTagFollowPayload, PagePostTagViewPayload } from "../../../data/api/services/professional-page/professional-page-payload";
import TagSearch from "../tag-search/tag-search";
import LoginPageDialog from "../../login/login-page-dialog";



const PlanList = (props: any) => {
    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(event.target.value);
    };
    let navigate: any = useNavigate();
    const handleIconClick = () => {

        setEditShow(true);
    };

    const [tagSearchLoading, setTagSearchLoading] = useState(false);
    const [titleSearchLoading, setTitleSearchLoading] = useState(false);

    const [data, setData] = useState<any>([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isView, setIsView] = useState(false);
    const [showLogInDialog, setShowLogInDialog] = useState(false);
    const handleShowLogInDialog = () => setShowLogInDialog(true);
    const handleCloseLogInDialog = () => setShowLogInDialog(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [newest, setNewest] = useState(' ');
    const [premium , setPremium] = useState(' ');
    const [free , setFree] = useState(' ');
     const [oldest, setOldest] = useState(' ');
     const [popularity, setPopularity] = useState(' ');
     const [followers, setFollowers] = useState(' ');

     const [viewers, setViewers] = useState(' ');
     const [users, setUsers] = useState(' ');




    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const userId = StorageService.getUserId();
    const [editShow, setEditShow] = useState(false);

    const [professionalPagePostTags, setProfessionalPagePostTags] = useState<any>([]);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10000000);

    const propertyApiService = new PropertyApiService();
    const professionalPageApiService = new ProfessionalPageApiService();
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);


    const [titleData, setTitleData] = useState([]);

    const imageArray = [1, 2, 3, 4];

    const [selectedTitle, setSelectedTitle] = useState([]);




    const dispatch = useDispatch();


    const [selectedTitleId, setSelectedTitleId] = useState('');
    const [selectedTitleName, setSelectedTitleName] = useState('');



    const [selectedParticularTagName, setSelectedParticularTagName] = useState('');



    const [totalRecord, setTotalRecord] = useState(0);

    const [showExtraFilters, setShowExtraFilters] = useState(false);
    const editHandleClose = (Filter?: any) => {
        setEditShow(false);
    
        let isNewestSelected = false;
        let isOldestSelected = false;
        let isPremiumTagsSelected = false;
        let isFreeTagsSelected = false;
        let isPopularitySelected = false;
        let isFollowersSelected = false;
        let isViewedSelected = false;
        let isUserSelected = false;
    
        if (Filter && Filter.length > 0) {
            const selectedFilter = Filter.map((item: any) => item.label).join(',');
            setSelectedFilter(selectedFilter);
    
            isNewestSelected = selectedFilter.includes('Newest');
            isPremiumTagsSelected = selectedFilter.includes('Premium Tags');
            isFreeTagsSelected = selectedFilter.includes('Free Tags');
            isOldestSelected = selectedFilter.includes('Oldest');
            isPopularitySelected = selectedFilter.includes('Popularity');
            isFollowersSelected = selectedFilter.includes('More Followers');
            isViewedSelected = selectedFilter.includes('Most Viewed');
            isUserSelected = selectedFilter.includes('Most Used');
        } else if (!Filter) {
            setSelectedFilter(''); 
        }
    
        // Set the values based on the consolidated conditions
        const premiumOrFree = isPremiumTagsSelected ? 'PAID' : (isFreeTagsSelected ? 'FREE' : ' ');
    
        setNewest(isNewestSelected ? 'newest' : ' ');
        setOldest(isOldestSelected ? 'oldest' : ' ');
        setPremium(premiumOrFree);
        setFree(isFreeTagsSelected && !isPremiumTagsSelected ? 'FREE' : ' ');
        setPopularity(isPopularitySelected ? 'popular' : ' ');
        setFollowers(isFollowersSelected ? 'followers' : ' ');
        setViewers(isViewedSelected ? 'viewers' : ' ');
        setUsers(isUserSelected ? 'user' : ' ');
    };
    
    
    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setUserPageData(res);
            const activePageItem = res.find(item => item.activePage === true);
            const userPageId = activePageItem?.id;
            getProfessionalPageById(userPageId);
            getPageData(currentPage, pageSize);

          
        } catch (error) {
            console.log(error);
        }
    };
    const getProfessionalPageById = async (userPageId) => {
        try {
            let res = await professionalPageApiService.getProfessionalPageById(userPageId);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    };
    const getProfessionalPagePostDataList = async () => {
        try {
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setProfessionalPagePosts(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };
    const handleFollowClick = (id:any) => {
        setIsFollowing((prevIsFollowing) => !prevIsFollowing);
         savePageFollow(id);
      };

      const handleViewClick = (id:any) => {
        setIsView((prevIsView) => !prevIsView);
         savePageView(id);
      };
      const savePageView = async (id:any) => {
        try {
          let payload: PagePostTagViewPayload = {
            viewTagId: id,
            userPageId: userPageId
          };
          await professionalPageApiService.savePagePostTagView(payload);
          getProfessionalPageDetails();
        } catch (error) {
          console.log(error)
        }
      }
      const savePageFollow = async (id:any) => {
        try {
          let payload: PagePostTagFollowPayload = {
            followingTagId: id,
            userPageId: userPageId
          };
          await professionalPageApiService.savePagePostTagFollow(payload);
          getProfessionalPageDetails();
        } catch (error) {
          console.log(error)
        }
      }
    const handleFollowButtonClick = (id:any) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            handleShowLogInDialog();
        } else {
          handleFollowClick(id);
        }
      };

   
      const showLoggedInConfirmDialog = () => {
        confirmAlert({
            title: 'Alert',
            message: 'You have to log in before Follow ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { navigate('/login'); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log('clicked no') }
                }
            ]
        });
      };
    
    useEffect(() => {
        getProfessionalPageDetails();
        getProfessionalPagePostDataList();
    }, []);
    const getPageData = async (page, size) => {
        try {
            let url = `?page=${page}&pageSize=${size}&title=${selectedTitleName}`;
            
            // let result = await professionalPageApiService.getAllPostData(url);
            let result = await professionalPageApiService.getAllnewPlanData(url);
            console.log("sssssssssssssssssssssssssssssss", result);
            setProfessionalPagePostTags(result.data);
            setTotalRecord(result.totalRecord);
        } catch (error) {
            console.log("poperty search Error");
            console.log(error);
            if (error.response) {
                toast.error(error.response.data.message, { containerId: 'TR' });
                StorageService.clear();
                navigate('/login');
            } else if (error.request) {
                toast.error('Error: No response received from the server');
            } else {
                toast.error(`Error: ${error.message}`);
            }
        }
    }


    useEffect(() => {
        getPageData(currentPage, pageSize);
    }, [selectedTitleName]);


   


  
    const handleTagFollowClick = async (id: number, tagUrl: any, rank: any) => {
        await handleViewClick(id);
        navigate(`/view-profession-tag-follow?tagUrl=${tagUrl}&tagId=${id}&rank=${rank}`, { state: {} });
        console.log(`View button clicked for tagId: ${id}`);
    };


   

    const handleViewButtonClick = async (id: number,doorFacingId:any) => {
        await handleViewClick(id);
        navigate(`/plan?planId=${id}&doorFacingId=${doorFacingId}`, { state: {} });

        console.log(`View button clicked for planId: ${id}`);
    };

   

    const renderMenuItemChildren = (option, props) => {
  
      
    
        return (
            <div>
                <img
                    src={option.coverImageURL != null ? option.coverImageURL : DefaultImage}
                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                />
                <span>{option.title}</span> 
            </div>
        );
    };
    
    
    









   

    const searchTitle = async (s: any) => {
      setTitleSearchLoading(true);
      let data = await propertyApiService.searchTitle(s);
      console.log(s);
      setTagSearchLoading(false);
      setTitleData(data);
  };
  const onTitleSelected = (t: any) => {
    if (t.length > 0) {
        console.log(t[0]);
        setSelectedTitle(t);
        setSelectedTitleId(t[0].id);
        setSelectedTitleName(t[0].title);
    }
};
    const handle = async (id: number, tagUrl: any, rank: any) => {
        await handleViewClick(id);
        navigate(`/view-profession-post-tag?tagUrl=${tagUrl}&tagId=${id}&rank=${rank}`, { state: {} });
        console.log(`View button clicked for tagId: ${id}`);
    };


    return (
        <>
            <Header />

            <div className="profession-post-tag">
    <div className="image-cover p-6 hero-banner mx-auto">
        <div className="container">
            <div className="mt-4">
                <div className="col-sm-10 mx-auto my-3 d-flex flex-column align-items-center justify-content-center">
                    <label
                        style={{
                            color: 'black',
                            fontSize: '20px',
                            marginBottom: '16px',
                            textAlign: 'center'
                        }}
                    >
                        PlanList
                    </label>
                    <div className="d-flex justify-content-between flex-wrap col-sm-8 align-items-center">
                        <div className="d-flex col-sm-12 justify-content-between align-items-center mb-3">
                            <Col sm={8}>
                                <Row className="tag-search-container me-3">
                                    <Col className="px-0 ms-3">
                                        <AsyncTypeahead
                                            id="title-search"
                                            className="async-typeahead-container custom-async-typeahead rounded-pill focus"
                                            labelKey="title"
                                            onSearch={searchTitle}
                                            options={titleData}
                                            onChange={onTitleSelected}
                                            useCache={false}
                                            placeholder="Search title"
                                            defaultSelected={selectedTitle}
                                            isLoading={titleSearchLoading}
                                            renderMenuItemChildren={renderMenuItemChildren}
                                        />
                                    </Col>
                                    <Col xs="auto" className="border-start">
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </Col>
                                </Row>
                            </Col>

                            <label
                                style={{
                                    color: 'grey',
                                    marginRight: '16px',
                                    fontSize: '16px'
                                }}
                            >
                                Filters
                            </label>
                            
                            <span onClick={handleIconClick} style={{ fontSize: '30px', cursor: 'pointer' }} className="ms-2">
                                <TuneIcon />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 mx-auto">
  <div className="row justify-content-between">
    {/* Render all items uniformly */}
    {professionalPagePostTags.map((item, index) => (
      <div className="col-lg-5 mb-4" key={index}>
        <div
          className="card tagsborder mx-auto second-record-style"
          style={{ minHeight: '254px' }} // Set default card height
        >
          {/* First Row: Image */}
          <Figure
            className="mb-0"
            style={{
              height: '180px',
              position: 'relative',
            }}
          >
            <img
              src={item.coverImageURL || DefaultImage}
              className="card-img-top"
              alt="Record Image"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '0 0 0 0',
              }}
            />
          </Figure>

          {/* Second Row: Title */}
          <div className="card-body">
            <label
              className="card-title"
              style={{
                color: 'black',
                fontSize: '15px',
                fontWeight: 'normal',
                textAlign: 'left', // Align text to start
                display: 'block',
              }}
            >
              {item.title}
            </label>
          </div>

          {/* Third Row: Button and Icon */}
          <div className="card-body d-flex align-items-center justify-content-between py-1">
  <div
    style={{
      color: 'black',
      fontSize: '15px',
    }}
  >
    ID: {item.id}
  </div>
  <button
    style={{
      borderRadius: '9px',  // Makes the button round
      backgroundColor: 'yellow',  // Button color
      border: 'none',
      padding: '5px 15px',
      color: 'black',
      cursor: 'pointer',
      fontSize: '15px',
    }}
    onClick={() => handleViewButtonClick(item.id,item.doorFacingId)}
  >
    View Details
  </button>
</div>



        </div>
      </div>
    ))}
  </div>
</div>



            </div>
        </div>
    </div>
</div>



            <Modal  size="sm"  show={editShow} onHide={editHandleClose}>
                            <TagSearch handleClose={editHandleClose} />
                        </Modal>
                        <Modal show={showLogInDialog} onHide={handleCloseLogInDialog} backdrop="static"
            centered className="login-page-dialog-modal">
            <LoginPageDialog handleClose={handleCloseLogInDialog} />
          </Modal>
                       
        </>
    );



};
export default PlanList;
