import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../create-professional-page-container.css';
import ValidationError from "../../../../common/validation-error";
import { Form, Spinner } from "react-bootstrap";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ProfessionalPageWorkHourImage from '../../../../assets/professional-page-2.jpeg';
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

const AddProfessionalPageWorkHour = ({ professionalPageFormData, onPreviousCallback, onNextCallback }) => {
    const tabNumber: number = 2;
    const [selectedChips] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedDayForDialog, setSelectedDayForDialog] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [processing, setProcessing] = useState(false);
    const desiredOrder = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const [timeOptions, setTimeOptions] = useState([]);

    const [showSwitchStates, setShowSwitchStates] = useState({
        SUNDAY: true,
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
    });

    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = hour.toString().padStart(2, "0");
                const formattedMinute = minute.toString().padStart(2, "0");
                options.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        setTimeOptions(options);
    };

    const renderTimeOptions = () => {
        return timeOptions.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
        ));
    };

    const openDialog = (dayObj: any) => {
        setSelectedDayForDialog(dayObj);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const handleTimeSelection = () => {
        setSelectedDays((prevSelectedDays) =>
            prevSelectedDays.map((prevDay) =>
                prevDay.day === selectedDayForDialog.day
                    ? { ...prevDay, endTime: selectedTime }
                    : prevDay
            )
        );
        closeDialog();
    };

   const handleChipSelect = (dayOfWeek: any) => {
    const isNewDaySelected = !selectedDays.some((selectedDay) => selectedDay.dayOfWeek === dayOfWeek);
    const isStartTimeEndTimeEmpty = selectedDays.some(
        (selectedDay) => selectedDay.dayOfWeek === dayOfWeek && (selectedDay.startTime === '' ) &&
                         (selectedDay.endTime === '' )
    );
    const isStartTimeEndTimeValue = selectedDays.some(
        (selectedDay) => selectedDay.dayOfWeek === dayOfWeek && ( selectedDay.startTime === '00:00') &&
                         ( selectedDay.endTime === '00:00')
    );


    if (isNewDaySelected || isStartTimeEndTimeValue) {
        setSelectedDays([
            ...selectedDays,
            {
                dayOfWeek,
                startTime: '',
                endTime: '',
                professionalPageWorkHourId: '',
                professionalPageId: ''
            }
        ]);
    }
};


  
    const handleAddTimes = (index) => {
        const dayObj = selectedDays[index];
        const updatedSelectedDays = [...selectedDays];
    
        if (switchStates[dayObj.dayOfWeek]) {
            const newTimeEntry = {
                ...selectedDays[index],
                startTime: '',
                endTime: '',
                professionalPageWorkHourId: '',
                professionalPageId: '',
                addTimes: true
            };
    
            updatedSelectedDays.splice(index + 1, 0, {
                ...newTimeEntry,
                switchStates: false,
                showSwitchStates: false,
            });
    
            setSwitchStates((prevSwitchStates) => ({
                ...prevSwitchStates,
                [dayObj.dayOfWeek]: false,
            }));
    
            setShowSwitchStates((prevShowSwitchStates) => ({
                ...prevShowSwitchStates,
                [dayObj.dayOfWeek]: false,
            }));
        } else {
            // If switchStates is closed, remove the newly created line
            updatedSelectedDays.splice(index + 1, 1);
        }
    
        updatedSelectedDays.forEach((day) => {
            if (!switchStates[day.dayOfWeek] && day !== dayObj) {
                setSwitchStates((prevSwitchStates) => ({
                    ...prevSwitchStates,
                    [day.dayOfWeek]: true,
                }));
            }
        });
    
        setShowSwitchStates((prevShowSwitchStates) => ({
            ...prevShowSwitchStates,
            [dayObj.dayOfWeek]: true,
        }));
    
        setSelectedDays(updatedSelectedDays);
    };
    
    
    const [switchStates, setSwitchStates] = useState({
        SUNDAY: true,
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
      });
     
    
      const handleDaySwitchChange = (day) => {
        setSwitchStates((prevSwitchStates) => ({
            ...prevSwitchStates,
            [day]: !prevSwitchStates[day],
        }));
    
        // Update startTime and endTime based on the switch state
        const updatedSelectedDays = selectedDays.map((dayObj) => {
            if (dayObj.dayOfWeek === day) {
                const defaultTime = '0'; // Change this to your desired default time
                return { ...dayObj, startTime: switchStates[day] ? '00:00' : defaultTime, endTime: switchStates[day] ? '00:00' : defaultTime };
            }
            return dayObj;
        });
    
        setSelectedDays(updatedSelectedDays);
    };
    
      const handleCancel = (index) => {
        const dayObj = selectedDays[index];
        const updatedSelectedDays = selectedDays.filter((day) => day !== dayObj);
    
        // Update switch states for the remaining days
        updatedSelectedDays.forEach((day) => {
            if (!switchStates[day.dayOfWeek]) {
                setSwitchStates((prevSwitchStates) => ({
                    ...prevSwitchStates,
                    [day.dayOfWeek]: true,
                }));
            }
        });
    
        // Reset showSwitchStates for the current day
        setShowSwitchStates((prevShowSwitchStates) => ({
            ...prevShowSwitchStates,
            [dayObj.dayOfWeek]: true,
        }));
    
        setSelectedDays(updatedSelectedDays);
    };
    
    const calculateTotalHours = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return '00:00';
        }
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);
        let totalMinutes = (endHour - startHour) * 60 + (endMinute - startMinute);
        if (totalMinutes < 0) {
            totalMinutes += 24 * 60;
        }
        const hours = Math.floor(totalMinutes / 60).toString().padStart(2, "0");
        const minutes = (totalMinutes % 60).toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    };

    const sortedSelectedDays = selectedDays.sort((a, b) => {
        return desiredOrder.indexOf(a.dayOfWeek) - desiredOrder.indexOf(b.dayOfWeek);

    });

    const getChipClassName = (day: string) => {
        let classNames = 'chip ' + day;
        if (selectedChips.includes(day)) {
            classNames += ' selected';
        }
        return classNames;
    };

    const preview = () => {
        onPreviousCallback(tabNumber - 1)

    }

    const validateAndSaveWorkHourFormData = () => {
        try {
            validateWorkHourFormData();
            if (professionalPageFormData.has('pageWorkHoursStr')) {
                professionalPageFormData.delete('pageWorkHoursStr');
            }
            if (selectedDays.length > 0) {
                professionalPageFormData.append('pageWorkHoursStr', JSON.stringify(selectedDays));
                let pageWorkHoursStr = JSON.stringify(selectedDays);
                console.log('pageWorkHoursStr', pageWorkHoursStr);
            }

            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            throw error;
        }
    }

    const validateWorkHourFormData = (): void => {
        let errors: any[] = [];
        const selectedWorkHours = JSON.stringify(selectedDays);
    
        const hasNonEmptyTime = selectedDays.some(
            (day) => day.startTime !== '00:00' || day.endTime !== '00:00'
        );
    
        if (selectedDays.length === 0 || !hasNonEmptyTime) {
            let error: any = {};
            error.validationCode = 'workhours';
            error.message = 'Work hour required';
            errors.push(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    
        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    };
    
    useEffect(() => {
        const updatedSwitchStates = {};
        selectedDays.forEach((dayObj) => {
            updatedSwitchStates[dayObj.dayOfWeek] = true;
        });
        setSwitchStates((prevSwitchStates) => ({
            ...prevSwitchStates,
            ...updatedSwitchStates,
        }));
    }, [selectedDays]);

    const highlightWorkHourFormData = () => {
        let formData: FormData = professionalPageFormData;
        if (!formData.has('pageWorkHoursStr')) {
            return;
        }
        let pageWorkHours: any[] = JSON.parse(formData.get('pageWorkHoursStr').toString());
        setSelectedDays([...pageWorkHours]);
    }

    const handleStartTimeChange = (newStartTime, index) => {
        const updatedDays = selectedDays.map((day, i) => {
            if (i === index) {
                return { ...day, startTime: newStartTime };
            }
            return day;
        });
        setSelectedDays(updatedDays);
    };

    const handleEndTimeChange = (newEndTime, index) => {
        const updatedDays = selectedDays.map((day, i) => {
            if (i === index) {
                return { ...day, endTime: newEndTime };
            }
            return day;
        });
        setSelectedDays(updatedDays);
    };

    useEffect(() => {
        highlightWorkHourFormData();
        generateTimeOptions();
    }, []);

    return <>
        <div className="container boder-sy  my-4">
            <div className="row justify-content-center">
                <div className="col-sm-1 col-lg-1text-center mt-5">
                    <span className=""> <ArrowBackIcon onClick={preview} /></span>
                </div>
                <div className="col-sm-5 col-lg-5 d-flex align-self-center">
                    <img src={ProfessionalPageWorkHourImage} className='h-100 w-100' alt="google-bussiness" ></img>
                </div>
                <div className="col-sm-7 col-lg-6 mt-3">
                    <section className="p-0 pt-4">
                        <div className="row">
                            <div className="col-sm-10 col-lg-12 d-flex LKfzVb mt-2">
                                <h3 className="text-center">Tell us what time will you be available ?</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-10 offset-sm-1 mt-1">
                                <span className='fs-5'>this helps customers find you if they are looking for a business like yours.</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mb-2">
                                <hr />
                            </div>
                            <div className="col-sm-12">
                                <span className='text-start fw-bold'>Select Working Days</span>
                            </div>
                            <div className="col-sm-12">
                                <div className="chips-container">
                                    <span
                                        className={getChipClassName('Sunday')}
                                        onClick={() => handleChipSelect('SUNDAY')}>Sunday
                                    </span>
                                    <span
                                        className={getChipClassName('Monday')}
                                        onClick={() => handleChipSelect('MONDAY')}>Monday
                                    </span>
                                    <span
                                        className={getChipClassName('Tuesday')}
                                        onClick={() => handleChipSelect('TUESDAY')}>Tuesday
                                    </span>

                                    <span
                                        className={getChipClassName('Wednesday')}
                                        onClick={() => handleChipSelect('WEDNESDAY')}
                                    >Wednesday</span>
                                    <span
                                        className={getChipClassName('Thursday')}
                                        onClick={() => handleChipSelect('THURSDAY')}>Thursday
                                    </span>
                                    <span
                                        className={getChipClassName('Friday')}
                                        onClick={() => handleChipSelect('FRIDAY')}>Friday
                                    </span>
                                    <span
                                        className={getChipClassName('Saturday')}
                                        onClick={() => handleChipSelect('SATURDAY')}>Saturday
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-12 overflow">
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Total Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedSelectedDays.map((dayObj, index) => (
                                            <tr key={index}>
<td>
    {switchStates[dayObj.dayOfWeek] && (dayObj.startTime !== '00:00' || dayObj.endTime !== '00:00') && (
        <>{dayObj.dayOfWeek}</>
    )}
</td>
                                               <td>
                                                {dayObj.startTime !== '00:00' && (
                            <select
                                className="form__input input_time p-1"
                                value={dayObj.startTime}
                                onChange={(e) => {
                                    handleStartTimeChange(e.target.value, index);
                                }}
                            >
                                <option value="" disabled selected>
                                    Open
                                </option>
                                {renderTimeOptions()}
                            </select>
                        )}
                    </td>

                    <td>
                      
                    {dayObj.endTime !== '00:00' && (
    <select
        className="form__input input_time p-1"
        value={dayObj.endTime}
        onChange={(e) => { handleEndTimeChange(e.target.value, index) }}
    >
        <option value="" disabled selected>
            Close
        </option>
        {renderTimeOptions()}
    </select>
)}

                       
                    </td>

                    <td>
    {switchStates[dayObj.dayOfWeek] && (dayObj.startTime !== '00:00' || dayObj.endTime !== '00:00') && (
        <> {/* Conditionally render the content only when switch state is Opened and either startTime or endTime is not 00:00 */}
            {calculateTotalHours(dayObj.startTime, dayObj.endTime)}
        </>
    )}
</td>

                    <td>
    <div className='d-flex'>
        
    {switchStates[dayObj.dayOfWeek] && !dayObj.addTimes && (dayObj.startTime !== '00:00' || dayObj.endTime !== '00:00') && (
    <span className="add-times" onClick={() => handleAddTimes(index)}>
        Add Times
    </span>
)}

{switchStates[dayObj.dayOfWeek] && dayObj.addTimes && (
    <div>
        <IconButton onClick={() => handleCancel(index)}>
            <ClearOutlinedIcon />
        </IconButton>
    </div>
)}

{showSwitchStates[dayObj.dayOfWeek] !== undefined && !dayObj.addTimes && switchStates[dayObj.dayOfWeek] && (
    <>
        {dayObj.startTime !== '00:00' || dayObj.endTime !== '00:00' ? (
            <div>
                <Form.Check
                    type="switch"
                    id={`custom-switch-${dayObj.dayOfWeek}`}
                    label={switchStates[dayObj.dayOfWeek] ? 'Opened' : 'Closed'}
                    onChange={() => handleDaySwitchChange(dayObj.dayOfWeek)}
                    checked={switchStates[dayObj.dayOfWeek]}
                />
            </div>
        ) : null}
    </>
)}



    </div>
</td>
          </tr>
            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="text-end p-3">
                            <button disabled={processing} className="btn btn-success" onClick={validateAndSaveWorkHourFormData} type="button">Next</button>
                            {(processing) && <Spinner animation="grow" variant="warning" />}
                        </div>
                    </section>
                </div>

            </div>
        </div>
        <div>
        </div>
    </>
}
export default AddProfessionalPageWorkHour; 