import { Footer, Header } from "../../../layouts";
import { Fragment, useRef, useState } from "react";
import "../view-profession-video/view-profession-video.css";
import { useLocation } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import Avatar from "../../../assets/avatar.png"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

const ViewProfessionVideo = () => {
  const location = useLocation();
  const videos = location?.state?.videos || [];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const previousVideoRef = useRef(null);

  const handleSlide = (selectedIndex, e) => {
    setCurrentVideoIndex(selectedIndex);
    pausePreviousVideo();
  };

  const pausePreviousVideo = () => {
    if (previousVideoRef.current) {
      previousVideoRef.current.pause();
    }
  };

return <>
  <div>
    <Header></Header>
    <div className="container-fluid main_page  ">
      <div className="row">
        <div className="col-sm-12 total-color ">
          <div className="row" >
            <div className="col-sm-9 bg-primary">
              {videos.length > 0 && (
                <Carousel interval={null} indicators={false} controls={true} activeIndex={currentVideoIndex}
                  onSelect={handleSlide}>
                  {videos.map((video, index) => (
                    <Carousel.Item key={index}>
                      <div className="d-flex justify-content-center">
                        <video
                          ref={index === currentVideoIndex ? previousVideoRef : null}
                          id={`video-${index}`}
                          className="p-2 mb-3 mt-3"
                          width="50%"
                          height="auto"
                          controls
                        >
                          <source src={video.video} type="video/mp4" />
                        </video>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </div>
            <div className="col-sm-3">
              <div className="d-flex mt-2 col-sm-12">
                <div className="p-2 col-sm-3">
                  <img
                    className="card_avatar_img"
                    src={Avatar} alt="avatar" />
                </div>
                <div className="d-flex flex-column col-sm-8">
                  <div className="fw-semibold align-self-start mt-2">
                    R Raju Ram
                  </div>
                  <div className="upload_date align-self-start">
                    21 February 2021
                  </div>
                </div>
                <div className="d-flex justify-content-end col-sm-1">
                  <span className="d-flex align-items-center"> <MoreHorizIcon /></span>
                </div>
              </div>
              <hr />
              <div className="d-flex col-sm-12 justify-content-evenly">
                <div className="col-sm-6 d-flex justify-content-center">
                  <span> <ThumbUpOffAltIcon /> Like</span>
                </div>
                <div className="col-sm-6 d-flex justify-content-center">
                  <span> <ChatBubbleOutlineOutlinedIcon /> Comment</span>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</>
}
export default ViewProfessionVideo;