import { Receipt, PersonOutline, ShieldOutlined } from "@mui/icons-material";
import PropertyListContainer from "../../../pages/property/property-list-page/property-list-container";
import Header from "../../header/header";
import "./work-flow.css";


const WorkFlow = () => {

    return <>
        <div className="mb-5">
            <Header />
        </div>    <div className="raw-html-embed">
            <section className="section1" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-10 text-center">
                            <div className="sec-heading center">
                                <h2>How It Works?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="middle-icon-features-item">
                                <div className="icon-features-wrap">
                                    <div className="middle-icon-large-features-box f-light-success">
                                        <Receipt className="text-success" />
                                    </div>
                                </div>
                                <div className="middle-icon-features-content">
                                    <h4>Evaluate Property</h4>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have Ipsum available.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="middle-icon-features-item">
                                <div className="icon-features-wrap">
                                    <div className="middle-icon-large-features-box f-light-warning">
                                        <PersonOutline className="text-warning" />
                                    </div>
                                </div>
                                <div className="middle-icon-features-content">
                                    <h4>Meet Your Agent</h4>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have Ipsum available.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="middle-icon-features-item remove">
                                <div>
                                    <div className="middle-icon-large-features-box f-light-blue">
                                        <ShieldOutlined className="text-blue" />
                                    </div>
                                </div>
                                <div className="middle-icon-features-content">
                                    <h4>Close The Deal</h4>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have Ipsum available.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section className="bg-light">
            <div className="container">

                <div className="row justify-content-center">
                    <div className="col-lg-12 text-center">
                        <div className="sec-heading center">
                            <h2>Explore Good Places</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>

}
export default WorkFlow;