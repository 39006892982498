import { Col, Modal } from "react-bootstrap";
import "./login-page-dialog.css";
import GoogleLogo from '../../assets/google-logo.png';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthPayload } from "../../data/api/services/auth/auth-payload";
import ApiConfig from "../../data/api/api-config";
import VeeduLogo from '../../assets/otp-page-logo.png'

const LoginPageDialog = (props: any) => {

    const authService: AuthApiService = new AuthApiService();
    const apiConfig = new ApiConfig();
    const [baseUrl, setBaseUrl] = useState("");
    const [mobile, setmobile] = useState("");
    const [mobileValidationError, setMobileNoValidationError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordValidationError, setPasswordValidationError] = useState(false);
    const [userType, setUserType] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleMobileNumberChange = (value: any) => {
        const formattedValue = value.slice(0, 10);
        findUserByMobileNo(formattedValue);
        setmobile(formattedValue);
        setMobileNoValidationError(false);
    };

    const handlePasswordChange = (value: any) => {
        setPassword(value);
        setPasswordValidationError(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateLoginDetails = () => {
        let isFormValid = true;
        if (!mobile) {
            setMobileNoValidationError(true);
            isFormValid = false;
        }
        if (mobile) {
            if (mobile.length <= 9) {
                toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
                isFormValid = false;
            }
        }
        if (!password) {
            setPasswordValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    };

    const findUserByMobileNo = async (mobile: any) => {
        try {
            let url = "?mobileNo=" + mobile;
            let data = await authService.fetchUserByMobileNo(url);
            console.log('dataaaaaa=>', data);
            if (data.id) {
                setUserType(data.roleCode);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const executeLoginApi = async () => {
        try {
            let payload: AuthPayload = {
                mobile: mobile,
                password: password,
                roleCode: userType
            };
            await authService.doLogin(payload);
            toast.success('Successfully Loggedin', { containerId: 'TR' });
            window.location.reload();
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    };

    const doLogin = async () => {
        try {
            if (validateLoginDetails()) {
                executeLoginApi();
            }
        } catch (error) {
            if (error.response.data.message) {
                toast.error(error.response.data.message, { containerId: 'TR' });
            }
        }
    };

    const googleLogin = () => {
        window.location.href = baseUrl + '/oauth2/authorization/google';
    };

    useEffect(() => {
        setBaseUrl(apiConfig.getBaseURL);
    }, []);

    return (
        <>
            <div className="container p-5">
                <form className="row g-4">
                    <Col md={12} className="p-0 m-0 d-flex justify-content-center position-relative align-items-center">
                        <img src={VeeduLogo} width="50px" alt="" />
                        <FontAwesomeIcon icon={faClose}
                            style={{ color: "gray", fontSize: 30, position: 'absolute', right: 0 }}
                            onClick={props.handleClose} />
                    </Col>
                    <Col md={12} className="fw-bold text-center fs-5 m-0" style={{ color: "gray" }}>
                        <p>
                            Join
                            <span style={{ color: "orangered" }}> Veedu App </span>
                            for free House Designs, Get Professionals Contacts,Follow pages, and more.
                        </p>
                    </Col>
                    <Col md={12} className="m-0">
                        <div className="google-login mt-2"
                            style={{ cursor: 'pointer' }}
                            onClick={googleLogin}>
                            <a className="btn" style={{ border: 'none' }}>
                                <img src={GoogleLogo} alt="Google Logo" className="google-logo" />
                                Continue with Google
                            </a>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="modal-divider m-0">
                            <span className="fw-bold" style={{ fontSize: "small", color: "gray" }}>OR</span>
                        </div>
                    </Col>
                    <p className="mb-0" style={{ fontWeight: "500", color: "gray" }}>Please enter your login credentials</p>
                    <Col md={12}>
                        <div className={`custom-input-field ${mobileValidationError ? 'is-invalid' : ''}`}>
                            <input type="number" placeholder=" "
                                value={mobile} onChange={e => { handleMobileNumberChange(e.target.value) }} />
                            <span className="fw-bold custom-input-field-placeholder">Mobile No.</span>
                        </div>
                        {mobileValidationError && <div className="invalid-feedback px-2">Mobile No. required</div>}
                    </Col>
                    <Col md={12}>
                        <div className={`custom-input-field ${passwordValidationError ? 'is-invalid' : ''}`}>
                            <input
                                className="pe-5"
                                type={showPassword ? "text" : "password"}
                                placeholder=" "
                                value={password}
                                onChange={e => handlePasswordChange(e.target.value)}
                            />
                            <span className="fw-bold custom-input-field-placeholder">Password</span>
                            <span onClick={togglePasswordVisibility} className="password-toggle-icon px-2"
                                style={{ color: "#aaa" }}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </span>
                        </div>
                        {passwordValidationError && <div className="invalid-feedback px-2">Password required</div>}
                    </Col>
                    <Col md={12}>
                        <a href="/forgotPassword" className="link text-primary fw-bold">
                            Forgot password?
                        </a>
                    </Col>
                    <Col md={12}>
                        <button
                            type="button"
                            className="btn w-100 p-3 bg-primary text-white"
                            onClick={doLogin}>
                            Continue
                        </button>
                    </Col>
                    <Col md={12}>
                        <p>
                            Don't have an account?
                            <a href="/register" className="link text-primary fw-bold">
                                {" "}Create Account
                            </a>
                        </p>
                    </Col>
                    <Col md={12} className="text-center m-0">
                        <p style={{ color: "#aaa" }}>
                            By clicking "Create Account" or "Continue with Google". You agree to the
                            <a href="/terms-of-use" className="link"> Veedu App TOS</a> and
                            <a href="/privacy-policy" className="link"> Privacy Policy</a>.
                        </p>
                    </Col>
                    <Col md={12} className="d-flex justify-content-center align-items-center m-0">
                        <input type="checkbox" className="mx-2" style={{ width: 18, height: 18 }} />
                        <span style={{ color: "gray" }}>Subscribe to Veedu App tips and updates*</span>
                    </Col>
                </form>
            </div>
        </>
    );
};
export default LoginPageDialog;