import HttpClientWrapper from "../../http-client-wrapper";
import{SignupPayload} from "./signup-payload";

class RegisterApiService{

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    register = async (payload:SignupPayload) => {
        console.log("RegisterApiService register() start = " + JSON.stringify(payload));
        try {
            let data: any = await this.httpClientWrapper.post('/v1/persons/createUser', payload);
            console.log("RegisterApiService register() response data " + JSON.stringify(data));
            console.log("RegisterApiService register() end = " + JSON.stringify(payload));
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveContactDetails = async (formData:FormData) => {
        try {
            let data: any = await this.httpClientWrapper.postFormData('/v1/contacts', formData);
            return data;
        } catch (error) {
            throw error;
        }
    }
    getPromoCodeByCode = async(code:any) =>{
        try{
            let data:any = await this.httpClientWrapper.get('/v1/promoCodes/code/'+ code);
            return data;
        } catch (error) {
            throw error;
        }

    }



}export default RegisterApiService;