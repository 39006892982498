import PropertyApiService from "../../../data/api/services/property/property-api-service";
import Footer from "../../../layouts/footer/footer"
import Header from "../../../layouts/header/header"
import { useLocation, useNavigate } from "react-router-dom";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { toast } from "react-toastify";
import PreviewVideoDialog from "../../../components/preview-video-dialog/preview-video-dialog";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ValidationError from "../../../common/validation-error";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "react-bootstrap/esm/Spinner";

const EditMediaPage = ({ propertyId, onPreviousCallback, propertyFormData }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tabNumber: number = 5;

    const [galleryImageValidationErrorMsg, setGalleryImageValidationErrorMsg] = useState(null);
    const [fileValidationErrorMsg, setFileValidationErrorMsg] = useState(null);
    const [virtualTourValidationErrorMsg, setVirtualTourValidationErrorMsg] = useState(null);
    const [videoUrlValidationErrorMsg, setVideoUrlValidationErrorMsg] = useState(null);
    const [planUrllidationErrorMsg, setPlanUrlValidationErrorMsg] = useState(null);
    const [officialWebsiteValidationErrorMsg, setOfficialWebsiteValidationErrorMsg] = useState(null);

    let navigate: any = useNavigate();

    const properyFileInput: any = useRef(null);
    const properyUploadFileInput: any = useRef(null);


    const [videShow, setVideoShow] = useState(false);

    const handlevideoShow = () => setVideoShow(true);

    const handleVideoClose = () => setVideoShow(false);

    const propertyApiService = new PropertyApiService();

    const [officialWebSite, setOfficialWebSite] = useState("");

    const [propertyImages, setPropertyImages] = useState<any>([]);

    const [propertyFile, setPropertyFile] = useState<any>([]);

    const [videoUrl, setVideoUrl] = useState("");

    const [planUrl, setPlanUrl] = useState("");

    const [virtualTour, setVirtualTour] = useState("");

    const [processing, setProcessing] = useState(false);


    const validateMediaFormData = (): void => {
        let errors: any[] = [];
        // if (!officialWebSite) {
        //     let error: any = {};
        //     error.validationCode = "officialWebSite";
        //     error.message = 'Official Website required';
        //     errors.push(error);
        // }
        // if (!videoUrl) {
        //     let error: any = {};
        //     error.validationCode = "videoUrl";
        //     error.message = 'Video Url required';
        //     errors.push(error);
        // }
        // if (!planUrl) {
        //     let error: any = {};
        //     error.validationCode = "planUrl";
        //     error.message = 'Plan Url required';
        //     errors.push(error);
        // }
        // if (!virtualTour) {
        //     let error: any = {};
        //     error.validationCode = "virtualTour";
        //     error.message = 'Virtual Tour required';
        //     errors.push(error);
        // }
        if (propertyImages.length === 0) {
            let error: any = {};
            error.validationCode = "propertyImages";
            error.message = 'Gallery Image required';
            errors.push(error);
        }
        // if (!propertyFile || propertyFile.length === 0) {
        //     let error: any = {};
        //     error.validationCode = "propertyFile";
        //     error.message = 'Plan Document required';
        //     errors.push(error);
        // }
        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const validateAndSaveMediaFormData = () => {
        try {
            validateMediaFormData();
            if (propertyFormData.has('images')) {
                propertyFormData.delete('images');
            }
            for (let i = 0; i < propertyImages.length; i++) {
                propertyFormData.append('images', propertyImages[i].file);
            }

            if (propertyFormData.has('files')) {
                propertyFormData.delete('files');
            }
            for (let i = 0; i < propertyFile.length; i++) {
                propertyFormData.append('files', propertyFile[i].file);
            }

            if (propertyFormData.has('officialWebsite')) {
                propertyFormData.delete('officialWebsite');
            }
            propertyFormData.append('officialWebsite', officialWebSite);

            if (propertyFormData.has('videoUrl')) {
                propertyFormData.delete('videoUrl');
            }
            propertyFormData.append('videoUrl', videoUrl);

            if (propertyFormData.has('planUrl')) {
                propertyFormData.delete('planUrl');
            }
            propertyFormData.append('planUrl', planUrl);

            if (propertyFormData.has('virtualTour')) {
                propertyFormData.delete('virtualTour');
            }
            propertyFormData.append('virtualTour', virtualTour);
        } catch (error: any) {
            throw error;
        }
    }

    const submitMediaFormData = async () => {
        try {
            validateAndSaveMediaFormData();
            setProcessing(true);
            let data = await propertyApiService.updateProperty(propertyId, propertyFormData);
            toast.success('Successfully Updated Your Property Id as ' + data.id + '.', { containerId: 'TR' });
            navigate('/')
        } catch (error: any) {
            setProcessing(false);
            showValidationErrorInlineUI(error);
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            // if (errorCode === 'officialWebSite') {
            //     setOfficialWebsiteValidationErrorMsg(errorMsg);
            // }
            // if (errorCode === 'videoUrl') {
            //     setVideoUrlValidationErrorMsg(errorMsg);
            // }
            // if (errorCode === 'planUrl') {
            //     setPlanUrlValidationErrorMsg(errorMsg);
            // }
            // if (errorCode === 'virtualTour') {
            //     setVirtualTourValidationErrorMsg(errorMsg);
            // }
            if (errorCode === 'propertyImages') {
                setGalleryImageValidationErrorMsg(errorMsg);
            }
            // if (errorCode === 'propertyFile') {
            //     setFileValidationErrorMsg(errorMsg);
            // }
        }
    }


    const setUpGalleryImageyUI = (galleryImages: any) => {
        let images: any = [];
        for (let i = 0; i < galleryImages.length; i++) {
            let image: any = {};
            image.id = galleryImages[i].id;
            image.imagePath = galleryImages[i].imagePath;
            images.push(image);
        }
        setPropertyImages([...propertyImages, ...images]);
    }
    const setUpFileUI = (saveFile: any) => {
        let uploadFile: any = [];
        for (let i = 0; i < saveFile.length; i++) {
            let selectFile: any = {};
            selectFile.id = saveFile[i].id;
            selectFile.saveFile = saveFile[i].filePath;
            uploadFile.push(selectFile);
        }
        setPropertyFile([...propertyFile, ...uploadFile]);
    }
    const onPropertyFileUploadListener = (event: any) => {
        let uploadFile: any = [];
        for (let i = 0; i < event.target.files.length; i++) {
            let selectFile: any = {};
            selectFile.file = event.target.files[i];
            selectFile.url = URL.createObjectURL(selectFile.file);
            uploadFile.push(selectFile);
        }
        setPropertyFile([...propertyFile, ...uploadFile]);
    }
    const onFileUploadListener = () => {
        properyFileInput.current?.click();
    }
    const onPropertyFileUpload = () => {
        properyUploadFileInput.current?.click();
    }

    const updateUI = (data: any, amenityTemplateData: any) => {
        setPlanUrl(data.planUrl);
        setOfficialWebSite(data.officialWebSite);
        setUpGalleryImageyUI(data.propertyImagesData);
        setUpFileUI(data.propertyFileData)
        setVideoUrl(data.videoUrl);
        setVirtualTour(data.virtualTour);

    }

    const handleOnVideoUrlChange = (value: any) => {
        setVideoUrl(value);
        setVideoUrlValidationErrorMsg('');
    }

    const handleOnPlanUrlChange = (value: any) => {
        setPlanUrl(value);
        setPlanUrlValidationErrorMsg('');
    }

    const handleOnOfficialWebsiteChange = (value: any) => {
        setOfficialWebSite(value);
        setOfficialWebsiteValidationErrorMsg('');
    }

    const handleOnVirtualTourChange = (value: any) => {
        setVirtualTour(value);
        setVirtualTourValidationErrorMsg('');
    }

    const onPropertyImagesUploadListener = (event: any) => {
        let images: any = [];
        for (let i = 0; i < event.target.files.length; i++) {
            let image: any = {};
            image.file = event.target.files[i];
            image.imagePath = URL.createObjectURL(image.file);
            images.push(image);
        }
        setPropertyImages([...propertyImages, ...images]);
    }

    const removePropertyImage = async (image: any, idx: any) => {
        try {
            if (image && image.id) {
                await propertyApiService.deletePropertyImage(propertyId, image.id);
            }
            let propertyImagesArr = propertyImages.filter((item: any, j: any) => idx !== j);
            setPropertyImages(propertyImagesArr);
        } catch (error) {
            console.log("ERROR occured removePropertyFilef() error=== " + JSON.stringify(error));
        }
    }
    const removePropertyFile = async (file: any, idx: any) => {
        try {
            if (file && file.id) {
                await propertyApiService.deletePropertyFile(propertyId, file.id);
            }
            let propertyfileArr = propertyFile.filter((item: any, j: any) => idx !== j);
            setPropertyFile(propertyfileArr);
        } catch (error) {
            console.log("ERROR occured removePropertyImage() error=== " + JSON.stringify(error));
        }
    }

    const onVideoDialogClose = () => {
        setVideoShow(false);
    }

    const previousPage = () => {
        //alert("The form you have filled will get lost in this page. Are you sure want to go previous?")
        onPreviousCallback((tabNumber - 1));
    };

    const highlightFormData = async () => {
        let formData: FormData = propertyFormData;
        console.log("AddGeneralPage highlightFormData");
        if (formData.get('officialWebsite')) {
            setOfficialWebSite(formData.get('officialWebsite').toString());
        }
        if (formData.get('videoUrl')) {
            setVideoUrl(formData.get('videoUrl').toString());
        }
        if (formData.get('planUrl')) {
            setPlanUrl(formData.get('planUrl').toString());
        }
        if (formData.get('virtualTour')) {
            setVirtualTour(formData.get('virtualTour').toString());
        }
        await getPropertyDetailsById();
    }

    const getPropertyDetailsById = async () => {
        try {
            console.log("START highlightPropertyPlanDocuments() ====");
            let propertyData = await propertyApiService.getProperty(propertyId);
            highlightPropertyPlanDocuments(propertyData.propertyPlanDocumentData);
            highlightGalleryImages(propertyData.propertyImagesData);
            console.log("END highlightPropertyPlanDocuments() ====");
        } catch (error) {
            console.log("Edit Media page getPropertyDetailsById() Error");
            console.log(error);
        }
    }

    const highlightGalleryImages = (galleryImages: any) => {
        setPropertyImages([]);
        if (!galleryImages) {
            return;
        }
        let images: any = [];
        for (let i = 0; i < galleryImages.length; i++) {
            let image: any = {};
            image.imagePath = galleryImages[i].imagePath;
            image.id = galleryImages[i].id;
            images.push(image);
        }
        setPropertyImages([...propertyImages, ...images]);
    }

    const highlightPropertyPlanDocuments = (planDocumentList: any[]) => {
        setPropertyFile([]);
        let planDocuments: any = [];
        for (let i = 0; i < planDocumentList.length; i++) {
            let planDocument: any = {};
            let file: any = {};
            planDocument.id = planDocumentList[i].id;
            file.type = planDocumentList[i].fileType;
            file.name = planDocumentList[i].name;
            planDocument.file = file;
            planDocument.url = planDocumentList[i].filePath;
            planDocuments.push(planDocument);
        }
        setPropertyFile([...propertyFile, ...planDocuments]);
    }

    useEffect(() => {
        highlightFormData();
    }, []);

    return (
        <>
            <div className="body container-fluid row">
                <div className=" container w-100  ms-1 ">
                    <div className="card-body">
                        <div className="col-sm-9 mx-auto ">
                            <div className="card ">
                                <div className="card-body">
                                    <div className="text-color text-center">
                                        <p> Media</p>
                                    </div>
                                    <div className="row ms-5">
                                        <div className="col-md-6 mb-2 ">
                                            <label className="mt-4">Upload Gallery Image<sup className="required">*</sup></label>
                                            <div className="form-control mt-4 h-auto" style={{ display: 'none' }}>
                                                <input type="file" className={galleryImageValidationErrorMsg ? 'error-field input-edit ' : 'input-edit '} ref={properyFileInput} onChange={onPropertyImagesUploadListener} multiple={true} />
                                            </div>
                                            <div className="form-control mt-4 h-auto">
                                                <input type="button" value="Upload Images" onClick={onFileUploadListener} />
                                            </div>
                                            {
                                                propertyImages.map((image: any, idx: any) => {
                                                    return (
                                                        <div key={'property_image_' + idx} className="col-md-6 mt-2" >
                                                            <div className="col-md-12">
                                                                <div className="col-md-6"><img src={image.imagePath} width={300} height={200}></img></div>
                                                                <div className="col-md-3">
                                                                    <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={() => removePropertyImage(image, idx)}>
                                                                        <span className="" aria-hidden="true">Remove</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <FormFieldError errorMessage={galleryImageValidationErrorMsg}></FormFieldError>

                                        </div>
                                        <div className="col-md-6 mb-2 ">
                                            <label className="mt-4">Upload Plan Document <sup className="required">*</sup></label>
                                            <div className="form-control mt-4 h-auto" style={{ display: 'none' }}>
                                                <input type="file" className={fileValidationErrorMsg ? 'error-field input-edit ' : 'input-edit '} ref={properyUploadFileInput} onChange={onPropertyFileUploadListener} multiple={true} />
                                            </div>
                                            <div className="form-control mt-4 h-auto">
                                                <input type="button" value="Upload Plan Document" onClick={onPropertyFileUpload} />
                                            </div>
                                            {
                                                propertyFile.map((file: any, idx: any) => {
                                                    console.log('filefilefilefilefilefile', file);
                                                    return (
                                                        <div key={'property_file_' + idx} className="col-md-12" >
                                                            <div className="col-md-12">
                                                                <div className="col-md-12">
                                                                    {
                                                                        file.file && file.file.type == 'application/pdf'
                                                                            ? <div className='col-md-12'><a href="javascript:void(0)">{file.file.name}</a></div>
                                                                            : <img src={file.url} width={300} height={200}></img>
                                                                    }
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={() => removePropertyFile(file, idx)}>
                                                                        <span className="" aria-hidden="true">Remove</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <FormFieldError errorMessage={fileValidationErrorMsg}></FormFieldError>

                                        </div>

                                        <div className="col-md-6 mb-2">
                                            <div className="mb-2">
                                                <label className="mt-4">Official Website</label>
                                                <input
                                                    type="url"
                                                    className={officialWebsiteValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    placeholder="Official Website"
                                                    value={officialWebSite.trim() === "0" ? "" : officialWebSite}
                                                    onChange={e => { handleOnOfficialWebsiteChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={officialWebsiteValidationErrorMsg}></FormFieldError>

                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="mb-2">
                                                <label className="mt-4">360° Virtual Tour</label>
                                                <textarea className={virtualTourValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    placeholder="360° Virtual Tour"
                                                    value={virtualTour.trim() === "0" ? "" : virtualTour}
                                                    onChange={e => { handleOnVirtualTourChange(e.target.value) }} />
                                            </div>
                                            <FormFieldError errorMessage={virtualTourValidationErrorMsg}></FormFieldError>

                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="mb-2">
                                                <label className="mt-4">Plan url</label>
                                                <input
                                                    type="url"
                                                    name="plan_url"
                                                    value={planUrl.trim() === "0" ? "" : planUrl}
                                                    className={planUrllidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    id="plan_url"
                                                    placeholder="Plan url"
                                                    onChange={e => { handleOnPlanUrlChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={planUrllidationErrorMsg}></FormFieldError>

                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <label className="mt-4">Video url</label>

                                            <input type="text" className={videoUrlValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                value={videoUrl.trim() === "0" ? "" : videoUrl}
                                                onChange={e => { handleOnVideoUrlChange(e.target.value) }}
                                                placeholder="video url" />

                                            <button className="btn btn-primary mt-4" onClick={handlevideoShow}>
                                                Preview Videos
                                            </button> <br />
                                            <FormFieldError errorMessage={videoUrlValidationErrorMsg}></FormFieldError>
                                        </div>
                                        {
                                            (videShow) && <PreviewVideoDialog onCloseCallback={onVideoDialogClose} url={videoUrl} />
                                        }

                                    </div>
                                </div>
                                <div className="my-4  d-flex justify-content-end me-5">
                                    <button className="btn btn-primary me-3" onClick={previousPage}>Previous</button>
                                    <button disabled={processing} onClick={submitMediaFormData} className="btn btn-success " type="button">
                                        Update
                                    </button>
                                    {(processing) && <Spinner animation="grow" variant="warning" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditMediaPage