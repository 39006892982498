import HttpClientWrapper from "../../http-client-wrapper";

class FooterApiService {
    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getFooterAboutByCode = async (footerType:any) => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/footers/'+footerType+'/fetchAll');
            console.log("LocationApi getLocationById() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

}
export default FooterApiService