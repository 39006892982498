import { useState, useEffect, useRef } from "react";
import { Footer, Header } from "../../../layouts";
import "../view-profession-image/view-profession-image.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Carousel, Col, Modal, Row } from "react-bootstrap";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RecommendIcon from '@mui/icons-material/Recommend';
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import StorageService from "../../../data/storage/storage-service";
import { CommentLikePayload, CommentReplyLikePayload, FavouritePostPayload, PagePostCommentPayload, PagePostCommentReplyPayload, PagePostLikePayload } from "../../../data/api/services/professional-page/professional-page-payload";
import { faEdit, faTrash, faGear, faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfessionPostReport from "../profession-post-report/profession-post-report";
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import EditProfessionPost from "../edit-profession-post/edit-profession-post";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { Tooltip } from 'react-tooltip'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SendIcon from '@mui/icons-material/Send';
import ProfessionPostShare from "../profession-post-share/profession-post-share";
import Avatar from "../../../assets/profile-pic.jpeg"
import LoginPageDialog from "../../login/login-page-dialog";

const ViewProfessionImage = () => {

  const location = useLocation();
  const encoded = new URLSearchParams(location.search).get('v');
  const decoded = atob(encoded);
  const params = new URLSearchParams(decoded);
  const professionalPageId = params.get('pageId');
  const image = parseInt(params.get('mediaId'));
  // const image = location?.state?.selectedImage;
  // const professionalPageId = location?.state?.professionalPageId;
  const reportClose = () => setReportShow(false);
  const shareClose = () => setShareShow(false);

  const [reportData, setReportData] = useState<any>([]);
  const [shareData, setShareData] = useState<any>([]);
  const [shareShow, setShareShow] = useState(false);

  const [reportShow, setReportShow] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [likedPosts, setLikedPosts] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [editShow, setEditShow] = useState(false);
  const editClose = () => setEditShow(false);
  const [editData, setEditData] = useState<any>([]);
  const [isSaved, setIsSaved] = useState(false);
  const [hasAddPostOption, setHasAddPostOption] = useState(false);
  const [data1, setData] = useState<any>([]);
  const [professionalPagePost, setProfessionalPagePosts] = useState<any>([]);

  let navigate: any = useNavigate();

  const professionalPageApiService = new ProfessionalPageApiService();
  const [userPageData, setUserPageData] = useState<any>([]);
  const activePageItem = userPageData.find(item => item.activePage === true);
  const userPageId = activePageItem?.id;

  const [commentInput, setCommentInput] = useState('');
  const [visibleReplies, setVisibleReplies] = useState([]);
  const [commentReplyData, setCommentReplyData] = useState<any>([]);
  const [showReplyInput, setShowReplyInput] = useState(null);
  const [replyCommentInput, setReplyCommentInput] = useState([]);
  const [visibleChildReplies, setVisibleChildReplies] = useState([]);
  const [showChildReplyInput, setShowChildReplyInput] = useState([]);
  const [replyChildCommentInput, setReplyChildCommentInput] = useState([]);
  const [commentChildReplyData, setCommentChildReplyData] = useState<any>([]);
  const [selectedItemCommentIndex, setSelectedItemCommentIndex] = useState(null);
  const [isCommentDropdownVisible, setCommentDropdownVisible] = useState(false);
  const [selectedItemReplyIndex, setSelectedItemReplyIndex] = useState(null);
  const [isReplyDropdownVisible, setReplyDropdownVisible] = useState(false);

  const [reportCommentData, setReportCommentData] = useState<any>([]);
  const [reportCommentShow, setReportCommentShow] = useState(false);
  const reportCommentHandleClose = () => setReportCommentShow(false);

  const [reportCommentReplyData, setReportCommentReplyData] = useState<any>([]);
  const [reportCommentReplyShow, setReportCommentReplyShow] = useState(false);
  const reportCommentReplyHandleClose = () => setReportCommentReplyShow(false);
  const [showLogInDialog, setShowLogInDialog] = useState(false);
  const handleShowLogInDialog = () => setShowLogInDialog(true);
  const handleCloseLogInDialog = () => setShowLogInDialog(false);

  const videoRefs = useRef([]);

  const validProfessionalPagePosts = professionalPagePost?.filter((item) =>
    item.postType === "NORMAL_POST" &&
    item.professionalPagePostImageGalleryData.map((imageData) => imageData.id)
  );

  const selectedImageIndex = validProfessionalPagePosts?.map((item) => item.professionalPagePostImageGalleryData.map((imageData) => imageData.id))
    .flat()
    .indexOf(image);

  const carouselItems = [
    ...(validProfessionalPagePosts || []).flatMap((item) =>
      (item?.professionalPagePostImageGalleryData || []).map((imageData) => imageData)
    ),
    ...(validProfessionalPagePosts[0]?.professionalPagePostImageGalleryData || []).map((imageData) => imageData),
  ];

  const rotatedCarouselItems = [
    ...carouselItems.slice(selectedImageIndex),
    ...carouselItems.slice(0, selectedImageIndex),
  ];

  const handleCarouselSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);

    const activeItem = document.querySelector('.carousel-item.active');
    const videoElement = activeItem.querySelector('video');
    if (videoElement) {
      videoElement.pause();
    }

    const selectedImagePath = rotatedCarouselItems[selectedIndex];
    const post = professionalPagePost.find(post =>
      post.professionalPagePostImageGalleryData.some(imageData => imageData === selectedImagePath)
    );
    setCommentReplyData([]);
    setVisibleReplies([]);
    getProfessionalPageDetails(post);
    getProfessionalPagePostById(post.id)
    const newMediaId = selectedImagePath.id;
    if (newMediaId) {
      const combined = `pageId=${professionalPageId}&mediaId=${newMediaId}`;
      const encoded = btoa(combined);
      const newUrl = `/view-media?v=${encoded}`;
      window.history.pushState(null, "", newUrl);
    }
  };

  const toggleDropdown = () => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setDropdownVisible(!isDropdownVisible);
    }
  };

  const handleCommentOnChange = (c: any) => {
    setCommentInput(c);
  };

  const saveProfessionalPagePostComment = async (post) => {
    try {
      let user = StorageService.isLoggedIn();
      if (!user) {
        // showLoggedInConfirmDialog();
        handleShowLogInDialog();
      } else {
        let payload: PagePostCommentPayload = {
          comments: commentInput,
          postId: post.id,
          pageId: userPageId
        };
        await professionalPageApiService.saveProfessionalPagePostComment(payload);
        handleCommentOnChange('');
        getProfessionalPagePostById(post.id);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleLikeCommentClick = (item: any, index: any) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      saveCommentLike(item)
    }
  };

  const handleReplyClick = (commentIndex) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setShowReplyInput(commentIndex);
    }
  };

  const saveCommentLike = async (item) => {
    try {
      let payload: CommentLikePayload = {
        userPageId: userPageId,
        commentId: item.id
      };
      await professionalPageApiService.saveCommentLike(payload);
      getProfessionalPagePostById(item.pagePostId);
    } catch (error) {
      console.log(error)
    }
  }

  const handleViewRepliesClick = (commentId, commentIndex) => {
    try {
      const updatedVisibleReplies = Array(selectedPost.professionalPageCommentData.length).fill(false);
      updatedVisibleReplies[commentIndex] = !visibleReplies[commentIndex];
      setVisibleReplies(updatedVisibleReplies);
      getProfessionalPagePostCommentReplyByCommentId(commentId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReplyCommentOnChange = (index: any, rc: any) => {
    const newComment = [...replyCommentInput];
    newComment[index] = rc;
    setReplyCommentInput(newComment);
  };

  const saveProfessionalPagePostCommentReply = async (index, item) => {
    try {
      let user = StorageService.isLoggedIn();
      if (!user) {
        // showLoggedInConfirmDialog();
        handleShowLogInDialog();
      } else {
        let payload: PagePostCommentReplyPayload = {
          comments: replyCommentInput[index],
          commentId: item.id,
          postId: item.pagePostId,
          pageId: userPageId,
          commentReplyId: 0
        };
        await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
        handleReplyCommentOnChange(index, '');
        getProfessionalPagePostById(item.pagePostId);
        getProfessionalPagePostCommentReplyByCommentId(item.id);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleLikeReplyCommentClick = (commentReply, commentReplyIndex) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      saveCommentReplyLike(commentReply, commentReplyIndex);
    }
  };

  const saveCommentReplyLike = async (item, index) => {
    try {
      let payload: CommentReplyLikePayload = {
        userPageId: userPageId,
        commentReplyId: item.id
      };
      await professionalPageApiService.saveCommentReplyLike(payload);
      getProfessionalPagePostById(item.pagePostId);
      getProfessionalPagePostCommentReplyByCommentId(item.commentId);
    } catch (error) {
      console.log(error)
    }
  }

  const handleChildReplyClick = (commentReplyId) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setShowChildReplyInput(prevState => {
        const updatedState = { ...prevState };
        Object.keys(updatedState).forEach(key => {
          updatedState[key] = false;
        });
        updatedState[commentReplyId] = !prevState[commentReplyId];

        return updatedState;
      });
    }
  };

  const handleViewChildRepliesClick = (commentReply, commentReplyIndex, childArrayData: any[]) => {
    try {
      const updatedVisibleReplies = Array(childArrayData.length).fill(false);
      setVisibleChildReplies(prevState => ({
        ...updatedVisibleReplies,
        [commentReply.id]: !prevState[commentReply.id],
      }));
      getProfessionalPagePostCommentReplyByParentId(commentReply, commentReplyIndex);
    } catch (error) {
      console.log(error);
    }
  }

  const getProfessionalPagePostCommentReplyByParentId = async (commentReply, commentReplyIndex) => {
    try {
      let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByParentId(commentReply.id);
      setCommentChildReplyData(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChildReplyCommentOnChange = (index: any, rcc: any) => {
    const newComment = [...replyChildCommentInput];
    newComment[index] = rcc;
    setReplyChildCommentInput(newComment);
  };

  const saveProfessionalPagePostChildCommentReply = async (index, item) => {
    try {
      let user = StorageService.isLoggedIn();
      if (!user) {
        // showLoggedInConfirmDialog();
        handleShowLogInDialog();
      } else {
        let payload: PagePostCommentReplyPayload = {
          comments: replyChildCommentInput[index],
          commentId: item.commentId,
          postId: item.pagePostId,
          pageId: userPageId,
          commentReplyId: item.id
        };
        await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
        handleChildReplyCommentOnChange(index, '');
        getProfessionalPagePostCommentReplyByCommentId(item.commentId);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleLikeReplyChildCommentClick = (commentReply, commentReplyIndex, replyId) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      saveCommentReplyChildLike(commentReply, commentReplyIndex, replyId);
    }
  };

  const saveCommentReplyChildLike = async (item, index, replyId) => {
    try {
      let payload: CommentReplyLikePayload = {
        userPageId: userPageId,
        commentReplyId: item.id
      };
      await professionalPageApiService.saveCommentReplyLike(payload);
      getChildCommentReplyByParentId(replyId);
    } catch (error) {
      console.log(error)
    }
  };

  const getChildCommentReplyByParentId = async (replyId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByParentId(replyId);
      setCommentChildReplyData(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfessionalPagePostCommentReplyByCommentId = async (commentId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByCommentId(commentId);
      setCommentReplyData(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const saveChildCommentReply = async (index, item, replyId) => {
    try {
      let user = StorageService.isLoggedIn();
      if (!user) {
        // showLoggedInConfirmDialog();
        handleShowLogInDialog();
      } else {
        let payload: PagePostCommentReplyPayload = {
          comments: replyChildCommentInput[index],
          commentId: item.commentId,
          postId: item.pagePostId,
          pageId: userPageId,
          commentReplyId: item.id
        };
        await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
        handleChildReplyCommentOnChange(index, '');
        getChildCommentReplyByParentId(replyId);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const toggleCommentDropdown = (index) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setSelectedItemCommentIndex(selectedItemCommentIndex === index ? null : index);
      setCommentDropdownVisible(!isCommentDropdownVisible);
    }
  };

  const showLoggedInConfirmDialog = () => {
    confirmAlert({
      title: 'Alert',
      message: 'You have to logIn.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { navigate('/login'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('clicked no') }
        }
      ]
    });
  };

  const toggleReplyDropdown = (item, index) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setSelectedItemReplyIndex(selectedItemReplyIndex === item.id ? null : item.id);
      setReplyDropdownVisible(!isReplyDropdownVisible);
    }
  };

  const getProfessionalPageDetails = async (selectedPost) => {
    try {
      const userId = StorageService.getUserId();
      console.log(userId);
      let res = await professionalPageApiService.getProfessionalPageData(userId);
      setUserPageData(res);
      const activePageItem = res.find(item => item.activePage === true);
      const userPageId = activePageItem?.id;
    } catch (error) {
      console.log(error);
    }
  };

  const closeSubMenu = (e) => {
    if (isDropdownVisible) {
      setDropdownVisible(false);
    }
    if (selectedItemCommentIndex !== null && !e.target.closest('.dropdown-content')) {
      setSelectedItemCommentIndex(null);
    }
    if (selectedItemReplyIndex !== null && !e.target.closest('.dropdown-content')) {
      setSelectedItemReplyIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeSubMenu);
    return () => {
      document.removeEventListener('click', closeSubMenu);
    };
  }, [isDropdownVisible, isCommentDropdownVisible, isReplyDropdownVisible]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProfessionalPageDetail();
  }, []);

  const getProfessionalPageDetail = async () => {
    try {
      const userId = StorageService.getUserId();
      console.log(userId);
      if (userId) {
        let res = await professionalPageApiService.getProfessionalPageData(userId);
        setUserPageData(res);
        const activePageItem = res.find(item => item.activePage === true);
        const userPageId = activePageItem?.id;
        //   getProfessionalPageById(userPageId);
        // } else {
        //   getProfessionalPageById('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (item) => {
    setEditData(item);
    setEditShow(true);
    setDropdownVisible(false);
  };

  const getProfessionalPageById = async (userPageId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPageById(userPageId);
      // const addPostOptionExists = res.id === userPageId;
      // setHasAddPostOption(addPostOptionExists);
      // setData(res);
      setProfessionalPagePosts(res.professionalPagePosts);
      if (image && res.professionalPagePosts.length > 0) {
        const post = res.professionalPagePosts.find(post =>
          post.professionalPagePostImageGalleryData.some(imageData => imageData.id === image)
        );
        getProfessionalPagePostById(post.id);
        getProfessionalPageDetails(post);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLikeClick = (post) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setLikedPosts((prevLikedPosts) => !prevLikedPosts);
      saveProfessionalPagePostLike(post);
    }
  };

  const handleIsSavedClick = (item) => {
    setIsSaved((prevIsSaved) => !prevIsSaved);
    saveFavouritePost(item);
  };

  const saveFavouritePost = async (item: any) => {
    try {
      let payload: FavouritePostPayload = {
        userPageId: userPageId,
        pagePostId: item.id
      };
      await professionalPageApiService.saveFavouritePost(payload);
      getProfessionalPageDetail();
    } catch (error) {
      console.log(error)
    }
  };

  const saveProfessionalPagePostLike = async (post) => {
    try {
      let payload: PagePostLikePayload = {
        postId: post.id,
        likedByPageId: userPageId
      };
      await professionalPageApiService.saveProfessionalPagePostLike(payload);
      getProfessionalPagePostById(post.id);
    } catch (error) {
      console.log(error)
    }
  };

  const handleDeleteConfirmed = (id: any) => {
    setDropdownVisible(false);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteProfessionalPagePost(id),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteProfessionalPagePost = async (id: any) => {
    try {
      if (id) {
        await professionalPageApiService.deleteProfessionalPost(id);
        toast.success('Post Deleted Successfully', { containerId: 'TR' });

        navigate('/professionals');
        return;
      }
    } catch (error) {
      console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
    }
  };

  const getProfessionalPagePostById = async (professionalPagePostId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPagePostData(professionalPagePostId);
      setSelectedPost(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReport = (item) => {
    setReportData(item);
    setReportShow(true);
    setDropdownVisible(false);
  };

  const handleCommentReport = (item) => {
    setReportCommentData(item);
    setReportCommentShow(true);
    setSelectedItemCommentIndex(null);
  }

  const handleShare = (item) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setShareData(item);
      setShareShow(true);
      setSelectedItemIndex(null);
    }
  };

  const handleCommentReplyReport = (item) => {
    setReportCommentReplyData(item);
    setReportCommentReplyShow(true);
    setSelectedItemReplyIndex(null);
  }

  const handleDeleteCommentDialog = (item: any) => {
    setSelectedItemCommentIndex(null);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteComment(item),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteComment = async (item: any) => {
    try {
      if (item) {
        await professionalPageApiService.deleteComment(item.id);
        toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
        getProfessionalPagePostById(item.pagePostId);
        return;
      }
    } catch (error) {
      console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
    }
  };

  const handleDeleteCommentReplyDialog = (item: any) => {
    setSelectedItemReplyIndex(null);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCommentReply(item),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteCommentReply = async (item: any) => {
    try {
      if (item) {
        await professionalPageApiService.deleteCommentReply(item.id);
        toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
        getProfessionalPagePostById(item.pagePostId);
        getProfessionalPagePostCommentReplyByCommentId(item.commentId);
        return;
      }
    } catch (error) {
      console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
    }
  };

  const handleDeleteCommentChildReplyDialog = (item: any, replyId: any) => {
    setSelectedItemReplyIndex(null);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCommentChildReply(item, replyId),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteCommentChildReply = async (item: any, replyId: any) => {
    try {
      if (item) {
        await professionalPageApiService.deleteCommentReply(item.id);
        toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
        getProfessionalPagePostById(item.pagePostId);
        getChildCommentReplyByParentId(replyId);
        return;
      }
    } catch (error) {
      console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
    }
  };


  useEffect(() => {
    getProfessionalPageById(professionalPageId);
  }, [image]);

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours and 30 minutes
    const istDate = new Date(date.getTime() + istOffset);
    console.log('istDate:', istDate)
    return istDate.toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata',
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const formatCommentTime = (createdAt) => {
    const currentDate = new Date();
    const commentDate = new Date(createdAt);
    const seconds = Math.floor((currentDate.getTime() - commentDate.getTime()) / 1000);

    if (seconds < 5) {
      return 'just now';
    }
    else if (seconds < 60) {
      return `${seconds}s`;
    }
    else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h`;
    } else if (seconds < 2592000) {
      const days = Math.floor(seconds / 86400);
      return `${days}d`;
    } else if (seconds < 31536000) {
      const months = Math.floor(seconds / 2592000);
      return `${months}m`;
    } else {
      const years = Math.floor(seconds / 31536000);
      return `${years}y`;
    }
  };

  const handleViewPage = (userId: number, id: number) => {
    // navigate(`/view-profession-profile?userId=${userId}&id=${id}`);
    const combined = `userId=${userId}&id=${id}`;
    const encoded = btoa(combined);
    navigate(`/professional?v=${encoded}`);
    console.log(`View button clicked for userId: ${userId}, id: ${id}`);
  };

  const renderChildReplies = (parentComment, replyId) => {

    if (!Array.isArray(parentComment)) {
      console.error('Parent comment is not an array:', parentComment);
      return null;
    }

    return parentComment.map((childCommentReply, childCommentReplyIndex) => (
      <>
        <Row className="comment-sec mt-0" key={childCommentReply.id}>
          <div className="post-comment-content p-1 pt-0">
            <Col md={2}>
              <img className="post-comment-profile-image"
                // src={childCommentReply.pageProfileImageUrl} 
                src={childCommentReply.pageProfileImageUrl != null ? childCommentReply.pageProfileImageUrl : Avatar}
                alt="" />
            </Col>
            <Col md={10}>
              <div className="d-flex align-items-center">
                <Col md={11} className="post-comment-text-container p-2 pt-0 pb-1">
                  <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                    onClick={() => handleViewPage(childCommentReply.createdBy, childCommentReply.pageId)}>
                    {childCommentReply.pageName}
                  </span>
                  <div className="post-comment-text">{childCommentReply.comment}</div>
                </Col>
                <Col md={1} onClick={(e) => e.stopPropagation()}>
                  <MoreHorizIcon className="m-2 more-horizicon"
                    onClick={() => toggleReplyDropdown(childCommentReply, childCommentReplyIndex)}
                  />
                  {userPageId == selectedPost?.professionalPageId && userPageId != childCommentReply.pageId && selectedPost?.professionalPageId != childCommentReply.pageId && (
                    <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                      style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                      <div className="ms-1" onClick={() => handleDeleteCommentChildReplyDialog(childCommentReply, replyId)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                        Delete
                      </div>
                      <div className="ms-1" onClick={() => handleCommentReplyReport(childCommentReply)}>
                        <FontAwesomeIcon icon={faFlag} className="me-2" />
                        Report
                      </div>
                    </div>
                  )}
                  {((userPageId == selectedPost?.professionalPageId) || (userPageId != selectedPost?.professionalPageId)) && userPageId == childCommentReply.pageId && ((selectedPost?.professionalPageId == childCommentReply.pageId) || (selectedPost?.professionalPageId != childCommentReply.pageId)) && (
                    <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                      style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                      <div className="ms-1" onClick={() => handleDeleteCommentChildReplyDialog(childCommentReply, replyId)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                        Delete
                      </div>
                    </div>
                  )}
                  {userPageId != selectedPost?.professionalPageId && userPageId != childCommentReply.pageId && (
                    <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                      style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                      <div className="ms-1" onClick={() => handleCommentReplyReport(childCommentReply)}>
                        <FontAwesomeIcon icon={faFlag} className="me-2" />
                        Report
                      </div>
                    </div>
                  )}
                </Col>
              </div>
              {childCommentReply.professionalPageCommentReplyLikeDataList && childCommentReply.professionalPageCommentReplyLikeDataList.length > 0 && (
                <div className="d-flex" style={{ marginTop: '-7px' }}>
                  <div className="recommend-container d-flex align-items-center">
                    <RecommendIcon style={{ color: '#007bff', fontSize: 'large' }} />
                    <span style={{ color: 'gray' }}>
                      {childCommentReply.professionalPageCommentReplyLikeDataList.length}
                    </span>
                  </div>
                </div>
              )}
              <div className="reply-container">
                <a className="">
                  {formatCommentTime(childCommentReply.createdAt)}
                </a>
                <a className="ms-2"
                  style={{
                    color: childCommentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                  }}
                  onClick={() => handleLikeReplyChildCommentClick(childCommentReply, childCommentReplyIndex, replyId)}>
                  {childCommentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                </a>
                <a className="ms-2" onClick={() => handleChildReplyClick(childCommentReply.id)}>
                  Reply
                </a>
              </div>
              {childCommentReply.professionalPageCommentChildReplyData && childCommentReply.professionalPageCommentChildReplyData.length > 0 && (
                <div className="d-flex align-items-end pb-1"
                  // onClick={() => handleViewChildRepliesClick(childCommentReply, childCommentReplyIndex)}
                  style={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    fontSize: 'small',
                    color: 'gray'
                  }}>
                  <SubdirectoryArrowRightIcon /> Replies
                </div>
              )}
            </Col>
          </div>
          {showChildReplyInput[childCommentReply.id] && (
            <Col className="comment-sec mt-0 ps-5">
              <img
                // src={activePageItem?.profileImageUrl} 
                src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                alt="" />
              <div className="input-div">
                <textarea
                  value={replyChildCommentInput[childCommentReplyIndex]}
                  placeholder="Write a comment..."
                  onChange={(e) => handleChildReplyCommentOnChange(childCommentReplyIndex, e.target.value)}
                />
                <SendIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => saveChildCommentReply(childCommentReplyIndex, childCommentReply, replyId)}
                />
              </div>
            </Col>
          )}
        </Row>
        {renderChildReplies(childCommentReply.professionalPageCommentChildReplyData, replyId)}
      </>
    ));
  };

  return (
    <>
      <div>
        <Header></Header>
        <div className="container-fluid main_page  ">
          <Row className="total-color">
            <div className="col-sm-9 bg-dark">
              {validProfessionalPagePosts.length > 0 && (
                <Carousel
                  fade
                  interval={null}
                  indicators={false}
                  controls={true}
                  activeIndex={activeIndex}
                  onSelect={handleCarouselSelect}
                >
                  {rotatedCarouselItems.map((item, index) => (
                    <Carousel.Item key={index}>
                      {item && (
                        <div className="d-flex justify-content-center">
                          {item.mimeType === 'IMAGE' ? (
                            <img
                              className="p-2 mb-3 mt-3"
                              src={item.imagePath}
                              alt={`Image ${index + 1}`}
                              style={{ maxWidth: "100%", height: "83vh" }}
                            />
                          ) : (
                            <>
                              <video
                                className="p-2 px-5 mb-3 mt-3"
                                style={{ width: '100%', height: '83vh' }}
                                controls disablePictureInPicture
                                controlsList="nodownload noplaybackrate"
                              >
                                <source src={item.imagePath} />
                              </video>
                            </>
                          )}
                        </div>
                      )}
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </div>
            <div className="col-sm-3">
              <div className="d-flex mt-2 col-sm-12 justify-content-between">
                <div className="d-flex col-sm-11 align-items-center">
                  <img
                    className="card_avatar_img"
                    // src={selectedPost?.pageProfileImageUrl}
                    src={selectedPost?.pageProfileImageUrl != null ? selectedPost?.pageProfileImageUrl : Avatar}
                    alt="avatar"
                  />
                  <div className="ms-2">
                    <div className="align-self-start mt-2" style={{ wordBreak: "break-word" }}>
                      <a className="page-name-style fw-semibold"
                        onClick={() => handleViewPage(selectedPost?.pageCreatedBy, selectedPost?.professionalPageId)}>
                        {selectedPost?.professionalName}
                      </a>
                    </div>

                    <div className="upload_date align-self-start">
                      <div className="pe-0">
                        <a data-tooltip-id="my-tooltip-styles"
                          data-tooltip-content={formattedDate(selectedPost?.createdAt)}>
                          <span>
                            {new Date(selectedPost?.createdAt).getDate()}{' '}
                            {new Date(selectedPost?.createdAt).toLocaleString('default', { month: 'long' })}{' '}
                            {new Date(selectedPost?.createdAt).getFullYear()}
                          </span>
                        </a>
                        <Tooltip id="my-tooltip-styles" className="tooltip" opacity={0.8} place={'bottom'} />
                        <span className="ms-1">·</span>
                        <span className="location_name_size">
                          <LocationOnIcon className="location_icon_size" />{selectedPost?.taggedLocationName}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="d-flex justify-content-end col-sm-1" onClick={(e) => e.stopPropagation()}>
                  <span onClick={() => toggleDropdown()}>
                    <MoreHorizIcon />
                  </span>
                  {selectedPost?.professionalPageId == userPageId && (
                    <div className={`page-dropdown-content ${isDropdownVisible ? 'show' : ''}`}
                      style={{ marginTop: '20px' }}>
                      <div className="mb-2 ms-1" onClick={() => handleEdit(selectedPost)}>
                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                        Edit Post
                      </div>
                      <div className="mb-2" onClick={() => handleIsSavedClick(selectedPost)}>
                        {selectedPost?.professionalPageFavouritePostDataList.some(favData => favData.userPageId === userPageId) ? (
                          <><TurnedInIcon className="me-1" />
                            Saved Post</>
                        ) : (
                          <><TurnedInNotIcon className="me-1" />
                            Save Post</>
                        )}
                      </div>
                      <div className="ms-1" onClick={() => handleDeleteConfirmed(selectedPost?.id)} >
                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                        Move to trash
                      </div>
                    </div>
                  )}
                  {selectedPost?.professionalPageId != userPageId && (
                    <div className={`page-dropdown-content ${isDropdownVisible ? 'show' : ''}`}
                      style={{ marginTop: '20px' }}>
                      <div className="mb-2" onClick={() => handleIsSavedClick(selectedPost)}>
                        {selectedPost?.professionalPageFavouritePostDataList.some(favData => favData.userPageId === userPageId) ? (
                          <><TurnedInIcon className="me-1" />
                            Saved Post</>
                        ) : (
                          <><TurnedInNotIcon className="me-1" />
                            Save Post</>
                        )}
                      </div>
                      <div className="ms-1" onClick={() => handleReport(selectedPost)}>
                        <FontAwesomeIcon icon={faFlag} className="me-2" />
                        Report
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {selectedPost?.caption == '' || selectedPost?.caption == "null" ? (
                null
              ) : (
                <div className="ms-3 me-5">{selectedPost?.caption}</div>
              )}

              {selectedPost?.professionalPagePostLikeData.length > 0 && (
                <div className="ms-2 d-flex align-items-center">
                  <RecommendIcon className="fs-5" style={{ color: '#007bff' }} />
                  {selectedPost?.professionalPagePostLikeData.length}
                </div>
              )}

              <hr className="m-1" />

              <Col className="d-flex justify-content-evenly">
                <Col md={4} className="d-flex justify-content-center">
                  <span className="d-flex align-items-center"
                    style={{
                      color: selectedPost?.professionalPagePostLikeData.some(likeData => likeData.likedByPageId === userPageId) ? '#007bff' : 'black',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleLikeClick(selectedPost)}>
                    <ThumbUpOffAltIcon />
                    {selectedPost?.professionalPagePostLikeData.some(likeData => likeData.likedByPageId === userPageId) ? 'Liked' : 'Like'}
                  </span>
                </Col>
                <Col md={4} className="d-flex justify-content-center">
                  <span className="d-flex align-items-center"
                    style={{ cursor: 'pointer' }}>
                    <ChatBubbleOutlineOutlinedIcon /> Comment
                  </span>
                </Col>
                <Col md={4} className="d-flex justify-content-center">
                  <span className="d-flex align-items-center"
                    style={{ cursor: 'pointer' }} onClick={() => handleShare(selectedPost)}>
                    <ShareIcon /> Share
                  </span>

                </Col>
              </Col>
              <hr className="m-1" />
              <Col className="comment-sec mt-2">
                <img className="me-2"
                  // src={activePageItem?.profileImageUrl} 
                  src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                />
                <div className="input-div">
                  <textarea
                    value={commentInput}
                    placeholder="Write a comment..."
                    onChange={e => { handleCommentOnChange(e.target.value) }}
                  />
                  <SendIcon style={{ cursor: 'pointer' }}
                    onClick={() => saveProfessionalPagePostComment(selectedPost)}
                  />
                </div>
              </Col>
              <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "55vh" }}
                className="switch-account-scrollbar">
                {Array.isArray(selectedPost?.professionalPageCommentData) &&
                  selectedPost?.professionalPageCommentData
                    .map((comment, commentIndex) => (
                      <Col key={commentIndex}>
                        {/* Step - 1  */}
                        <div className="post-comment-content ps-1">
                          <Col md={2}>
                            <img className="post-comment-profile-image"
                              // src={comment.pageProfileImageUrl}
                              src={comment.pageProfileImageUrl != null ? comment.pageProfileImageUrl : Avatar}
                              alt="" />
                          </Col>
                          <Col md={10}>
                            <div className="d-flex align-items-center">
                              <Col md={11} className="post-comment-text-container p-2 pt-0">
                                <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                                  onClick={() => handleViewPage(comment.createdBy, comment.pageId)}>
                                  {comment.pageName}
                                </span>
                                <div className="post-comment-comment-text">
                                  {comment.comment}
                                </div>
                              </Col>
                              <Col md={1} onClick={(e) => e.stopPropagation()}>
                                <MoreHorizIcon className="m-2 more-horizicon"
                                  onClick={() => toggleCommentDropdown(commentIndex)}
                                />
                                {userPageId == selectedPost?.professionalPageId && userPageId != comment.pageId && selectedPost?.professionalPageId != comment.pageId && (
                                  <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                    style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                    <div className="ms-1" onClick={() => handleDeleteCommentDialog(comment)}>
                                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                                      Delete
                                    </div>
                                    <div className="ms-1" onClick={() => handleCommentReport(comment)}>
                                      <FontAwesomeIcon icon={faFlag} className="me-2" />
                                      Report
                                    </div>
                                  </div>
                                )}
                                {((userPageId == selectedPost?.professionalPageId) || (userPageId != selectedPost?.professionalPageId)) && userPageId == comment.pageId && ((selectedPost?.professionalPageId == comment.pageId) || (selectedPost?.professionalPageId != comment.pageId)) && (
                                  <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                    style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                    <div className="ms-1" onClick={() => handleDeleteCommentDialog(comment)}>
                                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                                      Delete
                                    </div>
                                  </div>
                                )}
                                {userPageId != selectedPost?.professionalPageId && userPageId != comment.pageId && (
                                  <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                    style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                    <div className="ms-1" onClick={() => handleCommentReport(comment)}>
                                      <FontAwesomeIcon icon={faFlag} className="me-2" />
                                      Report
                                    </div>
                                  </div>
                                )}
                              </Col>
                            </div>
                            {comment.professionalPageCommentLikeDataList && comment.professionalPageCommentLikeDataList.length > 0 && (
                              <div className="d-flex"
                                style={{ marginTop: '-10px' }}>
                                <div className="recommend-container d-flex align-items-center">
                                  <RecommendIcon style={{
                                    color: '#007bff',
                                    fontSize: 'large'
                                  }} />
                                  <span style={{ color: 'gray' }}>{comment.professionalPageCommentLikeDataList.length}</span>
                                </div>
                              </div>
                            )}
                            <div className="reply-container">
                              <a className="">
                                {formatCommentTime(comment.createdAt)}
                              </a>
                              <a className="ms-2"
                                style={{
                                  color: comment.professionalPageCommentLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                                }}
                                onClick={() => handleLikeCommentClick(comment, commentIndex)}>
                                {comment.professionalPageCommentLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                              </a>
                              <a className="ms-2"
                                onClick={() => handleReplyClick(commentIndex)}>
                                Reply
                              </a>
                            </div>
                            {comment.professionalPageCommentReplyData && comment.professionalPageCommentReplyData.length > 0 && (
                              <div className="d-flex align-items-end"
                                onClick={() => handleViewRepliesClick(comment.id, commentIndex)}
                                style={{
                                  cursor: 'pointer',
                                  fontWeight: '600',
                                  fontSize: 'small',
                                  color: 'gray'
                                }}>
                                <SubdirectoryArrowRightIcon />
                                {visibleReplies[commentIndex] ? 'Replies' : 'View Replies'}
                              </div>
                            )}
                          </Col>
                        </div>
                        {showReplyInput === commentIndex && (
                          <Col className="comment-sec mt-0 px-2">
                            <img className="me-1"
                              // src={activePageItem?.profileImageUrl} 
                              src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                            />
                            <div className="input-div">
                              <textarea
                                value={replyCommentInput[commentIndex]}
                                placeholder="Write a comment..."
                                onChange={e => { handleReplyCommentOnChange(commentIndex, e.target.value) }}
                              />
                              <SendIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => saveProfessionalPagePostCommentReply(commentIndex, comment)}
                              />
                            </div>
                          </Col>
                        )}
                        {/* Step - 2  */}
                        {visibleReplies[commentIndex] && commentReplyData?.map((commentReply, commentReplyIndex) => (
                          <Row className="comment-sec mt-0 ps-3" key={commentReplyIndex}>
                            <div className="post-comment-content pt-0 p-2">
                              <Col md={2}>
                                <img className="post-comment-profile-image"
                                  style={{ height: '38px', width: '38px' }}
                                  // src={commentReply.pageProfileImageUrl} 
                                  src={commentReply.pageProfileImageUrl != null ? commentReply.pageProfileImageUrl : Avatar}
                                  alt="" />
                              </Col>
                              <Col md={10}>
                                <div className="d-flex align-items-center">
                                  <Col md={11} className="post-comment-text-container p-2 pt-0 pb-1">
                                    <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                                      onClick={() => handleViewPage(commentReply.createdBy, commentReply.pageId)}>
                                      {commentReply.pageName}
                                    </span>
                                    <div className="post-comment-text">{commentReply.comment}</div>
                                  </Col>
                                  <Col md={1} onClick={(e) => e.stopPropagation()}>
                                    <MoreHorizIcon className="m-2 more-horizicon"
                                      onClick={() => toggleReplyDropdown(commentReply, commentReplyIndex)}
                                    />
                                    {userPageId == selectedPost?.professionalPageId && userPageId != commentReply.pageId && selectedPost?.professionalPageId != commentReply.pageId && (
                                      <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                        style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                        <div className="ms-1" onClick={() => handleDeleteCommentReplyDialog(commentReply)}>
                                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                                          Delete
                                        </div>
                                        <div className="ms-1" onClick={() => handleCommentReplyReport(commentReply)}>
                                          <FontAwesomeIcon icon={faFlag} className="me-2" />
                                          Report
                                        </div>
                                      </div>
                                    )}
                                    {((userPageId == selectedPost?.professionalPageId) || (userPageId != selectedPost?.professionalPageId)) && userPageId == commentReply.pageId && ((selectedPost?.professionalPageId == commentReply.pageId) || (selectedPost?.professionalPageId != commentReply.pageId)) && (
                                      <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                        style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                        <div className="ms-1" onClick={() => handleDeleteCommentReplyDialog(commentReply)}>
                                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                                          Delete
                                        </div>
                                      </div>
                                    )}
                                    {userPageId != selectedPost?.professionalPageId && userPageId != commentReply.pageId && (
                                      <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                        style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                        <div className="ms-1" onClick={() => handleCommentReplyReport(commentReply)}>
                                          <FontAwesomeIcon icon={faFlag} className="me-2" />
                                          Report
                                        </div>
                                      </div>
                                    )}
                                  </Col>
                                </div>
                                {commentReply.professionalPageCommentReplyLikeDataList && commentReply.professionalPageCommentReplyLikeDataList.length > 0 && (
                                  <div className="d-flex"
                                    style={{ marginTop: '-7px' }}>
                                    <div className="recommend-container d-flex align-items-center">
                                      <RecommendIcon style={{
                                        color: '#007bff',
                                        fontSize: 'large'
                                      }} />
                                      <span style={{ color: 'gray' }}>
                                        {commentReply.professionalPageCommentReplyLikeDataList.length}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <div className="reply-container">
                                  <a className="">
                                    {formatCommentTime(commentReply.createdAt)}
                                  </a>
                                  <a className="ms-2"
                                    style={{
                                      color: commentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                                    }}
                                    onClick={() => handleLikeReplyCommentClick(commentReply, commentReplyIndex)}
                                  >
                                    {commentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                                  </a>
                                  <a className="ms-2"
                                    onClick={() => handleChildReplyClick(commentReply.id)}
                                  >
                                    Reply
                                  </a>
                                </div>
                                {commentReply.professionalPageCommentChildReplyData && commentReply.professionalPageCommentChildReplyData.length > 0 && (
                                  <div className="d-flex align-items-end"
                                    onClick={() => handleViewChildRepliesClick(commentReply, commentReplyIndex, commentReply.professionalPageCommentChildReplyData)}
                                    style={{
                                      cursor: 'pointer',
                                      fontWeight: '600',
                                      fontSize: 'small',
                                      color: 'gray'
                                    }}>
                                    <SubdirectoryArrowRightIcon />
                                    {visibleChildReplies[commentReply.id] ? 'Replies' : 'View Replies'}
                                  </div>
                                )}
                              </Col>
                            </div>
                            {showChildReplyInput[commentReply.id] && (
                              <Col className="comment-sec mt-0 ps-5">
                                <img
                                  // src={activePageItem?.profileImageUrl} 
                                  src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                                  alt="" />
                                <div className="input-div">
                                  <textarea
                                    value={replyChildCommentInput[commentReplyIndex]}
                                    placeholder="Write a comment..."
                                    onChange={e => { handleChildReplyCommentOnChange(commentReplyIndex, e.target.value) }}
                                  />
                                  <SendIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => saveProfessionalPagePostChildCommentReply(commentReplyIndex, commentReply)}
                                  />
                                </div>
                              </Col>
                            )}
                            {/* Step - 3  */}
                            {visibleChildReplies[commentReply.id] && renderChildReplies(commentChildReplyData, commentReply.id)}
                          </Row>
                        ))}
                      </Col>
                    ))
                }
              </div>
            </div>
          </Row>
        </div>
        <div className="bg-light white">
          <Modal size="lg" show={reportShow} onHide={reportClose}>
            <ProfessionPostReport data={reportData} reportType={'POST_REPORT'} handleClose={reportClose} />
          </Modal>
          <Modal size="lg" show={shareShow} onHide={shareClose}>
            <ProfessionPostShare data={shareData} handleClose={shareClose} />
          </Modal>
          <Modal size="lg" show={editShow} onHide={editClose}>
            <EditProfessionPost post={selectedPost} pageId={professionalPageId} postId={selectedPost?.id} handleClose={editClose} />
          </Modal>
          <Modal size="lg" show={reportCommentShow} onHide={reportCommentHandleClose}>
            <ProfessionPostReport data={reportCommentData} reportType={'COMMENT_REPORT'} handleClose={reportCommentHandleClose} />
          </Modal>
          <Modal size="lg" show={reportCommentReplyShow} onHide={reportCommentReplyHandleClose}>
            <ProfessionPostReport data={reportCommentReplyData} reportType={'REPLY_REPORT'} handleClose={reportCommentReplyHandleClose} />
          </Modal>
          <Modal show={showLogInDialog} onHide={handleCloseLogInDialog} backdrop="static"
            centered className="login-page-dialog-modal">
            <LoginPageDialog handleClose={handleCloseLogInDialog} />
          </Modal>
        </div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default ViewProfessionImage;

