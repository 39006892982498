import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../create-professional-page-container.css';
import ValidationError from "../../../../common/validation-error";
import { Form, Spinner } from "react-bootstrap";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ProfessionalPageWorkHourImage from '../../../../assets/professional-page-2.jpeg';
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";

const EditProfessionalPageWorkHour = ({ professionalPageId, professionalPageFormData, onPreviousCallback, onNextCallback }) => {

    const tabNumber: number = 2;
    const [selectedChips] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [processing, setProcessing] = useState(false);
    const desiredOrder = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const [timeOptions, setTimeOptions] = useState([]);

    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = hour.toString().padStart(2, "0");
                const formattedMinute = minute.toString().padStart(2, "0");
                options.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        setTimeOptions(options);
    };

    const renderTimeOptions = () => {
        return timeOptions.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
        ));
    };
    const [showSwitchStates, setShowSwitchStates] = useState({
        SUNDAY: true,
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
    });

    const handleChipSelect = (dayOfWeek: any) => {
        if (!selectedDays.some((selectedDay) => selectedDay.dayOfWeek === dayOfWeek)) {
            setSelectedDays([
                ...selectedDays,
                {
                    dayOfWeek,
                    startTime: '',
                    endTime: '',
                    professionalPageWorkHourId: '',
                    professionalPageId: ''
                }
            ]);
        }
    };
    const [switchStates, setSwitchStates] = useState({
        SUNDAY: true,
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
      });
      const handleAddTimes = (index) => {
        const newTimeEntry = {
            ...selectedDays[index],
            startTime: '',
            endTime: '',
            professionalPageWorkHourId: '',
            professionalPageId: '',
            addTimes: true
        };
        const updatedSelectedDays = [...selectedDays];
        updatedSelectedDays.splice(index + 1, 0, newTimeEntry);
        setSelectedDays(updatedSelectedDays);
    };
    
    const handleCancel = (index) => {
        const updatedSelectedDays = [...selectedDays];
        updatedSelectedDays.splice(index, 1);
        setSelectedDays(updatedSelectedDays);
    };
    const handleDaySwitchChange = (day) => {
        setSwitchStates((prevSwitchStates) => {
            // Retain the existing values when closing the switch
            setSelectedDays((prevSelectedDays) => {
                const updatedSelectedDays = prevSelectedDays.map((d) => {
                    if (d.dayOfWeek === day) {
                        // Clear both startTime and endTime when the switch state changes to "Closed"
                        return {
                            ...d,
                            startTime: prevSwitchStates[day] ? '' : '',
                            endTime: prevSwitchStates[day] ? '' : '',
                        };
                    } else {
                        return d;
                    }
                });
                return updatedSelectedDays;
            });
    
            // Update the switch state
            return {
                ...prevSwitchStates,
                [day]: !prevSwitchStates[day],
            };
        });
    };
    
    
    
    
    
    
    const calculateTotalHours = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return '00:00';
        }
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);
        let totalMinutes = (endHour - startHour) * 60 + (endMinute - startMinute);
        if (totalMinutes < 0) {
            totalMinutes += 24 * 60;
        }
        const hours = Math.floor(totalMinutes / 60).toString().padStart(2, "0");
        const minutes = (totalMinutes % 60).toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    };

    const sortedSelectedDays = selectedDays.sort((a, b) => {
        return desiredOrder.indexOf(a.dayOfWeek) - desiredOrder.indexOf(b.dayOfWeek);
    });

    const getChipClassName = (day: string) => {
        let classNames = 'chip ' + day;
        if (selectedChips.includes(day)) {
            classNames += ' selected';
        }
        return classNames;
    };

    const preview = () => {
        onPreviousCallback(tabNumber - 1)

    }

    const validateAndSaveWorkHourFormData = () => {
        try {
            validateWorkHourFormData();
            if (professionalPageFormData.has('pageWorkHoursStr')) {
                professionalPageFormData.delete('pageWorkHoursStr');
            }
            professionalPageFormData.append('pageWorkHoursStr', JSON.stringify(selectedDays));
            let pageWorkHoursStr = JSON.stringify(selectedDays);
            console.log('pageWorkHoursStr', pageWorkHoursStr);
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            throw error;
        }
    }

    const validateWorkHourFormData = (): void => {
        let errors: any[] = [];
        const selectedWorkHours = JSON.stringify(selectedDays);
    
        const hasNonEmptyTime = selectedDays.some(
            (day) => day.startTime !== '' || day.endTime !== ''
        );
    
        if (selectedDays.length === 0 || !hasNonEmptyTime) {
            let error: any = {};
            error.validationCode = 'workhours';
            error.message = 'Work hour required';
            errors.push(error);
            toast.error(error.message, { containerId: 'TR' });
        }
    
        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    };
    const highlightWorkHourFormData = () => {
        let formData: FormData = professionalPageFormData;
        if (!formData.has('pageWorkHoursStr')) {
            return;
        }
        let pageWorkHours: any[] = JSON.parse(formData.get('pageWorkHoursStr').toString());
        setSelectedDays([...pageWorkHours]);
    }
    const handleStartTimeChange = (newStartTime, index) => {
        setSwitchStates((prevSwitchStates) => {
            const updatedSwitchStates = {
                ...prevSwitchStates,
                [selectedDays[index].dayOfWeek]: newStartTime !== '' || selectedDays[index].endTime !== '',
            };
    
            setSelectedDays((prevSelectedDays) => {
                const updatedSelectedDays = prevSelectedDays.map((day, i) => {
                    if (i === index) {
                        return {
                            ...day,
                            startTime: updatedSwitchStates[day.dayOfWeek] ? newStartTime : '',
                            endTime: updatedSwitchStates[day.dayOfWeek] ? day.endTime : '',
                        };
                    }
                    return day;
                });
                return updatedSelectedDays;
            });
    
            return updatedSwitchStates;
        });
    };
    
    
    const handleEndTimeChange = (newEndTime, index) => {
        setSwitchStates((prevSwitchStates) => {
            const updatedSwitchStates = {
                ...prevSwitchStates,
                [selectedDays[index].dayOfWeek]: newEndTime !== '' || selectedDays[index].startTime !== '',
            };
    
            setSelectedDays((prevSelectedDays) => {
                const updatedSelectedDays = prevSelectedDays.map((day, i) => {
                    if (i === index) {
                        // Check if endTime is empty, then set startTime to empty as well
                        return {
                            ...day,
                            endTime: newEndTime,
                            startTime: newEndTime !== '' ? day.startTime : '',
                        };
                    }
                    return day;
                });
                return updatedSelectedDays;
            });
    
            return updatedSwitchStates;
        });
    };
    
    
    useEffect(() => {
        highlightWorkHourFormData();
        generateTimeOptions();
    }, []);

    return <>
       <div className="container boder-sy  my-4">
            <div className="row justify-content-center">
                <div className="col-sm-1 col-lg-1text-center mt-5">
                    <span className=""> <ArrowBackIcon onClick={preview} /></span>
                </div>
                <div className="col-sm-5 col-lg-5 d-flex align-self-center">
                    <img src={ProfessionalPageWorkHourImage} className='h-100 w-100' alt="google-bussiness" ></img>
                </div>
                <div className="col-sm-7 col-lg-6 mt-3">
                    <section className="p-0 pt-4">
                        <div className="row">
                            <div className="col-sm-10 col-lg-12 d-flex LKfzVb mt-2">
                                <h3 className="text-center">Tell us what time will you be available ?</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-10 offset-sm-1 mt-1">
                                <span className='fs-5'>this helps customers find you if they are looking for a business like yours.</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 mb-2">
                                <hr />
                            </div>
                            <div className="col-sm-12">
                                <span className='text-start fw-bold'>Select Working Days</span>
                            </div>
                            <div className="col-sm-12">
                                <div className="chips-container">
                                    <span
                                        className={getChipClassName('Sunday')}
                                        onClick={() => handleChipSelect('SUNDAY')}>Sunday
                                    </span>
                                    <span
                                        className={getChipClassName('Monday')}
                                        onClick={() => handleChipSelect('MONDAY')}>Monday
                                    </span>
                                    <span
                                        className={getChipClassName('Tuesday')}
                                        onClick={() => handleChipSelect('TUESDAY')}>Tuesday
                                    </span>

                                    <span
                                        className={getChipClassName('Wednesday')}
                                        onClick={() => handleChipSelect('WEDNESDAY')}
                                    >Wednesday</span>
                                    <span
                                        className={getChipClassName('Thursday')}
                                        onClick={() => handleChipSelect('THURSDAY')}>Thursday
                                    </span>
                                    <span
                                        className={getChipClassName('Friday')}
                                        onClick={() => handleChipSelect('FRIDAY')}>Friday
                                    </span>
                                    <span
                                        className={getChipClassName('Saturday')}
                                        onClick={() => handleChipSelect('SATURDAY')}>Saturday
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-12 overflow">
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Total Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {sortedSelectedDays.map((dayObj, index) => (
  <tr key={index}>
    <td>
      {switchStates[dayObj.dayOfWeek] && <>{dayObj.dayOfWeek}</>}
    </td>
    <td>
      {switchStates[dayObj.dayOfWeek] && (
        <select
          className="form__input input_time p-1"
          value={dayObj.startTime}
          onChange={(e) => {
            handleStartTimeChange(e.target.value, index);
          }}
        >
          <option value="" disabled>
            Open
          </option>
          {renderTimeOptions()}
        </select>
      )}
    </td>
    <td>
      {switchStates[dayObj.dayOfWeek] && (
        <select
          className="form__input input_time p-1"
          value={dayObj.endTime}
          onChange={(e) => {
            handleEndTimeChange(e.target.value, index);
          }}
        >
          <option value="" disabled>
            Close
          </option>
          {renderTimeOptions()}
        </select>
      )}
    </td>
    <td>
      {switchStates[dayObj.dayOfWeek] && (
        <>
          {/* Conditionally render the content only when switch state is Opened */}
          {calculateTotalHours(dayObj.startTime, dayObj.endTime)}
        </>
      )}
    </td>
    <td>
      <div className='d-flex'>
        {switchStates[dayObj.dayOfWeek] && (
          <>
            {console.log("addTimes value:", dayObj.addTimes)}
            {index === sortedSelectedDays.findIndex((d) => d.dayOfWeek === dayObj.dayOfWeek) && (
              // Display "Add Times" if it's the first occurrence of the day
              <span className="add-times" onClick={() => handleAddTimes(index)}>
                Add Times
              </span>
            )}
{index > sortedSelectedDays.findIndex((d) => d.dayOfWeek === dayObj.dayOfWeek) && (
  // Display the "Cancel" icon for subsequent occurrences
  <div>
    <IconButton onClick={() => handleCancel(index)}>
      <ClearOutlinedIcon />
    </IconButton>
  </div>
)}











          </>
        )}
        {/* Always display the switch control */}
        {index === sortedSelectedDays.findIndex((d) => d.dayOfWeek === dayObj.dayOfWeek) && (
  <div>
    {switchStates[dayObj.dayOfWeek] ? (
      <Form.Check
        type="switch"
        id={`custom-switch-${dayObj.dayOfWeek}`}
        label={switchStates[dayObj.dayOfWeek] ? 'Opened' : 'Closed'}
        onChange={() => handleDaySwitchChange(dayObj.dayOfWeek)}
        checked={switchStates[dayObj.dayOfWeek]}
      />
    ) : null}
  </div>
)}

      </div>
    </td>
  </tr>
))}




                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="text-end p-3">
                            <button disabled={processing} className="btn btn-success" onClick={validateAndSaveWorkHourFormData} type="button">Next</button>
                            {(processing) && <Spinner animation="grow" variant="warning" />}
                        </div>
                    </section>
                </div>

            </div>
        </div>
        <div>
        </div>
    </>
}
export default EditProfessionalPageWorkHour;