import HttpClientWrapper from "../../http-client-wrapper";

class AboutUsApiService {
    
    [x: string]: any;

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getAboutViewerData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/aboutViewers');
            return data;
        } catch(error) {
            throw error;
        }
    }

    getAboutArchitectsData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/aboutArchitects');
            return data;
        } catch(error) {
            throw error;
        }
    }
    getAboutEmployeeData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/aboutEmployees');
            return data;
        } catch(error) {
            throw error;
        }
    }

    getAboutPageVideoUrl = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/picklists/YtVideoUrl')
            return data;
        }catch(error){
            throw error;
        }
    }
}

export default AboutUsApiService;