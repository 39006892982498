import { useEffect, useState } from "react";
import "./tag-post-search.css";
import { toast } from "react-toastify";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import StorageService from "../../../data/storage/storage-service";
import { PostReportPayload } from "../../../data/api/services/professional-page/professional-page-payload";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TagPostSearch = (props: any) => {
    const professionalPageApiservice = new ProfessionalPageApiService();
    const [selectedProfessionalPostReports, setSelectedProfessionalPostReports] = useState<any[]>([]);
    const [report, setReport] = useState('');
    const [userPageData, setUserPageData] = useState<any>([]);
    const [userId, setUserId] = useState('');
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any[]>([]);
    const [professionalPostReportsValidationErrorMsg, setProfessionalPostReportsValidationErrorMsg] = useState(null);
    
    // Define predefined labels
    const predefinedLabels = [
        { id: '1', label: 'All Tags' },
         { id: '2', label: 'Recent' },
        { id: '3', label: 'More Likes' },
        { id: '4', label: 'More Comments' },     
       { id: '5', label: 'More Shares' },



    ];

    const handleProfessionalPostReportChange = (selectedValues: any[]) => {
        setSelectedProfessionalPostReports(selectedValues);
        setProfessionalPostReportsValidationErrorMsg('');
    };

    const handleReportOnChange = (ds: any) => setReport(ds);

    const apply = () => {
        if (selectedProfessionalPostReports.length === 0) {
            toast.error('Please select any Profession Type', { containerId: 'TR' });
        } else {
            props.handleClose(selectedProfessionalPostReports);
        }
    };

    const close = () => {
        props.handleClose(selectedProfessionalPostReports.length === 0 ? undefined : selectedProfessionalPostReports);
    };

    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            setUserId(userId);
            const res = await professionalPageApiservice.getProfessionalPageData(userId);
            setUserPageData(res);
            const activePageItem = res.find(item => item.activePage === true);
            const userPageId = activePageItem?.id;
            if (userPageId) {
                getProfessionalPagePostDataList(userPageId);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getProfessionalPagePostDataList = async (userPageId: string) => {
        try {
            const res = await professionalPageApiservice.getProfessionalPageData(userPageId);
            setProfessionalPagePosts(res);
        } catch (error) {
            console.error(error);
        }
    };

 

    useEffect(() => {
        getProfessionalPageDetails();
    }, []);

    return (
        <div className="p-3">
        <div className="text-end">
            <span onClick={close} style={{ color: 'grey', fontSize: '24px', cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
    
            <div className="col-sm-12 text-start">
                <label style={{ fontSize: '24px', color: '#777' }}>Filter</label>
            </div>
        </div>
    
        <div>
            <div className="col-sm-12">
                {predefinedLabels.map((pprData) => (
                    <div key={pprData.label} className="d-flex align-items-center my-2">
                        <div className="form-check">
                            <input
                                type="radio"
                                id={`ctaRadio_${pprData.label}`}
                                name="professionalPostReport" // Group radios by the same name
                                className="form-check-input ml-2"
                                value={pprData.label}
                                checked={selectedProfessionalPostReports.some(item => item.label === pprData.label)}
                                onChange={(e) => {
                                    const selectedLabel = e.target.value;
                                    handleProfessionalPostReportChange(
                                        selectedProfessionalPostReports.length === 0 || 
                                        selectedProfessionalPostReports[0].label !== selectedLabel
                                            ? [{ label: selectedLabel }]
                                            : []
                                    );
                                }}
                            />
                        </div>
                        <span
                            className="form-check-label text-dark mb-0"
                            style={{ fontSize: '15px', fontWeight: '500' }}
                        >
                            {pprData.label}
                        </span>
                    </div>
                ))}
            </div>
    
            <FormFieldError errorMessage={professionalPostReportsValidationErrorMsg} />
            <div className="d-flex justify-content-between align-items-center py-2" />
        </div>
    
        <div className="text-start mx-2">
            <div className="d-flex justify-content-between align-items-center">
                <div onClick={close} style={{ cursor: 'pointer', color: 'grey', fontSize: 'large' }}>
                    CLEAR
                </div>
                <button onClick={apply} className="btn btn-primary" type="button" style={{ borderRadius: '50px', padding: '0.5rem 1.5rem' }}>
                    APPLY
                </button>
            </div>
        </div>
    </div>
    
    );
};

export default TagPostSearch;