import { useEffect, useState } from "react";
import "../profession-photos/profession-photos.css";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import StorageService from "../../../data/storage/storage-service";
import { Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

const ProfessionPhotos = ({ professionalPageId }: { professionalPageId: number }) => {

  let navigate: any = useNavigate();
  const professionalPageApiService = new ProfessionalPageApiService();
  const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const handleImageClick = (imageData: any, professionalPagePosts: any) => {
    // navigate(`/view-profession-image`, { state: { data, selectedImage: imageData, professionalPagePosts, professionalPageId } });
    const combined = `pageId=${professionalPageId}&mediaId=${imageData.id}`;
    const encoded = btoa(combined);
    navigate(`/view-media?v=${encoded}`);
  };

  const getProfessionalPageById = async () => {
    try {
      const userId = StorageService.getUserId();
      console.log(userId);
      let res = await professionalPageApiService.getProfessionalPageById(professionalPageId);
      setData(res);
      setProfessionalPagePosts(res.professionalPagePosts);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfessionalPageById();
  }, []);

  return (
    <div className="p-2 mx-3">
      <h1>My Photo Gallery</h1>
      {loading ? (
        <Spinner>
        </Spinner>
      ) : (
        <Row className="">
          {professionalPagePosts.length < 1 ? (
            <div className="p-5">
              <div className="d-flex justify-content-center">
                <CameraAltOutlinedIcon className="p-3"
                  style={{ fontSize: '100px', border: 'solid', borderRadius: '50%' }} />
              </div>
              <div className="text-center"><span className="fs-5">No images has been added yet.</span></div>
            </div>
          ) : (
            <>
              {Array.isArray(professionalPagePosts) && professionalPagePosts.map((item: any, index: any) =>
                <>
                  {item.postType == 'NORMAL_POST' &&
                    item.professionalPagePostImageGalleryData.map((imageData: any, imageIndex: number) =>
                      <>
                        {imageData.mimeType === 'IMAGE' ? (
                          <Col md={3} xs={4} className="" key={imageIndex}
                            onClick={() => handleImageClick(imageData, item)}>
                            <img
                              key={imageIndex}
                              className="image-with-border"
                              src={imageData.imagePath} width="100%" height="100px"
                              alt={`Image ${index + 1}`}
                            />
                          </Col>
                        ) : (null)
                        }
                      </>
                    )}
                </>
              )}
            </>
          )}
        </Row>
      )}
    </div>
  );
};

export default ProfessionPhotos;