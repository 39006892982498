import { Footer, Header } from "../../../layouts";
import "../view-professional-page-single-post/view-professional-page-single-post.css";
import { useEffect, useRef, useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { faEdit, faFlag, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel, Col, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import EditProfessionPost from "../edit-profession-post/edit-profession-post";
import RecommendIcon from '@mui/icons-material/Recommend';
import StorageService from "../../../data/storage/storage-service";
import SendIcon from '@mui/icons-material/Send';
import { CommentLikePayload, CommentReplyLikePayload, FavouritePostPayload, PagePostCommentPayload, PagePostCommentReplyPayload, PagePostLikePayload } from "../../../data/api/services/professional-page/professional-page-payload";
import { Tooltip } from 'react-tooltip'
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import ProfessionPostReport from "../profession-post-report/profession-post-report";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ProfessionPostShare from "../profession-post-share/profession-post-share";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Avatar from "../../../assets/profile-pic.jpeg"
import LoginPageDialog from "../../login/login-page-dialog";

const ViewProfessionalPageSinglePost = () => {

    const professionalPageApiService = new ProfessionalPageApiService();
    const location = useLocation();
    const encoded = new URLSearchParams(location.search).get('v');
    const decoded = atob(encoded);
    const params = new URLSearchParams(decoded);
    const professionalPageId = params.get('pageId');
    const professionalPagePostId = params.get('postId');
    // const professionalPageId = new URLSearchParams(location.search).get('pageId');
    // const professionalPagePostId = new URLSearchParams(location.search).get('postId');
    const [data, setData] = useState<any>([]);
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
    const [editData, setEditData] = useState<any>([]);
    const [postId, setPostId] = useState<any>([]);
    const [editShow, setEditShow] = useState(false);
    const editHandleClose = () => setEditShow(false);
    const [reportPostData, setReportPostData] = useState<any>([]);
    const [reportPostShow, setReportPostShow] = useState(false);
    const reportPostHandleClose = () => setReportPostShow(false);
    const shareClose = () => setShareShow(false);

    const [reportCommentData, setReportCommentData] = useState<any>([]);
    const [reportCommentShow, setReportCommentShow] = useState(false);
    const reportCommentHandleClose = () => setReportCommentShow(false);
    const [reportCommentReplyData, setReportCommentReplyData] = useState<any>([]);
    const [reportCommentReplyShow, setReportCommentReplyShow] = useState(false);
    const reportCommentReplyHandleClose = () => setReportCommentReplyShow(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    let navigate: any = useNavigate();
    const [comment, setComment] = useState('');
    const [likedPosts, setLikedPosts] = useState(false);
    const [showReplyInput, setShowReplyInput] = useState(null);
    const [replyComment, setReplyComment] = useState([]);
    const [showChildReplyInput, setShowChildReplyInput] = useState([]);
    const [replyChildComment, setReplyChildComment] = useState([]);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const [isSaved, setIsSaved] = useState(false);
    const [likedComments, setLikedComments] = useState([]);
    const [visibleReplies, setVisibleReplies] = useState([]);
    const [commentReply, setCommentReply] = useState<any>([]);
    const [visibleChildReplies, setVisibleChildReplies] = useState([]);
    const [commentChildReply, setCommentChildReply] = useState<any>([]);
    const [selectedItemCommentIndex, setSelectedItemCommentIndex] = useState(null);
    const [isCommentDropdownVisible, setCommentDropdownVisible] = useState(false);
    const [selectedItemReplyIndex, setSelectedItemReplyIndex] = useState(null);
    const [isReplyDropdownVisible, setReplyDropdownVisible] = useState(false);
    const [shareData, setShareData] = useState<any>([]);
    const [shareShow, setShareShow] = useState(false);
    const videoRefs = useRef([]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showLogInDialog, setShowLogInDialog] = useState(false);
    const handleShowLogInDialog = () => setShowLogInDialog(true);
    const handleCloseLogInDialog = () => setShowLogInDialog(false);

    const handleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const handlePlay = (imageDataId) => {
        if (playingVideoIndex !== null && videoRefs.current[playingVideoIndex]) {
            videoRefs.current[playingVideoIndex].pause();
        }
        setPlayingVideoIndex(imageDataId);
    };

    const handlePause = (imageDataId) => {
        if (playingVideoIndex === imageDataId) {
            setPlayingVideoIndex(null);
        }
    };

    const handlePlayIconClick = (e, imageDataId) => {
        e.stopPropagation();
        if (videoRefs.current[imageDataId]) {
            videoRefs.current[imageDataId].play();
        }
    };

    const handleViewRepliesClick = (comment, commentIndex) => {
        try {
            const updatedVisibleReplies = Array(professionalPagePosts.professionalPageCommentData.length).fill(false);
            updatedVisibleReplies[commentIndex] = !visibleReplies[commentIndex];
            setVisibleReplies(updatedVisibleReplies);
            getProfessionalPagePostCommentReplyByCommentId(comment);
        } catch (error) {
            console.log(error);
        }
    };

    const getProfessionalPagePostCommentReplyByCommentId = async (comment) => {
        try {
            let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByCommentId(comment);
            setCommentReply(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleViewChildRepliesClick = (commentReply, commentReplyIndex, childArrayData: any[]) => {
        try {
            const updatedVisibleReplies = Array(childArrayData.length).fill(false);
            setVisibleChildReplies(prevState => ({
                ...updatedVisibleReplies,
                [commentReply.id]: !prevState[commentReply.id],
            }));
            getProfessionalPagePostCommentReplyByParentId(commentReply, commentReplyIndex);
        } catch (error) {
            console.log(error);
        }
    };

    const getProfessionalPagePostCommentReplyByParentId = async (commentReply, commentReplyIndex) => {
        try {
            let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByParentId(commentReply.id);
            setCommentChildReply(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleLikeCommentClick = (item: any, index: any) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            setLikedComments((prevLikedComments) => {
                const newLikeStatus = [...prevLikedComments];
                newLikeStatus[index] = !newLikeStatus[index];
                return newLikeStatus;
            });
            saveCommentLike(item)
        }
    };

    const handleLikeReplyCommentClick = (commentReply, commentReplyIndex) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            saveCommentReplyLike(commentReply, commentReplyIndex)
        }
    };

    const handleLikeReplyChildCommentClick = (commentReply, commentReplyIndex, replyId) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            saveCommentReplyChildLike(commentReply, commentReplyIndex, replyId)
        }
    };

    const getChildCommentReplyByParentId = async (replyId) => {
        try {
            let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByParentId(replyId);
            setCommentChildReply(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    const saveCommentReplyChildLike = async (item, index, replyId) => {
        try {
            let payload: CommentReplyLikePayload = {
                userPageId: userPageId,
                commentReplyId: item.id
            };
            await professionalPageApiService.saveCommentReplyLike(payload);
            getChildCommentReplyByParentId(replyId);
        } catch (error) {
            console.log(error)
        }
    }

    const saveChildCommentReply = async (index, item, replyId) => {
        try {
            let user = StorageService.isLoggedIn();
            if (!user) {
                // showLoggedInConfirmDialog();
                handleShowLogInDialog();
            } else {
                let payload: PagePostCommentReplyPayload = {
                    comments: replyChildComment[index],
                    commentId: item.commentId,
                    postId: item.pagePostId,
                    pageId: userPageId,
                    commentReplyId: item.id
                };
                await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
                handleChildReplyCommentOnChange(index, '');
                getChildCommentReplyByParentId(replyId);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleIsSavedClick = (item) => {
        setIsSaved((prevIsSaved) => !prevIsSaved);
        saveFavouritePost(item);
    };

    const handleLikeClick = () => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            setLikedPosts((prevLikedPosts) => !prevLikedPosts);
            saveProfessionalPagePostLike();
        }
    };

    const handleCommentOnChange = (c: any) => {
        setComment(c);
    };

    const handleReplyCommentOnChange = (index: any, rc: any) => {
        const newComment = [...replyComment];
        newComment[index] = rc;
        setReplyComment(newComment);
    };

    const handleChildReplyCommentOnChange = (index: any, rcc: any) => {
        const newComment = [...replyChildComment];
        newComment[index] = rcc;
        setReplyChildComment(newComment);
    };

    const getBackgroundColor = (label) => {
        switch (label) {
            case 'Shop Now':
                return 'rgb(0, 123, 255)';
            case 'Book Now':
                return 'rgb(255, 0, 81)';
            case 'Learn More':
                return '#27b071';
            default:
                return 'rgb(255 56 0)';
        }
    };

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const showLoggedInConfirmDialog = () => {
        confirmAlert({
            title: 'Alert',
            message: 'You have to logIn.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { navigate('/login'); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log('clicked no') }
                }
            ]
        });
    };

    const toggleDropdown = () => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            setDropdownVisible(!isDropdownVisible);
        }
    };

    const toggleCommentDropdown = (index) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            setSelectedItemCommentIndex(selectedItemCommentIndex === index ? null : index);
            setCommentDropdownVisible(!isCommentDropdownVisible);
        }
    };
    const handleShare = (item) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            setShareData(item);
            setShareShow(true);
            setSelectedItemIndex(null);
        }
    };

    const toggleReplyDropdown = (item, index) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            setSelectedItemReplyIndex(selectedItemReplyIndex === item.id ? null : item.id);
            setReplyDropdownVisible(!isReplyDropdownVisible);
        }
    };

    const closeSubMenu = (e) => {
        if (isDropdownVisible) {
            setDropdownVisible(false);
        }
        if (selectedItemCommentIndex !== null && !e.target.closest('.dropdown-content')) {
            setSelectedItemCommentIndex(null);
        }
        if (selectedItemReplyIndex !== null && !e.target.closest('.dropdown-content')) {
            setSelectedItemReplyIndex(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeSubMenu);
        return () => {
            document.removeEventListener('click', closeSubMenu);
        };
    }, [isDropdownVisible, isCommentDropdownVisible, isReplyDropdownVisible]);

    const handleEdit = (item) => {
        setEditData(item);
        setPostId(item.id);
        setEditShow(true);
        setDropdownVisible(false);
    };

    const handleReportPost = (item) => {
        setReportPostData(item);
        setReportPostShow(true);
        setDropdownVisible(false);
    };

    const handleCommentReport = (item) => {
        setReportCommentData(item);
        setReportCommentShow(true);
        setSelectedItemCommentIndex(null);
    };

    const handleCommentReplyReport = (item) => {
        setReportCommentReplyData(item);
        setReportCommentReplyShow(true);
        setSelectedItemReplyIndex(null);
    };

    const deleteProfessionalPagePost = async (id: any) => {
        try {
            const userId = StorageService.getUserId();
            if (id) {
                await professionalPageApiService.deleteProfessionalpagePost(id);
                toast.success('Post has been Deleted Successfully', { containerId: 'TR' });
                // navigate(`/view-profession-profile?userId=${userId}&id=${professionalPageId}`);
                const combined = `userId=${userId}&id=${professionalPageId}`;
                const encoded = btoa(combined);
                navigate(`/professional?v=${encoded}`);
                return;
            }
        } catch (error) {
            console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
        }
    };

    const handleDeleteConfirmed = (id: any) => {
        setDropdownVisible(false);
        confirmAlert({
            title: "Delete",
            message: "Are you sure to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteProfessionalPagePost(id),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const getProfessionalPagById = async () => {
        try {
            let res = await professionalPageApiService.getProfessionalPageById(professionalPageId);
            setData(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    const getProfessionalPagePostById = async () => {
        try {
            let res = await professionalPageApiService.getProfessionalPagePostData(professionalPagePostId);
            setProfessionalPagePosts(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    };

    const saveProfessionalPagePostComment = async () => {
        try {
            let user = StorageService.isLoggedIn();
            if (!user) {
                // showLoggedInConfirmDialog();
                handleShowLogInDialog();
            } else {
                let payload: PagePostCommentPayload = {
                    comments: comment,
                    postId: professionalPagePostId,
                    pageId: userPageId
                };
                await professionalPageApiService.saveProfessionalPagePostComment(payload);
                handleCommentOnChange('');
                getProfessionalPagePostById();
            }
        } catch (error) {
            console.log(error)
        }
    }

    const saveProfessionalPagePostCommentReply = async (index, item) => {
        try {
            let user = StorageService.isLoggedIn();
            if (!user) {
                // showLoggedInConfirmDialog();
                handleShowLogInDialog();
            } else {
                let payload: PagePostCommentReplyPayload = {
                    comments: replyComment[index],
                    commentId: item.id,
                    postId: item.pagePostId,
                    pageId: userPageId,
                    commentReplyId: 0
                };
                await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
                handleReplyCommentOnChange(index, '');
                getProfessionalPagePostById();
                getProfessionalPagePostCommentReplyByCommentId(item.id);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const saveProfessionalPagePostChildCommentReply = async (index, item) => {
        try {
            let user = StorageService.isLoggedIn();
            if (!user) {
                // showLoggedInConfirmDialog();
                handleShowLogInDialog();
            } else {
                let payload: PagePostCommentReplyPayload = {
                    comments: replyChildComment[index],
                    commentId: item.commentId,
                    postId: item.pagePostId,
                    pageId: userPageId,
                    commentReplyId: item.id
                };
                await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
                handleChildReplyCommentOnChange(index, '');
                getProfessionalPagePostCommentReplyByCommentId(item.commentId);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const saveProfessionalPagePostLike = async () => {
        try {
            let payload: PagePostLikePayload = {
                postId: professionalPagePostId,
                likedByPageId: userPageId
            };
            await professionalPageApiService.saveProfessionalPagePostLike(payload);
            getProfessionalPagePostById();
        } catch (error) {
            console.log(error)
        }
    }

    const saveFavouritePost = async (item) => {
        try {
            let payload: FavouritePostPayload = {
                userPageId: userPageId,
                pagePostId: item.id
            };
            await professionalPageApiService.saveFavouritePost(payload);
            getProfessionalPagePostById();
        } catch (error) {
            console.log(error)
        }
    }

    const saveCommentLike = async (item) => {
        try {
            let payload: CommentLikePayload = {
                userPageId: userPageId,
                commentId: item.id
            };
            await professionalPageApiService.saveCommentLike(payload);
            getProfessionalPagePostById();
        } catch (error) {
            console.log(error)
        }
    }

    const saveCommentReplyLike = async (item, index) => {
        try {
            let payload: CommentReplyLikePayload = {
                userPageId: userPageId,
                commentReplyId: item.id
            };
            await professionalPageApiService.saveCommentReplyLike(payload);
            getProfessionalPagePostById();
            getProfessionalPagePostCommentReplyByCommentId(item.commentId);
        } catch (error) {
            console.log(error)
        }
    }

    const handleReplyClick = (commentIndex) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            setShowReplyInput(commentIndex);
        }
    };

    const handleChildReplyClick = (commentReplyId) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            // showLoggedInConfirmDialog();
            handleShowLogInDialog();
        } else {
            setShowChildReplyInput(prevState => {
                const updatedState = { ...prevState };
                Object.keys(updatedState).forEach(key => {
                    updatedState[key] = false;
                });
                updatedState[commentReplyId] = !prevState[commentReplyId];

                return updatedState;
            });
        }
    };

    const handleImageClick = (imageData: any, professionalPagePosts: any, professionalPageId: any) => {
        // navigate(`/view-profession-image`, { state: { data, selectedImage: imageData.id, professionalPagePosts, professionalPageId } });
        const combined = `pageId=${professionalPageId}&mediaId=${imageData.id}`;
        const encoded = btoa(combined);
        navigate(`/view-media?v=${encoded}`);
    };

    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            if (userId != null) {
                let res = await professionalPageApiService.getProfessionalPageData(userId);
                setUserPageData(res);
                // getProfessionalPagById();
                // getProfessionalPagePostById();
            }
            getProfessionalPagById();
            getProfessionalPagePostById();
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteCommentDialog = (id: any) => {
        setSelectedItemCommentIndex(null);
        confirmAlert({
            title: "Delete",
            message: "Are you sure to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteComment(id),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const deleteComment = async (id: any) => {
        try {
            if (id) {
                await professionalPageApiService.deleteComment(id);
                toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
                getProfessionalPagePostById();
                return;
            }
        } catch (error) {
            console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
        }
    };

    const handleDeleteCommentReplyDialog = (item: any) => {
        setSelectedItemReplyIndex(null);
        confirmAlert({
            title: "Delete",
            message: "Are you sure to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteCommentReply(item),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const deleteCommentReply = async (item: any) => {
        try {
            if (item) {
                await professionalPageApiService.deleteCommentReply(item.id);
                toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
                getProfessionalPagePostById();
                getProfessionalPagePostCommentReplyByCommentId(item.commentId);
                return;
            }
        } catch (error) {
            console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
        }
    };

    const handleDeleteCommentChildReplyDialog = (item: any, replyId: any) => {
        setSelectedItemReplyIndex(null);
        confirmAlert({
            title: "Delete",
            message: "Are you sure to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => deleteCommentChildReply(item, replyId),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const deleteCommentChildReply = async (item: any, replyId: any) => {
        try {
            if (item) {
                await professionalPageApiService.deleteCommentReply(item.id);
                toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
                getProfessionalPagePostById();
                getChildCommentReplyByParentId(replyId);
                return;
            }
        } catch (error) {
            console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
        }
    };

    const formattedDate = (dateString) => {
        const date = new Date(dateString);
        const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours and 30 minutes
        const istDate = new Date(date.getTime() + istOffset);
        console.log('istDate:', istDate)
        return istDate.toLocaleString('en-US', {
            timeZone: 'Asia/Kolkata',
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getProfessionalPageDetails();
    }, []);


    const formatCommentTime = (createdAt) => {
        const currentDate = new Date();
        const commentDate = new Date(createdAt);
        const seconds = Math.floor((currentDate.getTime() - commentDate.getTime()) / 1000);

        if (seconds < 5) {
            return 'just now';
        }
        else if (seconds < 60) {
            return `${seconds}s`;
        }
        else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            return `${minutes}m`;
        } else if (seconds < 86400) {
            const hours = Math.floor(seconds / 3600);
            return `${hours}h`;
        } else if (seconds < 2592000) {
            const days = Math.floor(seconds / 86400);
            return `${days}d`;
        } else if (seconds < 31536000) {
            const months = Math.floor(seconds / 2592000);
            return `${months}m`;
        } else {
            const years = Math.floor(seconds / 31536000);
            return `${years}y`;
        }
    };

    const getImageClass = (index) => {
        const length = professionalPagePosts.professionalPagePostImageGalleryData.length;
        if (length <= 2) {
            return "col-sm-12";
        } else if (length >= 3) {
            return "col-sm-6";
        }
    };

    const handleViewPage = (userId: number, id: number) => {
        // navigate(`/view-profession-profile?userId=${userId}&id=${id}`);
        const combined = `userId=${userId}&id=${id}`;
        const encoded = btoa(combined);
        navigate(`/professional?v=${encoded}`);
        console.log(`View button clicked for userId: ${userId}, id: ${id}`);
    };

    const extractYouTubeVideoID = (url) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[7] && match[7].length === 11) ? match[7] : (match && match[8].length === 11 ? match[8] : null);
    };

    const renderChildReplies = (parentComment, replyId) => {

        if (!Array.isArray(parentComment)) {
            console.error('Parent comment is not an array:', parentComment);
            return null;
        }

        return parentComment.map((childCommentReply, childCommentReplyIndex) => (
            <>
                <Row className="comment-sec mt-0" key={childCommentReply.id}>
                    <div className="post-comment-content p-1 pt-0">
                        <Col md={1}>
                            <img className="post-comment-profile-image"
                                // src={childCommentReply.pageProfileImageUrl} 
                                src={childCommentReply.pageProfileImageUrl != null ? childCommentReply.pageProfileImageUrl : Avatar}
                                alt="" />
                        </Col>
                        <Col md={11}>
                            <div className="d-flex align-items-center">
                                <Col md={11} className="post-comment-text-container p-2 pt-0 pb-1">
                                    <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                                        onClick={() => handleViewPage(childCommentReply.createdBy, childCommentReply.pageId)}>
                                        {childCommentReply.pageName}
                                    </span>
                                    <div className="post-comment-text">{childCommentReply.comment}</div>
                                </Col>
                                <Col md={1} onClick={(e) => e.stopPropagation()}>
                                    <MoreHorizIcon className="m-2 more-horizicon"
                                        onClick={() => toggleReplyDropdown(childCommentReply, childCommentReplyIndex)} />
                                    {userPageId == professionalPageId && userPageId != childCommentReply.pageId && professionalPageId != childCommentReply.pageId && (
                                        <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                            <div className="ms-1" onClick={() => handleDeleteCommentChildReplyDialog(childCommentReply, replyId)}>
                                                <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                Delete
                                            </div>
                                            <div className="ms-1"
                                                onClick={() => handleCommentReplyReport(childCommentReply)}>
                                                <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                Report
                                            </div>
                                        </div>
                                    )}
                                    {((userPageId == professionalPageId) || (userPageId != professionalPageId)) && userPageId == childCommentReply.pageId && ((professionalPageId == childCommentReply.pageId) || (professionalPageId != childCommentReply.pageId)) && (
                                        <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                            <div className="ms-1" onClick={() => handleDeleteCommentChildReplyDialog(childCommentReply, replyId)}>
                                                <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                Delete
                                            </div>
                                        </div>
                                    )}
                                    {userPageId != professionalPageId && userPageId != childCommentReply.pageId && (
                                        <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                            <div className="ms-1"
                                                onClick={() => handleCommentReplyReport(childCommentReply)}>
                                                <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                Report
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </div>
                            {childCommentReply.professionalPageCommentReplyLikeDataList && childCommentReply.professionalPageCommentReplyLikeDataList.length > 0 && (
                                <div className="d-flex" style={{ marginTop: '-7px' }}>
                                    <div className="recommend-container d-flex align-items-center">
                                        <RecommendIcon style={{ color: '#007bff', fontSize: 'large' }} />
                                        <span style={{ color: 'gray' }}>
                                            {childCommentReply.professionalPageCommentReplyLikeDataList.length}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div className="reply-container">
                                <a className="">
                                    {formatCommentTime(childCommentReply.createdAt)}
                                </a>
                                <a className="ms-2"
                                    style={{
                                        color: childCommentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                                    }}
                                    onClick={() => handleLikeReplyChildCommentClick(childCommentReply, childCommentReplyIndex, replyId)}>
                                    {childCommentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                                </a>
                                <a className="ms-2" onClick={() => handleChildReplyClick(childCommentReply.id)}>
                                    Reply
                                </a>
                            </div>
                            {childCommentReply.professionalPageCommentChildReplyData && childCommentReply.professionalPageCommentChildReplyData.length > 0 && (
                                <div className="d-flex align-items-end pb-1"
                                    // onClick={() => handleViewChildRepliesClick(childCommentReply, childCommentReplyIndex)}
                                    style={{
                                        cursor: 'pointer',
                                        fontWeight: '600',
                                        fontSize: 'small',
                                        color: 'gray'
                                    }}>
                                    <SubdirectoryArrowRightIcon /> Replies
                                </div>
                            )}
                        </Col>
                    </div>
                    {showChildReplyInput[childCommentReply.id] && (
                        <Col className="comment-sec mt-0 ps-5">
                            <img
                                // src={activePageItem?.profileImageUrl} 
                                src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                                alt="" />
                            <div className="input-div">
                                <textarea
                                    value={replyChildComment[childCommentReplyIndex]}
                                    placeholder="Write a comment..."
                                    onChange={(e) => handleChildReplyCommentOnChange(childCommentReplyIndex, e.target.value)}
                                />
                                <SendIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => saveChildCommentReply(childCommentReplyIndex, childCommentReply, replyId)}
                                />
                            </div>
                        </Col>
                    )}
                </Row>
                {renderChildReplies(childCommentReply.professionalPageCommentChildReplyData, replyId)}
            </>
        ));
    };

    return <>
        <div className="view_professional_page_single_post_background">
            <Header></Header>
            {loading ? (
                <div className="d-flex justify-content-center"
                    style={{ marginTop: '20%', marginBottom: '20%', background: 'white' }}>
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="view_professional_page_single_post_main_page container">
                        <Row className="d-flex justify-content-center">
                            <Col md={7}>
                                <Row className="card m-2 mt-3" >
                                    {professionalPagePosts.postType == 'SHARED_POST' ? (
                                        <>
                                            {professionalPagePosts.sharePostData?.map((shareItem, shareItemIndex) =>
                                                <div>
                                                    <div className="d-flex justify-content-between mt-2 col-sm-12">
                                                        <div className="d-flex col-sm-11 align-items-center">
                                                            <div>
                                                                <img
                                                                    className="card_avatar_img"
                                                                    // src={shareItem.shareByProfileImage}
                                                                    src={shareItem.shareByProfileImage != null ? shareItem.shareByProfileImage : Avatar}
                                                                    alt="avatar"
                                                                />
                                                            </div>
                                                            <div className="ms-2 col-sm-11">
                                                                <span className="page-name-style fw-semibold" style={{ wordBreak: "break-word" }}
                                                                    onClick={() => handleViewPage(shareItem.createdBy, shareItem.sharedById)}>
                                                                    {shareItem.shareByName}
                                                                </span>
                                                                <span className="mx-2" style={{ color: 'grey', fontSize: '14px' }}>
                                                                    shared a post — with
                                                                </span>
                                                                <>
                                                                    {shareItem.shareToDateList
                                                                        ?.map((shareToItem, shareToIndex) =>
                                                                            <span key={shareToIndex} className="fw-semibold"
                                                                                style={{ wordBreak: "break-word" }}>
                                                                                {shareToIndex === 0
                                                                                    ? shareToItem.shareToName
                                                                                    : null
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    {shareItem.shareToDateList?.length > 1 && (
                                                                        <span style={{ fontSize: '13px', fontWeight: '600' }}>
                                                                            {' and '}
                                                                            {shareItem.shareToDateList.length - 1}{' others'}
                                                                        </span>
                                                                    )}
                                                                </>
                                                                <div className="mb-2">
                                                                    <div className="upload_date align-self-start row col-sm-12">
                                                                        <div className="pe-0">
                                                                            <a data-tooltip-id="my-tooltip-styles"
                                                                                data-tooltip-content={formattedDate(professionalPagePosts.createdAt)}>
                                                                                <span style={{ cursor: 'pointer' }}>
                                                                                    {new Date(professionalPagePosts.createdAt).getDate()}{' '}
                                                                                    {new Date(professionalPagePosts.createdAt).toLocaleString('default', { month: 'long' })}{' '}
                                                                                    {new Date(professionalPagePosts.createdAt).getFullYear()}
                                                                                </span>
                                                                            </a>
                                                                            <Tooltip id="my-tooltip-styles" className="tooltip" opacity={0.8} place={'bottom'} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Col md={1} className="d-flex justify-content-center" onClick={(e) => e.stopPropagation()}>
                                                            <span className="d-flex align-items-center"
                                                                onClick={() => toggleDropdown()}>
                                                                <MoreHorizIcon />
                                                            </span>
                                                            {professionalPagePosts.professionalPageId == userPageId && (
                                                                <div className={`dropdown-content ${isDropdownVisible ? 'show' : ''}`}>
                                                                    {/* <div className="mb-2 ms-1" onClick={() => handleEdit(professionalPagePosts)}>
                                                                <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                                Edit post
                                                            </div> */}
                                                                    <div className="mb-2" onClick={() => handleIsSavedClick(professionalPagePosts)}>
                                                                        {professionalPagePosts?.professionalPageFavouritePostDataList?.some(favData => favData.userPageId === userPageId) ? (
                                                                            <><TurnedInIcon className="me-1" />
                                                                                Saved Post</>
                                                                        ) : (
                                                                            <><TurnedInNotIcon className="me-1" />
                                                                                Save Post</>
                                                                        )}
                                                                    </div>
                                                                    <div className="ms-1" onClick={() => handleDeleteConfirmed(professionalPagePosts.id)}>
                                                                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                                        Move to trash
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {professionalPagePosts.professionalPageId != userPageId && (
                                                                <div
                                                                    className={`dropdown-content ${isDropdownVisible ? 'show' : ''}`}
                                                                    style={{ marginRight: '130px' }}>
                                                                    <div className="mb-2" onClick={() => handleIsSavedClick(professionalPagePosts)}>
                                                                        {professionalPagePosts?.professionalPageFavouritePostDataList?.some(favData => favData.userPageId === userPageId) ? (
                                                                            <><TurnedInIcon className="me-1" />
                                                                                Saved Post</>
                                                                        ) : (
                                                                            <><TurnedInNotIcon className="me-1" />
                                                                                Save Post</>
                                                                        )}
                                                                    </div>
                                                                    <div className="ms-1" onClick={() => handleReportPost(professionalPagePosts)}>
                                                                        <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                                        Report
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </div>
                                                    <div className="card p-0"
                                                        style={{ borderRadius: '10px', background: '#e9e6e6', border: 'none' }}>
                                                        <div className="d-flex justify-content-between mt-2 col-sm-12 px-2">
                                                            {/* <div className="d-flex col-sm-11 align-items-center"> */}
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    className="card_avatar_img"
                                                                    // src={shareItem.professionalPagePostData.pageProfileImageUrl}
                                                                    src={shareItem.professionalPagePostData.pageProfileImageUrl != null ? shareItem.professionalPagePostData.pageProfileImageUrl : Avatar}
                                                                    alt="avatar"
                                                                />
                                                            </div>
                                                            <div className="ms-2 col-sm-11">
                                                                <span className="" style={{ wordBreak: "break-word" }}>
                                                                    <a className="page-name-style fw-semibold"
                                                                        onClick={() => handleViewPage(shareItem.professionalPagePostData.pageCreatedBy, shareItem.professionalPagePostData.professionalPageId)}>
                                                                        {shareItem.professionalPagePostData.professionalName}
                                                                    </a>
                                                                </span>
                                                                <div className="mb-2">
                                                                    <div className="upload_date align-self-start row col-sm-12">
                                                                        <div className="pe-0">
                                                                            <a data-tooltip-id="my-tooltip-styles"
                                                                                data-tooltip-content={formattedDate(shareItem.professionalPagePostData.createdAt)}>
                                                                                <span style={{ cursor: 'pointer' }}>
                                                                                    {new Date(shareItem.professionalPagePostData.createdAt).getDate()}{' '}
                                                                                    {new Date(shareItem.professionalPagePostData.createdAt).toLocaleString('default', { month: 'long' })}{' '}
                                                                                    {new Date(shareItem.professionalPagePostData.createdAt).getFullYear()}
                                                                                </span>
                                                                            </a>
                                                                            <Tooltip id="my-tooltip-styles" className="tooltip" opacity={0.8} place={'bottom'} />
                                                                            <span className="ms-1">·</span>
                                                                            <span className="location_name_size">
                                                                                <LocationOnIcon className="location_icon_size" />{shareItem.professionalPagePostData.taggedLocationName}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* </div> */}
                                                        </div>

                                                        {/* {shareItem.professionalPagePostData.caption == '' || shareItem.professionalPagePostData.caption == "null" ? (
                                                            null
                                                        ) : (
                                                            <div className="ms-3 me-5">{shareItem.professionalPagePostData.caption}</div>
                                                        )} */}
                                                        {
                                                            shareItem.professionalPagePostData.caption && shareItem.professionalPagePostData.caption !== "null" ? (
                                                                <div className="ms-3 me-5">
                                                                    <div className={isExpanded ? 'full-caption' : 'caption'}>
                                                                        {shareItem.professionalPagePostData.caption}
                                                                    </div>
                                                                    {shareItem.professionalPagePostData.caption.split(' ').length > 20 && (
                                                                        <span className="read-more-caption" onClick={() => handleReadMore()}>
                                                                            {isExpanded ? 'Read less...' : 'Read more...'}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            ) : null
                                                        }

                                                        {shareItem.professionalPagePostData.professionalPagePostImageGalleryData && (
                                                            <Carousel interval={null}
                                                                indicators={(shareItem.professionalPagePostData.professionalPagePostImageGalleryData.length + (shareItem.professionalPagePostData.youtubeUrl && shareItem.professionalPagePostData.youtubeUrl !== 'null' ? 1 : 0)) > 1}
                                                                controls={(shareItem.professionalPagePostData.professionalPagePostImageGalleryData.length + (shareItem.professionalPagePostData.youtubeUrl && shareItem.professionalPagePostData.youtubeUrl !== 'null' ? 1 : 0)) > 1}>
                                                                {shareItem.professionalPagePostData.youtubeUrl && shareItem.professionalPagePostData.youtubeUrl !== 'null' && (
                                                                    <Carousel.Item>
                                                                        <div className="single-post-image-overlay d-flex justify-content-center align-items-center"
                                                                            style={{ cursor: 'pointer' }}>
                                                                            <div dangerouslySetInnerHTML={{ __html: `<iframe width="560" height="315" src="https://www.youtube.com/embed/${extractYouTubeVideoID(shareItem.professionalPagePostData.youtubeUrl)}" frameBorder="0" allowFullScreen></iframe>` }}></div>
                                                                            <div className="d-flex align-items-center justify-content-center video-play-icon m-3 p-1 px-2"
                                                                                style={{ borderRadius: '15px', position: 'absolute', top: '0', right: '0' }}>
                                                                                <span style={{ fontSize: 'small' }}>
                                                                                    1/{shareItem.professionalPagePostData.professionalPagePostImageGalleryData.length + 1}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Carousel.Item>
                                                                )}
                                                                {shareItem.professionalPagePostData.professionalPagePostImageGalleryData.map((imageData, imageIndex) => (
                                                                    <Carousel.Item key={imageIndex}>
                                                                        <div className="single-post-image-overlay d-flex justify-content-center align-items-center"
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleImageClick(imageData, shareItem.professionalPagePostData, shareItem.professionalPagePostData.professionalPageId)}>
                                                                            {(shareItem.professionalPagePostData.professionalPagePostImageGalleryData.length > 1 || (shareItem.professionalPagePostData.youtubeUrl && shareItem.professionalPagePostData.youtubeUrl !== 'null')) &&
                                                                                <div className="d-flex align-items-center justify-content-center video-play-icon m-3 p-1 px-2"
                                                                                    style={{ borderRadius: '15px', position: 'absolute', top: '0', right: '0' }}>
                                                                                    <span style={{ fontSize: 'small' }}>
                                                                                        {/* {imageIndex + 1}/{shareItem.professionalPagePostData.professionalPagePostImageGalleryData.length} */}
                                                                                        {shareItem.professionalPagePostData.youtubeUrl && shareItem.professionalPagePostData.youtubeUrl !== 'null' ? imageIndex + 2 : imageIndex + 1}/{shareItem.professionalPagePostData.professionalPagePostImageGalleryData.length + (shareItem.professionalPagePostData.youtubeUrl && shareItem.professionalPagePostData.youtubeUrl !== 'null' ? 1 : 0)}
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                            {imageData.mimeType === 'IMAGE' ? (
                                                                                <img
                                                                                    src={imageData.imagePath}
                                                                                    width={'100%'}
                                                                                    height={'100%'}
                                                                                    alt={`Image ${imageIndex + 1}`}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    <video
                                                                                        className=""
                                                                                        height={'100%'}
                                                                                        width={'100%'}
                                                                                        controls={playingVideoIndex === imageData.id}
                                                                                        onPlay={() => handlePlay(imageData.id)}
                                                                                        onPause={() => handlePause(imageData.id)}
                                                                                        ref={(ref) => (videoRefs.current[imageData.id] = ref)} >
                                                                                        <source src={imageData.imagePath} />
                                                                                    </video>
                                                                                    {playingVideoIndex !== imageData.id && (
                                                                                        <div className="d-flex align-items-center justify-content-center video-play-icon"
                                                                                            onClick={(e) => handlePlayIconClick(e, imageData.id)}>
                                                                                            <PlayCircleOutlineIcon style={{ fontSize: 'xxx-large' }} />
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </Carousel.Item>
                                                                ))}
                                                            </Carousel>
                                                        )}
                                                    </div>
                                                    {shareItem.professionalPagePostData.callToAction == 0 || shareItem.professionalPagePostData.callToAction == null ? (
                                                        null
                                                    ) : (
                                                        <>
                                                            {shareItem.professionalPagePostData.callToActionUrl && shareItem.professionalPagePostData.callToActionUrl != 'null' && (
                                                                <a href={shareItem.professionalPagePostData.callToActionUrl.startsWith('http') ? shareItem.professionalPagePostData.callToActionUrl : `http://${shareItem.professionalPagePostData.callToActionUrl}`} className="shop-now-link" target="_blank" rel="nofollow"
                                                                    style={{ textDecoration: 'none' }}>
                                                                    <div className="shop-now-bar mb-2" style={{ backgroundColor: getRandomColor() }}>
                                                                        {shareItem.professionalPagePostData.callToActionLabel}
                                                                        <span>
                                                                            <ChevronRightIcon />
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </>
                                                    )}
                                                    {professionalPagePosts.professionalPagePostLikeData.length > 0 && (
                                                        <div className="px-2 mb-2 d-flex align-items-center">
                                                            <RecommendIcon style={{ color: '#007bff' }} />
                                                            {professionalPagePosts.professionalPagePostLikeData.length}
                                                        </div>
                                                    )}
                                                    <hr />
                                                    <div>
                                                        <Col className="d-flex justify-content-evenly">
                                                            <Col md={4} className="d-flex justify-content-center">
                                                                <span className="d-flex align-items-center"
                                                                    style={{
                                                                        color: professionalPagePosts?.professionalPagePostLikeData?.some(likeData => likeData.likedByPageId === userPageId) ? '#007bff' : 'black',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={handleLikeClick}>
                                                                    <ThumbUpOffAltIcon />
                                                                    {professionalPagePosts?.professionalPagePostLikeData?.some(likeData => likeData.likedByPageId === userPageId) ? 'Liked' : 'Like'}
                                                                </span>
                                                            </Col>
                                                            <Col md={4} className="d-flex justify-content-center">
                                                                <span className="d-flex align-items-center"
                                                                    style={{ cursor: 'pointer' }}>
                                                                    <ChatBubbleOutlineOutlinedIcon /> Comment
                                                                </span>
                                                            </Col>
                                                            <Col md={4} className="d-flex justify-content-center">
                                                                <span className="d-flex align-items-center"
                                                                    style={{ cursor: 'pointer' }} onClick={() => handleShare(shareItem.professionalPagePostData)}>
                                                                    <ShareIcon /> Share
                                                                </span>
                                                            </Col>
                                                        </Col>
                                                        <hr />
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        // Normal Post
                                        <div className="p-0">
                                            <Col className="d-flex justify-content-between mt-2 px-3">
                                                <Col md={11} className="d-flex align-items-center">
                                                    <img
                                                        className="card_avatar_img"
                                                        // src={professionalPagePosts.pageProfileImageUrl} 
                                                        src={professionalPagePosts.pageProfileImageUrl != null ? professionalPagePosts.pageProfileImageUrl : Avatar}
                                                        alt="avatar"
                                                    />
                                                    <Col md={11} className="ms-2">
                                                        <div className="align-self-start mt-1" style={{ wordBreak: "break-word" }}>
                                                            <a className="page-name-style fw-semibold" onClick={() => handleViewPage(professionalPagePosts.pageCreatedBy, professionalPagePosts.professionalPageId)}>
                                                                {professionalPagePosts.professionalName}
                                                            </a>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Col className="upload_date align-self-start">
                                                                <div className="pe-0">
                                                                    <a data-tooltip-id="my-tooltip-styles"
                                                                        data-tooltip-content={formattedDate(professionalPagePosts.createdAt)}>
                                                                        <span>
                                                                            {new Date(professionalPagePosts.createdAt).getDate()}{' '}
                                                                            {new Date(professionalPagePosts.createdAt).toLocaleString('default', { month: 'long' })}{' '}
                                                                            {new Date(professionalPagePosts.createdAt).getFullYear()}
                                                                        </span>
                                                                    </a>
                                                                    <Tooltip id="my-tooltip-styles" className="tooltip" opacity={0.8} place={'bottom'} />
                                                                    <span className="ms-1">·</span>
                                                                    <span className="location_name_size">
                                                                        <LocationOnIcon className="location_icon_size" />{professionalPagePosts.taggedLocationName}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                </Col>
                                                <Col md={1} className="d-flex justify-content-center" onClick={(e) => e.stopPropagation()}>
                                                    <span className="d-flex align-items-center"
                                                        onClick={() => toggleDropdown()}>
                                                        <MoreHorizIcon />
                                                    </span>
                                                    {professionalPagePosts.professionalPageId == userPageId && (
                                                        <div className={`dropdown-content ${isDropdownVisible ? 'show' : ''}`}>
                                                            <div className="mb-2 ms-1" onClick={() => handleEdit(professionalPagePosts)}>
                                                                <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                                Edit post
                                                            </div>
                                                            <div className="mb-2" onClick={() => handleIsSavedClick(professionalPagePosts)}>
                                                                {professionalPagePosts?.professionalPageFavouritePostDataList?.some(favData => favData.userPageId === userPageId) ? (
                                                                    <><TurnedInIcon className="me-1" />
                                                                        Saved Post</>
                                                                ) : (
                                                                    <><TurnedInNotIcon className="me-1" />
                                                                        Save Post</>
                                                                )}
                                                            </div>
                                                            <div className="ms-1" onClick={() => handleDeleteConfirmed(professionalPagePosts.id)}>
                                                                <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                                Move to trash
                                                            </div>
                                                        </div>
                                                    )}
                                                    {professionalPagePosts.professionalPageId != userPageId && (
                                                        <div
                                                            className={`dropdown-content ${isDropdownVisible ? 'show' : ''}`}
                                                            style={{ marginRight: '130px' }}>
                                                            <div className="mb-2" onClick={() => handleIsSavedClick(professionalPagePosts)}>
                                                                {professionalPagePosts?.professionalPageFavouritePostDataList?.some(favData => favData.userPageId === userPageId) ? (
                                                                    <><TurnedInIcon className="me-1" />
                                                                        Saved Post</>
                                                                ) : (
                                                                    <><TurnedInNotIcon className="me-1" />
                                                                        Save Post</>
                                                                )}
                                                            </div>
                                                            <div className="ms-1" onClick={() => handleReportPost(professionalPagePosts)}>
                                                                <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                                Report
                                                            </div>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Col>

                                            {/* {professionalPagePosts.caption == '' || professionalPagePosts.caption == "null" ? (
                                                null
                                            ) : (
                                                <div className="ms-3 me-5">{professionalPagePosts.caption}</div>
                                            )} */}
                                            {
                                                professionalPagePosts.caption && professionalPagePosts.caption !== "null" ? (
                                                    <div className="ms-3 me-5">
                                                        <div className={isExpanded ? 'full-caption' : 'caption'}>
                                                            {professionalPagePosts.caption}
                                                        </div>
                                                        {professionalPagePosts.caption.split(' ').length > 20 && (
                                                            <span className="read-more-caption" onClick={() => handleReadMore()}>
                                                                {isExpanded ? 'Read less...' : 'Read more...'}
                                                            </span>
                                                        )}
                                                    </div>
                                                ) : null
                                            }
                                            {professionalPagePosts.professionalPagePostImageGalleryData && (
                                                <Carousel interval={null}
                                                    indicators={(professionalPagePosts.professionalPagePostImageGalleryData.length + (professionalPagePosts.youtubeUrl && professionalPagePosts.youtubeUrl !== 'null' ? 1 : 0)) > 1}
                                                    controls={(professionalPagePosts.professionalPagePostImageGalleryData.length + (professionalPagePosts.youtubeUrl && professionalPagePosts.youtubeUrl !== 'null' ? 1 : 0)) > 1}>
                                                    {professionalPagePosts.youtubeUrl && professionalPagePosts.youtubeUrl !== 'null' && (
                                                        <Carousel.Item>
                                                            <div className="single-post-image-overlay d-flex justify-content-center align-items-center"
                                                                style={{ cursor: 'pointer' }}>
                                                                <div dangerouslySetInnerHTML={{ __html: `<iframe width="560" height="315" src="https://www.youtube.com/embed/${extractYouTubeVideoID(professionalPagePosts.youtubeUrl)}" frameBorder="0" allowFullScreen></iframe>` }}></div>
                                                                <div className="d-flex align-items-center justify-content-center video-play-icon m-3 p-1 px-2"
                                                                    style={{ borderRadius: '15px', position: 'absolute', top: '0', right: '0' }}>
                                                                    <span style={{ fontSize: 'small' }}>
                                                                        1/{professionalPagePosts.professionalPagePostImageGalleryData.length + 1}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Carousel.Item>
                                                    )}
                                                    {professionalPagePosts.professionalPagePostImageGalleryData.map((imageData, imageIndex) => (
                                                        <Carousel.Item key={imageIndex}>
                                                            <div className="single-post-image-overlay d-flex justify-content-center align-items-center"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => handleImageClick(imageData, professionalPagePosts, professionalPagePosts.professionalPageId)}>
                                                                {(professionalPagePosts.professionalPagePostImageGalleryData.length > 1 || (professionalPagePosts.youtubeUrl && professionalPagePosts.youtubeUrl !== 'null')) &&
                                                                    <div className="d-flex align-items-center justify-content-center video-play-icon m-3 p-1 px-2"
                                                                        style={{ borderRadius: '15px', position: 'absolute', top: '0', right: '0' }}>
                                                                        <span style={{ fontSize: 'small' }}>
                                                                            {/* {imageIndex + 1}/{professionalPagePosts.professionalPagePostImageGalleryData.length} */}
                                                                            {professionalPagePosts.youtubeUrl && professionalPagePosts.youtubeUrl !== 'null' ? imageIndex + 2 : imageIndex + 1}/{professionalPagePosts.professionalPagePostImageGalleryData.length + (professionalPagePosts.youtubeUrl && professionalPagePosts.youtubeUrl !== 'null' ? 1 : 0)}
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {imageData.mimeType === 'IMAGE' ? (
                                                                    <img
                                                                        src={imageData.imagePath}
                                                                        width={'100%'}
                                                                        height={'100%'}
                                                                        alt={`Image ${imageIndex + 1}`}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <video
                                                                            className=""
                                                                            height={'100%'}
                                                                            width={'100%'}
                                                                            controls={playingVideoIndex === imageData.id}
                                                                            onPlay={() => handlePlay(imageData.id)}
                                                                            onPause={() => handlePause(imageData.id)}
                                                                            ref={(ref) => (videoRefs.current[imageData.id] = ref)} >
                                                                            <source src={imageData.imagePath} />
                                                                        </video>
                                                                        {playingVideoIndex !== imageData.id && (
                                                                            <div className="d-flex align-items-center justify-content-center video-play-icon"
                                                                                onClick={(e) => handlePlayIconClick(e, imageData.id)}>
                                                                                <PlayCircleOutlineIcon style={{ fontSize: 'xxx-large' }} />
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            )}
                                            {professionalPagePosts.callToAction == 0 || professionalPagePosts.callToAction == null ? (
                                                null
                                            ) : (
                                                <>
                                                    {professionalPagePosts.callToActionUrl && professionalPagePosts.callToActionUrl != 'null' && (
                                                        // <a href={professionalPagePosts.callToActionUrl} className="shop-now-link" rel="nofollow"
                                                        <a href={professionalPagePosts.callToActionUrl.startsWith('http') ? professionalPagePosts.callToActionUrl : `http://${professionalPagePosts.callToActionUrl}`} className="shop-now-link" target="_blank" rel="nofollow"
                                                            style={{ textDecoration: 'none' }}>
                                                            <div className="shop-now-bar mb-2" style={{ backgroundColor: getRandomColor() }}>
                                                                {professionalPagePosts.callToActionLabel}
                                                                <span>
                                                                    <ChevronRightIcon />
                                                                </span>
                                                            </div>
                                                        </a>
                                                    )}
                                                </>
                                            )}
                                            {
                                                professionalPagePosts.professionalPagePostLikeData && professionalPagePosts.professionalPagePostLikeData.length > 0 && (
                                                    <div className="px-2 mb-2 d-flex align-items-center">
                                                        <RecommendIcon style={{ color: '#007bff' }} />
                                                        {professionalPagePosts.professionalPagePostLikeData.length}
                                                    </div>
                                                )
                                            }
                                            < hr />
                                            <div>
                                                <Col className="d-flex justify-content-evenly">
                                                    <Col md={4} className="d-flex justify-content-center">
                                                        <span className="d-flex align-items-center"
                                                            style={{
                                                                color: professionalPagePosts?.professionalPagePostLikeData?.some(likeData => likeData.likedByPageId === userPageId) ? '#007bff' : 'black',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={handleLikeClick}>
                                                            <ThumbUpOffAltIcon />
                                                            {professionalPagePosts?.professionalPagePostLikeData?.some(likeData => likeData.likedByPageId === userPageId) ? 'Liked' : 'Like'}
                                                        </span>
                                                    </Col>
                                                    <Col md={4} className="d-flex justify-content-center">
                                                        <span className="d-flex align-items-center"
                                                            style={{ cursor: 'pointer' }}>
                                                            <ChatBubbleOutlineOutlinedIcon /> Comment
                                                        </span>
                                                    </Col>
                                                    <Col md={4} className="d-flex justify-content-center">
                                                        <span className="d-flex align-items-center"
                                                            style={{ cursor: 'pointer' }} onClick={() => handleShare(professionalPagePosts)}>
                                                            <ShareIcon /> Share
                                                        </span>
                                                    </Col>
                                                </Col>
                                                <hr />
                                            </div>
                                        </div>
                                    )}
                                    <Col className="comment-sec mt-0">
                                        <img
                                            // src={activePageItem?.profileImageUrl} 
                                            src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                                            alt="" />
                                        <div className="input-div">
                                            <textarea
                                                value={comment}
                                                placeholder="Write a comment..."
                                                onChange={e => { handleCommentOnChange(e.target.value) }}
                                            />
                                            <SendIcon style={{ cursor: 'pointer' }}
                                                onClick={() => saveProfessionalPagePostComment()}
                                            />
                                        </div>
                                    </Col>
                                    {Array.isArray(professionalPagePosts.professionalPageCommentData) &&
                                        professionalPagePosts.professionalPageCommentData
                                            .map((comment, commentIndex) => (
                                                <Col key={commentIndex}>
                                                    {/* Step - 1  */}
                                                    <div className="post-comment-content">
                                                        <Col md={1}>
                                                            <img className="post-comment-profile-image"
                                                                // src={comment.pageProfileImageUrl} 
                                                                src={comment.pageProfileImageUrl != null ? comment.pageProfileImageUrl : Avatar}
                                                                alt="" />
                                                        </Col>
                                                        <Col md={11}>
                                                            <div className="d-flex align-items-center">
                                                                <Col md={11} className="post-comment-text-container p-2 pt-0">
                                                                    <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                                                                        onClick={() => handleViewPage(comment.createdBy, comment.pageId)}>
                                                                        {comment.pageName}
                                                                    </span>
                                                                    <div className="post-comment-comment-text">
                                                                        {comment.comment}
                                                                    </div>
                                                                </Col>
                                                                <Col md={1} onClick={(e) => e.stopPropagation()}>
                                                                    <MoreHorizIcon className="m-2 more-horizicon"
                                                                        onClick={() => toggleCommentDropdown(commentIndex)} />
                                                                    {userPageId == professionalPageId && userPageId != comment.pageId && professionalPageId != comment.pageId && (
                                                                        <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                                                            <div className="ms-1" onClick={() => handleDeleteCommentDialog(comment.id)}>
                                                                                <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                                                Delete
                                                                            </div>
                                                                            <div className="ms-1"
                                                                                onClick={() => handleCommentReport(comment)}>
                                                                                <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                                                Report
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {((userPageId == professionalPageId) || (userPageId != professionalPageId)) && userPageId == comment.pageId && ((professionalPageId == comment.pageId) || (professionalPageId != comment.pageId)) && (
                                                                        <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                                                            <div className="ms-1" onClick={() => handleDeleteCommentDialog(comment.id)}>
                                                                                <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                                                Delete
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {userPageId != professionalPageId && userPageId != comment.pageId && (
                                                                        <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                                                            <div className="ms-1"
                                                                                onClick={() => handleCommentReport(comment)}>
                                                                                <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                                                Report
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </div>
                                                            {comment.professionalPageCommentLikeDataList && comment.professionalPageCommentLikeDataList.length > 0 && (
                                                                <div className="d-flex"
                                                                    style={{ marginTop: '-10px' }}>
                                                                    <div className="recommend-container d-flex align-items-center">
                                                                        <RecommendIcon style={{
                                                                            color: '#007bff',
                                                                            fontSize: 'large'
                                                                        }} />
                                                                        <span style={{ color: 'gray' }}>{comment.professionalPageCommentLikeDataList.length}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="reply-container">
                                                                <a className="">
                                                                    {formatCommentTime(comment.createdAt)}
                                                                </a>
                                                                <a className="ms-2"
                                                                    style={{
                                                                        color: comment.professionalPageCommentLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                                                                    }}
                                                                    onClick={() => handleLikeCommentClick(comment, commentIndex)}>
                                                                    {comment.professionalPageCommentLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                                                                </a>
                                                                <a className="ms-2"
                                                                    onClick={() => handleReplyClick(commentIndex)}>
                                                                    Reply
                                                                </a>
                                                            </div>
                                                            {comment.professionalPageCommentReplyData && comment.professionalPageCommentReplyData.length > 0 && (
                                                                <div className="d-flex align-items-end"
                                                                    onClick={() => handleViewRepliesClick(comment.id, commentIndex)}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        fontWeight: '600',
                                                                        fontSize: 'small',
                                                                        color: 'gray'
                                                                    }}>
                                                                    <SubdirectoryArrowRightIcon />
                                                                    {visibleReplies[commentIndex] ? 'Replies' : 'View Replies'}
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </div>
                                                    {showReplyInput === commentIndex && (
                                                        <Col className="comment-sec mt-0 ps-5">
                                                            <img
                                                                // src={activePageItem?.profileImageUrl} 
                                                                src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                                                                alt="" />
                                                            <div className="input-div">
                                                                <textarea
                                                                    value={replyComment[commentIndex]}
                                                                    placeholder="Write a comment..."
                                                                    onChange={e => { handleReplyCommentOnChange(commentIndex, e.target.value) }}
                                                                />
                                                                <SendIcon
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => saveProfessionalPagePostCommentReply(commentIndex, comment)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    )}
                                                    {/* Step - 2  */}
                                                    {visibleReplies[commentIndex] && commentReply?.map((commentReply, commentReplyIndex) => (
                                                        <Row className="comment-sec mt-0 ps-5" key={commentReplyIndex}>
                                                            <div className="post-comment-content pt-0 p-2">
                                                                <Col md={1}>
                                                                    <img className="post-comment-profile-image"
                                                                        style={{ height: '38px', width: '38px' }}
                                                                        // src={commentReply.pageProfileImageUrl} 
                                                                        src={commentReply.pageProfileImageUrl != null ? commentReply.pageProfileImageUrl : Avatar}
                                                                        alt="" />
                                                                </Col>
                                                                <Col md={11}>
                                                                    <div className="d-flex align-items-center">
                                                                        <Col md={11} className="post-comment-text-container p-2 pt-0 pb-1">
                                                                            <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                                                                                onClick={() => handleViewPage(commentReply.createdBy, commentReply.pageId)}>
                                                                                {commentReply.pageName}
                                                                            </span>
                                                                            <div className="post-comment-text">{commentReply.comment}</div>
                                                                        </Col>
                                                                        <Col md={1} onClick={(e) => e.stopPropagation()}>
                                                                            <MoreHorizIcon className="m-2 more-horizicon"
                                                                                onClick={() => toggleReplyDropdown(commentReply, commentReplyIndex)} />
                                                                            {userPageId == professionalPageId && userPageId != commentReply.pageId && professionalPageId != commentReply.pageId && (
                                                                                <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                                                                    style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                                                                    <div className="ms-1" onClick={() => handleDeleteCommentReplyDialog(commentReply)}>
                                                                                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                                                        Delete
                                                                                    </div>
                                                                                    <div className="ms-1"
                                                                                        onClick={() => handleCommentReplyReport(commentReply)}>
                                                                                        <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                                                        Report
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {((userPageId == professionalPageId) || (userPageId != professionalPageId)) && userPageId == commentReply.pageId && ((professionalPageId == commentReply.pageId) || (professionalPageId != commentReply.pageId)) && (
                                                                                <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                                                                    style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                                                                    <div className="ms-1" onClick={() => handleDeleteCommentReplyDialog(commentReply)}>
                                                                                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                                                        Delete
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {userPageId != professionalPageId && userPageId != commentReply.pageId && (
                                                                                <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                                                                    style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                                                                    <div className="ms-1"
                                                                                        onClick={() => handleCommentReplyReport(commentReply)}>
                                                                                        <FontAwesomeIcon icon={faFlag} className="me-2" />
                                                                                        Report
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </div>
                                                                    {commentReply.professionalPageCommentReplyLikeDataList && commentReply.professionalPageCommentReplyLikeDataList.length > 0 && (
                                                                        <div className="d-flex"
                                                                            style={{ marginTop: '-7px' }}>
                                                                            <div className="recommend-container d-flex align-items-center">
                                                                                <RecommendIcon style={{
                                                                                    color: '#007bff',
                                                                                    fontSize: 'large'
                                                                                }} />
                                                                                <span style={{ color: 'gray' }}>
                                                                                    {commentReply.professionalPageCommentReplyLikeDataList.length}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div className="reply-container">
                                                                        <a className="">
                                                                            {formatCommentTime(commentReply.createdAt)}
                                                                        </a>
                                                                        <a className="ms-2"
                                                                            style={{
                                                                                color: commentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                                                                            }}
                                                                            onClick={() => handleLikeReplyCommentClick(commentReply, commentReplyIndex)}>
                                                                            {commentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                                                                        </a>
                                                                        <a className="ms-2"
                                                                            onClick={() => handleChildReplyClick(commentReply.id)}>
                                                                            Reply
                                                                        </a>
                                                                    </div>
                                                                    {commentReply.professionalPageCommentChildReplyData && commentReply.professionalPageCommentChildReplyData.length > 0 && (
                                                                        <div className="d-flex align-items-end"
                                                                            onClick={() => handleViewChildRepliesClick(commentReply, commentReplyIndex, commentReply.professionalPageCommentChildReplyData)}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                fontWeight: '600',
                                                                                fontSize: 'small',
                                                                                color: 'gray'
                                                                            }}>
                                                                            <SubdirectoryArrowRightIcon />
                                                                            {visibleChildReplies[commentReply.id] ? 'Replies' : 'View Replies'}
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </div>
                                                            {showChildReplyInput[commentReply.id] && (
                                                                <Col className="comment-sec mt-0 ps-5">
                                                                    <img
                                                                        // src={activePageItem?.profileImageUrl} 
                                                                        src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                                                                        alt="" />
                                                                    <div className="input-div">
                                                                        <textarea
                                                                            value={replyChildComment[commentReplyIndex]}
                                                                            placeholder="Write a comment..."
                                                                            onChange={e => { handleChildReplyCommentOnChange(commentReplyIndex, e.target.value) }}
                                                                        />
                                                                        <SendIcon
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => saveProfessionalPagePostChildCommentReply(commentReplyIndex, commentReply)}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            )}
                                                            {/* Step - 3  */}
                                                            {visibleChildReplies[commentReply.id] && renderChildReplies(commentChildReply, commentReply.id)}
                                                        </Row>
                                                    ))}
                                                </Col>
                                            ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Modal size="lg" show={editShow} onHide={editHandleClose} backdrop="static">
                            <EditProfessionPost data={editData} postId={postId} handleClose={editHandleClose} />
                        </Modal>
                        <Modal size="lg" show={reportPostShow} onHide={reportPostHandleClose}>
                            <ProfessionPostReport data={reportPostData} reportType={'POST_REPORT'} handleClose={reportPostHandleClose} />
                        </Modal>
                        <Modal size="lg" show={shareShow} onHide={shareClose}>
                            <ProfessionPostShare data={shareData} handleClose={shareClose} />
                        </Modal>
                        <Modal size="lg" show={reportCommentShow} onHide={reportCommentHandleClose}>
                            <ProfessionPostReport data={reportCommentData} reportType={'COMMENT_REPORT'} handleClose={reportCommentHandleClose} />
                        </Modal>
                        <Modal size="lg" show={reportCommentReplyShow} onHide={reportCommentReplyHandleClose}>
                            <ProfessionPostReport data={reportCommentReplyData} reportType={'REPLY_REPORT'} handleClose={reportCommentReplyHandleClose} />
                        </Modal>
                        <Modal show={showLogInDialog} onHide={handleCloseLogInDialog} backdrop="static"
                            centered className="login-page-dialog-modal">
                            <LoginPageDialog handleClose={handleCloseLogInDialog} />
                        </Modal>
                    </div >
                    <Footer></Footer>
                </>
            )}
        </div >
    </>
}
export default ViewProfessionalPageSinglePost;