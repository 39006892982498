import React from "react";
import "./footer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppleIcon from '@mui/icons-material/Apple';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { useNavigate } from "react-router-dom";
import StorageService from "../../data/storage/storage-service";


const navigationItems = [
    {
        title: 'About',
        subTitle: [
            {
                subName: 'About us',
                path: '/aboutUs'
            },
            {
                subName: 'Contact us',
                path: '/contact'
            },
            {
                subName: 'Privacy policy',
                path: '/privacy-policy'
            },
            {
                subName: 'Refund Policy',
                path: '/refund-policy'
            },
            {
                subName: 'Terms of use',
                path: '/terms-of-use'
            },
            // {
            //     sub: 'How it Works',
            //     path: '/how-it-work'
            // }
        ]
    },
    // {
    //     title: 'MORE INFORMATION',
    //     subTitle: [
    //         {
    //             subName: 'All properties',
    //             path: '/property'
    //         },
    //         {
    //             subName: 'Houses for sale',
    //             path: '/property?deedtype=SALE'
    //         },
    //         {
    //             subName: 'Houses for rent',
    //             path: ''
    //         }
    //     ]
    // },
    // {
    //     title: 'NEWS',
    //     subTitle: [
    //         {
    //             subName: 'Latest news',
    //             path: ''
    //         },
    //         {
    //             subName: 'House architecture',
    //             path: '/'
    //         },
    //         {
    //             subName: 'House design',
    //             path: '/'
    //         },
    //         {
    //             subName: 'Building materials',
    //             path: '/login'
    //         }
    //     ]
    // },

];

const Footer = () => {
    let navigate: any = useNavigate();
    const handleButtonClick = () => {
        let user = StorageService.isLoggedIn();
        if (!user) {
            navigate('/register')
        } else {
            navigate('/')
        }
    }


return (
    <div>
        <div className="widget_shortcode">
            <div className="raw-html-embed">
                <section className="theme-bg call-to-act-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="call-to-act">
                                    <div className="call-to-act-head">
                                        <h3>Want to Become a Real Estate Agent?</h3>
                                        <span>
                                            We'll help you to grow your career and growth.
                                        </span>
                                    </div>
                                    <a onClick={handleButtonClick} className="btn btn-call-to-act">
                                        Sign Up Today
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div className="dark-footer skin-dark-footer">
            <div className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4">
                            <div className="footer-widget">
                                <h1 className="img-footer text-white">Veedu</h1>
                                <img src="" className="img-footer" alt="" />
                                <div className="footer-add">
                                    <p>
                                        <i className="fas fa-map-marker-alt"></i>
                                        {/* Collins Street West, Victoria 8007, Australia. */}
                                        No: 2/12, <br />
                                        South Thamaraikulam, <br />
                                        Kanyakumari District - 629701.
                                    </p>
                                    <p><i className="fas fa-phone-square"></i> +91 94 8606 0448</p>
                                    <p><i className="fas fa-envelope"></i> ezhilarasu007@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                {navigationItems.map((navigationItems1: any, index: any) => (
                                    <div className="col-sm-4">
                                        <div className="footer-widget">
                                            <h4 className="widget-title">{navigationItems1.title}</h4>
                                            <ul>
                                                {navigationItems1.subTitle.map((subMenue: any) => (
                                                    <li>
                                                        <a href={subMenue.path}>
                                                            <span>{subMenue.subName}</span>
                                                        </a>
                                                    </li>
                                                ))}
                                                {navigationItems1.subTitle.map((subMenue: any) => (
                                                    <li>
                                                        <a href={subMenue.path}>
                                                            <span className="text-light fs-6">{subMenue.sub}</span>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* <div className="col-lg-3 col-md-6">
                                <div className="widget_shortcode">
                                    <div className="raw-html-embed">
                                        <div className="footer-widget">
                                            <h4 className="widget-title">Download Apps</h4>
                                            <a href="#" className="other-store-link">
                                                <div className="other-store-app mb-3">
                                                    <div className="os-app-icon">
                                                        <SportsEsportsIcon />
                                                    </div>
                                                    <div className="os-app-caps">
                                                        Google Play
                                                        <span>Get It Now</span>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" className="other-store-link">
                                                <div className="other-store-app">
                                                    <div className="os-app-icon">
                                                        <AppleIcon />
                                                    </div>
                                                    <div className="os-app-caps">
                                                        App Store
                                                        <span>Now it Available</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-widget newsletter widget_newsletter">
                                    <div className="header-title-2">
                                        <h4 className="widget-title">
                                            <span>Subscribe</span>
                                        </h4>
                                    </div>
                                    <form className="form-subcriber newsletter-form mt-30">
                                        <div className="form-group d-flex">
                                            <input type="email" name="email" className="form-control bg-white font-small" placeholder="Enter your email" />
                                            <button className="btn bg-dark text-white" type="submit">Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}
export default Footer;