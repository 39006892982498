import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "antd";
import house from "../../../assets/housing.png"
import Mortgage from "../../../assets/mortgage-loan.png"
import Personal from "../../../assets/personal.png"
import Other from "../../../assets/ontherLoan.png"

const LoanWidgets = ({onLoanTypeClick}) => {
    const [selectedLoanType, setSelectedLoanType] = useState<String>();

    const [loanType] = useState([
        { label: 'Housing Loan', code:'HOUSING_LOAN', alt: 'Housing Loan', src: house },
        { label: 'Mortgage Loan',code:'MORTGAGE_LOAN', alt: 'Mortgage Loan', src: Mortgage },
        { label: 'Personal Loan',code:'PERSONAL_LOAN', alt: 'Personal Loan', src: Personal },
        { label: 'Other  Loans',code:'OTHER_LOAN', alt: 'Other Loan', src: Other }
    ]);
    const onLoanTypeClicked = (item:any, selectedValue:any) => {
        setSelectedLoanType(item.code);
        onLoanTypeClick(item);
    }

    useEffect(() => {
    }, []);
    return <>
        <Fragment>
                <Row   >
                    <Col className="d-flex flex-wrap">
                        {loanType.map((item: any, idx: number) => {
                            return <>
                                <div className="card-dev" key={'loan_type'+idx } >
                                    <input type="radio" className="input-boxs" id={item.label} checked={item.code == selectedLoanType} value={item.code}   name="radio" onClick={(e) => onLoanTypeClicked(item, e.target)} />
                                    <label htmlFor={item.label} className="label-name text-center">
                                        <h5>{item.label}</h5>
                                        <img className="png-icon" src={item.src} alt={item.alt} />
                                    </label>
                                </div>
                            </>
                        })
                        }
                    </Col>
                </Row>
        </Fragment>
    </>

};
export default LoanWidgets;
