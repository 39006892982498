import { useEffect, useState } from 'react';
import './add-professionalpage-basic-info.css';
import ProfessionalPageBasicInfoImage from '../../../../assets/professional-page-1.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropertyApiService from '../../../../data/api/services/property/property-api-service';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import FormFieldError from '../../../../components/form-field-error/form-field-error';
import ValidationError from '../../../../common/validation-error';
import { Col, Row, Spinner } from 'react-bootstrap';
import LocationApiService from '../../../../data/api/services/location/location-api-service';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "react-bootstrap";
const AddProfessioanlPageBasicinfo = ({ professionalPageFormData, onNextCallback, onPreviousCallback }) => {

    const locationApiService = new LocationApiService();
    const emailMaxLength = 50;
    const nameMaxLength = 100;
    const minLength = 0;
    const maxLengths = 200;
    const [nameText, setNameText] = useState('');
    const [nickNameText, setNickNameText] = useState('');
    const [aboutUs, setAboutUs] = useState('');

    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [registerType, setRegisterType] = useState('PAGE_USER');

    const propertyApiService = new PropertyApiService();
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);
    const [nameValidationErrorMsg, setNameValidationErrorMsg] = useState(null);
    const [emailValidationErrorMsg, setEmailValidationErrorMsg] = useState(null);
    const [addressValidationErrorMsg, setAddressValidationNameErrorMsg] = useState(null);
    const [processing, setProcessing] = useState(false);
    const tabNumber: number = 1;

    const validateBasicInfoFormData = (): void => {
        let errors: any[] = [];
        if (!nameText) {
            let error: any = {};
            error.validationCode = "nameText";
            error.message = 'Name required';
            errors.push(error);

        } else if (nameText.length < 3) {
            let error: any = {};
            error.validationCode = "nameText";
            error.message = 'Name should be at least 3 characters long';
            errors.push(error);
        }

        if (!email) {
            let error: any = {};
            error.validationCode = "email";
            error.message = 'Email required';
            errors.push(error);
        }

        if (!address) {
            let error: any = {};
            error.validationCode = "address";
            error.message = 'Address required';
            errors.push(error);
        }

        if (selectedLocation.length === 0) {
            let error: any = {};
            error.validationCode = "location";
            error.message = 'Location required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const submitBasicInfoFormData = async () => {
        try {
            validateAndSaveBasicInfoFormData();
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const validateAndSaveBasicInfoFormData = () => {
        try {
            validateBasicInfoFormData();
            if (professionalPageFormData.has('name')) {
                professionalPageFormData.delete('name');
            }
            professionalPageFormData.append('name', nameText);

            if (professionalPageFormData.has('nickName')) {
                professionalPageFormData.delete('nickName');
            }
            professionalPageFormData.append('nickName', nickNameText);

            if (professionalPageFormData.has('aboutUs')) {
                professionalPageFormData.delete('aboutUs');
            }
            professionalPageFormData.append('aboutUs', aboutUs);
            if (professionalPageFormData.has('email')) {
                professionalPageFormData.delete('email');
            }
            professionalPageFormData.append('email', email);

            if (professionalPageFormData.has('address')) {
                professionalPageFormData.delete('address');
            }

            professionalPageFormData.append('address', address);

            if (professionalPageFormData.has('registerType')) {
                professionalPageFormData.delete('registerType');
            }

            professionalPageFormData.append('registerType', registerType);

            if (professionalPageFormData.has('locationIdStr')) {
                professionalPageFormData.delete('locationIdStr');
            }
            if (selectedLocation.length > 0) {
                professionalPageFormData.append('locationIdStr', selectedLocation[0].id);
            }

        } catch (error: any) {
            throw error;
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;

            if (errorCode === 'nameText') {
                setNameValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'email') {
                setEmailValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'address') {
                setAddressValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'location') {
                setLocationValidationNameErrorMsg(errorMsg);
            }
        }
    }

    const handleNameChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setNameText(inputValue);
            if (inputValue.length >= 5) {
                setNameValidationErrorMsg(null);
            }
        }
    };
    const renderMenuItemChildren = (option, props, index) => {

        return (
            <div key={index}>
                <span style={{ color: 'black' }}>{option.name}</span>
                {' | '}
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName}</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.talukaName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.districtName}</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>

            </div>
        );
    };
    const handleNickNameChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setNickNameText(inputValue);
        }
    };
    const handleAboutUsChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setAboutUs(inputValue);
        }
    };

    const handleEmailChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setEmail(inputValue);
            if (inputValue.length >= 5) {
                setEmailValidationErrorMsg(null);
            }
        }
    };

    const handleAddressChange = (event: any) => {
        const inputValue = event.target.value;
        if (inputValue.length >= minLength) {
            setAddress(inputValue);
            if (inputValue.length >= 1) {
                setAddressValidationNameErrorMsg(null);
            }
        }
    };

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        formatLocationData(data);
    };

    const formatLocationData = (data: any) => {
        formatLocationDataResponse(data);
        setLocationData(data);
    };

    const formatLocationDataResponse = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            data[i].nameLabel = data[i].name + ' | ' + data[i].pinCode + ' | ' + data[i].postOfficeName + ' | ' + data[i].districtName + ' | ' + data[i].stateName;
        }
    };

    const onLocationSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
        }
        setLocationValidationNameErrorMsg('');
    };

    const highlightBasicInformationFormData = async () => {
        let formData: FormData = professionalPageFormData;
        if (formData.get('name')) {
            setNameText(formData.get('name').toString());
        }
        if (formData.get('nickName')) {
            setNickNameText(formData.get('nickName').toString());
        }
        if (formData.get('aboutUs')) {
            setAboutUs(formData.get('aboutUs').toString());
        }
        if (formData.get('email')) {
            setEmail(formData.get('email').toString());
        }
        if (formData.get('address')) {
            setAddress(formData.get('address').toString());
        }
        if (formData.get('locationIdStr')) {
            const locationIdObj = formData.get('locationIdStr');
            const selectedLocationData = await locationApiService.getLocationById(locationIdObj);
            const formattedLocation = `${selectedLocationData.name} | ${selectedLocationData.pinCode}  | ${selectedLocationData.postOfficeName}  | ${selectedLocationData.districtName} | ${selectedLocationData.stateName}`;
            setSelectedLocation([{ id: locationIdObj, nameLabel: formattedLocation }]);
        }
    }

    const clearSelectedLocation = () => {
        setSelectedLocation([]);
    };

    useEffect(() => {
        highlightBasicInformationFormData();
    }, []);

    return <>
        <div className="container boder-sy  my-4">
            <div className="row">
                <div className="col-sm-1 col-lg-1 text-center mt-5"></div>
                <div className="col-sm-5 col-lg-5 d-flex align-self-center pb-5">
                    <img src={ProfessionalPageBasicInfoImage} className='h-100 w-100' alt="google-bussiness" ></img>
                </div>
                <div className="col-sm-7 col-lg-6 mt-3">
                    <section className='p-0 pt-4'>
                        <div className="row">
                            <div className="col-sm-10 col-lg-12 d-flex LKfzVb mt-2">
                                <h3 className="text-center">Start building your Business Profile</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-10 mt-1">
                                <span className='fs-5'>This will help you get discovered by customers</span>
                            </div>
                        </div>
                        <div className="span-font mt-2">
                            <label className=''>Your Name</label>
                        </div>
                        <div className="col-sm-7 input100">
                            <input
                                className={`input-value form-control p-3 ${nameValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                value={nameText}
                                maxLength={nameMaxLength}
                                onChange={handleNameChange}
                                type="text"
                                placeholder='Example: "Zendaya Maree Stoermer Coleman"'
                            />
                            <FormFieldError errorMessage={nameValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className='below-text'>
                            <div className="d-flex justify-content-between">
                                <span>3 characters required:</span>
                                <span>{minLength + nameText.length}/100</span>
                            </div>
                        </div>

                        <div className="span-font mt-2">
                            <label className=''>Your Nick Name</label>
                        </div>
                        <div className="col-sm-7 input100">
                            <input
                                className="input-value form-control p-3 "
                                value={nickNameText}
                                minLength={minLength}
                                maxLength={nameMaxLength}
                                onChange={handleNickNameChange}
                                type="text"
                                placeholder='Example: "Stoermer Coleman"'
                            />

                        </div>
                        <div className='below-text'>
                            <div className="d-flex justify-content-between">
                                <span>5 characters required:</span>
                                <span>{minLength + nickNameText.length}/100</span>
                            </div>
                        </div>

                        <div className="span-font mt-2">
                            <label className=''>Your Email</label>
                        </div>
                        <div className="col-sm-7 input100">
                            <input
                                className={`input-value form-control p-3 ${emailValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                value={email}
                                minLength={minLength}
                                maxLength={emailMaxLength}
                                onChange={handleEmailChange}
                                type="text"
                                placeholder='Example: "zendaya@gmail.com"'
                            />
                            <FormFieldError errorMessage={emailValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className='below-text'>
                            <div className="d-flex justify-content-between">
                                <span>5 characters required:</span>
                                <span>{minLength + email.length}/50</span>
                            </div>
                        </div>

                        <div className="span-font mt-2">
                            <label className=''>Your Address</label>
                        </div>
                        <div className="col-sm-7 input100">
                            <input
                                className={`input-value form-control p-3 ${addressValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                value={address}
                                // maxLength={maxLength}
                                onChange={handleAddressChange}
                                type="text"
                                placeholder='Example: "16821 Oak View Dr, Encino, CA 91436, USA."'
                            />
                            <FormFieldError errorMessage={addressValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className='below-text'>
                            <div className="d-flex justify-content-between">
                                {/* <span>5 characters required:</span>
                                <span>{minLength + address.length}/20</span> */}
                            </div>
                        </div>
                        <div className="span-font mt-2">
                            <label className=''>AboutUs</label>
                        </div>
                        <div className="col-sm-7 input100">
                            <textarea style={{ resize: 'none' }}
                                className="input-value form-control p-3 "
                                value={aboutUs}
                                maxLength={maxLengths}
                                onChange={handleAboutUsChange}
                            />
                        </div>
                        <div className='below-text mt-4 mb-2'>
                            <div className="d-flex justify-content-between">
                                <span>Remaining:</span>
                                <span>{minLength + aboutUs.length}/200</span>
                            </div>
                        </div>
                        <div className="span-font">
                            <label className=''>What Region is your business based in ?</label>
                        </div>
                        <div className="col-sm-7 input100">
                        <Col className="justify-content-end align-items-center d-flex async-container">
                                                <Col xs="auto" className="close-icon">
                                    {selectedLocation.length > 0 && (

                                        <FontAwesomeIcon icon={faTimes} onClick={clearSelectedLocation} />
                                    )}
                                </Col>
                                <Col>
                                    <AsyncTypeahead
                                        className={` test ${locationValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                        labelKey="formatedLocationData"
                                        onSearch={(e) => searchLocation(e)}
                                        options={locationData}
                                        onChange={onLocationSelected}
                                        useCache={false}
                                        placeholder="Search Location"
                                        defaultSelected={selectedLocation}
                                        isLoading={locationSearchLoading}
                                        selected={selectedLocation}
                                        renderMenuItemChildren={renderMenuItemChildren}
                                    />
                                </Col>
                            </Col>


                            <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className='below-text mt-3'>
                            {selectedLocation.length > 0 && (
                                <button onClick={clearSelectedLocation} className="btn btn-danger mb-2 rounded-pill" type="button">
                                    Clear
                                </button>
                            )}
                            {/* <div className="d-flex justify-content-between">
                                <span>location : {selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].nameLabel : ''}</span>
                            </div> */}
                        </div>
                        <div className="text-end p-3">
                            <button disabled={processing} className="btn btn-success" onClick={submitBasicInfoFormData} type="button">Next</button>
                            {(processing) && <Spinner animation="grow" variant="warning" />}
                        </div>
                        <div className="text-center">
                        </div>
                    </section>

                </div>
            </div>
        </div>
    </>

}

export default AddProfessioanlPageBasicinfo;