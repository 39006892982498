import HttpClientWrapper from "../../http-client-wrapper";

class SabPaisaApiService {

    [x: string]: any;

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getSabPaisaResponse = async (url) => {
        try {
            let data: any = await this.httpClientWrapper.get('/v1/sabPaisa/resp' + url);
            return data;
        } catch (error) {
            console.error('Error fetching SabPaisa response:', error);
            throw error;
        }
    }
}
export default SabPaisaApiService;