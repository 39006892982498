import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UpdateLandPropertyInformation } from "../../../data/api/services/property/create-property-payload";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import { Header } from "../../../layouts";
import CloseIcon from '@mui/icons-material/Close';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation, useNavigate } from "react-router-dom";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ValidationError from "../../../common/validation-error";
import Spinner from "react-bootstrap/esm/Spinner";

const EditLandInformationPage = ({deedType, propertyId, propertyFormData, onNextCallback, onPreviousCallback }) => {

    const [landInformationFormData, setLandInformationFormData] = useState<any>(new FormData());
    let navigate: any = useNavigate();
    const tabNumber: number = 2;
    const propertyInformationFormData = new FormData();
    const [showSoilTypeOption, setShowSoilTypeOption] = useState(true);
    const [showCurrentYieldOption, setShowCurrentYieldOption] = useState(true);
    const [showLandSuitableForOption, setShowLandSuitableForOption] = useState(true);
    const [showBoundaryOption, setShowBoundaryOption] = useState(true);
    const [showApprovedByOption, setShowApprovedByOption] = useState(true);
    const [showYearlyIncomeOption, setShowYearlyIncomeOption] = useState(true);
    const [showPropertyAvailableOption, setShowPropertyAvailableOption] = useState(true);
    const [showRoadAccessOption, setShowRoadAccessOption] = useState(true);
    const [showPlotAreaTypeOption, setShowPlotAreaTypeOption] = useState(true);

    const propertyApiService = new PropertyApiService();
    // const [price, setPrice] = useState("");
    const [plotAreaIdStr, setPlotAreaIdStr] = useState("");
    const [plotAreaTypeData, setPlotAreaTypeData] = useState<any>([]);
    const [plotArea, setPlotArea] = useState("");
    const [plotLength, setPlotLength] = useState("");
    const [plotBreadth, setPlotBreadth] = useState("");
    const [roadAccessData, setRoadAccessData] = useState<any>([]);
    const [roadAccessId, setRoadAccessId] = useState("");
    const [widthRoad, setWidthRoad] = useState("");
    const [soilTypeData, setSoilTypeData] = useState<any>([]);
    const [soilTypeId, setSoilTypeId] = useState("");
    const [currentYieldData, setCurrentYieldData] = useState<any>([]);
    const [currentYieldId, setCurrentYieldId] = useState("");
    const [landSuitableForData, setLandSuitableForData] = useState<any>([]);
    const [landSuitableForId, setLandSuitableForId] = useState("");
    const [boundaryData, setBoundaryData] = useState<any>([]);
    const [boundaryId, setBoundaryId] = useState("");
    const [approvedByData, setApprovedByData] = useState<any>([]);
    const [approvedById, setApprovedById] = useState("");
    const [yearlyIncomeData, setYearlyIncomeData] = useState([]);
    const [yearlyIncomeIdStr, setYearlyIncomeIdStr] = useState("");
    const [description, setDescription] = useState("");
    const [availableStatus, setAvailableStatus] = useState("");
    const [gatedCommunity, setGatedCommunity] = useState("");
    const [hundredData, setHundredData] = useState<any>([]);
    const [hundredDataId, setHundredDataId] = useState("");
    const [thousandData, setThousandData] = useState<any>([]);
    const [thousandDataId, setThousandDataId] = useState("");
    const [lakhsData, setlakhsData] = useState<any>([]);
    const [lakhsDataId, setlakhsDataId] = useState("");
    const [croreData, setCroreData] = useState<any>([]);
    const [croreDataId, setCroreDataId] = useState("");

    // const [priceValidationErrorMsg, setPriceValidationNameErrorMsg] = useState(null);
    const [plotAreaTypeDataValidationErrorMsg, setPlotAreaTypeDataValidationNameErrorMsg] = useState(null);
    const [plotAreaValidationErrorMsg, setPlotAreaValidationNameErrorMsg] = useState(null);
    const [plotLengthValidationErrorMsg, setPlotLengthValidationNameErrorMsg] = useState(null);
    const [plotBreathValidationErrorMsg, setPlotBreathValidationNameErrorMsg] = useState(null);
    const [roadAccessValidationErrorMsg, setRoadAccessValidationNameErrorMsg] = useState(null);
    const [widthRoadValidationErrorMsg, setWidthRoadValidationNameErrorMsg] = useState(null);
    const [soilTypeValidationErrorMsg, setSoilTypeValidationNameErrorMsg] = useState(null);
    const [currentYieldValidationErrorMsg, setCurrentYieldValidationNameErrorMsg] = useState(null);
    const [landSuitableForValidationErrorMsg, setLandSuitableForValidationNameErrorMsg] = useState(null);
    const [boundaryValidationErrorMsg, setBoundaryValidationNameErrorMsg] = useState(null);
    const [approvedByValidationErrorMsg, setApprovedByValidationNameErrorMsg] = useState(null);
    const [yearlyIncomeValidationErrorMsg, setYearlyIncomeValidationNameErrorMsg] = useState(null);
    const [descriptionValidationErrorMsg, setDescriptionValidationNameErrorMsg] = useState(null);
    const [availableStatusValidationErrorMsg, setAvailableStatusValidationNameErrorMsg] = useState(null);
    const [gatedCommunityValidationErrorMsg, setGatedCommunityValidationNameErrorMsg] = useState(null);
    const [processing, setProcessing ] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [priceInText, setPriceInText] = useState("");


    // const handlePriceOnChange = (value: any) => {
    //     setPrice(value);
    //     setPriceValidationNameErrorMsg('');
    // }
    const handleWidthRoadOnChange = (value: any) => {
        setWidthRoad(value);
        setWidthRoadValidationNameErrorMsg('');
    }
    const handleRoadAccessOnChange = (raId: any) => {
        setRoadAccessId(raId);
        setRoadAccessValidationNameErrorMsg('');
        setShowRoadAccessOption(false);
    }
    const handleSoilTypeChange = (stId: any) => {
        setSoilTypeId(stId);
        setSoilTypeValidationNameErrorMsg('');
        setShowSoilTypeOption(false);
    };
    const handleCurrentYieldChange = (cyId: any) => {
        setCurrentYieldId(cyId);
        setCurrentYieldValidationNameErrorMsg('');
        setShowCurrentYieldOption(false);
    };
    const handleLandSuitableForChange = (lsfId: any) => {
        setLandSuitableForId(lsfId);
        setLandSuitableForValidationNameErrorMsg('');
        setShowLandSuitableForOption(false);
    };
    const handleBoundaryChange = (bId: any) => {
        setBoundaryId(bId);
        setBoundaryValidationNameErrorMsg('');
        setShowBoundaryOption(false);
    };
    const handleApprovedByChange = (abId: any) => {
        setApprovedById(abId);
        setApprovedByValidationNameErrorMsg('');
        setShowApprovedByOption(false);
    };
    const handleYearlyIncomeChange = (yiId: any) => {
        setYearlyIncomeIdStr(yiId);
        setYearlyIncomeValidationNameErrorMsg('');
        setShowYearlyIncomeOption(false);
    };
    const handlePropertyAvailbaleStatusChange = (availableStatus: any) => {
        setAvailableStatus(availableStatus);
        setAvailableStatusValidationNameErrorMsg('');
        setShowPropertyAvailableOption(false);
    };
    const handleOnGatedCommunityChange = (gatedCommunity: any) => {
        setGatedCommunity(gatedCommunity);
        setGatedCommunityValidationNameErrorMsg('');
    };
    const handlePlotLengthOnChange = (value: any) => {
        setPlotLength(value);
        setPlotLengthValidationNameErrorMsg('');
    }
    const handlePlotBreadthOnChange = (value: any) => {
        setPlotBreadth(value);
        setPlotBreathValidationNameErrorMsg('');
    }
    const handleDescriptionOnChange = (value: any) => {
        setDescription(value);
        setDescriptionValidationNameErrorMsg('');
    }
    const handlePlotAreaOnChange = (value: any) => {
        setPlotArea(value);
        setPlotAreaValidationNameErrorMsg('');
    }
    const handlePlotAreaTypeChange = (paId: any) => {
        setPlotAreaIdStr(paId);
        setPlotAreaTypeDataValidationNameErrorMsg('');
        setShowPlotAreaTypeOption(false);
    };
    const handleHundredOnChange = (hdId: any) => {
        setHundredDataId(hdId);

    };
    const handleThousandOnChange = (TdId: any) => {
        setThousandDataId(TdId);

    };
    const handleCroreOnChange = (crId: any) => {
        setCroreDataId(crId);

    };
    const handleLakhsOnChange = (lkId: any) => {
        setlakhsDataId(lkId);

    };
    const calculateTotalPrice = () => {
        let total = 0;
        let text = '';
        if (croreDataId) {
            const selectedCroreOption = croreData.find((crData: any) => crData.id == croreDataId);
            if (selectedCroreOption) {
                total += parseInt(selectedCroreOption.priceExpansion);
                text = selectedCroreOption.priceInWord;
            }
        }
        if (lakhsDataId) {
            const selectedLakhsOption = lakhsData.find((lkData: any) => lkData.id == lakhsDataId);
            if (selectedLakhsOption) {
                total += parseInt(selectedLakhsOption.priceExpansion);
                text = text + " and " + selectedLakhsOption.priceInWord;
            }
        }
        if (thousandDataId) {
            const selectedThousandOption = thousandData.find((tdData: any) => tdData.id == thousandDataId);
            if (selectedThousandOption) {
                total += parseInt(selectedThousandOption.priceExpansion);
                text = text + " and " + selectedThousandOption.priceInWord;
            }
        }
        if (hundredDataId) {
            const selectedHundredOption = hundredData.find((hdData: any) => hdData.id == hundredDataId);
            if (selectedHundredOption) {
                total += parseInt(selectedHundredOption.priceExpansion);
                text = text + " and " + selectedHundredOption.priceInWord + "Only";
            }
        }
        setPriceInText(text);
        setTotalPrice(total);
    };


    const validateLandInformationFormData = (): void => {
        let errors: any[] = [];
        // if (!price) {
        //     let error: any = {};
        //     error.validationCode = "price";
        //     error.message = 'Price required';
        //     errors.push(error);
        // }
        if (gatedCommunity !== 'YES' && gatedCommunity !== 'NO') {
            let error: any = {};
            error.validationCode = "gatedCommunity";
            error.message = 'Gated Community required';
            errors.push(error);
        }
        if (!availableStatus) {
            let error: any = {};
            error.validationCode = "propertyavailableStatus";
            error.message = 'Property Available required';
            errors.push(error);
        }
        if (!plotArea) {
            let error: any = {};
            error.validationCode = "plotArea";
            error.message = 'Total Area required';
            errors.push(error);
        }
        if (!plotAreaIdStr) {
            let error: any = {};
            error.validationCode = "plotAreaType";
            error.message = 'Total Area Unit required';
            errors.push(error);
        }
        if (!plotBreadth) {
            let error: any = {};
            error.validationCode = "plotBreadth";
            error.message = 'Plot Breadth required';
            errors.push(error);
        }
        if (!plotLength) {
            let error: any = {};
            error.validationCode = "plotLength";
            error.message = 'Plot Length required';
            errors.push(error);
        }
        if (!roadAccessId) {
            let error: any = {};
            error.validationCode = "roadAccess";
            error.message = 'Road Access required';
            errors.push(error);
        }
        if (!widthRoad) {
            let error: any = {};
            error.validationCode = "widthRoad";
            error.message = 'Width Road required';
            errors.push(error);
        }
        if (!soilTypeId) {
            let error: any = {};
            error.validationCode = "soilType";
            error.message = 'Soil Type required';
            errors.push(error);
        }
        if (!currentYieldId) {
            let error: any = {};
            error.validationCode = "currentYield";
            error.message = 'Current Yield required';
            errors.push(error);
        }
        if (!landSuitableForId) {
            let error: any = {};
            error.validationCode = "landSuitableFor";
            error.message = 'Land Suitable For required';
            errors.push(error);
        }
        if (!boundaryId) {
            let error: any = {};
            error.validationCode = "boundary";
            error.message = 'Boundary required';
            errors.push(error);
        }
        if (!approvedById) {
            let error: any = {};
            error.validationCode = "approvedBy";
            error.message = 'Approved By required';
            errors.push(error);
        }
        if (!yearlyIncomeIdStr) {
            let error: any = {};
            error.validationCode = "yearlyIncome";
            error.message = 'Yearly Income required';
            errors.push(error);
        }
        if (!description) {
            let error: any = {};
            error.validationCode = "description";
            error.message = 'Description required';
            errors.push(error);
        }
        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const validateAndSaveLandInformationFormData = () => {
        try {
            validateLandInformationFormData();
            // if (propertyFormData.has('price')) {
            //     propertyFormData.delete('price');
            // }
            // propertyFormData.append('price', price);

            if (propertyFormData.has('gatedCommunity')) {
                propertyFormData.delete('gatedCommunity');
            }
            propertyFormData.append('gatedCommunity', gatedCommunity);

            if (propertyFormData.has('propertyAvailableStatus')) {
                propertyFormData.delete('propertyAvailableStatus');
            }
            propertyFormData.append('propertyAvailableStatus', availableStatus);

            if (propertyFormData.has('plotArea')) {
                propertyFormData.delete('plotArea');
            }
            propertyFormData.append('plotArea', plotArea);

            if (propertyFormData.has('plotAreaTypeIdStr')) {
                propertyFormData.delete('plotAreaTypeIdStr');
            }
            propertyFormData.append('plotAreaTypeIdStr', plotAreaIdStr);

            if (propertyFormData.has('plotBreadth')) {
                propertyFormData.delete('plotBreadth');
            }
            propertyFormData.append('plotBreadth', plotBreadth);

            if (propertyFormData.has('plotLength')) {
                propertyFormData.delete('plotLength');
            }
            propertyFormData.append('plotLength', plotLength);

            if (propertyFormData.has('roadAccessId')) {
                propertyFormData.delete('roadAccessId');
            }
            propertyFormData.append('roadAccessId', roadAccessId);

            if (propertyFormData.has('widthRoad')) {
                propertyFormData.delete('widthRoad');
            }
            propertyFormData.append('widthRoad', widthRoad);

            if (propertyFormData.has('soilTypeIdStr')) {
                propertyFormData.delete('soilTypeIdStr');
            }
            propertyFormData.append('soilTypeIdStr', soilTypeId);

            if (propertyFormData.has('currentYieldIdStr')) {
                propertyFormData.delete('currentYieldIdStr');
            }
            propertyFormData.append('currentYieldIdStr', currentYieldId);

            if (propertyFormData.has('landSuitableForIdStr')) {
                propertyFormData.delete('landSuitableForIdStr');
            }
            propertyFormData.append('landSuitableForIdStr', landSuitableForId);

            if (propertyFormData.has('boundaryIdStr')) {
                propertyFormData.delete('boundaryIdStr');
            }
            propertyFormData.append('boundaryIdStr', boundaryId);

            if (propertyFormData.has('approvedByIdStr')) {
                propertyFormData.delete('approvedByIdStr');
            }
            propertyFormData.append('approvedByIdStr', approvedById);

            if (propertyFormData.has('yearlyIncomeIdStr')) {
                propertyFormData.delete('yearlyIncomeIdStr');
            }
            propertyFormData.append('yearlyIncomeIdStr', yearlyIncomeIdStr);

            if (propertyFormData.has('description')) {
                propertyFormData.delete('description');
            }
            propertyFormData.append('description', description);
            if (propertyFormData.has('priceInHundredIdStr')) {
                propertyFormData.delete('priceInHundredIdStr');
            }
            propertyFormData.append('priceInHundredIdStr', hundredDataId);
            if (propertyFormData.has('priceInThousandIdStr')) {
                propertyFormData.delete('priceInThousandIdStr');
            }
            propertyFormData.append('priceInThousandIdStr', thousandDataId);
            if (propertyFormData.has('priceInLakhsIdStr')) {
                propertyFormData.delete('priceInLakhsIdStr');
            }
            propertyFormData.append('priceInLakhsIdStr', lakhsDataId);
            if (propertyFormData.has('priceInCroresIdStr')) {
                propertyFormData.delete('priceInCroresIdStr');
            }
            propertyFormData.append('priceInCroresIdStr', croreDataId);

        } catch (error: any) {
            throw error;
        }
    }

    const validateAndUpdateLandInformationFormData = () => {
        try {
            validateLandInformationFormData();
            if (propertyInformationFormData.has('gatedCommunity')) {
                propertyInformationFormData.delete('gatedCommunity');
            }
            propertyInformationFormData.append('gatedCommunity', gatedCommunity);

            if (propertyInformationFormData.has('propertyAvailableStatus')) {
                propertyInformationFormData.delete('propertyAvailableStatus');
            }
            propertyInformationFormData.append('propertyAvailableStatus', availableStatus);

            if (propertyInformationFormData.has('plotAreaStr')) {
                propertyInformationFormData.delete('plotAreaStr');
            }
            propertyInformationFormData.append('plotAreaStr', plotArea);

            if (propertyInformationFormData.has('plotAreaTypeStr')) {
                propertyInformationFormData.delete('plotAreaTypeStr');
            }
            propertyInformationFormData.append('plotAreaTypeStr', plotAreaIdStr);

            if (propertyInformationFormData.has('plotBreadth')) {
                propertyInformationFormData.delete('plotBreadth');
            }
            propertyInformationFormData.append('plotBreadth', plotBreadth);

            if (propertyInformationFormData.has('plotLength')) {
                propertyInformationFormData.delete('plotLength');
            }
            propertyInformationFormData.append('plotLength', plotLength);

            if (propertyInformationFormData.has('roadAccessIdStr')) {
                propertyInformationFormData.delete('roadAccessIdStr');
            }
            propertyInformationFormData.append('roadAccessIdStr', roadAccessId);

            if (propertyInformationFormData.has('widthRoad')) {
                propertyInformationFormData.delete('widthRoad');
            }
            propertyInformationFormData.append('widthRoad', widthRoad);

            if (propertyInformationFormData.has('soilTypeIdStr')) {
                propertyInformationFormData.delete('soilTypeIdStr');
            }
            propertyInformationFormData.append('soilTypeIdStr', soilTypeId);

            if (propertyInformationFormData.has('currentYieldIdStr')) {
                propertyInformationFormData.delete('currentYieldIdStr');
            }
            propertyInformationFormData.append('currentYieldIdStr', currentYieldId);

            if (propertyInformationFormData.has('landSuitableForIdStr')) {
                propertyInformationFormData.delete('landSuitableForIdStr');
            }
            propertyInformationFormData.append('landSuitableForIdStr', landSuitableForId);

            if (propertyInformationFormData.has('boundaryIdStr')) {
                propertyInformationFormData.delete('boundaryIdStr');
            }
            propertyInformationFormData.append('boundaryIdStr', boundaryId);

            if (propertyInformationFormData.has('approvedByIdStr')) {
                propertyInformationFormData.delete('approvedByIdStr');
            }
            propertyInformationFormData.append('approvedByIdStr', approvedById);

            if (propertyInformationFormData.has('yearlyIncomeIdStr')) {
                propertyInformationFormData.delete('yearlyIncomeIdStr');
            }
            propertyInformationFormData.append('yearlyIncomeIdStr', yearlyIncomeIdStr);

            if (propertyInformationFormData.has('description')) {
                propertyInformationFormData.delete('description');
            }
            propertyInformationFormData.append('description', description);

            if (propertyInformationFormData.has('priceInHundredIdStr')) {
                propertyInformationFormData.delete('priceInHundredIdStr');
            }
            propertyInformationFormData.append('priceInHundredIdStr', hundredDataId);

            if (propertyInformationFormData.has('priceInThousandIdStr')) {
                propertyInformationFormData.delete('priceInThousandIdStr');
            }
            propertyInformationFormData.append('priceInThousandIdStr', thousandDataId);

            if (propertyInformationFormData.has('priceInLakhsIdStr')) {
                propertyInformationFormData.delete('priceInLakhsIdStr');
            }
            propertyInformationFormData.append('priceInLakhsIdStr', lakhsDataId);

            if (propertyInformationFormData.has('priceInCroresIdStr')) {
                propertyInformationFormData.delete('priceInCroresIdStr');
            }
            propertyInformationFormData.append('priceInCroresIdStr', croreDataId);

            propertyInformationFormData.append('deedType', deedType);

        } catch (error: any) {
            throw error;
        }
    }

    const submitLandInformationFormData = async () => {
        try {
            validateAndSaveLandInformationFormData();
            onNextCallback(tabNumber + 1);
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const updateLandInformationFormData = async () => {
        try {
            validateAndUpdateLandInformationFormData();
            setProcessing(true);
            await propertyApiService.updateInformation(propertyId, propertyInformationFormData);
            toast.success('Successfully Land Information Updated ', { containerId: 'TR' });
            navigate('/')
        } catch (error: any) {
            setProcessing(false);
            showValidationErrorInlineUI(error);
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            // if (errorCode === 'price') {
            //     setPriceValidationNameErrorMsg(errorMsg);
            // }
            if (errorCode === 'gatedCommunity') {
                setGatedCommunityValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'propertyavailableStatus') {
                setAvailableStatusValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'plotArea') {
                setPlotAreaValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'plotAreaType') {
                setPlotAreaTypeDataValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'plotBreadth') {
                setPlotBreathValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'plotLength') {
                setPlotLengthValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'roadAccess') {
                setRoadAccessValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'widthRoad') {
                setWidthRoadValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'soilType') {
                setSoilTypeValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'currentYield') {
                setCurrentYieldValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'landSuitableFor') {
                setLandSuitableForValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'boundary') {
                setBoundaryValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'approvedBy') {
                setApprovedByValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'yearlyIncome') {
                setYearlyIncomeValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'description') {
                setDescriptionValidationNameErrorMsg(errorMsg);
            }
        }
    }

    const highlightInformationFormData = () => {
        let formData: FormData = propertyFormData;
        // if (formData.get('price')) {
        //     setPrice(formData.get('price').toString());
        // }
        if (formData.get('roadAccessId')) {
            setRoadAccessId(formData.get('roadAccessId').toString());
        }
        if (formData.get('description')) {
            setDescription(formData.get('description').toString());
        }
        if (formData.get('propertyAvailableStatus')) {
            setAvailableStatus(formData.get('propertyAvailableStatus').toString());
        }
        if (formData.get('propertyAvailableStatus')) {
            setAvailableStatus(formData.get('propertyAvailableStatus').toString());
        }
        if (formData.get('plotLength')) {
            setPlotLength(formData.get('plotLength').toString());
        }
        if (formData.get('plotBreadth')) {
            setPlotBreadth(formData.get('plotBreadth').toString());
        }
        if (formData.get('widthRoad')) {
            setWidthRoad(formData.get('widthRoad').toString());
        }
        if (formData.get('soilTypeIdStr')) {
            setSoilTypeId(formData.get('soilTypeIdStr').toString());
        }
        if (formData.get('currentYieldIdStr')) {
            setCurrentYieldId(formData.get('currentYieldIdStr').toString());
        }
        if (formData.get('landSuitableForIdStr')) {
            setLandSuitableForId(formData.get('landSuitableForIdStr').toString());
        }
        if (formData.get('boundaryIdStr')) {
            setBoundaryId(formData.get('boundaryIdStr').toString());
        }
        if (formData.get('approvedByIdStr')) {
            setApprovedById(formData.get('approvedByIdStr').toString());
        }
        if (formData.get('yearlyIncomeIdStr')) {
            setYearlyIncomeIdStr(formData.get('yearlyIncomeIdStr').toString());
        }
        if (formData.get('gatedCommunity')) {
            setGatedCommunity(formData.get('gatedCommunity').toString());
        }
        if (formData.get('plotAreaTypeIdStr')) {
            setPlotAreaIdStr(formData.get('plotAreaTypeIdStr').toString());
        }
        if (formData.get('plotArea')) {
            setPlotArea(formData.get('plotArea').toString());
        }
        if (formData.get('priceInCroresIdStr')) {
            setCroreDataId(formData.get('priceInCroresIdStr').toString());
        }
        if (formData.get('priceInLakhsIdStr')) {
            setlakhsDataId(formData.get('priceInLakhsIdStr').toString());
        }
        if (formData.get('priceInThousandIdStr')) {
            setThousandDataId(formData.get('priceInThousandIdStr').toString());
        }
        if (formData.get('priceInHundredIdStr')) {
            setHundredDataId(formData.get('priceInHundredIdStr').toString());
        }
    }

    const getLandInformationTemplatesData = async () => {
        try {
            let res = await propertyApiService.getLandInformationTemplatesData();
            setSoilTypeData(res.soilTypeData);
            setCurrentYieldData(res.currentYieldData);
            setLandSuitableForData(res.landSuitableForData);
            setBoundaryData(res.boundaryData);
            setApprovedByData(res.approvedByData);
            setYearlyIncomeData(res.yearlyIncomeData);
            setGatedCommunity(res.gatedCommunities);
            setRoadAccessData(res.roadAccessData);
            setPlotAreaTypeData(res.plotAreaTypeData);
            setHundredData(res.priceInHundredData)
            setlakhsData(res.priceInLakhsData);
            setThousandData(res.priceInThousandData);
            setCroreData(res.priceInCroresData);
            highlightInformationFormData();
            console.log('res.roadAccessData', res);
        }
        catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const previousPage = async () => {
        onPreviousCallback(tabNumber - 1);
    };

    useEffect(() => {
        getLandInformationTemplatesData();
    }, []);

    useEffect(() => {
        calculateTotalPrice();
    }, [lakhsDataId, croreDataId,hundredDataId, thousandDataId])


    return (
        <>
            <div className="body container-fluid row">
                <div className=" container  w-100  ms-1">
                    <div className="card-body">
                        <div className="col-sm-9 mx-auto ">
                            <div className="card ">
                                <div className="card-body">
                                    <div className="text-color text-center">
                                        <p> Information</p>
                                    </div>
                                    <div className="row ms-5">
                                        <div className="col-sm-6 ">
                                            <div className="mb-2">
                                                <label className="mt-4">Property Available<sup className="required">*</sup></label>
                                                <select value={availableStatus} className={availableStatusValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} onChange={(e) => handlePropertyAvailbaleStatusChange(e.target.value)}>
                                                    {showPropertyAvailableOption && <option value="">Select</option>}
                                                    <option value='AVAILABLE'>Available</option>
                                                    <option value='SOLD_OUT'>Sold Out</option>
                                                </select>
                                                <FormFieldError errorMessage={availableStatusValidationErrorMsg}></FormFieldError>
                                            </div>
                                            {/* <div className="mb-2">
                                                <label className="mt-4">Price<sup className="required">*</sup></label>
                                                <input
                                                    className={priceValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    type="number"
                                                    min={0}
                                                    placeholder="Price"
                                                    value={price}
                                                    onChange={e => { handlePriceOnChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={priceValidationErrorMsg}></FormFieldError>
                                            </div> */}
                                            <div className="mb-2 row gx-1">
                                                <label className="fs-15">Price<sup className="required">*</sup></label>
                                                <div className="col-sm-3">
                                                    <label className="mt-4  fs-12">Crores</label>
                                                    <select className="input-edit form-control mt-2" value={croreDataId} onChange={(e) => handleCroreOnChange(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {croreData.map((crData: any, index: any) => (
                                                            <option key={crData.id} value={crData.id}>{crData.priceValue}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className="mt-4 fs-12">Lakhs</label>
                                                    <select className="input-edit form-control mt-2" value={lakhsDataId} onChange={(e) => handleLakhsOnChange(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {lakhsData.map((lkData: any, index: any) => (
                                                            <option key={lkData.id} value={lkData.id}>{lkData.priceValue}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className="mt-4 fs-12">Thousands</label> <select className="input-edit form-control mt-2" value={thousandDataId} onChange={(e) => handleThousandOnChange(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {thousandData.map((tdData: any, index: any) => (
                                                            <option key={tdData.id} value={tdData.id}>{tdData.priceValue}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className="mt-4 fs-12">Hundred</label>
                                                    <select className="input-edit form-control mt-2" value={hundredDataId} onChange={(e) => handleHundredOnChange(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {hundredData.map((hdData: any, index: any) => (
                                                            <option key={hdData.id} value={hdData.id}>{hdData.priceValue}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <label className="totalPrice mt-4">Total Price : <span className="totalPriceColour">{totalPrice} ( {priceInText} ) 
                                                </span></label>

                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Road Access<sup className="required">*</sup></label>
                                                <select className={roadAccessValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={roadAccessId}
                                                    onChange={(e) => handleRoadAccessOnChange(e.target.value)} >
                                                    {showRoadAccessOption && <option value="">Select</option>}
                                                    {roadAccessData.map((raData: any, index: any) => (
                                                        <option key={raData.id} value={raData.id}>{raData.roadAccess}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={roadAccessValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Width Of Facing Road</label>
                                                <input
                                                    className={widthRoadValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                    type="number"
                                                    min={0}
                                                    placeholder="Width Road"
                                                    value={widthRoad}
                                                    onChange={e => { handleWidthRoadOnChange(e.target.value) }}
                                                />
                                                <FormFieldError errorMessage={widthRoadValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2" >
                                                <label className="mt-4">Yearly Income<sup className="required">*</sup></label>
                                                <select className={yearlyIncomeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                    value={yearlyIncomeIdStr} onChange={(e) => handleYearlyIncomeChange(e.target.value)}>
                                                    {showYearlyIncomeOption && <option value="">Select</option>}
                                                    {yearlyIncomeData.map((yiData: any, index: any) => (<option className="text-edit" key={yiData.id} value={yiData.id}>{yiData.yearlyIncome}</option>))}
                                                </select>
                                                <FormFieldError errorMessage={yearlyIncomeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Soil Type<sup className="required">*</sup></label>
                                                <select className={soilTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                    value={soilTypeId} onChange={(e) => handleSoilTypeChange(e.target.value)} >
                                                    {showSoilTypeOption && <option value="">Select</option>}
                                                    {soilTypeData.map((stData: any, index: any) => (
                                                        <option key={stData.id} value={stData.id}>{stData.label}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={soilTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Current Yield<sup className="required">*</sup></label>
                                                <select className={currentYieldValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                    value={currentYieldId} onChange={(e) => handleCurrentYieldChange(e.target.value)} >
                                                    {showCurrentYieldOption && <option value="">Select</option>}
                                                    {currentYieldData.map((cyData: any, index: any) => (
                                                        <option key={cyData.id} value={cyData.id}>{cyData.label}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={currentYieldValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Land Suitable For<sup className="required">*</sup></label>
                                                <select className={landSuitableForValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                    value={landSuitableForId} onChange={(e) => handleLandSuitableForChange(e.target.value)} >
                                                    {showLandSuitableForOption && <option value="">Select</option>}
                                                    {landSuitableForData.map((lsfData: any, index: any) => (
                                                        <option key={lsfData.id} value={lsfData.id}>{lsfData.label}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={landSuitableForValidationErrorMsg}></FormFieldError>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="mb-2">
                                                <label className="mt-4">Boundary<sup className="required">*</sup></label>
                                                <select className={boundaryValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                    value={boundaryId} onChange={(e) => handleBoundaryChange(e.target.value)} >
                                                    {showBoundaryOption && <option value="">Select</option>}
                                                    {boundaryData.map((bData: any, index: any) => (
                                                        <option key={bData.id} value={bData.id}>{bData.label}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={boundaryValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">ApprovedBy<sup className="required">*</sup></label>
                                                <select className={approvedByValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                    value={approvedById} onChange={(e) => handleApprovedByChange(e.target.value)} >
                                                    {showApprovedByOption && <option value="">Select</option>}
                                                    {approvedByData.map((abData: any, index: any) => (
                                                        <option key={abData.id} value={abData.id}>{abData.label}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={approvedByValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2 row gx-1">
                                                <div className="col-sm-6">
                                                    <label className="mt-4">Total Area</label>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className={plotAreaValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                        placeholder="Total Area"
                                                        value={plotArea}
                                                        onChange={e => { handlePlotAreaOnChange(e.target.value) }}
                                                    />
                                                    <FormFieldError errorMessage={plotAreaValidationErrorMsg}></FormFieldError>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="mt-4">Total Area Unit</label>
                                                    <select className={plotAreaTypeDataValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                        value={plotAreaIdStr} onChange={(e) => handlePlotAreaTypeChange(e.target.value)}>
                                                        {showPlotAreaTypeOption && <option value="">Select</option>}
                                                        {plotAreaTypeData?.map((paData: any, index: any) =>
                                                            (<option key={paData.id} value={paData.id}>{paData.plotAreaType}</option>))}
                                                    </select>
                                                    <FormFieldError errorMessage={plotAreaTypeDataValidationErrorMsg}></FormFieldError>
                                                </div>
                                            </div>
                                            <div className="mb-2 row gx-1">
                                                <label className="mt-4">Plot Dimension</label>
                                                <div className="col-sm-6">
                                                    <input
                                                        type="number"
                                                        className={plotLengthValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                        placeholder="Plot Length" min={0}
                                                        value={plotLength}
                                                        onChange={e => { handlePlotLengthOnChange(e.target.value) }}
                                                    />
                                                    <FormFieldError errorMessage={plotLengthValidationErrorMsg}></FormFieldError>
                                                </div>

                                                <div className="col-sm-6">
                                                    <input
                                                        type="number"
                                                        className={plotBreathValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                        placeholder="Plot Breadth"
                                                        min={0}
                                                        value={plotBreadth}
                                                        onChange={e => { handlePlotBreadthOnChange(e.target.value) }}
                                                    />
                                                    <FormFieldError errorMessage={plotBreathValidationErrorMsg}></FormFieldError>
                                                </div>
                                                <div className="col-sm-6 ">
                                                    <div className="mb-2">
                                                        <label className="mt-4">Gated Community<sup className="required">*</sup></label>
                                                        <div className="form-check">
                                                            <input
                                                                className={gatedCommunityValidationErrorMsg ? 'error-field  form-check-input ' : 'form-check-input'}
                                                                type="radio"
                                                                value="YES"
                                                                checked={gatedCommunity === 'YES' ? true : false}
                                                                onChange={(e) => handleOnGatedCommunityChange(e.target.value)}
                                                            />
                                                            <label className="form-check-label">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className={gatedCommunityValidationErrorMsg ? 'error-field  form-check-input ' : 'form-check-input'}
                                                                type="radio"
                                                                value="NO"
                                                                checked={gatedCommunity === 'NO' ? true : false}
                                                                onChange={(e) => handleOnGatedCommunityChange(e.target.value)}
                                                            />
                                                            <label className="form-check-label">
                                                                No
                                                            </label>
                                                        </div>
                                                        <FormFieldError errorMessage={gatedCommunityValidationErrorMsg}></FormFieldError>
                                                    </div>
                                                </div>
                                                <div className="mb-2">
                                                    <label className="mt-4">Description</label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={description}
                                                        onReady={(editor: any) => {
                                                            console.log("CKEditor5 React Component is ready to use!", editor);
                                                        }}
                                                        onChange={(event: any, editor: any) => { handleDescriptionOnChange(editor.getData()) }}
                                                    />
                                                    <FormFieldError errorMessage={descriptionValidationErrorMsg}></FormFieldError>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="my-4 d-flex me-5 justify-content-end">
                                    <button className="btn btn-primary me-3" onClick={previousPage}>Previous</button>
                                    <button disabled={processing}  onClick={updateLandInformationFormData} className="btn btn-warning me-3 " type="button">
                                        Update & Skip
                                        </button>
                                    {(processing)&& <Spinner animation="grow" variant="warning" />}
                                    <button onClick={submitLandInformationFormData} className="btn btn-success " type="button">
                                        Next
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditLandInformationPage;