import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import "./user-type-widget.css"

const UserTypeWidget = (props: any) => {

    const [selectedValue, setSelectedValue] = useState("choose role");

    const handleDropdownItemClick = (displayText: any, value: any) => {
        setSelectedValue(displayText);
        props.onUserTypeChangeListener(value);
    };

    return <>
        <div className="form-group">
            <label>Choose a role<sup className="required">*</sup></label>
            <div className="dropdown mt-2">
                <Dropdown>
                    <Dropdown.Toggle className="bg-light text-dark w-100 custom-dropdown-button d-flex justify-content-center align-items-center" id="dropdown-basic">
                        {selectedValue}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: '100%' }}>
                        <Dropdown.Item
                            onClick={() => handleDropdownItemClick(<span className="d-flex justify-content-center align-items-center" style={{ gap: '5px' }}>Regular <span className="free">(Free)</span></span>, 'ROLE_VIEW_USER')}
                            className="text-center"
                        >
                            Regular <span className="free">(Free)</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => handleDropdownItemClick(<span className="d-flex justify-content-center align-items-center" style={{ gap: '5px' }}>Professional <span className="paid">(Paid)</span></span>, 'ROLE_PRO_USER')}
                            className="text-center"
                        >
                            Professional<span className="paid"> (Paid)</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    </>
}

export default UserTypeWidget;