import HttpClientWrapper from "../../http-client-wrapper";
import { housingdPropertyLoanPayload,mortgagePropertyLoanPayload,personalPropertyLoanPayload,otherPropertyLoanPayload } from "./create-property-loan-payload";


class PropertyLoanApiService {
    
    [x: string]: any;

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    getPropertyLoanTemplatesData = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/propertyLoans/templates');
            console.log("PropertyApi getPropertyLoanTemplatesData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }
    savePropertyHousingLoan = async (payload: housingdPropertyLoanPayload) => {
        console.log("AuthApiService savePropertyLoan() start = " + JSON.stringify(payload));
        try {          
            let data: any = await this.httpClientWrapper.post('/v1/propertyLoans', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    savePropertMortgageLoan = async (payload: mortgagePropertyLoanPayload) => {
        console.log("AuthApiService savePropertyLoan() start = " + JSON.stringify(payload));
        try {          
            let data: any = await this.httpClientWrapper.post('/v1/propertyLoans', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    savePropertPersonalLoan = async (payload: personalPropertyLoanPayload) => {
        console.log("AuthApiService savePropertyLoan() start = " + JSON.stringify(payload));
        try {          
            let data: any = await this.httpClientWrapper.post('/v1/propertyLoans', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    savePropertOtherLoan = async (payload: otherPropertyLoanPayload) => {
        console.log("AuthApiService savePropertyLoan() start = " + JSON.stringify(payload));
        try {          
            let data: any = await this.httpClientWrapper.post('/v1/propertyLoans', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    getGeneratedCaptcha = async () => {
        try {
            let data:any = await this.httpClientWrapper.get('/v1/propertyLoans/captcha');
            console.log("PropertyApi getPropertyLoanTemplatesData() response data "+JSON.stringify(data));
            return data;
        } catch(error) {
            throw error;
        }
    }

    saveContactDetails = async (formData:FormData) => {
        try {
            let data: any = await this.httpClientWrapper.postFormData('/v1/contacts', formData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    generateOtp = async (mobileNo:any) => {
        try{
            let data:any = await this.httpClientWrapper.get('/v1/contacts/otp/'+ mobileNo);
            return data;
        } catch (error) {
            throw error;
        }
    }

    getPropertyLoanByMobileNo = async(mobileNo:any) =>{
        try{
            let data:any = await this.httpClientWrapper.get('/v1/propertyLoans/loan/'+ mobileNo);
            return data;
        } catch (error) {
            throw error;
        }

    }

}
export default PropertyLoanApiService;