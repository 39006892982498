import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./preview-video-dialog.css";


const PreviewVideoDialog = (props: any) => {

    const [videShow, setVideoShow] = useState(true);

    const handleVideoClose = () => {
        setVideoShow(false);
        props.onCloseCallback();
    };

    useEffect(() => {
        console.log("Hello preview dialog");
        console.log(props.url);
    }, []);

    return <>
        <Modal show={videShow} onHide={handleVideoClose}>
            <Modal.Header closeButton>
                <Modal.Title>Preview Videos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <object data={props.url} width="400" height="340"></object>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleVideoClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
export default PreviewVideoDialog;