const searchReducerInitialstate:any = {
    searchReq: []
}
const searchReducer = (state = searchReducerInitialstate, action: {type:any, payload:any}) => {
    switch(action.type) {
        case "PROPERTY_SEARCH":
            return {
                ...state,
                searchReq: action.payload,
              };
        
        default:
            return state;
    }
}
export default searchReducer;