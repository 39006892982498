import "./housing-loan.css";
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead/AsyncTypeahead";
import { useEffect, useState } from "react";
import PropertyLoanApiService from "../../../data/api/services/property-loan/property-loan-api-service";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import { housingdPropertyLoanPayload } from "../../../data/api/services/property-loan/create-property-loan-payload";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import Form from 'react-bootstrap/Form';
import { Spinner } from "react-bootstrap";
const HousingLoan = ({ loanType, captcha, refreshCaptcha, customerToggle }) => {

    const propertyLoanApiService = new PropertyLoanApiService();
    const propertyApiService = new PropertyApiService();
    let navigate: any = useNavigate();

    // const [customerToggle, setCustomerToggle] = useState('SALARIED');
    const [housingLoanData, setHousingLoanData] = useState<any>([]);
    const [housingLoanDataId, setHousingLoanDataId] = useState("");
    const [salaryRangeData, setSalaryRangeData] = useState<any>([]);
    const [salaryRangeDataId, setSalaryRangeDataId] = useState("");
    const [personJobExperienceData, setPersonJobExperienceData] = useState<any>([]);
    const [personJobExperienceDataId, setPersonJobExperienceDataId] = useState("");
    const [personIncomeData, setPersonIncomeData] = useState<any>([]);
    const [personIncomeDataId, setPersonIncomeDataId] = useState("");
    const [primarybankData, setPrimaryBankData] = useState<any>([]);
    const [primaryBankDataId, setPrimaryBankDataId] = useState("");
    const [taxReturnData, setTaxReturnData] = useState<any>([]);
    const [taxReturnDataId, setTaxReturnDataId] = useState("");
    const [ageData, setAgeData] = useState<any>([]);
    const [ageDataId, setAgeDataId] = useState("");
    const [countryData, setCountryData] = useState<any>([]);
    const [countryDataId, setCountryDataId] = useState("");
    const [locationData, setLocationData] = useState<any>([]);
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [whatsappNumber, setWhatsappNumber] = useState("");
    const [accountType, setAccountType] = useState("");
    const [enterCaptcha, setEnterCaptcha] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [processing, setProcessing] = useState(false);


    const [housingLoanValidationError, setHousingLoanValidationError] = useState(false);
    const [salaryRangeValidationError, setSalaryRangeValidationError] = useState(false);
    const [personJobExperienceValidationError, setPersonJobExperienceValidationError] = useState(false);
    const [personIncomeValidationError, setPersonIncomeValidationError] = useState(false);
    const [primarybankValidationError, setPrimarybankValidationError] = useState(false);
    const [taxReturnValidationError, setTaxReturnValidationError] = useState(false);
    const [ageValidationError, setAgeValidationError] = useState(false);
    const [nameValidationError, setNameValidationError] = useState(false);
    const [emailValidationError, setEmailValidationError] = useState(false);
    const [whatsappNumberValidationError, setWhatsappNumberValidationError] = useState(false);
    const [countryCodeValidationError, setCountryValidationError] = useState(false);
    const [accountTypeValidationError, setAccountTypeValidationError] = useState(true);
    const [enterCaptchaValidationError, setEnterCaptchaValidationError] = useState(false);
    const [locationValidationError, setLocationValidationNameError] = useState(false);



    const saveHousingPropertyLoan = async () => {
        try {
            let payload: housingdPropertyLoanPayload = {
                loanType: loanType,
                personType: customerToggle,
                housingLoanTypeId: housingLoanDataId,
                salaryRangePerMonthId: salaryRangeDataId,
                personJobExperienceId: personJobExperienceDataId,
                personIncomeId: personIncomeDataId,
                taxReturnId: taxReturnDataId,
                primaryBankId: primaryBankDataId,
                name: name,
                ageId: ageDataId,
                countryId: countryDataId,
                whatsAppNumber: whatsappNumber,
                email: email,
                accountType: accountType,
                captcha: captcha,
                enteredCaptcha: enterCaptcha,
                locationId: selectedLocation[0].id,
                mortgageLoanTypeId: 0,
                personalLoanTypeId: 0
            };
            setProcessing(true);
            await propertyLoanApiService.savePropertyHousingLoan(payload);
            toast.success('Successfully Saved Property Loan', { containerId: 'TR' });
            navigate('/');
        } catch (error) {
            setProcessing(false);
            console.log(error)
        }
    }

    const submitContactDetailsFormData = async () => {
        try {
            let formData: FormData = new FormData();
            formData.append('firstName', name)
            formData.append('address', "")
            formData.append('companyName', "")
            formData.append('latitude', "")
            formData.append('longitude', "")
            formData.append('lastName', "")
            formData.append('whatsAppNumber', whatsappNumber)
            formData.append('email', email)
            formData.append('genderType', "")
            formData.append('professionIdStr', "")
            formData.append('ageIdStr', "")
            formData.append('locationIdStr', selectedLocation[0].id)
            formData.append('countryCode', "")
            formData.append('profileImageURL', "")
            formData.append('contactEntryTypeCode', "FROM_HOUSING_LOAN")
            await propertyLoanApiService.saveContactDetails(formData);
        } catch (error: any) {

        }
    }


    const handleHousingLoanChange = (hlId: any) => {
        setHousingLoanDataId(hlId);
        setHousingLoanValidationError(false);
    };
    const handleSalaryRangeChange = (srId: any) => {
        setSalaryRangeDataId(srId);
        setSalaryRangeValidationError(false);
    };
    const handlePersonJobChange = (pjId: any) => {
        setPersonJobExperienceDataId(pjId);
        setPersonJobExperienceValidationError(false);
    };
    const handlePersonIncomeChange = (piId: any) => {
        setPersonIncomeDataId(piId);
        setPersonIncomeValidationError(false);
    };
    const handlePrimaryBankChange = (pbId: any) => {
        setPrimaryBankDataId(pbId);
        setPrimarybankValidationError(false);
    };
    const handleTaxReturnChange = (trId: any) => {
        setTaxReturnDataId(trId);
        setTaxReturnValidationError(false);
    };
    const handleAgeDataChange = (trId: any) => {
        setAgeDataId(trId);
        setAgeValidationError(false);
    };
    const handleCountryDataChange = (coId: any) => {
        setCountryDataId(coId);
        setCountryValidationError(false);
    };
    // const handleOnAccountTypeChange = (accountType: any) => {
    //     setAccountType(accountType);
    //     setAccountTypeValidationError(false);
    // };
    const handleOnAccountTypeChange = (selectedAccountType:any) => {
        if (accountType === selectedAccountType) {
          setAccountType('');
        } else {
          setAccountType(selectedAccountType);
        }
      };
        const handleNameChange = (value: any) => {
            setName(value);
            setNameValidationError(false);
        };
        const handleEmailChange = (value: any) => {
            setEmail(value);
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setIsValidEmail(emailPattern.test(value));
            setEmailValidationError(false);
        };
        const handleWhatsappNumberChange = (value: any) => {
            const formattedValue = value.replace(/\D/g, '').slice(0, 10); 
            setWhatsappNumber(formattedValue);
            setWhatsappNumberValidationError(false);
        };
        const handleEnterCaptchaChange = (value: any) => {
            setEnterCaptcha(value);
            setEnterCaptchaValidationError(false);
        };

        const getTemplatesData = async () => {
            try {
                let res = await propertyLoanApiService.getPropertyLoanTemplatesData();
                console.log(res);
                setHousingLoanData(res.housingLoanData);
                setPersonJobExperienceData(res.personJobExperienceData);
                setPersonIncomeData(res.personIncomeData);
                setPrimaryBankData(res.primaryBankData);
                setTaxReturnData(res.taxReturnData);
                setAgeData(res.ageData);
                setCountryData(res.countryData);
                setSalaryRangeData(res.salaryRangePerMonthData);
                // setAccountType(res.accountTypes);
            } catch (error) {
                console.log("LoginPage Error");
                console.log(error);
            }
        }
        const onChangeRefreshCaptcha = () => {
            refreshCaptcha();
        };
        const searchLocation = async (q: any) => {
            setLocationSearchLoading(true);
            let data = await propertyApiService.searchLocation(q);
            console.log(q);
            setLocationSearchLoading(false);
            formatLocationData(data);
        };

        const formatLocationData = (data: any) => {
            formatLocationDataResponse(data);
            setLocationData(data);
        }

        const formatLocationDataResponse = (data: any) => {
            for (let i = 0; i < data.length; i++) {
                data[i].nameLabel = data[i].name + ' | ' + data[i].pinCode;
            }
        }

        const onLocationSelected = (e: any) => {
            if (e.length > 0) {
                console.log(e[0]);
                setSelectedLocation(e);
            }
            setLocationValidationNameError(false);
        }

        const validateForm = () => {
            let isFormValid = true;

            if (!housingLoanDataId) {
                setHousingLoanValidationError(true);
                isFormValid = false;
            }

            if (customerToggle === 'SALARIED') {

                if (!salaryRangeDataId) {
                    setSalaryRangeValidationError(true);
                    isFormValid = false;
                }

                if (!personJobExperienceDataId) {
                    setPersonJobExperienceValidationError(true);
                    isFormValid = false;
                }
            } else if (customerToggle === 'SELF_EMPLOYED') {

                if (!personIncomeDataId) {
                    setPersonIncomeValidationError(true);
                    isFormValid = false;
                }
            }

            if (!primaryBankDataId) {
                setPrimarybankValidationError(true);
                isFormValid = false;
            }

            if (!taxReturnDataId) {
                setTaxReturnValidationError(true);
                isFormValid = false;
            }

            if (!ageDataId) {
                setAgeValidationError(true);
                isFormValid = false;
            }
            if (!countryDataId) {
                setCountryValidationError(true);
                isFormValid = false;
            }

            if (!name) {
                setNameValidationError(true);
                isFormValid = false;
            }

            if (!email) {
                setEmailValidationError(true);
                isFormValid = false;
            }

            if (!whatsappNumber) {
                setWhatsappNumberValidationError(true);
                isFormValid = false;
            }

            // if (accountType !== 'NRI' && accountType !== 'NRE') {
            //     setAccountTypeValidationError(true);
            //     isFormValid = false;
            // }

            if (selectedLocation.length === 0) {
                setLocationValidationNameError(true);
                isFormValid = false;
            }

            if (!enterCaptcha) {
                setEnterCaptchaValidationError(true);
                isFormValid = false;
            }

            if (enterCaptcha && enterCaptcha != captcha) {
                toast.error("Incorrect Captcha", { containerId: 'TR' });
                return;
            }

            return isFormValid;
        }

        const handleSubmit = () => {
            if (validateForm()) {
                submitContactDetailsFormData();
                saveHousingPropertyLoan();
            }
        }

        useEffect(() => {
            getTemplatesData();
        }, []);

        return <>
            <div className="container ">

                {customerToggle == 'SALARIED' &&
                    <section> <div className="row ">
                        <div className="col-sm-6 d-flex align-items-center mt-2">
                            <label htmlFor="">Housing Type Loan</label>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <select className={`input-value form-control ${housingLoanValidationError ? 'is-invalid' : ''}`}
                                value={housingLoanDataId} onChange={(e) => handleHousingLoanChange(e.target.value)}>
                                <option value="">select</option>
                                {housingLoanData.map((hlData: any, index: any) => (
                                    <option key={hlData.id} value={hlData.id}>{hlData.label}</option>
                                ))}
                            </select>
                            {housingLoanValidationError && <div className="invalid-feedback">Housing Type Loan required.</div>}
                        </div>

                        <div className="col-sm-6 d-flex align-items-center my-3">
                            <label htmlFor="">Salary Range per Month</label>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <select className={`input-value form-control ${salaryRangeValidationError ? 'is-invalid' : ''}`}
                                value={salaryRangeDataId} onChange={(e) => handleSalaryRangeChange(e.target.value)}>
                                <option value="">select</option>
                                {salaryRangeData.map((srData: any, index: any) => (
                                    <option key={srData.id} value={srData.id}>{srData.label}</option>
                                ))}
                            </select>
                            {salaryRangeValidationError && <div className="invalid-feedback">Salary Range per Month required.</div>}
                        </div>

                        <div className="col-sm-6 d-flex align-items-center mt-4">
                            <label htmlFor="">Year of Experience in Current Organization</label>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <select className={`input-value form-control ${personJobExperienceValidationError ? 'is-invalid' : ''}`}
                                value={personJobExperienceDataId} onChange={(e) => handlePersonJobChange(e.target.value)}>
                                <option value="">select</option>
                                {personJobExperienceData.map((pjData: any, index: any) => (
                                    <option key={pjData.id} value={pjData.id}>{pjData.label}</option>
                                ))}
                            </select>
                            {personJobExperienceValidationError && <div className="invalid-feedback">Year of Experience in Current Organization required.</div>}
                        </div>

                        <div className="col-sm-6 d-flex align-items-center mt-4">
                            <label htmlFor="">File Income Tax Return</label>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <select className={`input-value form-control ${taxReturnValidationError ? 'is-invalid' : ''}`}
                                value={taxReturnDataId} onChange={(e) => handleTaxReturnChange(e.target.value)}>
                                <option value="">select</option>
                                {taxReturnData.map((trData: any, index: any) => (
                                    <option key={trData.id} value={trData.id}>{trData.label}</option>
                                ))}
                            </select>
                            {taxReturnValidationError && <div className="invalid-feedback">File Income Tax Return required.</div>}
                        </div>

                        <div className="col-sm-6 d-flex align-items-center mt-4">
                            <label htmlFor="">Primary Bank</label>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <select className={`input-value form-control ${primarybankValidationError ? 'is-invalid' : ''}`}
                                value={primaryBankDataId} onChange={(e) => handlePrimaryBankChange(e.target.value)}>
                                <option value="">select</option>
                                {primarybankData.map((pbData: any, index: any) => (
                                    <option key={pbData.id} value={pbData.id}>{pbData.label}</option>
                                ))}
                            </select>
                            {primarybankValidationError && <div className="invalid-feedback">Primary Bank required.</div>}
                        </div>

                    </div>
                        <div className="row">
                            <div className="col-sm-6 my-4">
                                <div className="row">
                                    <div className="col-sm-3 d-flex align-items-center ">
                                        <label htmlFor="">Name</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input className={`input-value form-control ${nameValidationError ? 'is-invalid' : ''}`}
                                            type="text"
                                            placeholder="Name"
                                            value={name}
                                            onChange={e => { handleNameChange(e.target.value) }} />
                                        {nameValidationError && <div className="invalid-feedback">Name required.</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6  my-4">
                                <div className="row">
                                    <div className="col-sm-3 d-flex align-items-center  ">
                                        <label htmlFor="">Email</label>
                                    </div>
                                    <div className="col-sm-9 ">
                                        <input className={`input-value form-control ${emailValidationError ? 'is-invalid' : ''}`}
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => { handleEmailChange(e.target.value) }} />
                                                  {!isValidEmail && <p>Please enter a valid email address.</p>}

                                        {emailValidationError && <div className="invalid-feedback">Email required.</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 my-4">
                                <div className="row">
                                    <div className="col-sm-3 d-flex align-items-center">
                                        <label htmlFor="">Whatsapp No.</label>
                                    </div>
                                    <div className="col-sm-3">
                                        <select className={`input-value form-control ${countryCodeValidationError ? 'is-invalid' : ''}`}
                                            value={countryDataId} onChange={(e) => handleCountryDataChange(e.target.value)}>
                                            <option value="">select</option>
                                            {countryData.map((cData: any, index: any) => (
                                                <option key={cData.id} value={cData.id}>{cData.countryCode}</option>
                                            ))}
                                        </select>
                                        {countryCodeValidationError && <div className="invalid-feedback">Code required.</div>}
                                    </div>
                                    <div className="col-sm-6">
                                        <input className={`input-value form-control ${whatsappNumberValidationError ? 'is-invalid' : ''}`}
                                            type="number"
                                            placeholder="Whatsapp No."
                                            value={whatsappNumber}
                                            maxLength={10}
                                            onChange={e => { handleWhatsappNumberChange(e.target.value) }} />
                                        {whatsappNumberValidationError && <div className="invalid-feedback">Whatsapp No. required.</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 my-4">
                                <div className="row">
                                    <div className="col-sm-3  d-flex align-items-center">
                                        <label htmlFor="">Age</label>
                                    </div>
                                    <div className="col-sm-9 ">
                                        <select className={`input-value form-control ${ageValidationError ? 'is-invalid' : ''}`}
                                            value={ageDataId} onChange={(e) => handleAgeDataChange(e.target.value)}>
                                            <option value="">select</option>
                                            {ageData.map((aData: any, index: any) => (
                                                <option key={aData.id} value={aData.id}>{aData.age}</option>
                                            ))}
                                        </select>
                                        {ageValidationError && <div className="invalid-feedback">Age required.</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 d-flex align-items-center my-4">
                                <div className="row">
                                    <div className="col-sm-3 ">
                                        <label htmlFor="">Location</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="input-with-icon">
                                            <AsyncTypeahead
                                                className={locationValidationError ? 'error-field ' : ''}
                                                id="basic-typeahead-single"
                                                labelKey="nameLabel"
                                                onSearch={(e) => searchLocation(e)}
                                                options={locationData}
                                                onChange={onLocationSelected}
                                                useCache={false}
                                                placeholder="Location"
                                                isLoading={locationSearchLoading} />
                                            {locationValidationError && <div className="invalid-feedback">Location required.</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6  my-4  ">
                                <div className="row">
                                    <div className="col-sm-3 form-check">
                                        {/* <input className={`form-check-input ${accountTypeValidationError ? 'is-invalid' : ''}`}
                                        type="radio"
                                        value="NRI"
                                        id="NRI"
                                        checked={accountType === 'NRI' ? true : false}
                                        onChange={(e) => handleOnAccountTypeChange(e.target.value)}
                                    /> */}
                                        <Form.Check
                                            type="checkbox"
                                            value="NRI"
                                            id="NRI"
                                            checked={accountType === 'NRI'}
                                            onChange={(e) => handleOnAccountTypeChange(e.target.value)}
                                            aria-label="option 1"
                                        />
                                        <label htmlFor="NRI" className="form-check-label">
                                            NRI
                                        </label>
                                    </div>
                                  
                                    <div className=" col-sm-3 form-check">
                                        {/* <input className={`form-check-input ${accountTypeValidationError ? 'is-invalid' : ''}`}
                                        type="radio"
                                        value="NRE"
                                        id="NRE"
                                        checked={accountType === 'NRE' ? true : false}
                                        onChange={(e) => handleOnAccountTypeChange(e.target.value)}
                                    /> */}
                                        <Form.Check
                                            value="NRE"
                                            id="NRE"
                                            checked={accountType === 'NRE' ? true : false}
                                            onChange={(e) => handleOnAccountTypeChange(e.target.value)}
                                            aria-label="option 1" />
                                        <label htmlFor="NRE" className="form-check-label">
                                            NRE
                                        </label>
                                    </div>
                                    {accountTypeValidationError && <div className="invalid-feedback"></div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 mx-auto my-4">
                                <div className="row">
                                    <div className="col-sm-5"></div>
                                    <div className="col-sm-6 text-center mb-3 captcha-container">
                                        <label className="captcha-label">{captcha}</label>
                                        <button className="refresh-button " onClick={onChangeRefreshCaptcha}>
                                            <RefreshIcon />
                                        </button>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-5 d-flex align-items-center ">
                                                <label htmlFor="">Enter Captcha</label>
                                            </div>
                                            <div className="col-sm-7">
                                                <input className={`input-value form-control ${enterCaptchaValidationError ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    placeholder="Enter Captcha"
                                                    value={enterCaptcha}
                                                    onChange={e => { handleEnterCaptchaChange(e.target.value) }} />
                                                {enterCaptchaValidationError && <div className="invalid-feedback">Enter Captcha required.</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 text-center top">
                            <button disabled={processing}  className="btn btn-success" onClick={handleSubmit} type="button">Submit</button>
                                {(processing)&& <Spinner animation="grow" variant="warning" />}                            </div>
                        </div>
                    </section>}
                {customerToggle == 'SELF_EMPLOYED' &&
                    <section> <div className="row ">
                        <div className="col-sm-6 d-flex align-items-center mt-2">
                            <label htmlFor="">Housing Type Loan</label>
                        </div>
                        <div className="col-sm-6 mt-2">
                            <select className={`input-value form-control ${housingLoanValidationError ? 'is-invalid' : ''}`}
                                value={housingLoanDataId} onChange={(e) => handleHousingLoanChange(e.target.value)}>
                                <option value="">select</option>
                                {housingLoanData.map((hlData: any, index: any) => (
                                    <option key={hlData.id} value={hlData.id}>{hlData.label}</option>
                                ))}
                            </select>
                            {housingLoanValidationError && <div className="invalid-feedback">Housing Type Loan required.</div>}
                        </div>

                        <div className="col-sm-6 d-flex align-items-center my-3">
                            <label htmlFor="">Income</label>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <select className={`input-value form-control ${personIncomeValidationError ? 'is-invalid' : ''}`}
                                value={personIncomeDataId} onChange={(e) => handlePersonIncomeChange(e.target.value)}>
                                <option value="">select</option>
                                {personIncomeData.map((piData: any, index: any) => (
                                    <option key={piData.id} value={piData.id}>{piData.label}</option>
                                ))}
                            </select>
                            {personIncomeValidationError && <div className="invalid-feedback">Income required.</div>}
                        </div>

                        <div className="col-sm-6 d-flex align-items-center mt-4">
                            <label htmlFor="">File Income Tax Return</label>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <select className={`input-value form-control ${taxReturnValidationError ? 'is-invalid' : ''}`}
                                value={taxReturnDataId} onChange={(e) => handleTaxReturnChange(e.target.value)}>
                                <option value="">select</option>
                                {taxReturnData.map((trData: any, index: any) => (
                                    <option key={trData.id} value={trData.id}>{trData.label}</option>
                                ))}
                            </select>
                            {taxReturnValidationError && <div className="invalid-feedback">File Income Tax Return required.</div>}
                        </div>

                        <div className="col-sm-6 d-flex align-items-center mt-4">
                            <label htmlFor="">Primary bank</label>
                        </div>
                        <div className="col-sm-6 mt-4">
                            <select className={`input-value form-control ${primarybankValidationError ? 'is-invalid' : ''}`}
                                value={primaryBankDataId} onChange={(e) => handlePrimaryBankChange(e.target.value)}>
                                <option value="">select</option>
                                {primarybankData.map((pbData: any, index: any) => (
                                    <option key={pbData.id} value={pbData.id}>{pbData.label}</option>
                                ))}
                            </select>
                            {primarybankValidationError && <div className="invalid-feedback">Primary Bank required.</div>}
                        </div>

                    </div>
                        <div className="row">
                            <div className="col-sm-6 my-4">
                                <div className="row">
                                    <div className="col-sm-3 d-flex align-items-center ">
                                        <label htmlFor="">Name</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <input className={`input-value form-control ${nameValidationError ? 'is-invalid' : ''}`}
                                            type="text"
                                            placeholder="Name"
                                            value={name}
                                            onChange={e => { handleNameChange(e.target.value) }} />
                                        {nameValidationError && <div className="invalid-feedback">Name required.</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6  my-4">
                                <div className="row">
                                    <div className="col-sm-3 d-flex align-items-center  ">
                                        <label htmlFor="">Email</label>
                                    </div>
                                    <div className="col-sm-9 ">
                                        <input className={`input-value form-control ${emailValidationError ? 'is-invalid' : ''}`}
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => { handleEmailChange(e.target.value) }} />
                                                  {!isValidEmail && <p>Please enter a valid email address.</p>}

                                        {emailValidationError && <div className="invalid-feedback">Email required.</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 my-4">
                                <div className="row">
                                    <div className="col-sm-3 d-flex align-items-center">
                                        <label htmlFor="">Whatsapp No.</label>
                                    </div>
                                    <div className="col-sm-3">
                                        <select className={`input-value form-control ${countryCodeValidationError ? 'is-invalid' : ''}`}
                                            value={countryDataId} onChange={(e) => handleCountryDataChange(e.target.value)}>
                                            <option value="">select</option>
                                            {countryData.map((cData: any, index: any) => (
                                                <option key={cData.id} value={cData.id}>{cData.countryCode}</option>
                                            ))}
                                        </select>
                                        {countryCodeValidationError && <div className="invalid-feedback">Code required.</div>}

                                    </div>
                                    <div className="col-sm-6">
                                        <input className={`input-value form-control ${whatsappNumberValidationError ? 'is-invalid' : ''}`}
                                            type="number"
                                            placeholder="Whatsapp No."
                                            value={whatsappNumber}
                                            maxLength={10}
                                            onChange={e => { handleWhatsappNumberChange(e.target.value) }} />
                                        {whatsappNumberValidationError && <div className="invalid-feedback">Whatsapp No. required.</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 my-4">
                                <div className="row">
                                    <div className="col-sm-3  d-flex align-items-center">
                                        <label htmlFor="">Age</label>
                                    </div>
                                    <div className="col-sm-9 ">
                                        <select className={`input-value form-control ${ageValidationError ? 'is-invalid' : ''}`}
                                            value={ageDataId} onChange={(e) => handleAgeDataChange(e.target.value)}>
                                            <option value="">select</option>
                                            {ageData.map((aData: any, index: any) => (
                                                <option key={aData.id} value={aData.id}>{aData.age}</option>
                                            ))}
                                        </select>
                                        {ageValidationError && <div className="invalid-feedback">Age required.</div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 d-flex align-items-center my-4">
                                <div className="row">
                                    <div className="col-sm-3 ">
                                        <label htmlFor="">Location</label>
                                    </div>
                                    <div className="col-sm-9">
                                        <div className="input-with-icon">
                                            <AsyncTypeahead
                                                className={locationValidationError ? 'error-field ' : ''}
                                                id="basic-typeahead-single"
                                                labelKey="nameLabel"
                                                onSearch={(e) => searchLocation(e)}
                                                options={locationData}
                                                onChange={onLocationSelected}
                                                useCache={false}
                                                placeholder="Location"
                                                isLoading={locationSearchLoading} />
                                            {locationValidationError && <div className="invalid-feedback">Location required.</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6  my-4  ">
                                <div className="row">
                                    <div className="col-sm-3 form-check">
                                    <Form.Check
                                            type="checkbox"
                                            value="NRI"
                                            id="NRI"
                                            checked={accountType === 'NRI'}
                                            onChange={(e) => handleOnAccountTypeChange(e.target.value)}
                                            aria-label="option 1"
                                        />
                                        <label htmlFor="NRI" className="form-check-label">
                                            NRI
                                        </label>
                                    </div>
                                    <div className=" col-sm-3 form-check">
                                    <Form.Check
                                            value="NRE"
                                            id="NRE"
                                            checked={accountType === 'NRE' ? true : false}
                                            onChange={(e) => handleOnAccountTypeChange(e.target.value)}
                                            aria-label="option 1" />
                                        <label htmlFor="NRE" className="form-check-label">
                                            NRE
                                        </label>
                                    </div>
                                    {accountTypeValidationError && <div className="invalid-feedback"></div>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 mx-auto my-4">
                                <div className="row">
                                    <div className="col-sm-5"></div>
                                    <div className="col-sm-6 text-center mb-3 captcha-container">
                                        <label className="captcha-label">{captcha}</label>
                                        <button className="refresh-button " onClick={onChangeRefreshCaptcha}>
                                            <RefreshIcon />
                                        </button>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-5 d-flex align-items-center ">
                                                <label htmlFor="">Enter Captcha</label>
                                            </div>
                                            <div className="col-sm-7">
                                                <input className={`input-value form-control ${enterCaptchaValidationError ? 'is-invalid' : ''}`}
                                                    type="text"
                                                    placeholder="Enter Captcha"
                                                    value={enterCaptcha}
                                                    onChange={e => { handleEnterCaptchaChange(e.target.value) }} />
                                                {enterCaptchaValidationError && <div className="invalid-feedback">Enter Captcha required.</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 text-center top">
                                <button disabled={processing}  className="btn btn-success" onClick={handleSubmit} type="button">Submit</button>
                                {(processing)&& <Spinner animation="grow" variant="warning" />}
                            </div>
                        </div>
                    </section>}
            </div>
        </>

    };
    export default HousingLoan;
