import { useEffect, useRef, useState, useCallback } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './add-professionalpage-contact-info.css';
import { FaPhone } from "react-icons/fa";
import Select from 'react-select';
import ProfessionalPageApiService from "../../../../data/api/services/professional-page/professional-page-api-service";
import FormFieldError from "../../../../components/form-field-error/form-field-error";
import { Button, Col, Modal, Nav, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ValidationError from "../../../../common/validation-error";
import { confirmAlert } from "react-confirm-alert";
import ProfessionalPageContactInfoImage from '../../../../assets/professional-page-5.jpeg';
import Cropper, { Point } from "react-easy-crop";


const AddProfessionalPageContavtInfo = ({ professionalPageFormData, onPreviousCallback }) => {
    const tabNumber: number = 5;
    const [showNext, setShowNext] = useState(false);
    const professionalPageApiservice = new ProfessionalPageApiService();
    const [countryOptions, setcountryOptions] = useState([]);
    const [nickNameValidationErrorMsg, setNickValidationNameErrorMsg] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [showExtraFilters, setShowExtraFilters] = useState(false);
    const [zoom, setZoom] = useState(1)
    const [zoomCover, setZoomCover] = useState(1)
    const [croppedImage, setCroppedImage] = useState(null);


    const [mobileNumber, setMobileNumber] = useState('');
    const [website, setWebsite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youTube, setYouTube] = useState('');
    const [pinterest, setPinterest] = useState('');
    const [profileImage, setProfileImage] = useState<any>();
    const profileImageInput: any = useRef(null);
    const [coverImage, setCoverImage] = useState<any>();
    const coverImageInput: any = useRef(null);
    const [cropSize, setCropSize] = useState({ height: 186, width: 186 });
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [cropCover, setCropCover] = useState({ x: 0, y: 0 });


    const [completedCrop, setCompletedCrop] = useState<Point>({
        x: 0,
        y: 0,

    });
    const [completedCoverCrop, setCompletedCoverCrop] = useState<Point>({
        x: 0,
        y: 0,

    });

    const [showCroppedImage, setShowCroppedImage] = useState(true);
    const [showCoverCroppedImage, setShowCoverCroppedImage] = useState(true);
    const imageRef = useRef(null);
    const [croppedImageSrc, setCroppedImageSrc] = useState<any>();
    const [croppedCoverImageSrc, setCroppedCoverImageSrc] = useState<any>();


    const [cropConfirmed, setCropConfirmed] = useState(false);
    const [cropCoverConfirmed, setCropCoverConfirmed] = useState(false);


    const [countryCodeValidationErrorMsg, setCountryCodeValidationErrorMsg] = useState(null);
    const [mobileNumberValidationErrorMsg, setMobileNumberValidationErrorMsg] = useState(null);
    const [profileImageValidationErrorMsg, setProfileImageValidationErrorMsg] = useState(null);
    const [coverImageValidationErrorMsg, setCoverImageValidationErrorMsg] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showProfileImageModal, setShowProfileImageModal] = useState(false);
    const handleCloseProfileImageModal = () => {
        setShowProfileImageModal(false);
    };
    const handleImageCrop = async () => {
        if (completedCrop && profileImage) {
            try {
                const originalAspectRatio = profileImage.width / profileImage.height;
                const croppedAspectRatio = completedCrop.width / completedCrop.height;
                 const desiredAspectRatio = 1; 
                  const aspectRatioTolerance = 1
                   setCropConfirmed(true);
                   setShowProfileImageModal(false);
                
            } catch (error) {
                console.error('Error cropping image:', error.message);
                setShowProfileImageModal(false);
            }
        } else {
            console.error('Error: Unable to crop image. Missing data.');
            setShowProfileImageModal(false);
        }
    };
    const handleCoverImageCrop = async () => {
        if (completedCoverCrop && coverImage) {
            try {
                const originalAspectRatio = coverImage.width / coverImage.height;
                const croppedAspectRatio = completedCoverCrop.width / completedCoverCrop.height;
                 const desiredAspectRatio = 1; 
                  const aspectRatioTolerance = 1
                   setCropCoverConfirmed(true);
                   setShowModal(false);
                
            } catch (error) {
                console.error('Error cropping image:', error.message);
                setShowModal(false);
            }
        } else {
            console.error('Error: Unable to crop image. Missing data.');
            setShowModal(false);
        }
    };

    let navigate: any = useNavigate();
    const handleImageSelection = () => {
        profileImageInput.current.click();
    };

    // Function to hide the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleCountryChange = (cc: any) => {
        setSelectedCountry(cc);
        console.log(cc);
    };


    const handleCoverImageSelection = () => {
        coverImageInput.current.click();
    };
    const handleMobileNumberOnChange = (mn: any) => {
        setMobileNumber(mn);
    };

    const handleWebsiteOnChange = (ws: any) => {
        setWebsite(ws);
    };

    const handleFacebookOnChange = (fb: any) => {
        setFacebook(fb);
    };

    const handleTwitterOnChange = (tw: any) => {
        setTwitter(tw);
    };

    const handleInstagramOnChange = (ig: any) => {
        setInstagram(ig);
    };

    const handleYouTubeOnChange = (yt: any) => {
        setYouTube(yt);
    };

    const handlePinterestOnChange = (pt: any) => {
        setPinterest(pt);
    };
    const handleCropChange = (newCrop) => {
        setCrop(newCrop);
    };

    const handleCropComplete = (crop) => {
        setCompletedCrop(crop);
    };

    // const onCropComplete = (croppedArea, croppedAreaPixels) => {
    //     handleCropComplete(croppedArea);
    //     console.log(croppedArea, croppedAreaPixels)
    // }
    const onCropComplete = async (_, croppedAreaPixels) => {
        try {
          const croppedImageBase64 = await getCroppedImg(
            profileImage.imagePath, // Original image path
            croppedAreaPixels,
            1 // Output image quality, you can adjust this value
          );
          setCroppedImageSrc(croppedImageBase64);
        } catch (e) {
          console.error('Error creating cropped image:', e);
        }
      };
      const onCropCoverComplete = async (_, croppedPixels) => {
        try {
          const croppedImageBase = await getCroppedCoverImg(
            coverImage.imagePath, // Original image path
            croppedPixels,
            1 // Output image quality, you can adjust this value
          );
          setCroppedCoverImageSrc(croppedImageBase);
        } catch (e) {
          console.error('Error creating cropped image:', e);
        }
      };
    type Point = {
        x: number;
        y: number;
        width?: number; // Add the width property
        height?: number; // Add the height property
    };

   


    const getCroppedCoverImg = (imagePath, cropCover, quality = 0.92) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imagePath;

            image.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = cropCover.width;
                    canvas.height = cropCover.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(
                        image,
                        cropCover.x, // X position on the source image
                        cropCover.y, // Y position on the source image
                        cropCover.width, // Width on the source image
                        cropCover.height, // Height on the source image
                        0, // X position on the canvas
                        0, // Y position on the canvas
                        cropCover.width, // Width on the canvas
                        cropCover.height // Height on the canvas
                    );

                    resolve(canvas.toDataURL('image/jpeg', quality));
                } catch (error) {
                    console.error('Error in getCroppedImg:', error);
                    reject(error);
                }
            };

            image.onerror = (error) => {
                console.error('Image failed to load in getCroppedImg:', error);
                reject(error);
            };
        });
    };
    const getCroppedImg = (imagePath, crop, quality = 0.92) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imagePath;

            image.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = crop.width;
                    canvas.height = crop.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(
                        image,
                        crop.x, // X position on the source image
                        crop.y, // Y position on the source image
                        crop.width, // Width on the source image
                        crop.height, // Height on the source image
                        0, // X position on the canvas
                        0, // Y position on the canvas
                        crop.width, // Width on the canvas
                        crop.height // Height on the canvas
                    );

                    resolve(canvas.toDataURL('image/jpeg', quality));
                } catch (error) {
                    console.error('Error in getCroppedImg:', error);
                    reject(error);
                }
            };

            image.onerror = (error) => {
                console.error('Image failed to load in getCroppedImg:', error);
                reject(error);
            };
        });
    };

    const handleCropConfirm = async () => {
        try {
            const croppedImage = await getCroppedImg(profileImage.imagePath, crop, 0.92);
            setCroppedImageSrc(croppedImage);
            setCropConfirmed(true);
        } catch (error) {
            console.error('Error while cropping image:', error);

        }
    };
    const validateContactInfoFormData = (): void => {
        let errors: any[] = [];

        if (!selectedCountry) {
            let error: any = {};
            error.validationCode = "countryIdStr";
            error.message = 'Country Code required';
            errors.push(error);
        }
        if (!mobileNumber) {
            let error: any = {};
            error.validationCode = "mobile";
            error.message = 'Mobile Number required';
            errors.push(error);
        }
        if (!profileImage) {
            let error: any = {};
            error.validationCode = "profileImage";
            error.message = 'Profile Image required';
            errors.push(error);
        }
        if (!coverImage) {
            let error: any = {};
            error.validationCode = "coverImage";
            error.message = 'Cover Image required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }
   
    const submitContactInfoFormData = async () => {
        try {
            validateAndSaveContactInfoFormData();
            let data = await professionalPageApiservice.saveProfessionalPage(professionalPageFormData);
            navigate('/')
            console.log(data)
            toast.success('Successfully Saved Your ProfessionalPage Id as ' + data.id + '.', { containerId: 'TR' });
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const validateAndSaveContactInfoFormData = () => {
        try {
            validateContactInfoFormData();
            if (professionalPageFormData.has('countryIdStr')) {
                professionalPageFormData.delete('countryIdStr');
            }
            professionalPageFormData.append('countryIdStr', selectedCountry.countryCode);
            if (professionalPageFormData.has('mobile')) {
                professionalPageFormData.delete('mobile');
            }
            professionalPageFormData.append('mobile', mobileNumber);
            if (professionalPageFormData.has('website')) {
                professionalPageFormData.delete('website');
            }
            professionalPageFormData.append('website', website);
            if (professionalPageFormData.has("profileImage")) {
                professionalPageFormData.delete('profileImage');
              }
              
              // Provide a filename for the File object
              const filename = 'profileImage.jpg';
              const file = dataURItoFile(croppedImageSrc, 'image/jpeg', filename);
              
              // Append the file to FormData
              professionalPageFormData.append('profileImage', file);
              if (professionalPageFormData.has("coverImage")) {
                professionalPageFormData.delete('coverImage');
              }
              
              // Provide a filename for the File object
              const filenames = 'coverImage.jpg';
              const files = dataURItoFiles(croppedCoverImageSrc, 'image/jpeg', filenames);
              
              // Append the file to FormData
              professionalPageFormData.append('coverImage', files);
            if (professionalPageFormData.has('facebook')) {
                professionalPageFormData.delete('facebook');
            }
            professionalPageFormData.append('facebook', facebook);

            if (professionalPageFormData.has('twitter')) {
                professionalPageFormData.delete('twitter');
            }
            professionalPageFormData.append('twitter', twitter);

            if (professionalPageFormData.has('instagram')) {
                professionalPageFormData.delete('instagram');
            }
            professionalPageFormData.append('instagram', instagram);

            if (professionalPageFormData.has('youTube')) {
                professionalPageFormData.delete('youTube');
            }
            professionalPageFormData.append('youTube', youTube);

            if (professionalPageFormData.has('pinterest')) {
                professionalPageFormData.delete('pinterest');
            }
            professionalPageFormData.append('pinterest', pinterest);

        } catch (error: any) {
            throw error;
        }
    }

    const onProfileImageUploadListener = () => {
        profileImageInput.current?.click();
    }

    const onProfessionalProfileImageUploadListener = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            let profileImage: any = {};
            profileImage.file = file;
            profileImage.imagePath = URL.createObjectURL(profileImage.file);
            setProfileImage(profileImage);
            setProfileImageValidationErrorMsg('');

            setShowProfileImageModal(true);
        } else {
            setProfileImage(null);
            setProfileImageValidationErrorMsg('Invalid file. Please upload a valid image file.');
        }
    };
    const dataURItoFile = (dataURI, type, filename) => {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
      
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
      
        // Create a Blob
        const blob = new Blob([ab], { type });
      
        // Create a File with the Blob and filename
        return new File([blob], filename, { type });
      };
      const dataURItoFiles = (dataURI, type, filename) => {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
      
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
      
        // Create a Blob
        const blob = new Blob([ab], { type });
      
        // Create a File with the Blob and filename
        return new File([blob], filename, { type });
      };
    const onLoads = useCallback((img) => {
        setCropCover((prevCrop) => ({
            ...prevCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
        setCompletedCoverCrop((prevCompletedCrop) => ({
            ...prevCompletedCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
    }, []);
    const onLoad = useCallback((img) => {
        setCrop((prevCrop) => ({
            ...prevCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
        setCompletedCoverCrop((prevCompletedCrop) => ({
            ...prevCompletedCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
    }, []);
    const clearProfileImage = () => {
        setProfileImage(null);
    }



    const onProfessionalCoverImageUploadListener = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            let coverImage: any = {};
            coverImage.file = file;
            coverImage.imagePath = URL.createObjectURL(coverImage.file);
            setCoverImage(coverImage);
            setCoverImageValidationErrorMsg('');

            // Show the modal here
            setShowModal(true);
        } else {
            setCoverImage(null);
            setCoverImageValidationErrorMsg('Invalid file. Please upload a valid image file.');
        }
    };
    const clearCoverImage = () => {
        setCoverImage(null);
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'countryIdStr') {
                setCountryCodeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'mobile') {
                setMobileNumberValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'profileImage') {
                setProfileImageValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'coverImage') {
                setCoverImageValidationErrorMsg(errorMsg);
            }
        }
    }

    const getTemplateData = async () => {
        let data = await professionalPageApiservice.getProfessionalPageTemplateData();
        setcountryOptions(data.countryData)
        console.log(data);
    }

    useEffect(() => {
        getTemplateData();
    }, []);

    const preview = () => {
        onPreviousCallback(tabNumber - 1);
        setShowNext(false);
    }

    const previous = () => {
        confirmAlert({
            title: "Alert Message",
            message: "The form you have filled will get lost in this page. Are you sure want to go previous?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => preview(),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const handleShowFilters = () => {
        setShowExtraFilters(true);
    };

    const handleHideFilters = () => {
        setShowExtraFilters(false);
    };
    return <>
        <div className="container boder-sy  my-4">
            <div className="row d-flex justify-content-center">
                <div className="col-sm-1 col-lg-1text-center mt-5">
                    <span className=""> <ArrowBackIcon onClick={previous} /></span>
                </div>
                <div className="col-sm-5 col-lg-5 d-flex align-self-center">
                    <img src={ProfessionalPageContactInfoImage} className='contact_info_image' alt="google-bussiness" ></img>
                </div>
                <div className="col-sm-7 col-lg-6 mt-3">
                    <section className="p-0 pt-4">
                        <div className="row">
                            <div className="col-sm-10 col-lg-12 d-flex LKfzVb mt-2">
                                <h3 className="text-center">What contact details do you want to show to customers?</h3>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>Help customers get in touch by including this info on your listing Phone number.</span>

                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <hr />
                            </div>
                            <div className="col-sm-12 mb-4">
                                <div className="mobile-number-input">
                                    <div className="input-container d-flex justify-content-between">
                                        <span className="phone-icon">
                                            <FaPhone />
                                        </span>
                                        <div>
                                            <Select
                                                className={`country-code-select custom-select ${countryCodeValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                                options={countryOptions}
                                                getOptionLabel={(option) => `${option.countryCode}`}
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                                placeholder="Code"
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: 'black',
                                                        background: 'white',
                                                    })
                                                }}
                                            />
                                            <FormFieldError errorMessage={countryCodeValidationErrorMsg}></FormFieldError>
                                        </div>
                                        <div className="col-sm-9">
                                            <input
                                                className={`input-value form-control p-3 ${mobileNumberValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                                type="text"
                                                placeholder="Enter Mobile Number"
                                                value={mobileNumber}
                                                onChange={e => { handleMobileNumberOnChange(e.target.value) }}
                                            />
                                            <FormFieldError errorMessage={mobileNumberValidationErrorMsg}></FormFieldError>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <input
                                className="input-value form-control p-3 "
                                value={website}
                                onChange={e => { handleWebsiteOnChange(e.target.value) }}
                                type="text"
                                placeholder='Website (optional)'
                            />
                            <FormFieldError errorMessage={nickNameValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className="row col-sm-12">
                            <div className="col-sm-6 mb-2 ">
                                <label className="mt-4">Upload Profile Image<sup className="required">*</sup></label>
                                <div className="mt-4 h-auto" style={{ display: 'none' }}>
                                    <input type="file" className={profileImageValidationErrorMsg ? 'error-field input-edit ' : 'input-edit '} ref={profileImageInput} onChange={onProfessionalProfileImageUploadListener} />
                                </div>
                                {!profileImage && (
                                    <div>
                                        <div className="mt-4 h-auto">
                                            <input
                                                type="button"
                                                className="form-control border-dark w-auto"
                                                value="Upload Profile Image"
                                                onClick={handleImageSelection}
                                            />
                                        </div>
                                        <FormFieldError errorMessage={profileImageValidationErrorMsg}></FormFieldError>
                                    </div>
                                )}
                                {showCroppedImage && croppedImageSrc && (
                                    <Row className="mt-2">
                                        <Col md={12} className="d-flex justify-content-center">
                                            <img
                                                src={croppedImageSrc}
                                                onLoad={(e) => onLoad(e.target)}
                                                alt="Selected Profile"
                                                style={{ width: '100%', height: 200 }}
                                            />
                                        </Col>
                                        <Col md={12} className="">
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm rounded-pill mt-2"
                                                aria-label="Close"
                                                onClick={() => clearProfileImage()}
                                            >
                                                <span className="" aria-hidden="true">
                                                    Remove
                                                </span>
                                            </button>
                                        </Col>
                                    </Row>
                                )}

                            </div>
                            {showProfileImageModal && (
                                <Modal show={showProfileImageModal} onHide={handleCloseProfileImageModal}>
                                    <Modal.Body>
                                        {profileImage && profileImage.imagePath && (
                                            <>
                                                {/* Display the original selected image */}
                                                {!showCroppedImage && (
                                                    <img
                                                        src={profileImage.imagePath}
                                                        width={'100%'}
                                                        height={200}
                                                        alt="Selected Profile"
                                                    />
                                                )}

                                                {/* Display the Cropper only if the user clicked the "Crop Image" button */}
                                                {showCroppedImage && (
                                                    <div className="row" style={{ position: 'relative', width: '100%', height: '450px' }}>
                                                        <Cropper
                                                            image={profileImage.imagePath}
                                                            crop={crop}
                                                            zoom={zoom}
                                                            onCropChange={setCrop}
                                                            onZoomChange={setZoom}
                                                            onCropComplete={onCropComplete}
                                                            aspect={1 / 1} />
                                                        <div className="controls row">
                                                            <input
                                                                type="range"
                                                                value={zoom}
                                                                min={1}
                                                                max={3}
                                                                step={0.1}
                                                                aria-labelledby="Zoom"
                                                                onChange={(e) => {
                                                                    setZoom(parseFloat(e.target.value))
                                                                }}
                                                                className="zoom-range"
                                                            />
                                                        </div>
                                                        {/* Display the cropped image */}
                                                        {cropConfirmed && croppedImageSrc && (
                                                            <img
                                                                src={croppedImageSrc}
                                                                width={'100%'}
                                                                height={'100%'}
                                                                alt="Cropped Image"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>

                                        {showCroppedImage && (
                                            <Button variant="primary" onClick={handleImageCrop}>
                                                Confirm Crop
                                            </Button>
                                        )}


                                    </Modal.Footer>
                                </Modal>
                            )}

                            <div className="col-sm-6 mb-2 ">
                                <label className="mt-4">Upload Cover Image<sup className="required">*</sup></label>
                                <div className="mt-4 h-auto" style={{ display: 'none' }}>
                                    <input type="file" className={coverImageValidationErrorMsg ? 'error-field input-edit ' : 'input-edit '} ref={coverImageInput} onChange={onProfessionalCoverImageUploadListener} />
                                </div>
                                {!coverImage && <div>
                                    <div className="mt-4 h-auto">
                                        <input
                                            type="button"
                                            className="form-control border-dark w-auto"
                                            value="Upload Cover Image"
                                            onClick={handleCoverImageSelection}
                                        />                                    </div>
                                    <FormFieldError errorMessage={coverImageValidationErrorMsg}></FormFieldError>
                                </div>}
                                {showCoverCroppedImage && croppedCoverImageSrc && (
                                    <Row className="mt-2">
                                        <Col md={12} className="d-flex justify-content-center">
                                            <img
                                                src={croppedCoverImageSrc}
                                                onLoad={(e) => onLoads(e.target)}
                                                alt="Selected Profile"
                                                style={{ width: '100%', height: 200 }}
                                            />
                                        </Col>
                                            <Col md={12} className="">
                                                <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={() => clearCoverImage()}>
                                                    <span className="" aria-hidden="true">Remove</span>
                                                </button>
                                            </Col>
                                        </Row>
                                    ) 
                                }
                            </div>
                        </div>
                        {showModal && (
                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Body>
                                        {coverImage && coverImage.imagePath && (
                                            <>
                                                {/* Display the original selected image */}
                                                {!showCoverCroppedImage && (
                                                    <img
                                                        src={coverImage.imagePath}
                                                        width={'100%'}
                                                        height={'100%'}
                                                        alt="Selected Profile"
                                                    />
                                                )}

                                                {/* Display the Cropper only if the user clicked the "Crop Image" button */}
                                                {showCoverCroppedImage && (
                                                    <div className="row" style={{ position: 'relative', width: '100%', height: '450px' }}>
                                                        <Cropper
  image={coverImage.imagePath}
  crop={cropCover}
  zoom={zoomCover}
  onCropChange={setCropCover}
  onZoomChange={setZoomCover}
  onCropComplete={onCropCoverComplete}
  aspect={1 / 1}
  cropSize={{ width: 400, height: 200 }} 
/>

                                                        <div className="controls row">
                                                            <input
                                                                type="range"
                                                                value={zoomCover}
                                                                min={1}
                                                                max={3}
                                                                step={0.1}
                                                                aria-labelledby="Zoom"
                                                                onChange={(e) => {
                                                                    setZoom(parseFloat(e.target.value))
                                                                }}
                                                                className="zoom-range"
                                                            />
                                                        </div>
                                                        {/* Display the cropped image */}
                                                        {cropCoverConfirmed && croppedCoverImageSrc && (
                                                            <img
                                                                src={croppedCoverImageSrc}
                                                                width={'100%'}
                                                                height={'100%'}
                                                                alt="Cropped Image"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>

                                        {showCoverCroppedImage && (
                                            <Button variant="primary" onClick={handleCoverImageCrop}>
                                                Confirm Crop
                                            </Button>
                                        )}


                                    </Modal.Footer>
                                </Modal>
                            )}
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <label className="d-flex justify-content-end p-2">
                                {!showExtraFilters && <Nav.Link className="underline-on-hover" onClick={handleShowFilters}>More Options</Nav.Link>}
                                {showExtraFilters && <Nav.Link className="underline-on-hover" onClick={handleHideFilters}>Less Options</Nav.Link>}

                            </label>
                        </div>

                        {showExtraFilters && (
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <input
                                        className="input-value form-control p-3 "
                                        value={facebook}
                                        onChange={e => { handleFacebookOnChange(e.target.value) }}
                                        type="text"
                                        placeholder='Enter Facebook Link (optional)'
                                    />
                                </div>
                                <div className="col-sm-6 mb-3">
                                    <input
                                        className="input-value form-control p-3 "
                                        value={twitter}
                                        onChange={e => { handleTwitterOnChange(e.target.value) }}
                                        type="text"
                                        placeholder='Enter Twitter Link (optional)'
                                    />
                                </div>
                            </div>
                        )}

                        {showExtraFilters && (
                            <div className="row">
                                <div className="col-sm-6 mb-3">
                                    <input
                                        className="input-value form-control p-3 "
                                        value={instagram}
                                        onChange={e => { handleInstagramOnChange(e.target.value) }}
                                        type="text"
                                        placeholder='Enter Instagram Link (optional)'
                                    />
                                </div>

                                <div className="col-sm-6 mb-3">
                                    <input
                                        className="input-value form-control p-3 "
                                        value={youTube}
                                        onChange={e => { handleYouTubeOnChange(e.target.value) }}
                                        type="text"
                                        placeholder='Enter YouTube Link (optional)'
                                    />
                                </div>
                            </div>
                        )}

                        {showExtraFilters && (
                            <div className="col-sm-12 mb-3">
                                <input
                                    className="input-value form-control p-3 "
                                    value={pinterest}
                                    onChange={e => { handlePinterestOnChange(e.target.value) }}
                                    type="text"
                                    placeholder='Enter Pinterest Link (optional)'
                                />
                            </div>
                        )}
                        <div className="text-end p-5">
                            <button className="btn btn-success" onClick={submitContactInfoFormData} type="button">submit</button>
                        </div>
                        <div className="text-center">
                        </div>
                    </section>

                </div>
            </div>
        </div>
        <div>
        </div>
    </>
}
export default AddProfessionalPageContavtInfo;