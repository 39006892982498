import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import { useNavigate } from "react-router-dom";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import ValidationError from "../../../common/validation-error";
import "../add-location-page/add-location-page.css";
import Spinner from "react-bootstrap/esm/Spinner";

const EditModelHouseInformationPage = ({deedType, propertyId, propertyFormData, onNextCallback, onPreviousCallback }) => {
    const tabNumber: number = 2;
    let navigate: any = useNavigate();
    const propertyInformationFormData = new FormData();
    const [showTotalRoomOption, setShowTotalRoomOption] = useState(true);
    const [showBedOption, setShowBedOption] = useState(true);
    const [showLivingRoomOption, setShowLivingRoomOption] = useState(true);
    const [showFrontDoorTypeOption, setShowFrontDoorTypeOption] = useState(true);
    const [showKitchenTypeOption, setShowKitchenTypeOption] = useState(true);
    const [showFlooringTypeOption, setShowFloorinTypeOption] = useState(true);
    const [showPlotAreaTypeOption, setShowPlotAreaTypeOption] = useState(true);
    const [showBathOption, setShowBathOption] = useState(true);
    const [showKitchenOption, setShowKitchenOption] = useState(true);
    const [showBalconyOption, setShowBalconyOption] = useState(true);

    const propertyApiService = new PropertyApiService();
    const [totalRoomData, setTotalRoomData] = useState<any>([]);
    const [totalRoomId, setTotalRoomId] = useState("");
    const [frontDoorTypeData, setFrontDoorTypeData] = useState<any>([]);
    const [frontDoorTypeId, setFrontDoorTypeId] = useState("");
    const [livingRoomData, setLivingRoomData] = useState<any>([]);
    const [livingRoomId, setLivingRoomId] = useState("");
    const [bedData, setBedData] = useState<any>([]);
    const [bedId, setBedId] = useState("");
    const [bathData, setBathData] = useState<any>([]);
    const [bathId, setBathId] = useState("");
    const [kitchenData, setKitchenData] = useState<any>([]);
    const [kitchenId, setKitchenId] = useState("");
    const [kitchenTypeData, setKitchenTypeData] = useState<any>([]);
    const [kitchenTypeId, setKitchenTypeId] = useState("");
    const [balconyData, setBalconyData] = useState<any>([]);
    const [balconyId, setBalconyId] = useState("");
    const [flooringTypeData, setFlooringTypeData] = useState<any>([]);
    const [flooringTypeId, setFlooringTypeId] = useState("");
    const [plotAreaIdStr, setPlotAreaIdStr] = useState("");
    const [plotAreaTypeData, setPlotAreaTypeData] = useState<any>([]);
    const [plotArea, setPlotArea] = useState("");
    const [plotLength, setPlotLength] = useState("");
    const [plotBreadth, setPlotBreadth] = useState("");
    const [buildingLength, setBuildingLength] = useState("");
    const [buildingBreadth, setBuildingBreadth] = useState("");
    const [description, setDescription] = useState("");
    const [hundredData, setHundredData] = useState<any>([]);
    const [hundredDataId, setHundredDataId] = useState("");
    const [thousandData, setThousandData] = useState<any>([]);
    const [thousandDataId, setThousandDataId] = useState("");
    const [lakhsData, setlakhsData] = useState<any>([]);
    const [lakhsDataId, setlakhsDataId] = useState("");
    const [croreData, setCroreData] = useState<any>([]);
    const [croreDataId, setCroreDataId] = useState("");

    const [totalRoomValidationErrorMsg, setTotalRoomValidationNameErrorMsg] = useState(null);
    const [bedValidationErrorMsg, setBedValidationNameErrorMsg] = useState(null);
    const [livingRoomValidationErrorMsg, setLivingRoomValidationNameErrorMsg] = useState(null);
    const [frontDoorTypeValidationErrorMsg, setFrontDoorTypeValidationNameErrorMsg] = useState(null);
    const [bathValidationErrorMsg, setBathValidationNameErrorMsg] = useState(null);
    const [kitchenValidationErrorMsg, setKitchenValidationNameErrorMsg] = useState(null);
    const [kitchenTypeValidationErrorMsg, setKitchenTypeValidationNameErrorMsg] = useState(null);
    const [balconyValidationErrorMsg, setBalconyValidationNameErrorMsg] = useState(null);
    const [flooringTypeValidationErrorMsg, setFlooringTypeValidationNameErrorMsg] = useState(null);
    const [plotLengthValidationErrorMsg, setPlotLengthValidationNameErrorMsg] = useState(null);
    const [plotAreaTypeDataValidationErrorMsg, setPlotAreaTypeDataValidationNameErrorMsg] = useState(null);
    const [plotBreathValidationErrorMsg, setPlotBreathValidationNameErrorMsg] = useState(null);
    const [BuildingBreathValidationErrorMsg, setBuildingBreathValidationNameErrorMsg] = useState(null);
    const [BuildingLengthValidationErrorMsg, setBuildingLengthValidationNameErrorMsg] = useState(null);
    const [plotAreaValidationErrorMsg, setPlotAreaValidationNameErrorMsg] = useState(null);
    const [descriptionValidationErrorMsg, setDescriptionValidationNameErrorMsg] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [priceInText, setPriceInText] = useState("");
    const [processing, setProcessing] = useState(false);


    const handleDescriptionOnChange = (value: any) => {
        setDescription(value);
        setDescriptionValidationNameErrorMsg('');
    }
    const handlePlotAreaOnChange = (value: any) => {
        setPlotArea(value);
        setPlotAreaValidationNameErrorMsg('');
    }
    const handleBuildingLengthOnChange = (value: any) => {
        setBuildingLength(value);
        setBuildingLengthValidationNameErrorMsg('');
    }
    const handleBuildingBreadthOnChange = (value: any) => {
        setBuildingBreadth(value);
        setBuildingBreathValidationNameErrorMsg('');
    }
    const handlePlotLengthOnChange = (value: any) => {
        setPlotLength(value);
        setPlotLengthValidationNameErrorMsg('');
    }
    const handlePlotBreadthOnChange = (value: any) => {
        setPlotBreadth(value);
        setPlotBreathValidationNameErrorMsg('');
    }
    const handleLivingRoomOnChange = (lrId: any) => {
        setLivingRoomId(lrId);
        setLivingRoomValidationNameErrorMsg('');
        setShowLivingRoomOption(false);
    }
    const handleKitchenOnChange = (kiId: any) => {
        setKitchenId(kiId);
        setKitchenValidationNameErrorMsg('');
        setShowKitchenOption(false);
    }
    const handleTotalRoomOnChange = (trId: any) => {
        setTotalRoomId(trId);
        setTotalRoomValidationNameErrorMsg('');
        setShowTotalRoomOption(false);
    }
    const handleBedOnChange = (beId: any) => {
        setBedId(beId);
        setBedValidationNameErrorMsg('');
        setShowBedOption(false);
    }
    const handleBathOnChange = (baId: any) => {
        setBathId(baId);
        setBathValidationNameErrorMsg('');
        setShowBathOption(false);
    }
    const handleKitchenTypeChange = (ktId: any) => {
        setKitchenTypeId(ktId);
        setKitchenTypeValidationNameErrorMsg('');
        setShowKitchenTypeOption(false);
    };
    const handleFrontDoorTypeChange = (fdtId: any) => {
        setFrontDoorTypeId(fdtId);
        setFrontDoorTypeValidationNameErrorMsg('');
        setShowFrontDoorTypeOption(false);
    };
    const handleFlooringTypeChange = (ftId: any) => {
        setFlooringTypeId(ftId);
        setFlooringTypeValidationNameErrorMsg('');
        setShowFloorinTypeOption(false);
    };
    const handlePlotAreaTypeChange = (paId: any) => {
        setPlotAreaIdStr(paId);
        setPlotAreaTypeDataValidationNameErrorMsg('');
        setShowPlotAreaTypeOption(false);
    };
    const handleBalconyOnChange = (blId: any) => {
        setBalconyId(blId);
        setBalconyValidationNameErrorMsg('');
        setShowBalconyOption(false);
    }
    const handleHundredOnChange = (hdId: any) => {
        setHundredDataId(hdId);
    }
    const handleThousandOnChange = (TdId: any) => {
        setThousandDataId(TdId);
    }
    const handleCroreOnChange = (crId: any) => {
        setCroreDataId(crId);
    }
    const handleLakhsOnChange = (lkId: any) => {
        setlakhsDataId(lkId);
    }
    const calculateTotalPrice = () => {
        let total = 0;
        let text = '';
        if (croreDataId) {
            const selectedCroreOption = croreData.find((crData: any) => crData.id == croreDataId);
            if (selectedCroreOption) {
                total += parseInt(selectedCroreOption.priceExpansion);
                text = selectedCroreOption.priceInWord;
            }
        }
        if (lakhsDataId) {
            const selectedLakhsOption = lakhsData.find((lkData: any) => lkData.id == lakhsDataId);
            if (selectedLakhsOption) {
                total += parseInt(selectedLakhsOption.priceExpansion);
                text = text + " and " + selectedLakhsOption.priceInWord;
            }
        }
        if (thousandDataId) {
            const selectedThousandOption = thousandData.find((tdData: any) => tdData.id == thousandDataId);
            if (selectedThousandOption) {
                total += parseInt(selectedThousandOption.priceExpansion);
                text = text + " and " + selectedThousandOption.priceInWord;
            }
        }
        if (hundredDataId) {
            const selectedHundredOption = hundredData.find((hdData: any) => hdData.id == hundredDataId);
            if (selectedHundredOption) {
                total += parseInt(selectedHundredOption.priceExpansion);
                text = text + " and " + selectedHundredOption.priceInWord + "Only";
            }
        }
        setPriceInText(text);
        setTotalPrice(total);
    };


    const previousPage = () => {
        onPreviousCallback((tabNumber - 1));
    };

    const validateModelHouseInformationFormData = (): void => {
        let errors: any[] = [];
        if (!totalRoomId) {
            let error: any = {};
            error.validationCode = "totalRoom";
            error.message = 'Total Room required';
            errors.push(error);
        }
        if (!frontDoorTypeId) {
            let error: any = {};
            error.validationCode = "frontDoorType";
            error.message = 'Front Door Type required';
            errors.push(error);
        }
        if (!livingRoomId) {
            let error: any = {};
            error.validationCode = "livingRoom";
            error.message = 'Living Room required';
            errors.push(error);
        }
        if (!bedId) {
            let error: any = {};
            error.validationCode = "bed";
            error.message = 'Bed required';
            errors.push(error);
        }
        if (!bathId) {
            let error: any = {};
            error.validationCode = "bath";
            error.message = 'Bath required';
            errors.push(error);
        }
        if (!kitchenId) {
            let error: any = {};
            error.validationCode = "kitchen";
            error.message = 'Kitchen required';
            errors.push(error);
        }
        if (!kitchenTypeId) {
            let error: any = {};
            error.validationCode = "kitchenType";
            error.message = 'Kitchen Type required';
            errors.push(error);
        }
        if (!balconyId) {
            let error: any = {};
            error.validationCode = "balcony";
            error.message = 'Balcony required';
            errors.push(error);
        }
        if (!flooringTypeId) {
            let error: any = {};
            error.validationCode = "flooringType";
            error.message = 'Flooring Type required';
            errors.push(error);
        }
        if (!plotLength) {
            let error: any = {};
            error.validationCode = "plotLength";
            error.message = 'Plot Length required';
            errors.push(error);
        }
        if (!plotBreadth) {
            let error: any = {};
            error.validationCode = "plotBreadth";
            error.message = 'Plot Breadth required';
            errors.push(error);
        }
        if (!buildingLength) {
            let error: any = {};
            error.validationCode = "buildingLength";
            error.message = 'Building Length required';
            errors.push(error);
        }
        if (!buildingBreadth) {
            let error: any = {};
            error.validationCode = "buildingBreadth";
            error.message = 'Building Breadth required';
            errors.push(error);
        }
        if (!description) {
            let error: any = {};
            error.validationCode = "description";
            error.message = 'Description required';
            errors.push(error);
        }
        if (!plotAreaIdStr) {
            let error: any = {};
            error.validationCode = "plotAreaType";
            error.message = 'Plot Area Unit required';
            errors.push(error);
        }
        if (!plotArea) {
            let error: any = {};
            error.validationCode = "plotArea";
            error.message = 'Plot Area required';
            errors.push(error);
        }
        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const validateAndSaveModelHouseInformationFormData = () => {
        try {
            validateModelHouseInformationFormData();
            if (propertyFormData.has('totalRoomId')) {
                propertyFormData.delete('totalRoomId');
            }
            propertyFormData.append('totalRoomId', totalRoomId);

            if (propertyFormData.has('frontDoorTypeIdStr')) {
                propertyFormData.delete('frontDoorTypeIdStr');
            }
            propertyFormData.append('frontDoorTypeIdStr', frontDoorTypeId);

            if (propertyFormData.has('livingRoomId')) {
                propertyFormData.delete('livingRoomId');
            }
            propertyFormData.append('livingRoomId', livingRoomId);

            if (propertyFormData.has('bedId')) {
                propertyFormData.delete('bedId');
            }
            propertyFormData.append('bedId', bedId);

            if (propertyFormData.has('bathId')) {
                propertyFormData.delete('bathId');
            }
            propertyFormData.append('bathId', bathId);

            if (propertyFormData.has('kitchenId')) {
                propertyFormData.delete('kitchenId');
            }
            propertyFormData.append('kitchenId', kitchenId);

            if (propertyFormData.has('kitchenTypeIdStr')) {
                propertyFormData.delete('kitchenTypeIdStr');
            }
            propertyFormData.append('kitchenTypeIdStr', kitchenTypeId);

            if (propertyFormData.has('balconyId')) {
                propertyFormData.delete('balconyId');
            }
            propertyFormData.append('balconyId', balconyId);

            if (propertyFormData.has('flooringTypeIdStr')) {
                propertyFormData.delete('flooringTypeIdStr');
            }
            propertyFormData.append('flooringTypeIdStr', flooringTypeId);

            if (propertyFormData.has('plotLength')) {
                propertyFormData.delete('plotLength');
            }
            propertyFormData.append('plotLength', plotLength);

            if (propertyFormData.has('plotBreadth')) {
                propertyFormData.delete('plotBreadth');
            }
            propertyFormData.append('plotBreadth', plotBreadth);

            if (propertyFormData.has('buildingLength')) {
                propertyFormData.delete('buildingLength');
            }
            propertyFormData.append('buildingLength', buildingLength);

            if (propertyFormData.has('buildingBreadth')) {
                propertyFormData.delete('buildingBreadth');
            }
            propertyFormData.append('buildingBreadth', buildingBreadth);

            if (propertyFormData.has('description')) {
                propertyFormData.delete('description');
            }
            propertyFormData.append('description', description);

            if (propertyFormData.has('plotAreaTypeIdStr')) {
                propertyFormData.delete('plotAreaTypeIdStr');
            }
            propertyFormData.append('plotAreaTypeIdStr', plotAreaIdStr);

            if (propertyFormData.has('plotArea')) {
                propertyFormData.delete('plotArea');
            }
            propertyFormData.append('plotArea', plotArea);
            if (propertyFormData.has('priceInHundredIdStr')) {
                propertyFormData.delete('priceInHundredIdStr');
            }
            propertyFormData.append('priceInHundredIdStr', hundredDataId);
            if (propertyFormData.has('priceInThousandIdStr')) {
                propertyFormData.delete('priceInThousandIdStr');
            }
            propertyFormData.append('priceInThousandIdStr', thousandDataId);
            if (propertyFormData.has('priceInLakhsIdStr')) {
                propertyFormData.delete('priceInLakhsIdStr');
            }
            propertyFormData.append('priceInLakhsIdStr', lakhsDataId);
            if (propertyFormData.has('priceInCroresIdStr')) {
                propertyFormData.delete('priceInCroresIdStr');
            }
            propertyFormData.append('priceInCroresIdStr', croreDataId);
        } catch (error: any) {
            throw error;
        }
    }
    const validateAndUpdateModelHouseInformationFormData = () => {
        try {
            validateModelHouseInformationFormData();
            if (propertyInformationFormData.has('totalRoomIdStr')) {
                propertyInformationFormData.delete('totalRoomIdStr');
            }
            propertyInformationFormData.append('totalRoomIdStr', totalRoomId);

            if (propertyInformationFormData.has('frontDoorTypeIdStr')) {
                propertyInformationFormData.delete('frontDoorTypeIdStr');
            }
            propertyInformationFormData.append('frontDoorTypeIdStr', frontDoorTypeId);

            if (propertyInformationFormData.has('livingRoomIdStr')) {
                propertyInformationFormData.delete('livingRoomIdStr');
            }
            propertyInformationFormData.append('livingRoomIdStr', livingRoomId);

            if (propertyInformationFormData.has('bedIdStr')) {
                propertyInformationFormData.delete('bedIdStr');
            }
            propertyInformationFormData.append('bedIdStr', bedId);

            if (propertyInformationFormData.has('bathIdStr')) {
                propertyInformationFormData.delete('bathIdStr');
            }
            propertyInformationFormData.append('bathIdStr', bathId);

            if (propertyInformationFormData.has('kitchenIdStr')) {
                propertyInformationFormData.delete('kitchenIdStr');
            }
            propertyInformationFormData.append('kitchenIdStr', kitchenId);

            if (propertyInformationFormData.has('kitchenTypeIdStr')) {
                propertyInformationFormData.delete('kitchenTypeIdStr');
            }
            propertyInformationFormData.append('kitchenTypeIdStr', kitchenTypeId);

            if (propertyInformationFormData.has('balconyIdStr')) {
                propertyInformationFormData.delete('balconyIdStr');
            }
            propertyInformationFormData.append('balconyIdStr', balconyId);

            if (propertyInformationFormData.has('flooringTypeIdStr')) {
                propertyInformationFormData.delete('flooringTypeIdStr');
            }
            propertyInformationFormData.append('flooringTypeIdStr', flooringTypeId);

            if (propertyInformationFormData.has('plotLength')) {
                propertyInformationFormData.delete('plotLength');
            }
            propertyInformationFormData.append('plotLength', plotLength);

            if (propertyInformationFormData.has('plotBreadth')) {
                propertyInformationFormData.delete('plotBreadth');
            }
            propertyInformationFormData.append('plotBreadth', plotBreadth);

            if (propertyInformationFormData.has('buildingLength')) {
                propertyInformationFormData.delete('buildingLength');
            }
            propertyInformationFormData.append('buildingLength', buildingLength);

            if (propertyInformationFormData.has('buildingBreadth')) {
                propertyInformationFormData.delete('buildingBreadth');
            }
            propertyInformationFormData.append('buildingBreadth', buildingBreadth);

            if (propertyInformationFormData.has('description')) {
                propertyInformationFormData.delete('description');
            }
            propertyInformationFormData.append('description', description);

            if (propertyInformationFormData.has('plotAreaTypeStr')) {
                propertyInformationFormData.delete('plotAreaTypeStr');
            }
            propertyInformationFormData.append('plotAreaTypeStr', plotAreaIdStr);

            if (propertyInformationFormData.has('plotAreaStr')) {
                propertyInformationFormData.delete('plotAreaStr');
            }
            propertyInformationFormData.append('plotAreaStr', plotArea);
            if (propertyInformationFormData.has('priceInHundredIdStr')) {
                propertyInformationFormData.delete('priceInHundredIdStr');
            }
            propertyInformationFormData.append('priceInHundredIdStr', hundredDataId);
            if (propertyInformationFormData.has('priceInThousandIdStr')) {
                propertyInformationFormData.delete('priceInThousandIdStr');
            }
            propertyInformationFormData.append('priceInThousandIdStr', thousandDataId);
            if (propertyInformationFormData.has('priceInLakhsIdStr')) {
                propertyInformationFormData.delete('priceInLakhsIdStr');
            }
            propertyInformationFormData.append('priceInLakhsIdStr', lakhsDataId);
            if (propertyInformationFormData.has('priceInCroresIdStr')) {
                propertyInformationFormData.delete('priceInCroresIdStr');
            }
            propertyInformationFormData.append('priceInCroresIdStr', croreDataId);
            propertyInformationFormData.append('deedType', deedType);

        } catch (error: any) {
            throw error;
        }
    }
    const submitModelHouseInformationFormData = async () => {
        try {
            validateAndSaveModelHouseInformationFormData();
            onNextCallback(tabNumber + 1);
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }
    const updateModelHouseInformationFormData = async () => {
        try {
            validateAndUpdateModelHouseInformationFormData();
            setProcessing(true);
            await propertyApiService.updateInformation(propertyId, propertyInformationFormData);
            toast.success('Successfully ModelHouse Information Updated ', { containerId: 'TR' });
            navigate('/')
        } catch (error: any) {
            setProcessing(false);
            showValidationErrorInlineUI(error);
        }
    }
    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'totalRoom') {
                setTotalRoomValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'frontDoorType') {
                setFrontDoorTypeValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'livingRoom') {
                setLivingRoomValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'bed') {
                setBedValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'bath') {
                setBathValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'kitchen') {
                setKitchenValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'kitchenType') {
                setKitchenTypeValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'balcony') {
                setBalconyValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'flooringType') {
                setFlooringTypeValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'plotLength') {
                setPlotLengthValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'plotBreadth') {
                setPlotBreathValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'buildingLength') {
                setBuildingLengthValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'buildingBreadth') {
                setBuildingBreathValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'description') {
                setDescriptionValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'plotArea') {
                setPlotAreaValidationNameErrorMsg(errorMsg);
            }
            if (errorCode === 'plotAreaType') {
                setPlotAreaTypeDataValidationNameErrorMsg(errorMsg);
            }
        }

    }

    const getModelInformationTemplatesData = async () => {
        try {
            let res = await propertyApiService.getModelInformationTemplateData();
            setKitchenTypeData(res.kitchenTypeData);
            setFrontDoorTypeData(res.frontDoorTypeData);
            setFlooringTypeData(res.flooringTypeData);
            setPlotAreaTypeData(res.plotAreaTypeData);
            setTotalRoomData(res.totalRoomData);
            setBedData(res.bedData);
            setLivingRoomData(res.livingRoomData);
            setBathData(res.bathData);
            setKitchenData(res.kitchenData);
            setBalconyData(res.balconyData);
            setHundredData(res.priceInHundredData)
            setlakhsData(res.priceInLakhsData);
            setThousandData(res.priceInThousandData);
            setCroreData(res.priceInCroresData);
            highlightModelHouseInformationFormData();
        }
        catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const highlightModelHouseInformationFormData = () => {
        let formData: FormData = propertyFormData;
        if (formData.get('totalRoomId')) {
            setTotalRoomId(formData.get('totalRoomId').toString());
        }
        if (formData.get('bedId')) {
            setBedId(formData.get('bedId').toString());
        }
        if (formData.get('bathId')) {
            setBathId(formData.get('bathId').toString());
        }
        if (formData.get('kitchenId')) {
            setKitchenId(formData.get('kitchenId').toString());
        }
        if (formData.get('kitchenTypeIdStr')) {
            setKitchenTypeId(formData.get('kitchenTypeIdStr').toString());
        }
        if (formData.get('frontDoorTypeIdStr')) {
            setFrontDoorTypeId(formData.get('frontDoorTypeIdStr').toString());
        }
        if (formData.get('balconyId')) {
            setBalconyId(formData.get('balconyId').toString());
        }
        if (formData.get('description')) {
            setDescription(formData.get('description').toString());
        }
        if (formData.get('flooringTypeIdStr')) {
            setFlooringTypeId(formData.get('flooringTypeIdStr').toString());
        }
        if (formData.get('plotLength')) {
            setPlotLength(formData.get('plotLength').toString());
        }
        if (formData.get('plotBreadth')) {
            setPlotBreadth(formData.get('plotBreadth').toString());
        }
        if (formData.get('livingRoomId')) {
            setLivingRoomId(formData.get('livingRoomId').toString());
        }
        if (formData.get('plotAreaTypeIdStr')) {
            setPlotAreaIdStr(formData.get('plotAreaTypeIdStr').toString());
        }
        if (formData.get('plotArea')) {
            setPlotArea(formData.get('plotArea').toString());
        }
        if (formData.get('buildingBreadth')) {
            setBuildingBreadth(formData.get('buildingBreadth').toString());
        }
        if (formData.get('buildingLength')) {
            setBuildingLength(formData.get('buildingLength').toString());
        }
        if (formData.get('priceInCroresIdStr')) {
            setCroreDataId(formData.get('priceInCroresIdStr').toString());
        }
        if (formData.get('priceInLakhsIdStr')) {
            setlakhsDataId(formData.get('priceInLakhsIdStr').toString());
        }
        if (formData.get('priceInThousandIdStr')) {
            setThousandDataId(formData.get('priceInThousandIdStr').toString());
        }
        if (formData.get('priceInHundredIdStr')) {
            setHundredDataId(formData.get('priceInHundredIdStr').toString());
        }
    }


    useEffect(() => {
        getModelInformationTemplatesData();
    }, [])

    useEffect(() => {
        calculateTotalPrice();
    }, [lakhsDataId, croreDataId,hundredDataId, thousandDataId])

    return (
        <>
            <div className="body container-fluid row">
                <div className=" container  w-100  ms-1">
                    <div className="card-body">
                        <div className="col-sm-9 mx-auto ">
                            <div className="card ">
                                <div className="card-body">
                                    <div className="text-color text-center">
                                        <p> Information</p>
                                    </div>
                                    <div className="row ms-5">
                                        <div className="col-sm-6 ">
                                            <div className="mb-2">
                                                <label className="mt-4">Total No. Of Rooms<sup className="required">*</sup></label>
                                                <select className={totalRoomValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                    value={totalRoomId} onChange={(e) => handleTotalRoomOnChange(e.target.value)} >
                                                    {showTotalRoomOption && <option value="">Select</option>}
                                                    {totalRoomData.map((trData: any, index: any) => (
                                                        <option key={trData.id} value={trData.id}>{trData.totalRoom}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={totalRoomValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2 row gx-1">
                                                <label className="fs-15"> Construction Cost<sup className="required">*</sup></label>
                                                <div className="col-sm-3">
                                                    <label className="mt-4  fs-12">Crores</label>
                                                    <select className="input-edit form-control mt-2" value={croreDataId} onChange={(e) => handleCroreOnChange(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {croreData && croreData.map((crData: any, index: any) => (
                                                            <option key={crData.id} value={crData.id}>{crData.priceValue}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className="mt-4 fs-12">Lakhs</label>
                                                    <select className="input-edit form-control mt-2" value={lakhsDataId} onChange={(e) => handleLakhsOnChange(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {lakhsData && lakhsData.map((lkData: any, index: any) => (
                                                            <option key={lkData.id} value={lkData.id}>{lkData.priceValue}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-3">
                                                <label className="mt-4 fs-12">Thousands</label> 
                                                    <select className="input-edit form-control mt-2" value={thousandDataId} onChange={(e) => handleThousandOnChange(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {thousandData && thousandData.map((tdData: any, index: any) => (
                                                            <option key={tdData.id} value={tdData.id}>{tdData.priceValue}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-sm-3">
                                                    <label className="mt-4 fs-12">Hundred</label>
                                                    <select className="input-edit form-control mt-2" value={hundredDataId} onChange={(e) => handleHundredOnChange(e.target.value)}>
                                                        <option value="">Select</option>
                                                        {hundredData && hundredData.map((hdData: any, index: any) => (
                                                            <option key={hdData.id} value={hdData.id}>{hdData.priceValue}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                
                                                <label className="totalPrice mt-4">Total Price : <span className="totalPriceColour">{totalPrice} ( {priceInText} ) 
                                                </span></label>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Living Rooms<sup className="required">*</sup></label>
                                                <select className={livingRoomValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={livingRoomId}
                                                    onChange={(e) => handleLivingRoomOnChange(e.target.value)} >
                                                    {showLivingRoomOption && <option value="">Select</option>}
                                                    {livingRoomData.map((lrData: any, index: any) => (
                                                        <option key={lrData.id} value={lrData.id}>{lrData.livingRoom}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={livingRoomValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Beds<sup className="required">*</sup></label>
                                                <select className={bedValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={bedId}
                                                    onChange={(e) => handleBedOnChange(e.target.value)} >
                                                    {showBedOption && <option value="">Select</option>}
                                                    {bedData.map((beData: any, index: any) => (
                                                        <option key={beData.id} value={beData.id}>{beData.bed}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={bedValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Bath<sup className="required">*</sup></label>
                                                <select className={bathValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={bathId}
                                                    onChange={(e) => handleBathOnChange(e.target.value)} >
                                                    {showBathOption && <option value="">Select</option>}
                                                    {bathData.map((baData: any, index: any) => (
                                                        <option key={baData.id} value={baData.id}>{baData.bath}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={bathValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Balcony<sup className="required">*</sup></label>
                                                <select className={balconyValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={balconyId}
                                                    onChange={(e) => handleBalconyOnChange(e.target.value)} >
                                                    {showBalconyOption && <option value="">Select</option>}
                                                    {balconyData.map((blData: any, index: any) => (
                                                        <option key={blData.id} value={blData.id}>{blData.balcony}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={balconyValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Front Door Type<sup className="required">*</sup></label>
                                                <select className={frontDoorTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={frontDoorTypeId}
                                                    onChange={(e) => handleFrontDoorTypeChange(e.target.value)} >
                                                    {showFrontDoorTypeOption && <option value="">Select</option>}
                                                    {frontDoorTypeData.map((fdtData: any, index: any) => (
                                                        <option key={fdtData.id} value={fdtData.id}>{fdtData.label}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={frontDoorTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Kitchen<sup className="required">*</sup></label>
                                                <select className={kitchenValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={kitchenId}
                                                    onChange={(e) => handleKitchenOnChange(e.target.value)} >
                                                    {showKitchenOption && <option value="">Select</option>}
                                                    {kitchenData.map((kiData: any, index: any) => (
                                                        <option key={kiData.id} value={kiData.id}>{kiData.kitchen}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={kitchenValidationErrorMsg}></FormFieldError>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="mb-2">
                                                <label className="mt-4">Kitchen Type<sup className="required">*</sup></label>
                                                <select className={kitchenTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'} value={kitchenTypeId}
                                                    onChange={(e) => handleKitchenTypeChange(e.target.value)} >
                                                    {showKitchenTypeOption && <option value="">Select</option>}
                                                    {kitchenTypeData.map((ktData: any, index: any) => (
                                                        <option key={ktData.id} value={ktData.id}>{ktData.label}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={kitchenTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2">
                                                <label className="mt-4">Flooring Type<sup className="required">*</sup></label>
                                                <select className={flooringTypeValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                    value={flooringTypeId} onChange={(e) => handleFlooringTypeChange(e.target.value)} >
                                                    {showFlooringTypeOption && <option value="">Select</option>}
                                                    {flooringTypeData.map((ftData: any, index: any) => (
                                                        <option key={ftData.id} value={ftData.id}>{ftData.label}</option>
                                                    ))}
                                                </select>
                                                <FormFieldError errorMessage={flooringTypeValidationErrorMsg}></FormFieldError>
                                            </div>
                                            <div className="mb-2 row gx-1">
                                                <div className="col-sm-6">
                                                    <label className="mt-4">Plot Area</label>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className={plotAreaValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                        placeholder="Plot Area"
                                                        value={plotArea}
                                                        onChange={e => { handlePlotAreaOnChange(e.target.value) }}
                                                    />
                                                    <FormFieldError errorMessage={plotAreaValidationErrorMsg}></FormFieldError>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="mt-4">Plot Area Unit</label>
                                                    <select className={plotAreaTypeDataValidationErrorMsg ? 'error-field input-edit form-control mt-4 ' : 'input-edit form-control mt-4'}
                                                        value={plotAreaIdStr} onChange={(e) => handlePlotAreaTypeChange(e.target.value)}>
                                                        {showPlotAreaTypeOption && <option value="">Select</option>}
                                                        {plotAreaTypeData.map((paData: any, index: any) =>
                                                            (<option key={paData.id} value={paData.id}>{paData.plotAreaType}</option>))}
                                                    </select>
                                                    <FormFieldError errorMessage={plotAreaTypeDataValidationErrorMsg}></FormFieldError>
                                                </div>
                                            </div>
                                            <div className="mb-2 row gx-1">
                                                <label className="mt-4">Plot Dimension</label>
                                                <div className="col-sm-6">
                                                    <input
                                                        type="number"
                                                        className={plotLengthValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                        placeholder="Plot Length" min={0}
                                                        value={plotLength}
                                                        onChange={e => { handlePlotLengthOnChange(e.target.value) }}
                                                    />
                                                    <FormFieldError errorMessage={plotLengthValidationErrorMsg}></FormFieldError>
                                                </div>

                                                <div className="col-sm-6">
                                                    <input
                                                        type="number"
                                                        className={plotBreathValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                        placeholder="Plot Breadth"
                                                        min={0}
                                                        value={plotBreadth}
                                                        onChange={e => { handlePlotBreadthOnChange(e.target.value) }}
                                                    />
                                                    <FormFieldError errorMessage={plotBreathValidationErrorMsg}></FormFieldError>
                                                </div>
                                                <div className="mb-2 row gx-1">
                                                    <label className="mt-4">Building Dimension</label>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="number"
                                                            className={BuildingLengthValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                            placeholder="Building Length" min={0}
                                                            value={buildingLength}
                                                            onChange={e => { handleBuildingLengthOnChange(e.target.value) }}
                                                        />
                                                        <FormFieldError errorMessage={BuildingLengthValidationErrorMsg}></FormFieldError>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input
                                                            type="number"
                                                            className={BuildingBreathValidationErrorMsg ? 'error-field input-box form-control  mt-4' : 'input-edit form-control  mt-4'}
                                                            placeholder="Building Breadth" min={0}
                                                            value={buildingBreadth}
                                                            onChange={e => { handleBuildingBreadthOnChange(e.target.value) }}
                                                        />
                                                        <FormFieldError errorMessage={BuildingBreathValidationErrorMsg}></FormFieldError>
                                                    </div>

                                                    <div className="mb-2">
                                                        <label className="mt-4">Description</label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            onReady={(editor: any) => {
                                                                console.log("CKEditor5 React Component is ready to use!", editor);
                                                            }}
                                                            onChange={(event: any, editor: any) => { handleDescriptionOnChange(editor.getData()) }}
                                                        />
                                                        <FormFieldError errorMessage={descriptionValidationErrorMsg}></FormFieldError>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 d-flex me-5 justify-content-end">
                                    <button className="btn btn-primary me-3" onClick={previousPage}>Previous</button>
                                  <button disabled={processing}  onClick={updateModelHouseInformationFormData} className="btn btn-warning me-3 " type="button">
                                        Update & Skip
                                        </button>
                                    {(processing)&& <Spinner animation="grow" variant="warning" />}
                                    <button onClick={submitModelHouseInformationFormData} className="btn btn-success " type="button">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditModelHouseInformationPage;

