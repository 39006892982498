import { useEffect, useRef, useState } from "react";
import Header from "../header/header";
import "./about-page.css"
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AboutUsApiService from "../../data/api/services/about-us/about-us-api-service";
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import avatar from "../../../src/assets/avatar.png";

const AboutPage = () => {
    const aboutUsApiService = new AboutUsApiService();
    const [architectName, setArchitectName] = useState([]);
    const [aboutUsViewer, setAboutUsViewerName] = useState([]);
    const [employeeName, setAboutUsEmployeeName] = useState([]);
    const [YtVide0Url, setYtVideoUrl] = useState("");

    const settings = {
        className: "center rounded-circle",
    focusOnSelect: true,
    adaptiveHeight: true,
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 5,
    swipeToSlide: true,
    nextArrow: <NavigateNextIcon />,
    prevArrow: <NavigateBeforeIcon />,
    afterChange: function (index) {
        console.log(
            `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        );
    },
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                centerPadding: "40px",

            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerPadding: "20px"

            }
        }
    ]
    };
    const getAboutArchitectsData = async () => {
        try {
            let data = await aboutUsApiService.getAboutArchitectsData();
            let initialArchitects = new Array(5).fill({ imageURL: avatar });

            if (data.length > 0) {
                const availableData = data.slice(0);
                availableData.forEach((item, index) => {
                    initialArchitects[index] = item;
                });
            }
            setArchitectName(initialArchitects);
        } catch (error) {
            console.log(error);
        }
    };

    const getAboutViewersDetails = async () => {
        try {
            let data = await aboutUsApiService.getAboutViewerData();
            let initialViewers = new Array(5).fill({ architectImageURL: avatar });
            if (data.length > 0) {
                const availableData = data.slice(0);
                availableData.forEach((item, index) => {
                    initialViewers[index] = item;
                });
            }
            setAboutUsViewerName(initialViewers);
        } catch (error) {
            console.log(error);
        }
    };

    const getAboutEmployeeDetails = async () => {
        try {
            let data = await aboutUsApiService.getAboutEmployeeData();
            console.log(data);
            setAboutUsEmployeeName(data);
        } catch (error) {
            console.log(error);
        }
    };

    const getYtVideoUrl = async() => {
        try{
            let data = await aboutUsApiService.getAboutPageVideoUrl();
            if(data){
                setYtVideoUrl(data.label);
            }else{
                setYtVideoUrl("https://www.youtube.com/embed/Eerr_zaG2oo?autoplay=1&mute=1");
            }
        }catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
        getAboutArchitectsData();
        getAboutViewersDetails();
        getAboutEmployeeDetails();
        getYtVideoUrl();
    }, []);

    return <>
        <div className="mb-5">
            <Header />
        </div>
        <div className="page-header">
            <iframe width="100%" height="600px" src={YtVide0Url} allowFullScreen>
            </iframe>
        </div>

        <section className="bg-light my-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 text-center">
                        <div className="sec-heading center">
                            <h2>What is Veedu?</h2>
                            <p className="def">Veedu is a content platform that broadcasts videos and PDF eBooks of amazing homes in India.
                                Be it a homeowner, a practicing architect or a student, our content will help you to understand home design in detail.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="my-5">
            <h2 className="text-center my-3">Architects Featured on Veedu</h2>
            <Slider {...settings}>
                {architectName && architectName.map((item: any) => (
                    <div className="px-2" key={item.proUserFirstName}>
                        <div className="pro_user_card card">
                            <img className="pro_user_image" src={item.architectImageURL || avatar} alt="Card image cap" height={400}  />
                            <div className="set d-flex justify-content-center">
                                <div className="text-center d-flex flex-column " >
                                    <p className="fsize" >_</p>
                                    <h6 className="text-center">{item.proUserFirstName} {item.proUserLastName}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                }
            </Slider>
        </div>

        <div className="my-5">
            <h2 className="text-center my-3">Our viewers <span className="heart-icon">❤️</span> us</h2>
            <Slider {...settings}>
                {aboutUsViewer && aboutUsViewer.map((item: any) => (
                    <div className="px-2" key={item.name}>
                        <div className="card card-weight p-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="d-flex mb-2">
                                        <img
                                            className="profile-image"
                                            src={item.viewerImageURL || avatar}
                                            alt="Profile"
                                        />
                                        <div className="ms-3">
                                            <div className="font">{item.userFirstName} {item.userLastName}</div>
                                            {item.socialMedia && item.socialMedia.toLowerCase() === 'twitter' && (
                                                <label>@
                                                    <span className="text-primary">
                                                        <TwitterIcon />
                                                    </span>
                                                    {item.socialMedia}
                                                </label>
                                            )}
                                            {item.socialMedia && item.socialMedia.toLowerCase() === 'facebook' && (
                                                <label>@
                                                    <span className="text-primary">
                                                        <FacebookIcon />
                                                    </span>
                                                    {item.socialMedia}
                                                </label>
                                            )}
                                            {item.socialMedia && item.socialMedia.toLowerCase() === 'youtube' && (
                                                <label>@
                                                    <span className="text-danger">
                                                        <YouTubeIcon />
                                                    </span>
                                                    {item.socialMedia}
                                                </label>
                                            )}
                                            {item.socialMedia && item.socialMedia.toLowerCase() === 'linkedin' && (
                                                <label>@
                                                    <span className="text-primary">
                                                        <LinkedInIcon />
                                                    </span>
                                                    {item.socialMedia}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <p>{item.description || "❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>

        <div className=" ">
            <h2 className="text-center">Lean team @ work</h2>
            {employeeName && employeeName.map((item: any) => (
                <div className="container left p-2 employee-container mt-5  mx-auto " >
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-4">
                            <div className="mx-2">
                                <img className="card-img" width="500px" height="220px" src={item.employeeImageURL} />
                                <div className="name-container">
                                    <div className="name">{item.userFirstName} {item.userLastName}</div>
                                    <div>{item.employeePosition}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8 d-flex justify-content-center">
                            <div className="employee-description-container">
                                <div>{item.description}</div>
                                <div className="linear-line"></div>
                                <div className="employee-status">
                                    {item.employeeStatus && item.employeeStatus.includes(',') ? (
                                        item.employeeStatus.split(',').map((status, index) => (
                                            <div className="solid-box" key={index}>{status}</div>
                                        ))
                                    ) : (
                                        <div className="solid-box">{item.employeeStatus}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </>
}
export default AboutPage;